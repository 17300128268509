import { Box, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {updateCustomTotalWorkExperience} from 'api/users'
import { debounce } from 'lodash';

const TotalWorkExperienceDisplay = ({isResume, handleResumeProfile, workExperienceData, candidateData}) => {
  const [experience, setExperience] = useState(candidateData?.custom_total_experience || 0.0);
  const [ debouncer , setDebouncer] = useState("");

useEffect (()=>{
  const timer = setTimeout(()=>{
    setDebouncer(experience);
  },500);
  return () => clearTimeout(timer);
},[experience]);
 
useEffect (()=>{
  if(debouncer!==""){
    const updateExperience = async () => {
      try {
        if (isResume) {
          handleResumeProfile({ custom_total_experience: debouncer });
          return;
        }
        await updateCustomTotalWorkExperience({ totalWorkExperience: debouncer });
        console.log("Updated successfully:", debouncer);
      } catch (error) {
        console.error("Error updating experience:", error);
      }
    };
    updateExperience();
  }
},[debouncer]);

useEffect(() => {
  if (workExperienceData && workExperienceData.length > 0) {
    let total = 0.0;
    workExperienceData.forEach((item) => {
      if (item?.present) {
        const fromDate = new Date(item?.work_history_from);
        const currentDate = new Date();
        const exp = ((currentDate - fromDate) / (1000 * 60 * 60 * 24 * 30.44)) / 12;
        total += exp;
      } else {
        total += item?.work_experience || 0;
      }
    });

    total = parseFloat(total.toFixed(1));

    console.log("Total Experience:", total);
    setExperience(total);
  }
}, [workExperienceData]);


  const handleChange = (e) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) { // ✅ Allows only numbers with optional decimal point
      setExperience(value);
    }

  };

  return (
    <Box className="total-work-exp">
      <Stack direction={{ xs: 'column', md: 'row' }} alignItems="center" spacing={1}>
        <Box className="count-years">
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <input
              type="text"
              value={experience}
              onChange={handleChange}
              inputMode="decimal"
              readOnly={candidateData?.master_resume_id ? true : false}
              pattern="[0-9]*[.]?[0-9]*"
              style={{
                width: '50px',
                textAlign: 'center', 
                fontSize: '16px',
                background: 'transparent', 
                border: 'none',
                outline: 'none', 
                padding: 0 ,
                color: '#2B6CA3',
                fontWeight: 'bold',
              }}
            />
            <Typography className="year-txt">Years</Typography>
          </Stack>
        </Box>
        <Typography className="info-txt">
          is your total work experience (please update if needed)
        </Typography>
      </Stack>
    </Box>
  );
};

export default TotalWorkExperienceDisplay;

import React from 'react';
import { Box, Divider, Stack, SvgIcon, Typography } from '@mui/material';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { useAuth } from 'hooks/useAuthContext';

const Logo = `${process.env.REACT_APP_HOMEPAGE}assets/TJ-Logo-Black 1.svg`;

const MobileSidebar = ({ onClose }) => {

    const navigate = useNavigate();
    const { jobApply, setJobApply } = useAuth();

    const handleJobApplication = (tab) => {
        navigate('/job-application');
        setJobApply({ tab: tab });
        onClose(false);
    };

    return (
        <Box className="mobile-sidebar">
            <Box className="menu-header">
                <Stack direction="row" alignItems={'center'} justifyContent={'space-between'}>
                    <Box
                        component="img"
                        alt="Talent Junction"
                        src={Logo}
                    />
                    <SvgIcon sx={{ width: '16px', height: '16px' }} onClick={() => onClose(false)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M5.1898 4.20414C4.91762 3.93195 4.47632 3.93195 4.20414 4.20414C3.93195 4.47632 3.93195 4.91762 4.20414 5.1898L9.01434 10L4.20414 14.8102C3.93195 15.0824 3.93195 15.5237 4.20414 15.7959C4.47632 16.068 4.91762 16.068 5.1898 15.7959L10 10.9857L14.8102 15.7959C15.0824 16.068 15.5237 16.068 15.7959 15.7959C16.068 15.5237 16.068 15.0824 15.7959 14.8102L10.9857 10L15.7959 5.1898C16.068 4.91762 16.068 4.47632 15.7959 4.20414C15.5237 3.93195 15.0824 3.93195 14.8102 4.20414L10 9.01434L5.1898 4.20414Z" fill="white" />
                        </svg>
                    </SvgIcon>
                </Stack>
            </Box>
            <Stack spacing={2.5} sx={{ width: '100%' }}>
                <Stack direction="row" justifyContent={'space-between'} onClick={() => handleJobApplication('1')}>
                    <Typography className="">Dashboard</Typography>
                    <SvgIcon sx={{ width: '16px', height: '16px' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M5.9668 2.71734L10.3135 7.06401C10.8268 7.57734 10.8268 8.41734 10.3135 8.93068L5.9668 13.2773" stroke="white" stroke-width="1.33333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </SvgIcon>
                </Stack>
                <Divider />
                <Stack direction="row" justifyContent={'space-between'} onClick={() => handleJobApplication('2')}>
                    <Typography className="">Jobs Market</Typography>
                    <SvgIcon sx={{ width: '16px', height: '16px' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M5.9668 2.71734L10.3135 7.06401C10.8268 7.57734 10.8268 8.41734 10.3135 8.93068L5.9668 13.2773" stroke="white" stroke-width="1.33333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </SvgIcon>
                </Stack>
                <Divider />
                <Stack direction="row" justifyContent={'space-between'} onClick={() => handleJobApplication('3')}>
                    <Typography className="">My Jobs</Typography>
                    <SvgIcon sx={{ width: '16px', height: '16px' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M5.9668 2.71734L10.3135 7.06401C10.8268 7.57734 10.8268 8.41734 10.3135 8.93068L5.9668 13.2773" stroke="white" stroke-width="1.33333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </SvgIcon>
                </Stack>
                <Divider />
                <Stack direction="row" justifyContent={'space-between'} onClick={() => handleJobApplication('4')}>
                    <Typography className="">My Resumes</Typography>
                    <SvgIcon sx={{ width: '16px', height: '16px' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M5.9668 2.71734L10.3135 7.06401C10.8268 7.57734 10.8268 8.41734 10.3135 8.93068L5.9668 13.2773" stroke="white" stroke-width="1.33333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </SvgIcon>
                </Stack>
            </Stack>
        </Box>
    )
}

export default MobileSidebar

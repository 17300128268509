import React, { useState, useEffect } from 'react';
import { getTimeDifferenceString } from 'utils/Utils';
import { Box, Button, Icon, Card, CardContent, CircularProgress, SvgIcon, Dialog, FormControl, InputLabel, Stack, Grid, Typography, Drawer, List, ListItem, ListItemButton, ListItemText, Popover, useMediaQuery } from '@mui/material';
const backIcon = `${process.env.REACT_APP_HOMEPAGE}assets/arrow_back.svg`;
import MyResumeDetails from './MyResumeDetails';
import { getResumesData } from 'api/users';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'components/snackbar';
import { SplashScreen } from 'components/loading-screen';
import { useTheme } from '@mui/material/styles';
import LeaveWithoutCreate from './LeaveWithoutCreate';
const MyResumes = ({ jobData, tabForRedirectBack, redirectToExternalJob, resumeId }) => {
  const [selectedCard, setSelectedCard] = useState('');
  const [resumeData, setResumeData] = useState([]);
  const [openDetailsDrawer, setOpenDetailsDrawer] = React.useState(jobData ? true : false);
  const [selectedResume, setSelectedResume] = useState(null);
  // const [createNewResume, setCreateNewResume] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const fetchData = async () => {
    try {
      const response = await getResumesData();
      if (response?.success && response.data && response.data.length > 0) {
        let resumeWithLink = response.data.find(item => item.link);
        let selectedResume = resumeWithLink || response.data[0];

        setSelectedCard(selectedResume._id);

        const updatedResume = {
          ...selectedResume
        };

        // Place the resume with a link at the top of the list
        const sortedResumes = [
          updatedResume,
          ...response.data.filter(item => item._id !== updatedResume._id)
        ];

        setResumeData(sortedResumes);
        setSelectedResume(updatedResume);
        return sortedResumes;
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      enqueueSnackbar('Failed to fetch data. Please try again later.', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchDataAsync = async () => {
      const allResumes = await fetchData();
      if (resumeId) {
        setSelectedCard(resumeId);
        // console.log(allResumes, resumeId);
        // console.log(allResumes?.find(item => item._id === resumeId));
        setSelectedResume(allResumes?.find(item => item._id === resumeId) || null);
      }
    };
    fetchDataAsync();
  }, []);

  // custom section onclick event
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isActive, setActive] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setActive(true);
  };

  const handleClicked = () => {
    handleNavigation();
  }
  const handleClose = () => {
    setAnchorEl(null);
    setActive(false);
  };

  const [withoutUpdateDialog, setLeaveWihoutUpdatingDialog] = React.useState(false);
  const handleOpenLeaveWihoutUpdatingDialog = () => {
    setLeaveWihoutUpdatingDialog(true);
  };
  const handleCloseLeaveWihoutUpdatingDialog = () => {
    setLeaveWihoutUpdatingDialog(false);
  };
  const openCS = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleOpenDrawer = (newOpen) => () => {
    setOpenDetailsDrawer(newOpen);
  };
  const handleCardClick = (resume) => {
    setSelectedCard(resume._id);
    setSelectedResume(resume);
    setOpenDetailsDrawer(true);
  };
  const handleResumeDataChange = async () => {
    // setSelectedResume(null);
    // setSelectedCard(resumeData.original_id);
    await fetchData();
  };

  const bankInIcon = (
    <Icon className="extLoginIcon">
      <img alt="edit" width="24px" height="24px" src={backIcon} />
    </Icon>
  );

  const handleNavigation = () => {
    navigate('/create-update-resume', { state: { totalResume: resumeData.length, createNewResume: true } });
  };

  const handleUploadResume = () => {
    navigate('/create-update-resume', { state: { totalResume: resumeData.length, createNewResume: true, uploadResume: true } });
  }
  const handleRedirectToExternalJob = (applicationData, tab) => {
    if (applicationData && tab) {
      redirectToExternalJob(applicationData, tab);
    }
  };

  if (loading) {
    return (
      <Grid container justifyContent="center" sx={{ position: 'relative', overflow: 'hidden', width: '100%', height: 'calc(100vh - 10vh)' }} alignItems="center">
        <SplashScreen />
      </Grid>
    );
  }

  return (
    <Box sx={{
      position: 'relative',
      overflow: 'hidden',
      width: '100%',
      height: 'calc(100vh - 100px)'
    }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} >
          <Box className="resume-type-container">
            <Box className="resume-type-header" >
              <Typography className="title">My Resumes</Typography>
            </Box>
            <Box className="resume-type-content" sx={{ textAlign: 'center' }}>

              <Box
                sx={{
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  height: 'auto'
                }}>
                {resumeData?.map((resume) => (
                  <Card
                    className={`resume-type-card master-resume-card ${selectedCard === resume._id ? ' selected-card' : ''
                      }`}
                    variant="outlined"
                    onClick={() => handleCardClick(resume)}
                    sx={{ marginBottom: '1rem' }}
                    key={resume?._id || index}
                  >
                    <CardContent>
                      {resume.IsMostRelevent && (
                        <Typography variant="p" className="suggestion-tag">
                          Most Relevant For This Job
                        </Typography>
                      )}
                      <Box>
                        <Stack direction="row" alignItems={'center'} justifyContent={'space-between'}>
                          <Typography className="card-title">{resume?.link ? 'Uploaded Resume' : resume?.title}</Typography>
                          <SvgIcon sx={{ display: { xs: 'block', md: 'none', lg: 'none' } }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                              <path d="M7.45837 3.39961L12.8917 8.83294C13.5334 9.47461 13.5334 10.5246 12.8917 11.1663L7.45837 16.5996" stroke="#6B7280" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          </SvgIcon>
                        </Stack>
                        <Typography className="last-updated">{resume?.updatedAt ? getTimeDifferenceString(resume?.updatedAt, "Updated") : 'N/A'}</Typography>
                      </Box>
                    </CardContent>
                  </Card>
                ))}
                {resumeData.some(resume => resume.link) ? (
                  <Button variant="outlined" size="large" disabled={resumeData.length >= 5} sx={{ border: 'none!important' }} className="create-new-resume-btn" onClick={() => { handleOpenLeaveWihoutUpdatingDialog(); setAnchorEl(null); }} >
                    + Create TJ Resume
                  </Button>
                ) : (
                  <Box className="add-resume-dp-container">
                    <Button mb={2} className={isActive ? 'active add-resume-dropdown-btn' : 'add-resume-dropdown-btn'} variant="text" endIcon={
                      <SvgIcon sx={{ width: '20px', height: '20px' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M19.9181 8.94995L13.3981 15.47C12.6281 16.24 11.3681 16.24 10.5981 15.47L4.07812 8.94995" stroke={resumeData?.length >= 5 ? "lightgray" : "#1575C6"} stroke-width="1.8" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M19.9181 15.05L13.3981 8.53005C12.6281 7.76005 11.3681 7.76005 10.5981 8.53005L4.07812 15.05" stroke="#1575C6" stroke-width="1.8" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg> */}
                      </SvgIcon>
                    } aria-describedby={id} onClick={handleClick} disableRipple disableElevation disabled={resumeData?.length >= 5}>Add New Resume</Button>

                    <Popover
                      id={id}
                      className="add-resume-dropdown-popover"
                      open={openCS}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                      <Box className="custom-sec-list">
                        <List >
                          <ListItem disablePadding>
                            <ListItemButton onClick={() => { handleOpenLeaveWihoutUpdatingDialog(); setAnchorEl(null); }} disabled={resumeData.length >= 5} >
                              <ListItemText sx={{ margin: 0 }} primary="Create TJ resume" className="create-new-resume-btn" />
                            </ListItemButton>
                          </ListItem>
                          <ListItem disablePadding>
                            <ListItemButton onClick={() => { handleUploadResume(); }}>
                              <ListItemText sx={{ margin: 0 }} primary="Upload existing resume" />
                            </ListItemButton>
                          </ListItem>
                        </List>
                      </Box>
                    </Popover>
                  </Box>
                )}
                <Typography sx={{ marginTop: '1rem' }} className="total-resume-text">
                  {resumeData.length < 5 ? `${5 - resumeData.length} more resumes can be created` : "A maximum of 5 resumes can be added.  Please delete one of the above resumes to add new."}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={8}>
          <Grid item xs={12} md={12} sx={{ display: { xs: 'none', md: 'block' } }}>
            {resumeData && <MyResumeDetails
              data={selectedResume}
              totalResume={resumeData?.length}
              onDataChange={handleResumeDataChange}
              jobData={jobData}
              redirectedToExternalJob={handleRedirectToExternalJob}
            />}
          </Grid>
        </Grid>
      </Grid >
      {
        isMobile &&
        <Drawer className="mob-side-panel" open={openDetailsDrawer} anchor={'right'} onClose={handleOpenDrawer(false)}>
          <MyResumeDetails data={selectedResume} totalResume={resumeData?.length} onClose={handleOpenDrawer(false)} onDataChange={handleResumeDataChange} jobData={jobData} redirectedToExternalJob={handleRedirectToExternalJob} />
        </Drawer>
      }
      {isMobile ?
        <Drawer
          anchor="bottom"
          open={withoutUpdateDialog}
          onClose={handleCloseLeaveWihoutUpdatingDialog}
          className="forgot-password-dialog career-breaks-dialog"
        >
          <LeaveWithoutCreate onClose={handleCloseLeaveWihoutUpdatingDialog} handleClicked={handleClicked} />
        </Drawer> :
        <Dialog
          open={withoutUpdateDialog}
          onClose={handleCloseLeaveWihoutUpdatingDialog}
          className="action-dialog-card"
        >
          <LeaveWithoutCreate onClose={handleCloseLeaveWihoutUpdatingDialog} handleClicked={handleClicked} />
        </Dialog>}
    </Box >
  );
};

export default MyResumes;

import React from 'react'
import { Box, Button, Divider, Stack, ToggleButton, ToggleButtonGroup, Typography, Dialog, Grid, useMediaQuery, Drawer } from '@mui/material'
import Header from '../header/Header'
import UploadProfile from '../../components/basic-profile/UploadProfile';
import PersonalDetails from '../../components/basic-profile/PersonalDetails';
import ContactDetails from '../../components/basic-profile/ContactDetails';
import EducationDetails from '../../components/basic-profile/EducationSection';
import WorkExperienceDetails from '../../components/basic-profile/WorkExperienceSection';
import FinanceTechnicalCompetencies from '../../components/basic-profile/FinanceTechnicalCompetencies';
import TotalWorkExperience from '../../components/basic-profile/WorkExperienceSection';
import CertificationSection from '../../components/basic-profile/CertificationSection';
import ProfessionalSummary from '../../components/basic-profile/ProfessionalSummary';
import CustomSection from '../../components/basic-profile/CustomSection';
import CareerBreaksIdentifiedDialog from '../../components/basic-profile/CareerBreaksIdentifiedDialog';
import UploadExistingProfile from '../../components/basic-profile/UploadExistingProfile';
import TotalWorkExperienceDisplay from '../../components/basic-profile/TotalWorkExperienceDisplay'
import CandidateResume from '../../components/basic-profile/CandidateResume';
import { useAuth } from 'hooks/useAuthContext';
import { useState, useRef, useEffect } from 'react';
import { getBasicProfileDetails, getAllEducationDetails, getAllCertificationDetails, updateUser } from 'api/users';
import { useSnackbar } from 'components/snackbar';
import { useLocation, useNavigate } from 'react-router-dom';
import { SplashScreen } from 'components/loading-screen';
import { useTheme } from '@mui/material/styles';
import LeaveWithoutUpdatingDialog from 'components/basic-profile/LeaveWithoutUpdatingDialog';

const JobApplication = () => {

    // jobs type toggle buttons
    const location = useLocation();
    const theme = useTheme();
    const [profile, setProfile] = useState();
    const [educationData, setEducationData] = useState([]);
    const [workExperienceData, setWorkHistory] = useState();
    const personalDetailRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [alignment, setAlignment] = useState('using-existing-resume');
    const [profilephoto, setProfilePhoto] = useState([]);
    const [contactDetailsError, setContactDetailsError] = useState();
    const [expandedSection, setExpandedSection] = useState({ section: null, index: null }); const { enqueueSnackbar } = useSnackbar();
    const [certificateData, setCertificate] = useState();
    const [financeTechologiesData, setfinanceTechologiesData] = useState([]);
    const [summaryData, setSummary] = useState();
    const [templateType, setTemplateType] = useState('');
    const [customSection, setCustomSection] = useState([]);
    const [loading, setLoading] = useState(true);
    const [flag, setFlag] = useState(true);
    const [nextPage, setNextPage] = useState(false);
    const { user, updateContext } = useAuth();
    const navigate = useNavigate();
    const [workExperienceGap, setGap] = useState([]);
    const { uploadResume, createNewResume } = location.state || { uploadResume: false, createNewResume: false };
    const [withoutUpdateDialog, setLeaveWihoutUpdatingDialog] = useState(false);
    const [loads, setloads] = useState(false);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [resume, setResume] = useState('');

    const handleProfilePhotoChange = (resumeLink) => {
        if (resumeLink) {
            setResume(resumeLink);
        }
        fetchData();
    };
    const handleToggleChange = (event, newAlignment) => {
        if (newAlignment !== null) {
            setAlignment(newAlignment);
        }
    };


    const fetchData = async () => {
        try {
            setUpdating(true);
            const [profileResponse, educationAndWorkResponse, certificateResponse] = await Promise.all([
                getBasicProfileDetails(),
                getAllEducationDetails(),
                getAllCertificationDetails(),
            ]);
            if (profileResponse?.success && profileResponse.data) {
                const profileData = profileResponse.data.candidate ?? {};
                // const prevPath = localStorage.getItem('prevPath');
                // if (prevPath === '/login' && profileData?.stages === 3) {
                //   localStorage.removeItem('prevPath');
                //   navigate('/job-application');
                // }
                setProfile(profileResponse.data);
                setSummary(profileData.summary || '');
                if (profileData.custom_section) {
                    setCustomSection(profileData.custom_section);
                }
                if (profileData.template) {
                    setTemplateType(profileData.template);
                }
                if (profileData.finance_technologies) {
                    setfinanceTechologiesData(profileData.finance_technologies);
                }
            }
            if (educationAndWorkResponse?.success && educationAndWorkResponse.data) {
                const eduUnsavedData = localStorage.getItem("education") ? JSON.parse(localStorage.getItem("education")) : null;
                const workUnsavedData = localStorage.getItem('workExperience') ? JSON.parse(localStorage.getItem('workExperience')) : null;
                setEducationData([...educationAndWorkResponse.data?.educationList || [], ...(eduUnsavedData && !checkLocalDataIsEmpty(eduUnsavedData) ? [{ ...eduUnsavedData }] : [])]);
                setWorkHistory([...educationAndWorkResponse.data?.workHistoryList || [], ...(workUnsavedData && !checkLocalDataIsEmpty(workUnsavedData) ? [{ ...workUnsavedData }] : [])]);
            }
            if (certificateResponse?.success && certificateResponse.data) {
                const certificateUnsavedData = localStorage.getItem("certificate") ? JSON.parse(localStorage.getItem("certificate")) : null;
                setCertificate([...certificateResponse.data || [], ...(certificateUnsavedData && !checkLocalDataIsEmpty(certificateUnsavedData) ? [{ ...certificateUnsavedData }] : [])]);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setUpdating(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        // console.log(uploadResume, createNewResume);
        fetchData();
    }, []);

    const handleEducationDataChange = () => {
        setExpandedSection({ section: null, index: null });
        fetchData();
    };

    const handleWorkExperienceDataChange = () => {
        setExpandedSection({ section: null, index: null });
        fetchData();
    };

    const handleFinanceDataChange = () => {
        fetchData();
    };
    const handleCertificateDataChange = () => {
        setExpandedSection({ section: null, index: null });
        fetchData();
    };
    const handleSummaryDataChange = (data) => {
        setSummary(data || '');
    };
    const handleCustomSectionDataChange = async () => {
        setFlag(false);
        fetchData();
    };
    const handleExpand = (section, index) => {
        setExpandedSection((prev) => {
            if (!(prev.section === section && prev.index === index)) {
                if (prev?.section === "education") {
                    const eduUnsavedData = localStorage.getItem("education") ? JSON.parse(localStorage.getItem("education")) : null;
                    setEducationData([...educationData?.filter(edu => edu?._id), ...(eduUnsavedData && !checkLocalDataIsEmpty(eduUnsavedData) ? [{ ...eduUnsavedData }] : [])])
                }
                if (prev?.section === "certificate") {
                    const eduUnsavedData = localStorage.getItem("certificate") ? JSON.parse(localStorage.getItem("certificate")) : null;
                    setCertificate([...certificateData?.filter(edu => edu?._id), ...(eduUnsavedData && !checkLocalDataIsEmpty(eduUnsavedData) ? [{ ...eduUnsavedData }] : [])])
                }
                if (prev?.section === "work") {
                    const eduUnsavedData = localStorage.getItem("workExperience") ? JSON.parse(localStorage.getItem("workExperience")) : null;
                    setWorkHistory([...workExperienceData?.filter(edu => edu?._id), ...(eduUnsavedData && !checkLocalDataIsEmpty(eduUnsavedData) ? [{ ...eduUnsavedData }] : [])])
                }
                return { section, index }
            } else {
                return { section: null, index: null }
            }
        });
    };

    const gapWorkExperience = (workExperienceList, educationData, certificateData) => {

        let gaps = [];
        const calculate = (startDate, endDate) => {
            const timeDifference = startDate - endDate;
            const differenceInHours = Math.floor(timeDifference / (1000 * 60 * 60));
            const differenceInDays = Math.floor(differenceInHours / 24);
            // console.log(differenceInDays);
            return differenceInDays;
        };

        const checkEndDate = (index, startDate, gapDays, isupdated = false) => {
            let AddGap = {};
            for (let i = index + 1; i < workExperienceList.length; i++) {
                const endDate = new Date(workExperienceList[i].work_history_to);
                let gap = calculate(startDate, endDate);
                if (gap <= gapDays) {
                    gapDays = gap;
                    if (gap >= 90) {
                        const gapStartDate = new Date(startDate);
                        const gapEndDate = new Date(endDate);

                        gapStartDate.setDate(gapStartDate.getDate() - 1);
                        gapEndDate.setDate(gapEndDate.getDate() + 1);
                        AddGap = {
                            endDate: gapStartDate,
                            startDate: gapEndDate,
                        };
                        return { AddGap, isupdated: true };
                    } else {
                        isupdated = true;
                    }
                }
            }
            return { AddGap: {}, isupdated: isupdated };
        }

        if (workExperienceList?.length > 0 && !workExperienceList[0]?.present) {
            const startDate = new Date();
            const endDate = new Date(workExperienceList[0].work_history_to);
            if (calculate(startDate, endDate) >= 90) {
                const gapStartDate = new Date(startDate);
                const gapEndDate = new Date(endDate);

                gapEndDate.setDate(gapEndDate.getDate() + 1);

                gaps.push({
                    endDate: gapStartDate,
                    startDate: gapEndDate,
                });

            }
        }

        if (workExperienceList.length > 1) {
            for (let i = 1; i < workExperienceList.length; i++) {
                const startDate = new Date(workExperienceList[i - 1].work_history_from);
                const endDate = new Date(workExperienceList[i].work_history_to);
                const gapDays = calculate(startDate, endDate);
                let { AddGap, isupdated } = checkEndDate(i, startDate, gapDays);
                if (AddGap?.endDate && AddGap?.startDate) gaps.push(AddGap);
                if (gapDays >= 90 && !isupdated) {
                    const gapStartDate = new Date(startDate);
                    const gapEndDate = new Date(endDate);

                    gapStartDate.setDate(gapStartDate.getDate() - 1);
                    gapEndDate.setDate(gapEndDate.getDate() + 1);

                    gaps.push({
                        endDate: gapStartDate,
                        startDate: gapEndDate,
                    });
                }
            }
        }

        let result
        if (gaps && gaps?.length > 0 && (educationData && educationData?.length > 0 || certificateData && certificateData?.length > 0)) {
            result = adjustGapsForPeriods(gaps, educationData, certificateData);
            gaps = result;
        }
        setGap(gaps);
        return gaps;
    };
    const adjustGapsForPeriods = (gaps, educationData, certificateData) => {
        const combinedData = [...educationData, ...certificateData];
        const filteredData = combinedData.filter(item =>
            item.learning_format === "Full time - offline" || item.learning_format === "Full time - online"
        );

        const periods = filteredData.map(item => ({
            from: new Date(item.education_from),
            to: new Date(item.education_to)
        }));

        const adjustedGaps = [];

        for (const gap of gaps) {
            let gapStart = new Date(gap.startDate);
            let gapEnd = new Date(gap.endDate);

            gapStart.setMonth(gapStart.getMonth());
            gapStart.setDate(1);
            gapEnd = new Date(gapEnd.getFullYear(), gapEnd.getMonth() + 1, 0);

            let hasOverlap = false;

            for (const period of periods) {
                const periodStart = new Date(period.from);
                const periodEnd = new Date(period.to);

                if (gapStart <= periodEnd && gapEnd >= periodStart) {
                    hasOverlap = true;

                    if (gapStart < periodStart) {
                        const beforeGapEnd = new Date(periodStart);
                        beforeGapEnd.setDate(beforeGapEnd.getDate() - 1);
                        const beforeGapDuration = (beforeGapEnd - gapStart) / (1000 * 60 * 60 * 24);
                        if (beforeGapDuration > 90) {
                            adjustedGaps.push({
                                startDate: new Date(gapStart),
                                endDate: new Date(beforeGapEnd)
                            });
                        }
                    }

                    if (gapEnd > periodEnd) {
                        const afterGapStart = new Date(periodEnd);
                        afterGapStart.setMonth(afterGapStart.getMonth() + 1);
                        afterGapStart.setDate(1);
                        const afterGapDuration = (gapEnd - afterGapStart) / (1000 * 60 * 60 * 24);
                        if (afterGapDuration > 90) {
                            adjustedGaps.push({
                                startDate: new Date(afterGapStart),
                                endDate: new Date(gapEnd)
                            });
                        }
                    }

                    break;
                }
            }

            if (!hasOverlap) {
                const gapDuration = (gapEnd - gapStart) / (1000 * 60 * 60 * 24);
                if (gapDuration > 88) {
                    adjustedGaps.push({
                        startDate: new Date(gapStart),
                        endDate: new Date(gapEnd)
                    });
                }
            }
        }

        return adjustedGaps;
    };
    const handleClose = () => {
        setOpen(false);
    };
    const validateAndProceed = async () => {
        try {
            setloads(true);
            const isPersonalDetailValid = await personalDetailRef.current.triggerValidation();
            if (!user?.phone) {
                setContactDetailsError(true);
            } else {
                setContactDetailsError(false);
            }
            if (alignment === "using-existing-resume" && !resume) {
                enqueueSnackbar(`Please upload resume.`, { variant: 'error' });
                return;
            }
            if (user && isPersonalDetailValid && user?.phone && user?.email && educationData && educationData?.length > 0 && workExperienceData && workExperienceData?.length > 0 && financeTechologiesData && financeTechologiesData?.length >0) {
                const gap = gapWorkExperience(workExperienceData?.filter(item => item?._id), educationData, certificateData);
                if (gap && gap.length > 0) {
                    setOpen(true);
                } else {
                    if (user?.stages <= 3) {
                        setNextPage(true);
                        try {
                            const response = await updateUser();
                            if (response && response?.data) {
                                updateContext({ stages: response?.data?.stages });
                                updateContext({ stages: 3 });
                            }
                        } catch (error) {
                            console.error(error);
                        } finally {
                            setNextPage(false);
                        }
                    }
                    { (uploadResume || createNewResume) ? navigate('/job-application', { state: { tab: '4', data: null } }) : navigate('/job-preferences') }
                }
            } else {
                const personalInfo = isPersonalDetailValid ? '' : "personal details";
                const contactInfo = user?.phone && user?.email ? '' : "contact details";
                const education = educationData && educationData?.length > 0 ? '' : "education";
                const workHistory = workExperienceData && workExperienceData?.length > 0 ? '' : "work history";
                const finance_technologies = financeTechologiesData && financeTechologiesData?.length>0? '' : "finance technologies"
                const missingFields = [
                    personalInfo,
                    contactInfo,
                    education,
                    workHistory,
                    finance_technologies
                ].filter(field => field !== '');
                if (missingFields?.length > 0) {
                    const missingFieldsString = missingFields?.join(', ')?.replace(/,([^,]*)$/, ' and$1');
                    enqueueSnackbar(`Please fill out ${missingFieldsString}.`, { variant: 'error' });
                }
            }
        } catch (error) {
            console.error(error);
        } finally {
            setloads(false);
        }
    };
    const handleCancelEducation = () => {
        setExpandedSection((prev) => {
            if (prev?.section === "education") {
                const eduUnsavedData = localStorage.getItem("education") ? JSON.parse(localStorage.getItem("education")) : null;
                setEducationData([...educationData?.filter(edu => edu?._id), ...(eduUnsavedData && !checkLocalDataIsEmpty(eduUnsavedData) ? [{ ...eduUnsavedData }] : [])])
            }
            if (prev?.section === "certificate") {
                const eduUnsavedData = localStorage.getItem("certificate") ? JSON.parse(localStorage.getItem("certificate")) : null;
                setCertificate([...certificateData?.filter(edu => edu?._id), ...(eduUnsavedData && !checkLocalDataIsEmpty(eduUnsavedData) ? [{ ...eduUnsavedData }] : [])])
            }
            if (prev?.section === "work") {
                const eduUnsavedData = localStorage.getItem("workExperience") ? JSON.parse(localStorage.getItem("workExperience")) : null;
                setWorkHistory([...workExperienceData?.filter(edu => edu?._id), ...(eduUnsavedData && !checkLocalDataIsEmpty(eduUnsavedData) ? [{ ...eduUnsavedData }] : [])])
            }
            return { section: null, index: null }
        });
    };

    const scrollToChild = (targetId) => {
        if (scrollableContainerRef.current) {
            const targetElement = document.getElementById(targetId);
            if (targetElement) {
                targetElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });
            }
        }
    };

    const handleOpenLeaveWihoutUpdatingDialog = () => {
        setLeaveWihoutUpdatingDialog(true);
    };
    const handleCloseLeaveWihoutUpdatingDialog = () => {
        setLeaveWihoutUpdatingDialog(false);
    };

    if (loading) {
        return (
            <Grid container justifyContent="center" alignItems="center">
                <SplashScreen />
            </Grid>
        );
    }

    return (
        <Box className="registration-upload-page">
            <Grid container>
                {/* Left Section with Form */}
                <Grid item xs={12} md={alignment === "using-existing-resume" ? 12 : 7} lg={alignment === "using-existing-resume" ? 13 : 8}>
                    <Box className="regi-upload-page-content">
                        <Box mb={3}>
                            <Box mb={3}>
                                <Typography className="step-heading" variant="h6">{uploadResume ? 'Update ' : 'Create '} Your Profile</Typography>
                                <Typography className="step-info" variant="body2">
                                    {uploadResume ? 'Please update relevant fields here' : 'Provide the following details to make sure we bring the best jobs for you'}
                                </Typography>
                            </Box>
                            {!uploadResume && <Box className="resume-type-btn-group">
                                <ToggleButtonGroup
                                    className="resume-type-toggle-btn"
                                    color="primary"
                                    value={alignment}
                                    exclusive
                                    onChange={handleToggleChange}
                                    aria-label="Platform"
                                    sx={{ gap: '16px' }}
                                >
                                    <ToggleButton value="using-existing-resume" disableRipple>Using Existing Resume</ToggleButton>
                                    <ToggleButton value="talent-juntion-style" disableRipple>Talent Junction Style</ToggleButton>
                                </ToggleButtonGroup>
                            </Box>}
                        </Box>
                        <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', margin: '1.5rem 0' }} />

                        {alignment === "talent-juntion-style" && (
                            <UploadProfile profilephoto={profilephoto} setUpdating={setUpdating} onProfilePhotoChange={handleProfilePhotoChange} />
                        )}
                        {alignment === "using-existing-resume" && (
                            <UploadExistingProfile onProfilePhotoChange={handleProfilePhotoChange} setResume={setResume} />
                        )}

                        <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', margin: '1.5rem 0' }} />
                        <PersonalDetails profileData={profile} ref={personalDetailRef} setUpdating={setUpdating} hideBox={true} alignment={alignment} />
                        <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', margin: '1.5rem 0' }} />
                        <ContactDetails contactDetailsError={contactDetailsError} setUpdating={setUpdating} />
                        <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', margin: '1.5rem 0' }} />
                        <EducationDetails scrollToChild={scrollToChild} educationData={educationData} setUpdating={setUpdating} onCancel={handleCancelEducation} onEducationDataChange={handleEducationDataChange} expandedSection={expandedSection} handleExpand={handleExpand} certificateData={certificateData} workExperienceData={workExperienceData} uploadResume={alignment === "using-existing-resume" ? true : false}/>
                        <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', margin: '1.5rem 0' }} />
                        <WorkExperienceDetails scrollToChild={scrollToChild} setUpdating={setUpdating} workExperienceData={workExperienceData} onCancel={handleCancelEducation} onWorkExperienceDataChange={handleWorkExperienceDataChange} expandedSection={expandedSection} handleExpand={handleExpand} certificateData={certificateData} educationData={educationData} uploadResume={alignment === "using-existing-resume" ? true : false}/>
                        <TotalWorkExperienceDisplay workExperienceData={workExperienceData}  candidateData={profile?.candidate}/>
                        <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', margin: '1.5rem 0' }} />
                        <FinanceTechnicalCompetencies financeTechologiesData={financeTechologiesData} setUpdating={setUpdating} onFinanceDataChange={handleFinanceDataChange} />
                        <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', margin: '1.5rem 0' }} />

                        {alignment === "talent-juntion-style" && (
                            <>
                                <CertificationSection scrollToChild={scrollToChild} setUpdating={setUpdating} certificateData={certificateData} onCancel={handleCancelEducation} onCertificateDataChange={handleCertificateDataChange} expandedSection={expandedSection} handleExpand={handleExpand} educationData={educationData} workExperienceData={workExperienceData} />
                                <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', margin: '1.5rem 0' }} />

                                <ProfessionalSummary summaryData={summaryData} setUpdating={setUpdating} onSummaryDataChange={handleSummaryDataChange} />
                                <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', margin: '1.5rem 0' }} />

                                <CustomSection customSectionData={customSection} flag={flag} setUpdating={setUpdating} onCustomDataChange={handleCustomSectionDataChange} />
                                <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', margin: '1.5rem 0' }} />
                            </>
                        )}

                        {(uploadResume || createNewResume) ?
                            <Box className="form-action-btn" sx={{ display: { xs: 'none', md: 'block' } }}>
                                <Stack direction="row" spacing={{ xs: 0, md: 2 }} mt={{ xs: 0, md: 3 }}>
                                    <Button fullwidth sx={{ width: { xs: '100%', sm: 'auto' } }} onClick={handleOpenLeaveWihoutUpdatingDialog} className="text-button" size="medium" disableRipple disableElevation>Cancel</Button>
                                    <Button
                                        fullWidth
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            gap: '8px',
                                            whiteSpace: 'nowrap',
                                            width: { xs: '100%', sm: 'auto' },
                                            backgroundColor: '#1575c6',
                                            borderRadius: '12px',
                                            padding: '10px'
                                        }}
                                        variant="contained"
                                        className="nxt-btn"
                                        onClick={() => validateAndProceed()}
                                        disabled={loads}
                                    >
                                        {loads ? (createNewResume ? 'Creating...' : 'Updating...') : (createNewResume ? 'Create Resume' : 'Update Resume')}
                                    </Button>
                                </Stack>
                            </Box> :
                            <Button sx={{ display: { xs: 'none', md: 'block' } }} className='next-btn' variant="contained" size="large" disableRipple disableElevation onClick={validateAndProceed} disabled={nextPage}>Next</Button>
                        }
                        {isMobile ?
                            <Drawer
                                anchor="bottom"
                                open={open}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                onClose={handleClose}
                                className="forgot-password-dialog career-breaks-dialog"
                            >
                                <CareerBreaksIdentifiedDialog gap={workExperienceGap} onClose={handleClose} onWorkExperienceDataChange={handleWorkExperienceDataChange} />
                            </Drawer> :
                            <Dialog
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                className="career-breaks-dialog"
                            >
                                <CareerBreaksIdentifiedDialog gap={workExperienceGap} onClose={handleClose} onWorkExperienceDataChange={handleWorkExperienceDataChange} />
                            </Dialog>}
                    </Box>
                    {(uploadResume || createNewResume) ?
                        <Box className="form-action-btn" sx={{ display: { xs: 'block', md: 'none' } }}>
                            <Stack direction="row" spacing={{ xs: 0, md: 2 }} mt={{ xs: 0, md: 3 }}>
                                <Button fullwidth sx={{ width: { xs: '100%', sm: 'auto' } }} onClick={handleOpenLeaveWihoutUpdatingDialog} className="text-button" size="medium" disableRipple disableElevation>Cancel</Button>
                                <Button
                                    fullWidth
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: '8px',
                                        whiteSpace: 'nowrap',
                                        width: { xs: '100%', sm: 'auto' },
                                        backgroundColor: '#1575c6',
                                        borderRadius: '12px',
                                        padding: '10px'
                                    }}
                                    variant="contained"
                                    className="nxt-btn"
                                    onClick={() => validateAndProceed()}
                                    disabled={loads}
                                >
                                    {loads ? (createNewResume ? 'Creating...' : 'Updating...') : (createNewResume ? 'Create Resume' : 'Update Resume')}
                                </Button>
                            </Stack>
                        </Box> :
                        <Box className="form-action-btn" sx={{ display: { xs: 'block', md: 'none' } }}>
                            <Stack direction="row" spacing={{ xs: 0, md: 2 }} mt={{ xs: 0, md: 3 }}>
                                <Button className='next-btn' sx={{ display: { xs: 'block', md: 'none' } }} variant="contained" size="large" disableRipple disableElevation onClick={validateAndProceed} disabled={nextPage}>Next</Button>
                            </Stack>
                        </Box>}
                </Grid>

                {/* Resume Section - Moved Outside of regi-upload-page-content */}
                {alignment === "talent-juntion-style" && (
                    <Grid item md={4} lg={3.5} sx={{ display: { xs: 'none', md: 'block' } }}>
                        <CandidateResume
                            updating={updating}
                            setUpdating={setUpdating}
                            templateType={templateType}
                            profile={user}
                            summaryData={summaryData}
                            educationData={educationData}
                            workExperienceData={workExperienceData}
                            certificateData={certificateData}
                            financeTechologiesData={financeTechologiesData}
                            customSection={customSection}
                        />
                    </Grid>
                )}
            </Grid>

            {/* Leave without updating changes dialog */}
            {isMobile ?
                <Drawer
                    anchor="bottom"
                    open={withoutUpdateDialog}
                    onClose={handleCloseLeaveWihoutUpdatingDialog}
                    className="forgot-password-dialog career-breaks-dialog"
                >
                    <LeaveWithoutUpdatingDialog updateResume={validateAndProceed} handleClose={handleCloseLeaveWihoutUpdatingDialog} />
                </Drawer> :
                <Dialog
                    open={withoutUpdateDialog}
                    onClose={handleCloseLeaveWihoutUpdatingDialog}
                    className="action-dialog-card"
                >
                    <LeaveWithoutUpdatingDialog updateResume={validateAndProceed} handleClose={handleCloseLeaveWihoutUpdatingDialog} />
                </Dialog>}
        </Box>
    );


}

export default JobApplication

import { Box, Checkbox, FormControlLabel, Link, Stack, Typography, Dialog, Tooltip, IconButton, SvgIcon } from '@mui/material'
import React, { useState } from 'react'

import { useForm, register, watch, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { updateFinanceTechnologies } from 'api/users';
import AddTechnology from './AddTechnology';
import { useSnackbar } from 'components/snackbar';
import { useMediaQuery, Drawer, Card, CardContent, Button, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const FinanceTechnicalCompetencies = ({ setUpdating, financeTechologiesData, onFinanceDataChange, handleFinance, Finance }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { enqueueSnackbar } = useSnackbar();
    const [allFinanceTechnologiesList, setAllFinanceTechnologiesList] = useState([
        "Microsoft Excel",
        "Microsoft Word",
        "Microsoft PowerPoint",
        "Power BI",
        "Tableau",
        "Spotfire",
        "Dundas BI",
        "Qlik Sense",
        "Looker",
        "Google Data Studio",
        "SAP Crystal Reports",
        "IBM Cognos Analytics",
        "SAP S/4HANA",
        "SAP FICO",
        "Oracle Financials Cloud",
        "NetSuite ERP",
        "QuickBooks",
        "Xero",
        "Tally ERP",
        "Alteryx",
        "Python (Pandas, NumPy)",
        "R",
        "SQL",
        "MATLAB",
        "Bloomberg Terminal",
        "Reuters Eikon",
        "C++",
        "Java",
        "Scala",
        "Hadoop",
        "Power Query",
        "Macros and VBA",
        "Adaptive Insights",
        "SAS (Statistical Analysis System)",
        "Hyperion",
        "Workday",
        "ETL Tools (Informatica, Talend)",
        "QuantLib",
        "Tableau Prep",
        "Anaconda",
        "Looker Studio",
        "Zoho Analytics",
        "Domo",
        "AWS (Amazon Web Services)",
        "Google Cloud Platform (GCP)",
        "Azure (Microsoft Cloud)"
    ]);
    const [open, setOpen] = useState(false);
    const [checkedState, setCheckedState] = useState(financeTechologiesData);

    const [bindTechnologyList, setBindTechnologyList] = useState(financeTechologiesData);
    const [selectedSoftware, setSelectedSoftware] = useState(
        financeTechologiesData.map(tech => tech)
    );

    const [showFlag, setShowFlag] = useState(false);

    const handleOpenOtpDialog = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleAddMoreTechnology = async (moreTechnologies) => {
        setCheckedState(moreTechnologies);
        setSelectedSoftware(moreTechnologies);
        await onSubmit("", false, moreTechnologies, true);
    }

    const handleCheckboxChange = async (name, isChecked, index) => {
        let updatedCheckedState = !isChecked ? checkedState.filter(item => item !== name) : [...checkedState, name];

        if (updatedCheckedState && updatedCheckedState.length) {
            setCheckedState(updatedCheckedState);
            setSelectedSoftware(updatedCheckedState);
        } else {
            setCheckedState([]);
            setSelectedSoftware([]);
        }
        await onSubmit(name, isChecked, [], false, updatedCheckedState);
    };

    const onSubmit = (async (item, ischecked, data, isAddMore, updatedCheckedState) => {
        try {
            setUpdating(true);
            if (selectedSoftware && selectedSoftware.length <= 12) {
                if (Finance) {
                    handleFinance('financeTechologiesData', (data?.length > 0 ? data : updatedCheckedState));
                    setBindTechnologyList(data);
                    return;
                }
                const response = await updateFinanceTechnologies({ item, ischecked, data });
                if (response?.success === true && response.data) {
                    if (isAddMore) {
                        setCheckedState(response.data);
                        setSelectedSoftware(response.data);
                        setBindTechnologyList(response.data);
                    }
                    onFinanceDataChange();
                } else {
                    enqueueSnackbar(response.message, { variant: 'error' });
                }
            } else {
                setShowFlag(true);
            }
        } catch (e) {
            setUpdating(false);
        }
    });

    return (
        <Box className={Finance ? "finance-tech-wrapper" : ""}>
            <Box mb={2}>
                <Stack direction="row" alignItems={'center'} justifyContent={'space-between'}>
                    <Typography className="step-heading" variant="h6" gutterBottom>
                        Finance Technical Competencies
                    </Typography>
                    {Finance && <Tooltip title="Data updated in this section will be reflected across all resumes" placement="top" arrow>
                        <IconButton aria-label="delete" sx={{ padding: 0 }} className="edit-icon" disableRipple>
                            <SvgIcon>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M12.2906 16.0002H2.10131C0.944535 16.0002 0.00341797 15.0592 0.00341797 13.9023V3.71301C0.00341797 2.55623 0.94442 1.61511 2.10131 1.61511H6.77045C7.10161 1.61511 7.36983 1.88332 7.36983 2.21448C7.36983 2.54564 7.10161 2.81385 6.77045 2.81385H2.10131C1.60534 2.81385 1.20225 3.21694 1.20225 3.71291V13.9022C1.20225 14.3982 1.60534 14.8013 2.10131 14.8013H12.2906C12.7866 14.8013 13.1897 14.3982 13.1897 13.9022V9.23306C13.1897 8.9019 13.4579 8.63369 13.789 8.63369C14.1202 8.63369 14.3884 8.9019 14.3884 9.23306V13.9022C14.3884 15.059 13.4475 16.0002 12.2906 16.0002Z" fill="#1575C6" />
                                    <path d="M4.44385 9.0085L11.0011 2.45129L13.5476 4.9978L6.99036 11.555L4.44385 9.0085Z" fill="#1575C6" />
                                    <path d="M15.7113 1.43961L14.5605 0.28882C14.1754 -0.0962733 13.5506 -0.0962733 13.1647 0.28882L11.8491 1.60444L14.3956 4.15095L15.7113 2.83533C16.0963 2.45024 16.0964 1.82545 15.7113 1.43961Z" fill="#1575C6" />
                                    <path d="M3.67534 9.9352L3.08272 12.5229C3.02878 12.7597 3.24005 12.971 3.47681 12.917L6.06455 12.3244L3.67534 9.9352Z" fill="#1575C6" />
                                </svg>
                            </SvgIcon>
                        </IconButton>
                    </Tooltip>}
                </Stack>
                <Typography className="step-info" variant="body2" gutterBottom>
                    Please select or enter upto 12 finance technical skills in which you have reasonable proficiency
                </Typography>
            </Box>

            <Box className="ftc-checkbox-wrapper">
                <Stack direction="row" flexWrap="wrap" mb={1}>
                    {bindTechnologyList && bindTechnologyList.length > 0 ?
                        bindTechnologyList.map((software, index) => (
                            <FormControlLabel
                                key={index}
                                control={
                                    <Checkbox
                                        checked={checkedState.includes(software)}
                                        onChange={(e) => handleCheckboxChange(software, e.target.checked, index)}
                                        tabIndex={-1}
                                    />
                                }
                                label={software}
                            />
                        )) :
                        allFinanceTechnologiesList.slice(0, 12).map((software, index) => (
                            <FormControlLabel
                                key={index}
                                control={
                                    <Checkbox
                                        checked={checkedState.includes(software)}
                                        onChange={(e) => handleCheckboxChange(software, e.target.checked, index)}
                                        tabIndex={-1}
                                    />
                                }
                                label={software}
                            />
                        ))
                    }
                </Stack>
                {showFlag && (
                    <Typography style={{ color: 'red', fontSize: 'small' }} mb={2}>
                        Please select upto 12 finance technical skills.
                    </Typography>
                )}
                <Link className="add-more-technology-link" underline="none" size="small" onClick={handleOpenOtpDialog} tabIndex={0}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleOpenOtpDialog();
                        }
                    }}
                >
                    Select from complete list
                </Link>
            </Box>

            {isMobile ?
                <Drawer
                    open={open}
                    anchor="bottom"
                    onClose={handleClose}
                    className="forgot-password-dialog otp-dialog-wrapper"
                >
                    <AddTechnology onClose={handleClose} technology={allFinanceTechnologiesList} selectedsoftware={selectedSoftware} onAddTechnology={handleAddMoreTechnology} />
                </Drawer> :
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className="otp-dialog-wrapper"
                >
                    <AddTechnology onClose={handleClose} technology={allFinanceTechnologiesList} selectedsoftware={selectedSoftware} onAddTechnology={handleAddMoreTechnology} />
                </Dialog>
            }

        </Box>
    )
}

export default FinanceTechnicalCompetencies

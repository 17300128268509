import { useState, useEffect, useLayoutEffect, useRef } from 'react';
import {
  FormHelperText,
  MenuItem,
  Dialog,
  Link,
  CardContent,
  InputLabel,
  Chip,
  Card,
  Select,
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  SvgIcon,
  Grid,
  Checkbox,
  Stack,
  TextField,
  Typography,
  OutlinedInput,
  CircularProgress,
  useMediaQuery,
  Drawer,
  Tooltip
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useSnackbar } from 'components/snackbar';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import FormProvider from 'hooks/form/form-provider';
import { CandidateWorkHistorySchema, CandidateWorkHistoryForGapSchema } from 'models/CandidateProfileSchema';
import { updateCandidateWorkExperience, getWorkHistoryData, deleteWorkHistory } from 'api/users';
import dayjs from 'dayjs'; // Import Day.js library
import DynamicTextField from 'components/DynamicTextField';
import TypeAhead from 'components/TypeAhead';
import { endpoints } from 'utils/axios';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import AddIcon from '@mui/icons-material/Add';
import { changeNameFormat, toTitleCase, allCapitalize } from 'utils/Utils';
import AddSkills from './AddSkills';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import RemoveWorkExperience from './RemoveWorkExperienceDialog';
import { cleanData } from 'data/city.js';
import Overlapping from './Overlapping';
import { useAuth } from 'hooks/useAuthContext';
import { workDefSkill } from 'data/language';
import { useTheme } from '@mui/material/styles';
// import { industrySector } from 'data/language'

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  top: 5,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  }
};

const AddWorkExperience = ({ onCancel, onUploadSuccess, isEdit, workHistoryData, onWorkExperienceDataChange, currentCompany, roleData, checkingOverlapping, showWork, handleShowWork, handleDeleteEdu }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();
  const [explaination, setExplnation] = useState(workHistoryData?.gap_explaination ? true : false);
  const [from, setFrom] = useState(dayjs(workHistoryData?.work_history_from) || null);
  const [showFlag, setShowFlag] = useState('');
  const [present, setPresent] = useState(workHistoryData?.present ? true : false);
  const [keyAccomplishments, setKeyAccomplishments] = useState(
    workHistoryData?.key_accomplishments?.length > 0 ? [...workHistoryData?.key_accomplishments, ''] : ['']
  );
  const focusRef = useRef(null);
  const [selectedChips, setSelectedChips] = useState(workHistoryData?.skills || []);
  const [open, setOpen] = useState(false);
  const [openRemove, setOpenRemove] = useState(false);
  const [error, setErrors] = useState(false);
  const [chipData, setChipData] = useState(workHistoryData?.skills?.length > 0 ? workHistoryData?.skills : workHistoryData?.all_skills || []);
  const [addMoreSkills, setAddMoreSkills] = useState(workHistoryData?.all_skills || []);

  const jobType = [{ label: 'Full time' }, { label: 'Part time' }, { label: 'Temporary' }, { label: 'Internship' }, { label: 'Trainee' }, { label: 'Freelancer (Self employed)' }];

  const defaultValues = {
    work_history_from: null,
    work_history_to: null,
    work_history_location: '',
    organization_name: '',
    organization_details: null,
    industry_sector: '',
    present: false,
    last_job_function: '',
    highest_designation: '',
    job_type: 'Full time',
    team_size_managed: 'Individual contributor (IC)',
    work_oriented_skills: [],
    gap_explaination: '',
    // gap_start_date: '',
    key_accomplishments: [],
    ...workHistoryData
  };

  const checkLocalDataIsEmpty = (data) => {
    if (data) {
      return Object.values(data)?.every(value =>
        value === null ||
        value === undefined ||
        (typeof value === 'string' && value.trim() === '') ||
        (Array.isArray(value) && value.length === 0)
      );
    }
  }

  // const initialFormValues = (isEdit || !checkLocalDataIsEmpty(workHistoryData)) ? workHistoryData : defaultValues;

  const methods = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(explaination ? CandidateWorkHistoryForGapSchema(present) : CandidateWorkHistorySchema(explaination, present, user?.date_of_birth)),
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    formState: { errors, isSubmitting, isDirty },
    watch,
    trigger,
    setError,
    clearErrors,
  } = methods;

  const watchFileds = watch();

  useLayoutEffect(() => {
    if (!isEdit) focusRef.current?.focus()
  }, []);

  const [isChangeSkills, setIsChangeSkills] = useState(false);
  const [openOverlapping, setOpenOverlapping] = useState(false);
  const [type, setType] = useState('');
  const [degree, setDegree] = useState('');
  const [newWorkData, setNewWorkData] = useState(null);

  useEffect(() => {
    if ((isDirty || isChangeSkills) && !isEdit) {
      const formData = getValues();
      formData.key_accomplishments = keyAccomplishments.filter((accomplishment) => accomplishment.trim() !== '');
      formData.skills = selectedChips;
      formData.all_skills = addMoreSkills;
      localStorage.setItem("workExperience", JSON.stringify(formData));
      if (checkLocalDataIsEmpty(formData)) localStorage.removeItem("workExperience");
      setIsChangeSkills(false);
    }
  }, [watchFileds, isDirty, isChangeSkills]);


  const handleAccomplishmentChange = (index, value) => {
    const newAccomplishments = [...keyAccomplishments];
    newAccomplishments[index] = value;

    if (index === keyAccomplishments.length - 1 && value.trim() !== '') {
      setKeyAccomplishments([...newAccomplishments, '']);
    } else if (!value && keyAccomplishments.length > 1) {
      newAccomplishments.splice(index, 1);
      setKeyAccomplishments(newAccomplishments);
    } else {
      setKeyAccomplishments(newAccomplishments);
    }
  };

  const handlePresentDateChange = (event) => {
    const isChecked = event.target.checked;
    if (currentCompany && (isEdit ? !workHistoryData.present : true)) {
      setErrors(true);
    } else {
      setPresent(isChecked);
      setValue('present', isChecked);
      if (isChecked) {
        setValue('work_history_to', null);
      }
    }
  };
  const onSubmit = handleSubmit(async (formData) => {
    if (isEdit) {
      formData.workId = workHistoryData._id;
    }
    if (!validateSkills(selectedChips)) {
      if (!explaination) {
        return;
      }
      setShowFlag('');
    } else {
      setShowFlag('');
    }
    formData.skills = selectedChips;
    formData.all_skills = chipData;
    formData.key_accomplishments = keyAccomplishments.filter((accomplishment) => accomplishment.trim() !== '');
    const { isOverlapping, type, degree } = checkingOverlapping(formData)
    // console.log(isOverlapping, type, degree);
    if (isOverlapping) {
      setNewWorkData(formData);
      setDegree(degree);
      setType(type);
      setOpenOverlapping(true);
      return;
    }
    if (showWork) {
      handleShowWork(formData);
      reset();
      return;
    }
    await updateWork(formData);
  });

  const updateWork = async (data) => {
    // console.log(data);
    if (data) {
      const response = await updateCandidateWorkExperience(data);
      if (response?.success === true && response?.data) {
        if (response?.removedWorkGapResult)
          enqueueSnackbar("Career break has been removed.", { variant: 'success' });
        (!isEdit && localStorage.removeItem('workExperience'));
        onWorkExperienceDataChange(true);
        onUploadSuccess();
        reset();
      } else if (response?.success === false) {
        enqueueSnackbar(response?.message, { variant: 'error' });
      }
    }
  };
  const updateWorkToDate = (date) => {
    if (date) {
      const lastDayOfMonth = date.endOf('month');
      setValue('work_history_to', lastDayOfMonth?.$d);
      trigger('work_history_to');
    }
  };
  const updateWorkFromDate = async (date) => {
    if (date) {
      const firstDayOfMonth = date.startOf('month');
      setFrom(date);
      setValue('work_history_from', firstDayOfMonth?.$d);
      trigger('work_history_from');
    }
  };

  const handleClick = (label) => {
    setIsChangeSkills(true);
    let totalSkillls;
    if (selectedChips.includes(label)) {
      totalSkillls = selectedChips.filter((chip) => chip !== label);
      setSelectedChips(totalSkillls);
    } else {
      if (selectedChips.length >= 8) {
        setShowFlag('You can select up to 8 skills.');
        return;
      } else {
        totalSkillls = [...selectedChips, label];
        setSelectedChips(totalSkillls);
        setShowFlag('');
      }
    }
    validateSkills(totalSkillls);
  };

  const validateSkills = (totalSkillls) => {
    if (totalSkillls?.length < 3) {
      setShowFlag('Please select at least 3 skills.');
      return false;
    } else if (totalSkillls?.length > 8) {
      setShowFlag('You can select up to 8 skills.');
      return false;
    }
    setShowFlag('');
    return true;
  };

  const handleOpenOtpDialog = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenRemove(false);
  };
  const handleAddMoreSkills = (moreSkills) => {
    validateSkills(moreSkills);
    if (moreSkills?.length > 0) {
      setIsChangeSkills(true);
      setSelectedChips(moreSkills);
      setChipData(moreSkills);
    }
  };

  const handleChangeGapExplaination = (event) => {
    setValue('gap_explaination', event.target.value);
  }
  const handleDeleteAction = async () => {
    if (showWork) {
      handleDeleteEdu(workHistoryData?._id);
      handleClose();
      return;
    }
    const response = await deleteWorkHistory({ workId: workHistoryData._id });
    if (response && response?.success) {
      onWorkExperienceDataChange(true);
    } else {
      enqueueSnackbar(response.message, { variant: 'error' });
    }
    handleClose();
  };
  const handleDeleteWork = async (workId, title, event) => {
    event.stopPropagation();
    if (!workId) {
      localStorage.removeItem("workExperience");
      onCancel();
    } else {
      setOpenRemove(true);
    }
  };
  const handleCloseOverlapping = () => {
    setOpenOverlapping(false);
  };
  const handleConfirm = (newWorkData) => {
    if (showWork) {
      handleShowWork(newWorkData);
      return;
    }
    updateWork(newWorkData);
  };

  const EditIcon = ({ margin }) => {
    return (
      <Tooltip title="Data updated in this section will be reflected across all resumes" placement="top" arrow>
        <IconButton
          aria-label="toggle password visibility"
          edge="end"
          disableRipple
          sx={{ marginRight: margin ? "2px" : "" }}
        >
          <SvgIcon sx={{ width: '16px', height: '16px' }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M12.2907 16.0002H2.10143C0.944657 16.0002 0.00354004 15.0592 0.00354004 13.9023V3.71301C0.00354004 2.55623 0.944542 1.61511 2.10143 1.61511H6.77058C7.10174 1.61511 7.36995 1.88332 7.36995 2.21448C7.36995 2.54564 7.10174 2.81385 6.77058 2.81385H2.10143C1.60546 2.81385 1.20238 3.21694 1.20238 3.71291V13.9022C1.20238 14.3982 1.60546 14.8013 2.10143 14.8013H12.2907C12.7867 14.8013 13.1898 14.3982 13.1898 13.9022V9.23306C13.1898 8.9019 13.458 8.63369 13.7892 8.63369C14.1203 8.63369 14.3885 8.9019 14.3885 9.23306V13.9022C14.3885 15.059 13.4476 16.0002 12.2907 16.0002Z" fill="#1575C6" />
              <path d="M4.44409 9.0085L11.0013 2.45129L13.5478 4.9978L6.9906 11.555L4.44409 9.0085Z" fill="#1575C6" />
              <path d="M15.7117 1.43961L14.5609 0.28882C14.1758 -0.0962733 13.551 -0.0962733 13.1651 0.28882L11.8495 1.60444L14.396 4.15095L15.7116 2.83533C16.0967 2.45024 16.0968 1.82545 15.7117 1.43961Z" fill="#1575C6" />
              <path d="M3.6757 9.93518L3.08308 12.5229C3.02914 12.7597 3.24042 12.9709 3.47717 12.917L6.06492 12.3244L3.6757 9.93518Z" fill="#1575C6" />
            </svg>
          </SvgIcon>
        </IconButton>
      </Tooltip>
    )
  };

  if (!roleData || !roleData?.length > 0) {
    return (
      <div key="add_edit_edu">
        <Box className="candidate-basic-det-wrapper"></Box>
        <Card className="form-wrapper" variant="outlined">
          <CardContent>
            <Stack direction="row" justifyContent={'space-between'} alignItems={'center'} mb={3}>
              <CircularProgress />
            </Stack>
          </CardContent>
        </Card>
      </div>
    );
  }

  return (
    <Box className="candidate-basic-det-wrapper work-exp-details-form">
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Box className="job-update-form-top" mb={1}>
          <Box className="details-info-header" mb={2.5}>
            <Stack direction="row" justifyContent={'space-between'} alignItems={'center'} mb={3}>
              <Typography variant="h6">{
                watch('organization_name') === 'Work Gap'
                  ? (watch('gap_title') ? `CAREER BREAK: ${allCapitalize(watch('gap_title'))}` : 'CAREER BREAK')
                  : (watch('highest_designation') ? (`${watch('highest_designation')} `) + 'at ' : '') +
                  (watch('organization_name') ? allCapitalize(`${watch('organization_name')}`) : 'Work Experience Details')
              }
              </Typography>
              <Stack direction="row" alignItems={'center'} spacing={2}>
                {workHistoryData && !isMobile &&
                  <IconButton
                    className="delete-sec-btn"
                    aria-label="delete"
                    onClick={(event) => handleDeleteWork(workHistoryData?._id, workHistoryData?.organization_name, event)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.0948 2.37734C11.0948 2.22733 11.0353 2.08301 10.9289 1.97715C10.8231 1.87074 10.6787 1.81131 10.5287 1.81131H8.70671L8.44689 0.771503C8.33369 0.317545 7.92557 -0.000564375 7.45803 7.54076e-07H4.54298C4.07543 -0.000565244 3.66732 0.317545 3.55412 0.771503L3.29431 1.81131H1.47228C1.15983 1.81131 0.90625 2.06489 0.90625 2.37734C0.90625 2.68978 1.15983 2.94337 1.47228 2.94337H1.50171C1.46096 3.10186 1.44737 3.26658 1.46096 3.42959L2.14473 10.2668C2.24096 11.2523 3.0702 12.0027 4.06023 12H7.94092C8.93091 12.0028 9.7602 11.2523 9.85642 10.2668L10.5402 3.42901C10.5538 3.266 10.5396 3.10185 10.4994 2.94335H10.5289C10.6789 2.94335 10.8232 2.88392 10.9291 2.77751C11.0355 2.67166 11.0948 2.52733 11.0948 2.37734ZM4.63071 1.13206H7.3697L7.53951 1.8113H4.46087L4.63071 1.13206ZM9.32707 3.05494C9.3916 3.1257 9.4233 3.22135 9.41311 3.31702L8.72933 10.1542C8.69028 10.5601 8.34839 10.8691 7.94085 10.868H4.06015C3.65261 10.8691 3.31072 10.5601 3.27167 10.1542L2.58789 3.3176C2.57827 3.22194 2.6094 3.12684 2.67393 3.05552C2.73846 2.9842 2.82959 2.94345 2.92582 2.94345H9.07529C9.17151 2.94288 9.26255 2.98362 9.32707 3.05494ZM5.09484 4.52832C4.94483 4.52832 4.80051 4.58775 4.69465 4.69416C4.58824 4.80001 4.52881 4.94435 4.52881 5.09434V7.81131C4.52881 8.12375 4.78239 8.37734 5.09484 8.37734C5.40728 8.37734 5.66086 8.12375 5.66086 7.81131V5.09434C5.66086 4.94434 5.60143 4.80001 5.49502 4.69416C5.38917 4.58774 5.24483 4.52832 5.09484 4.52832ZM6.50599 4.69416C6.61185 4.58775 6.75617 4.52832 6.90618 4.52832C7.05617 4.52832 7.20051 4.58774 7.30636 4.69416C7.41277 4.80001 7.4722 4.94434 7.4722 5.09434V7.81131C7.4722 8.12375 7.21862 8.37734 6.90618 8.37734C6.59373 8.37734 6.34015 8.12375 6.34015 7.81131V5.09434C6.34015 4.94435 6.39958 4.80001 6.50599 4.69416Z"
                        fill="#FF4949"
                      />
                    </svg>
                  </IconButton>
                }
                {!isMobile && <KeyboardArrowUpOutlinedIcon onClick={() => { reset(); onCancel(); }} style={{ cursor: 'pointer' }} />}
              </Stack>
            </Stack>
          </Box>

          <Box className="details-info-content">
            {(workHistoryData?.organization_name !== "Work Gap") && <>
              <Grid container spacing={2} mb={3}>
                <Grid item xs={12} md={6}>
                  {/* company name */}
                  <FormControl fullWidth size="small">
                    <Controller
                      control={control}
                      name="organization_name"
                      defaultValue={toTitleCase(defaultValues?.organization_name) || ''}
                      render={({ field }) => (
                        <TypeAhead
                          {...field}
                          {...register('organization_name')}
                          ref={focusRef}
                          api={`${process.env.REACT_APP_END_POINT}${endpoints?.services?.company}`}
                          fullWidth
                          label="Company"
                          placeholder="Enter your company name"
                          displayTextKey="name"
                          freeSolo={true}
                          onInputChange={(e, value) => {
                            setValue("organization_name", value, { shouldDirty: true });
                            setValue("organization_details", {});
                          }}
                          onChange={(e, value) => {
                            if (value?.name) {
                              setValue("organization_name", toTitleCase(value?.name), { shouldDirty: true });
                              setValue("organization_details", value);
                            } else {
                              setValue("organization_name", toTitleCase(value), { shouldDirty: true });
                              setValue("organization_details", {});
                            }
                          }}
                          inputProps={{
                            style: { textTransform: 'capitalize' }
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  {/* designation */}
                  <FormControl fullWidth size="small">
                    <Controller
                      control={control}
                      name="highest_designation"
                      defaultValue={defaultValues?.highest_designation || []}
                      render={({ field }) => (
                        <TextField
                          size="small"
                          {...field}
                          {...register('highest_designation')}
                          label="Designation"
                          variant="outlined"
                          fullWidth
                          error={!!errors.highest_designation}
                          helperText={errors.highest_designation ? errors.highest_designation.message : ''}
                          onChange={(e) =>
                            setValue("highest_designation", e.target.value, { shouldDirty: true })
                          }
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  {/* job function */}
                  <FormControl fullWidth size="small">
                    <Controller
                      control={control}
                      name="last_job_function"
                      defaultValue={defaultValues?.last_job_function || ''}
                      render={({ field }) => (
                        <TypeAhead
                          {...field}
                          {...register('last_job_function')}
                          data={roleData}
                          freeSolo={true}
                          label="Job Function"
                          size="small"
                          displayTextKey="name"
                          onInputChange={(e, value) => {
                            setValue("last_job_function", value, { shouldDirty: true });
                            const skills = chipData?.length ? workDefSkill.every(skill => chipData.includes(skill)) : false;
                            if (!skills) {
                              setChipData(workDefSkill);
                              setAddMoreSkills(workDefSkill);
                              setSelectedChips([]);
                              setValue('all_skills', workDefSkill)
                            }
                          }}
                          onChange={(e, value) => {
                            setValue('all_skills', value?.skills);
                            setChipData(value?.skills);
                            setAddMoreSkills(value?.skills);
                            setSelectedChips([]);
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                {/* job type */}
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="job_type">Job Type</InputLabel>
                    <Controller
                      control={control}
                      name="job_type"
                      defaultValue={defaultValues?.job_type || ''}
                      render={({ field }) => (
                        <Select
                          {...field}
                          {...register('job_type')}
                          labelId="job_type"
                          error={!!errors.job_type}
                          helperText={errors.job_type?.message}
                          label="Job Type"
                          variant="outlined"
                          fullWidth
                        >
                          {jobType?.map((item) =>
                            <MenuItem value={item?.label}>{item?.label}</MenuItem>
                          )}
                        </Select>
                      )}
                    />
                    <Typography variant="caption" color="error">
                      {errors.job_type?.message}
                    </Typography>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  {/* industry name */}
                  <FormControl fullWidth size="small" error={!!errors.organization_details?.industry_sector}>
                    <Controller
                      control={control}
                      name="organization_details.industry_sector"
                      defaultValue={defaultValues?.organization_details?.industry_sector || ''}
                      render={({ field }) => (
                        <TypeAhead
                          {...field}
                          {...register('organization_details.industry_sector')}
                          api={`${process.env.REACT_APP_END_POINT}${endpoints?.services?.industry}`}
                          label="Industry"
                          displayTextKey="name"
                          freeSolo={false}
                          // onInputChange={(e, value) => {
                          //   setValue("organization_details.industry_sector", value, { shouldDirty: true });
                          // }}
                          onChange={(e, value) => {
                            setValue("organization_details", (prev) => ({
                              ...prev,
                              industry_sector: value,
                            }));
                          }}
                        />
                      )}
                    />
                    {errors.organization_details?.industry_sector && (
                      <Typography variant="caption" color="error" sx={{ ml: 1 }}>
                        {errors.organization_details.industry_sector.message}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
                {/* location */}
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth size="small">
                    <Controller
                      control={control}
                      name="work_history_location"
                      defaultValue={defaultValues?.work_history_location || ''}
                      render={({ field }) => (
                        <TypeAhead
                          {...field}
                          {...register('work_history_location')}
                          api={`${process.env.REACT_APP_END_POINT}${endpoints?.services?.location}`}
                          label="Location"
                          freeSolo={true}
                          onInputChange={(e, value) => {
                            setValue("work_history_location", value, { shouldDirty: true });
                          }}
                          onChange={(e, value) => {
                            setValue("work_history_location", value, { shouldDirty: true });
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                {/* team size managed */}
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth size="small" error={!!errors?.team_size_managed}>
                    <InputLabel id="team_size_managed">People Managed (Direct Reportees)</InputLabel>
                    <Controller
                      control={control}
                      name="team_size_managed"
                      defaultValue={defaultValues?.team_size_managed || ''}
                      render={({ field }) => (
                        <Select
                          {...field}
                          {...register('team_size_managed')}
                          labelId="team_size_managed"
                          label="People Managed (Direct Reportees)"
                          variant="outlined"
                          fullWidth

                        >
                          <MenuItem value="Individual contributor (IC)">Individual contributor (IC)</MenuItem>
                          <MenuItem value="1-4">1-4</MenuItem>
                          <MenuItem value="5-10">5-10</MenuItem>
                          <MenuItem value="11-25">11-25</MenuItem>
                          <MenuItem value="26-50">26-50</MenuItem>
                          <MenuItem value="51-100">51-100</MenuItem>
                          <MenuItem value="100+">100+</MenuItem>
                        </Select>
                      )}
                    />
                    {errors?.team_size_managed && <FormHelperText error>{errors?.team_size_managed?.message}</FormHelperText>}
                  </FormControl>
                </Grid>

              </Grid>
              <Box mb={2}>
                <Card className="disability-card" variant="outlined" sx={{ background: showWork ? '#F1F5F7 !important' : '#E7EBFD !important' }}>
                  <CardContent sx={{ background: showWork ? '#F1F5F7 !important' : '#E7EBFD !important' }}>
                    <FormControlLabel
                      control={
                        <Stack direction="row" spacing={1} alignItems="start">
                          <AntSwitch
                            checked={present}
                            onChange={handlePresentDateChange}
                            inputProps={{ 'aria-label': 'ant design' }}
                          />
                          <Box>
                            <Typography className="heading" variant="p" gutterBottom>
                              You are currently working with {watch('organization_name') ? toTitleCase(watch('organization_name')) : ' this company'} as{' '}
                              {watch('highest_designation') ? toTitleCase(watch('highest_designation')) : ' your current role'}
                            </Typography>
                            <Typography className="info" variant="body2" gutterBottom>
                              Please select this if you are still working in this company & current role
                            </Typography>
                          </Box>
                        </Stack>
                      }
                    />
                  </CardContent>
                </Card>
                {error && (
                  <Typography className="error-text" component="p" style={{ color: 'red' }}>
                    You are currently working with {currentCompany}.
                  </Typography>
                )}
              </Box>
            </>
            }
            <Grid container spacing={2} mb={3}>
              {(workHistoryData?.organization_name !== "Work Gap") && <>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <Controller
                      control={control}
                      name="work_history_from"
                      defaultValue={defaultValues?.work_history_from}
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          id="work_history_from"
                          {...register('work_history_from')}
                          format="MM/YYYY"
                          views={['year', 'month']}
                          disableFuture
                          label="Start Date"
                          sx={{ width: '100%' }}
                          value={dayjs(field?.value)}
                          onChange={(date) => updateWorkFromDate(date)}
                          slotProps={{
                            textField: {
                              size: 'small',
                              error: !!errors?.work_history_from,
                              helperText: errors?.work_history_from?.message,
                              // InputProps: {
                              //   readOnly: true,
                              //   onClick: (event) => event.target.blur(),
                              // },                              
                              // inputProps: {
                              //   tabIndex: -1,
                              // },
                            },
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <Controller
                      control={control}
                      name="work_history_to"
                      defaultValue={defaultValues?.work_history_to}
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          id="work_history_to"
                          format="MM/YYYY"
                          views={['year', 'month']}
                          disableFuture
                          sx={{ width: '100%' }}
                          disabled={present}
                          // minDate={from}
                          label="End Date"
                          value={dayjs(field?.value)}
                          onChange={(date) => updateWorkToDate(date)}
                          slotProps={{
                            textField: {
                              size: 'small',
                              error: !!errors?.work_history_to,
                              helperText: errors?.work_history_to?.message,
                              // InputProps: {
                              //   readOnly: true,
                              //   onClick: (event) => event.target.blur(),
                              // },
                              // inputProps: {
                              //   tabIndex: -1,
                              // },
                            },
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </>
              }
              <Grid item xs={12} md={12}>
                {workHistoryData?.organization_name == "Work Gap" && (
                  <>
                    <Typography className="info-wrapper" variant="body2" gutterBottom mb={3} sx={{ marginTop: '-20px' }}>
                      {workHistoryData.work_history_from ? `${dayjs(workHistoryData.work_history_from).format('MMM YYYY')} - ${workHistoryData.work_history_to && dayjs(workHistoryData.work_history_to).format('MMM YYYY')}` : ''}
                    </Typography>
                    <Grid item xs={12} spacing={2} mb={3}>
                      <FormControl fullWidth>
                        <Controller
                          control={control}
                          name="gap_title"
                          defaultValue={defaultValues?.gap_title || ''}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label="Title"
                              error={!!errors.gap_title}
                              helperText={errors.gap_title ? errors.gap_title?.message : ''}
                              size='small'
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                if (inputValue?.length <= 50) {
                                  setValue('gap_title', inputValue);
                                  clearErrors('gap_title');
                                } else {
                                  setError('gap_title', { message: "The title must not exceed 50 characters" });
                                }
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <Controller
                          control={control}
                          name="gap_explaination"
                          defaultValue={defaultValues?.gap_explaination || ''}
                          render={({ field }) => (
                            <TextField
                              multiline
                              {...field}
                              label="Reason"
                              error={!!errors.gap_explaination}
                              helperText={errors.gap_explaination?.message || `${getValues('gap_explaination') ? getValues('gap_explaination')?.trim()?.split(/\s+/)?.length || 0 : 0} / 25 words`}
                              size='small'
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                const words = inputValue.trim().split(/\s+/);
                                if (words?.length <= 25) {
                                  setValue('gap_explaination', inputValue);
                                  clearErrors('gap_explaination');
                                } else {
                                  setError('gap_explaination', { message: "Maximum 25 words allowed." });
                                }
                              }}
                            />
                          )}
                        />
                      </FormControl>
                      <FormControlLabel sx={{ fontSize: '14px' }}
                        control={
                          <Checkbox
                            {...methods.register(`show_in_resume `)}
                            checked={watch('show_in_resume')}
                            onChange={(e) => setValue('show_in_resume', e.target.checked)}
                            defaultChecked={false}
                            id="show_in_resume "
                            name="show_in_resume "
                          />
                        }
                        label="Show this break in my resume"
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Box>

          {/* <Box mt={2}>
                <TextField
                  multiline
                  rows={4}
                  fullWidth
                  variant="outlined"
                  value={workHistoryData.gap_explaination}
                  onChange={(e) => handleChangeGapExplaination(e)}
                />
            </Box> */}
        </Box>
        {(workHistoryData?.organization_name !== "Work Gap") && <>
          <Box className="job-update-form-top" mb={1} sx={{ padding: showWork && '0px 20px !important' }}>
            <Box className="details-info-content">
              <Box className="besic-det-skills-wrapper">
                <Box mb={2}>
                  <Typography className="title" variant="body2" gutterBottom>
                    Skills
                  </Typography>
                  <Typography className="info-txt" variant="body2" gutterBottom>
                    3 to 8 skills can be selected.
                  </Typography>
                </Box>
                <Stack direction="row" flexWrap="wrap">
                  {chipData?.map((label, index) => (
                    <Chip
                      key={index}
                      label={label}
                      size="small"
                      clickable
                      variant={selectedChips.includes(label) ? 'filled' : 'outlined'}
                      style={{
                        color: selectedChips.includes(label) ? 'white' : 'black',
                        backgroundColor: selectedChips.includes(label) ? '#ade9ab' : 'transparent',
                        border: selectedChips.includes(label) ? 'none' : '1px solid rgba(0, 0, 0, 0.23)',
                      }}
                      onClick={() => handleClick(label)}
                      tabIndex={-1}
                    />
                  ))}
                </Stack>
                {showFlag && (
                  <Typography style={{ color: 'red', fontSize: 'small' }}>
                    {showFlag}
                  </Typography>
                )}
                <Link className="add-more-skills-link" underline="none" size="small" onClick={handleOpenOtpDialog} tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleOpenOtpDialog();
                    }
                  }}
                >
                  Add More Skills
                </Link>
              </Box>
            </Box>
          </Box>
          <Box className={showWork ? "job-update-form-bottom" : "job-update-form-top"}>
            <Box className="accomplishments" mt={3} mb={2}>
              <Typography className="title" variant="body2" gutterBottom>
                Accomplishments
              </Typography>
              <Typography className="info-txt" variant="body2" gutterBottom>
                Well structured accomplishments help recruiters get visibility on your strengths
              </Typography>
              {keyAccomplishments.map((accomplishment, index) => (
                <Box key={index} mt={2}>
                  <FormControl fullWidth>
                    <DynamicTextField
                      label="Key Accomplishments"
                      rowsMax={10}
                      value={accomplishment}
                      onChange={(value) => handleAccomplishmentChange(index, value)}
                    />
                  </FormControl>
                </Box>
              ))}
            </Box>
          </Box>
        </>
        }
        <Box className="job-update-form-top">
          <Box className="details-info-header" mb={showWork && 2.5}>
            <Box className="form-action-btn">
              <Stack direction="row" spacing={{ xs: 0, md: 2 }} mt={{ xs: 0, md: showWork ? 0 : 3 }}>
                <Button variant="text" className="cancel-btn" sx={{ display: { xs: 'none', md: 'block' } }} size="large" onClick={onCancel} tabIndex={-1}>Cancel</Button>
                <Button variant="contained" className="done-btn" size="large" type="submit" disabled={isSubmitting}>
                  {isEdit ? (isSubmitting ? 'Updating...' : 'Done') : (isSubmitting ? 'Saving...' : 'Done')}
                </Button>
              </Stack>
            </Box>
          </Box>
        </Box>
        {isMobile ?
          <Drawer
            anchor="bottom"
            open={open}
            onClose={handleClose}
            className="forgot-password-dialog otp-dialog-wrapper"
          >
            <AddSkills
              onClose={handleClose}
              skills={addMoreSkills}
              selectedskills={selectedChips}
              onAddSkills={handleAddMoreSkills}
            />
          </Drawer> :
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="otp-dialog-wrapper"
          >
            <AddSkills
              onClose={handleClose}
              skills={addMoreSkills}
              selectedskills={selectedChips}
              onAddSkills={handleAddMoreSkills}
            />
          </Dialog>}
        {isMobile ?
          <Drawer
            anchor="bottom"
            open={openRemove}
            onClose={handleClose}
            className="forgot-password-dialog otp-dialog-wrapper"
          >
            <RemoveWorkExperience onClose={handleClose} onRemove={handleDeleteAction} title={workHistoryData?.organization_name} />
          </Drawer> :
          <Dialog
            open={openRemove}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="otp-dialog-wrapper"
          >
            <RemoveWorkExperience onClose={handleClose} onRemove={handleDeleteAction} title={workHistoryData?.organization_name} />
          </Dialog>}
        {isMobile ?
          <Drawer
            anchor="bottom"
            open={openOverlapping}
            onClose={handleCloseOverlapping}
            className="forgot-password-dialog otp-dialog-wrapper"
          >
            <Overlapping onClose={() => handleCloseOverlapping()}
              onConfirm={() => handleConfirm(newWorkData)}
              type={type}
              degree={degree} />
          </Drawer> :
          <Dialog
            open={openOverlapping}
            onClose={handleCloseOverlapping}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="otp-dialog-wrapper"
          >
            <Overlapping onClose={() => handleCloseOverlapping()}
              onConfirm={() => handleConfirm(newWorkData)}
              type={type}
              degree={degree} />
          </Dialog>}
      </FormProvider>
    </Box>
  );
};

export default AddWorkExperience;

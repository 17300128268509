import { Box, Button, Stack, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { createPDF } from 'utils/createPDF';
import { downloadBlobFile } from 'utils/Utils';
const ResumeDownload = ({ handleDownloadResumePdf, loading }) => {

  return (
    <Box>
      <Stack justifyContent={'center'}>
        <Box sx={{ textAlign: 'center' }} mb={3}>
          <Typography className="title" mb={1}>
            Download Resume
          </Typography>
          {/* <Typography className="info-text">
            Now you can proceed to the external platform & complete your application with the downloaded
            application. All the best.
          </Typography> */}
        </Box>
        <Stack direction={{ xs: 'column-reverse', md: 'row' }} alignItems={'center'} spacing={2.5}> 
          <Button fullWidth variant="outlined" size="medium"
            disabled={true}
            sx={{ opacity: '0.48', color: "gray!important", border: " 1px solid gray!important" }}>
            Download DocX (Word)
          </Button>
          <Button
            fullWidth
            variant="contained"
            size="medium"
            onClick={handleDownloadResumePdf}
            disabled={loading}
          >
            {loading ? 'Downloading...' : 'Download PDF'}
          </Button>
        </Stack>
      </Stack>
    </Box>
  )
}

export default ResumeDownload;

import React, { useState, useRef, useEffect } from 'react';
import { Box, Button, Stack, Typography, CircularProgress, Dialog, DialogTitle, DialogContent, Grid, Drawer } from '@mui/material';
import { styled } from '@mui/material/styles';
import uploadImg from '../assets/upload-img.png';
import PreviewResume from '../assets/perview-resume-img.png';
import { updateResumeUrl, getResumeUrl, addCandidateResume, uploadResumeInResume } from 'api/users';
import { useSnackbar } from 'components/snackbar';
import { Close as CloseIcon } from "@mui/icons-material";
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { useMediaQuery, useTheme } from "@mui/material";

const UploadButton = styled(Button)({
    position: 'relative',
    padding: 0,
    width: '50px',
    height: '50px',
    '&:hover .overlay': {
        opacity: 1,
    },
});

const Overlay = styled('div')({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 0,
    transition: 'opacity 0.3s ease',
    fontSize: '12px',
    textAlign: 'center',
});

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const UploadExistingProfile = ({ onProfilePhotoChange, setResume, isResume, handleUploadResume }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [resumeLink, setResumeLink] = useState(null);
    const fileInputRef = useRef(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [viewerKey, setViewerKey] = useState(0);
    useEffect(() => {
        fetchResume();
    }, []);

    const fetchResume = async () => {
        try {
            const response = await getResumeUrl();
            if (response?.success) {
                setResumeLink(process.env.REACT_APP_END_POINT + response?.data);
                setResume(true);
            } else {
                // enqueueSnackbar("Failed to load resume preview", { variant: "error" });
            }
        } catch (error) {
            console.error("Error fetching resume:", error);
            enqueueSnackbar("An error occurred while loading resume", { variant: "error" });
        }
    };
    const handleFileChange = async (e) => {
        if (!e.target.files || e.target.files.length === 0) return;

        const file = e.target.files[0];

        // Restrict file size to 2MB
        if (file.size > 2 * 1024 * 1024) {
            enqueueSnackbar("File size should be less than 2MB", { variant: "error" });
            return;
        }

        // Allow only PDFs
        if (file.type !== "application/pdf") {
            enqueueSnackbar("Only PDF files are allowed", { variant: "error" });
            return;
        }

        setIsLoading(true);
        try {
            const formData = new FormData();
            formData.append("file", file);

            let response;
            let resumeUrl;
            if (isResume) {
                const url = await uploadResumeInResume(formData);
                console.log(url);                
                setResumeLink(process.env.REACT_APP_END_POINT + url?.data);
                handleUploadResume(url?.data);
                return;
            }
            if (resumeLink) {
                response = await updateResumeUrl();
                if (response?.success && response?.data) {
                    resumeUrl = response?.data;
                } else {
                    enqueueSnackbar("Failed to update resume", { variant: "error" });
                    return;
                }
            } else {
                response = await addCandidateResume(formData);
                if (response?.success && response?.data?.resumeUrl) {
                    resumeUrl = response?.data?.resumeUrl;
                } else {
                    enqueueSnackbar("Failed to update resume", { variant: "error" });
                    return;
                }

            }

            if (response.success) {
                enqueueSnackbar(
                    resumeLink ? "Resume updated successfully!" : "Resume uploaded successfully!",
                    { variant: "success" }
                );
                onProfilePhotoChange(true);
                setResumeLink(process.env.REACT_APP_END_POINT + resumeUrl);
                setTimeout(() => fetchResume(), 250);
                setOpenDialog(false);
            } else {
                enqueueSnackbar(response.message || "Upload failed", { variant: "error" });
            }
        } catch (error) {
            console.error("Error handling resume upload:", error);
            enqueueSnackbar("An error occurred while uploading", { variant: "error" });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Box>
            <Stack
                className="upload-photo-stack-wrapper"
                direction={{ xs: "column", sm: "row" }}
                justifyContent={"space-between"}
                alignItems={"center"}
                spacing={{ xs: "20px", md: 0 }}
            >
                <Box className="upload-profile-photo-info">
                    <Typography className="step-heading xs-fs-16" variant="h6">
                        Your Uploaded Resume
                    </Typography>
                    <Typography className="step-info" variant="body2" gutterBottom>
                        This resume will be shared with recruiters for your job applications.
                    </Typography>
                </Box>

                {/* Resume Thumbnail */}
                <Box className="upload-photo-wrapper">
                    <UploadButton component="label" variant="contained" className="upload-profile-btn">
                        <img
                            alt="resume"
                            src={resumeLink ? PreviewResume : uploadImg}
                            onError={(e) => (e.target.src = uploadImg)}
                            className="select-resume-option-container"
                            onClick={() => setOpenDialog(true)}
                            // style={{
                            //     cursor: "pointer",
                            //     width: "80px",
                            //     height: "100px",
                            //     borderRadius: "8px",
                            //     border: "2px solid #ddd",
                            //     padding: "5px",
                            //     objectFit: "cover",
                            // }}
                        />
                    </UploadButton>
                </Box>
            </Stack>
            <input
                type="file"
                accept="application/pdf"
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: 'none' }}
            />
            {/* ✅ Full-Screen Mobile Preview */}
            {isMobile ? (
                <Drawer open={openDialog} anchor={'right'}
                    fullScreen
                    className='preview-resume-dialog'
                    sx={{ "& .MuiDrawer-paper": { width: "100%" } }}
                >
                    <Box sx={{ width: { xs: '100%', md: '450px' } }} className="job-app-drawer-container" role="presentation" >
                        <Box className="drawer-header">
                            <Stack direction="row" alignItems={'flex-start'} spacing={1}>
                                <Box>
                                    <Typography className="title">Uploaded Resume</Typography>
                                    {/* <Typography className="sub-text">Please help us with answers to few questions to complete your application</Typography> */}
                                </Box>
                            </Stack>
                        </Box>
                        <Box className="drawer-content">
                            <Box className="my-resume-overview" >
                                {isLoading ? (
                                    <Stack justifyContent="center" alignItems="center" >
                                        <CircularProgress size={40} />
                                    </Stack>
                                ) : resumeLink ? (
                                    <>
                                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js" />
                                        <Viewer fileUrl={resumeLink} width={window.innerWidth * 1} />
                                    </>
                                ) : (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            height: "100%",
                                            width: "100%",
                                            border: "2px dashed #ccc",
                                            borderRadius: "8px",
                                            cursor: "pointer",
                                            textAlign: "center",
                                            padding: "20px"
                                        }}
                                        onClick={() => fileInputRef.current.click()}
                                    >
                                        <Typography variant="body1" color="textSecondary">No resume uploaded</Typography>
                                        <Button
                                            variant="contained"
                                            sx={{ mt: 2, backgroundColor: "#1575C6", "&:hover": { backgroundColor: "#0056b3" } }}
                                        >
                                            Upload PDF
                                        </Button>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                        <Box className="drawer-footer">
                            <Stack direction="row" spacing={2}>
                                <Button fullWidth size="large" className="cancel-btn"
                                    onClick={() => fileInputRef.current.click()}
                                    disabled={isLoading}>
                                    Replace
                                </Button>
                                <Button fullWidth variant="contained" size="large" className="update-btn"
                                    onClick={() => setOpenDialog(false)}
                                    disabled={isLoading}>
                                    Continue
                                </Button>
                            </Stack>
                        </Box>
                    </Box>
                </Drawer>
            ) : (
                /* ✅ Desktop Dialog */
                <Dialog open={openDialog} onClose={() => setOpenDialog(false)} sx={{ maxWidth: "600px", margin: "auto", zIndex: 1300 }} fullWidth>
                    <DialogTitle sx={{ padding: "12px 20px", borderBottom: "1px solid #ddd" }}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography variant="h6" sx={{ fontWeight: 600 }}>Uploaded Resume</Typography>
                            <Stack direction="row" spacing={1}>
                                <Button
                                    variant="text"
                                    size="small"
                                    onClick={() => fileInputRef.current.click()}
                                    disabled={isLoading}
                                    sx={{ color: "#1575C6", textTransform: "none", fontWeight: 500 }}
                                >
                                    Replace
                                </Button>
                                <Button
                                    variant="contained"
                                    size="small"
                                    onClick={() => setOpenDialog(false)}
                                    disabled={isLoading}
                                    sx={{
                                        backgroundColor: "#1575C6",
                                        color: "white",
                                        textTransform: "none",
                                        fontWeight: 500,
                                        "&:hover": { backgroundColor: "#0056b3" },
                                    }}
                                >
                                    Continue
                                </Button>
                            </Stack>
                        </Stack>
                    </DialogTitle>

                    {/* PDF Viewer */}
                    <DialogContent sx={{ padding: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Box
                            className="resume-options-preview"
                            sx={{
                                height: "75vh",
                                width: "100%",
                                overflow: "hidden",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "8px",
                            }}
                        >
                            {isLoading ? (
                                <Stack justifyContent="center" alignItems="center">
                                    <CircularProgress size={40} />
                                </Stack>
                            ) : resumeLink ? (
                                <>
                                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js" />
                                    <Box sx={{ width: "100%", height: "100%" }}>
                                        <Viewer fileUrl={resumeLink} />
                                    </Box>
                                </>
                            ) : (
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "100%",
                                        width: "100%",
                                        border: "2px dashed #ccc",
                                        borderRadius: "8px",
                                        cursor: "pointer",
                                        textAlign: "center",
                                        padding: "20px"
                                    }}
                                    onClick={() => fileInputRef.current.click()}
                                >
                                    <Typography variant="body1" color="textSecondary">No resume uploaded</Typography>
                                    <Button
                                        variant="contained"
                                        sx={{ mt: 2, backgroundColor: "#1575C6", "&:hover": { backgroundColor: "#0056b3" } }}
                                    >
                                        Upload PDF
                                    </Button>
                                </Box>
                            )}

                        </Box>

                    </DialogContent>
                </Dialog>
            )}
        </Box>
    );
};

export default UploadExistingProfile;
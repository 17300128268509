import React, { useState } from 'react';
import { Box, Button, Card, CardContent, Link, IconButton, InputLabel, OutlinedInput, Stack, SvgIcon, Typography, TextField, FormControl, InputAdornment, Divider } from '@mui/material'
import { MuiOtpInput } from 'mui-one-time-password-input'


const UnsaveCard = ({ onClose, onUnsave, title }) => {
    const [isRemoving, setIsRemoving] = useState(false);

    const handleRemove = () => {
        setIsRemoving(true);
        onUnsave();
    };

    return (
        <Box className="ext-job-dialog-wraper">
            <Stack justifyContent={'center'}>
                <Box sx={{ textAlign: 'center' }} mb={3}>
                    <Typography className="title" mb={1}>Unsave this job</Typography>
                    <Typography className="info-text">This job would no longer be visible under “Saved Jobs” section. However, you can always find it under “Jobs Market” as long as it is active</Typography>
                </Box>
                <Stack direction={{ xs: 'column-reverse', md: 'row' }} alignItems={'center'} spacing={2.5}>
                    <Button fullWidth variant="outlined" size="medium" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button fullWidth variant="contained" size="medium" onClick={handleRemove} disabled={isRemoving}>
                        {isRemoving ? 'Unsaving...' : 'Unsave Job'}
                    </Button>
                </Stack>
            </Stack>
        </Box>
    )
}

export default UnsaveCard;

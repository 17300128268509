import React from 'react'
import { Box, Card, CardContent, Chip, Container, Divider, Grid, List, ListItem, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { changeNameFormat } from 'utils/Utils';
import { useAuth } from 'hooks/useAuthContext';
import dayjs from 'dayjs';
const imgLogo = `${process.env.REACT_APP_HOMEPAGE}assets/default.png`;

const ResumeFormatVersionOne = ({ educationData, data, summaryData, workExperienceData, customSection, certificateData, financeTechologiesData, pdfRef }) => {
    const { user, updateContext } = useAuth();
    const groupedData = workExperienceData?.reduce((acc, data) => {
        if (data?.organization_name !== 'Work Gap') {
            if (!acc[data.organization_name]) {
                acc[data.organization_name] = {
                    industry: data.industry,
                    entries: [],
                };
            }
            acc[data.organization_name].entries.push(data);
        }
        return acc;
    }, {});

    function formatExperience(experience) {
        if (!experience) return 'Present';
      
    const experienceParts = experience.toString().split('.');
    const years = parseInt(experienceParts[0], 10); // Whole number part as years
    const decimalPart = experienceParts[1] ? experienceParts[1].substring(0, 2) : '0'; 
      
    const months = parseInt(decimalPart, 10); // Convert decimal to months

    // Create a formatted string for years and months
    let yearLabel = years === 1 ? 'year' : 'years';
    let monthLabel = months === 1 ? 'month' : 'months';
  
    if (years > 0 && months > 0) {
      return `${years} ${yearLabel} and ${months} ${monthLabel}`;
    } else if (years > 0) {
      return `${years} ${yearLabel}`;
    } else if (months > 0) {
      return `${months} ${monthLabel}`;
    }
        return 'Less than a month';
      }
      
      
    return (
        <Box >
            <Container maxWidth="xl">
                <Box sx={{
                    backgroundColor: "white",
                    position: 'relative',
                    width: '100%',
                    display: 'block',
                    padding: '1.5rem 0.5rem',
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                    // marginTop: '1rem',
                    // marginBottom: '1rem',
                    minHeight: '76vh', //70vh
                    maxHeight: '90vh',
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    },

                }}>

                    <Box ref={pdfRef}>
                        <Box>
                            <Stack direction="row" spacing={1} alignItems="start" mb={1}>
                                <Box sx={{ width: '100%' }}>
                                    <Stack direction="row" spacing={1.5} alignItems="center">
                                        <Box sx={{ display: 'inline-flex', overflow: 'hidden', borderRadius: '4.8px' }}>
                                            <img alt="profile" width="55px" height="auto" src={user?.photo || imgLogo}
                                                onError={(e) => (e.target.src = imgLogo)}
                                            />
                                        </Box>
                                        <Box>
                                            <Typography mb={0.5} sx={{
                                                color: '#002149',
                                                fontFamily: 'Times New Roman',
                                                fontSize: '10px',
                                                fontStyle: 'normal',
                                                fontWeight: '700',
                                                lineHeight: '10px'
                                            }}>{changeNameFormat(user?.name)}</Typography>
                                            <Typography
                                                sx={{
                                                    color: '#002149',
                                                    fontFamily: 'Times New Roman',
                                                    fontSize: '10px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '10px'
                                                }}
                                            >{user?.email} {user?.phone ? `| +91-${user?.phone}` : ''}  {user?.city ? `| ${user?.city}` : ''}</Typography>
                                        </Box>

                                    </Stack>
                                </Box>
                            </Stack>
                        </Box>

                        {summaryData &&
                            <Box>
                                <Divider sx={{ borderStyle: 'solid', borderColor: '#E7EBFD', borderWidth: '1px', marginTop: '1rem', marginBottom: '1rem' }} />
                                <Box mb={2}>
                                    <Typography mb={0.5} sx={{
                                        color: '#002149',
                                        fontFamily: 'Times New Roman',
                                        fontSize: '10px',
                                        fontStyle: 'normal',
                                        fontWeight: '700',
                                        lineHeight: '10px'
                                    }}>Professional Summary</Typography>
                                    <Typography sx={{
                                        color: '#002149',
                                        fontFamily: 'Times New Roman',
                                        fontSize: '7px',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        lineHeight: '7px'
                                    }}>{summaryData}</Typography>
                                </Box>
                            </Box>
                        }

                        {educationData?.filter(item => item?._id)?.length > 0 && (
                            <Box mb={2}>
                                <TableContainer sx={{ border: '1.5px solid #E7EBFD', borderRadius: '8px' }}>
                                    <Table sx={{ minWidth: '100%', overflow: 'hidden', borderRadius: '8px' }} size="small" aria-label="a dense table">
                                        <TableHead sx={{
                                            borderRadius: '8px 8px 0px 0px',
                                            background: '#E7EBFD'
                                        }}>
                                            <TableRow>
                                                <TableCell sx={{borderBottom: 'none',
                                                background: '#E7EBFD'}}>
                                                    <Typography sx={{
                                                        color: '#002149',
                                                        fontFamily: 'Times New Roman',
                                                        fontSize: '10px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '700',
                                                        lineHeight: '10px'
                                                    }}>Education</Typography>
                                                </TableCell>
                                                <TableCell sx={{ borderBottom: 'none',
                                                background: '#E7EBFD' }}>
                                                    <Typography sx={{
                                                        color: '#002149',
                                                        fontFamily: 'Times New Roman',
                                                        fontSize: '10px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '700',
                                                        lineHeight: '10px'
                                                    }}>Key Skills</Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {educationData?.filter(item => item?._id)?.map((item, index) => (
                                                <TableRow key={index}>
                                                    <TableCell sx={{ width: '70%', borderRight: '1px solid rgba(224, 224, 224, 1)'}}>
                                                        <Stack direction="row" alignItems={'center'} justifyContent={'space-between'} spacing={2} mb={1}>
                                                            <Typography sx={{
                                                                color: '#002149',
                                                                fontFamily: 'Times New Roman',
                                                                fontSize: '7px',
                                                                fontStyle: 'normal',
                                                                fontWeight: '700',
                                                                lineHeight: '7px'
                                                            }}>{item?.degree_received || ''} ( {item?.field_of_study || ''}), {item?.education_institute_name || ''}</Typography>
                                                            <Typography sx={{
                                                                color: '#374151',
                                                                fontFamily: 'Times New Roman',
                                                                fontSize: '7px',
                                                                fontStyle: 'normal',
                                                                fontWeight: '400',
                                                                lineHeight: '7px',
                                                                width: '80px'
                                                            }}>{dayjs(item?.education_from).format('MMM YY')} - {dayjs(item?.education_to).format('MMM YY') || '-'}</Typography>
                                                        </Stack>

                                                        <List sx={{ paddingTop: 0, paddingLeft: '1.5rem', listStyleType: 'disc' }}>
                                                            {item?.key_accomplishments && item?.key_accomplishments?.map((accomplishment, index) => (
                                                                <ListItem key={index} sx={{
                                                                    display: 'list-item',
                                                                }} disablePadding>
                                                                    <Typography sx={{
                                                                        color: '#002149',
                                                                        fontFamily: 'Times New Roman',
                                                                        fontSize: '7px',
                                                                        fontStyle: 'normal',
                                                                        fontWeight: '200',
                                                                        lineHeight: '7px'
                                                                    }}>{accomplishment}</Typography>
                                                                </ListItem>
                                                            ))}
                                                        </List>
                                                    </TableCell>
                                                    <TableCell sx={{width: '30%'}}>
                                                    <Stack direction="row" alignItems={'flex-start'} flexWrap="wrap"> 
                                                        {item?.skills && item?.skills.map((skill, index) => (
                                                            <Typography key={index} sx={{
                                                                color: '#002149',
                                                                fontFamily: 'Times New Roman',
                                                                fontSize: '7px',
                                                                fontStyle: 'normal',
                                                                fontWeight: '400',
                                                                lineHeight: '7px'
                                                            }}>{skill}</Typography>
                                                        ))}
                                                         </Stack>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        )}

                        {certificateData?.filter(item => item?._id)?.length > 0 && (
                            <Box mb={2}>
                                <Card variant="outlined" sx={{ borderRadius: '8px' }}>
                                    <CardContent sx={{ padding: '0!important' }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                padding: '4px 0px 4px 10px',
                                                alignItems: 'flex-start',
                                                alignSelf: 'stretch',
                                                background: '#E7EBFD'
                                            }}>
                                            <Typography sx={{
                                                color: '#002149',
                                                fontFamily: 'Times New Roman',
                                                fontSize: '10px',
                                                fontStyle: 'normal',
                                                fontWeight: '700',
                                                lineHeight: '10px'
                                            }}>Certification</Typography>
                                        </Box>

                                        <Box sx={{
                                            padding: '1rem 1.5rem'
                                        }}>
                                            <Stack spacing={1}>
                                                {certificateData?.filter(item => item?._id).map((item, index) => (
                                                    <Box key={index}>
                                                        <Stack direction="row" alignItems={'center'} justifyContent={'space-between'} spacing={2}>
                                                            <Typography sx={{
                                                                color: '#002149',
                                                                fontFamily: 'Times New Roman',
                                                                fontSize: '7px',
                                                                fontStyle: 'normal',
                                                                fontWeight: '700',
                                                                lineHeight: '7px'
                                                            }}>{item?.certificate_received || ''}{item?.learning_format && ` (${item?.learning_format})`}</Typography>
                                                            <Typography sx={{
                                                                color: '#374151',
                                                                fontFamily: 'Times New Roman',
                                                                fontSize: '7px',
                                                                fontStyle: 'normal',
                                                                fontWeight: '400',
                                                                lineHeight: '7px'
                                                            }}>{dayjs(item?.education_from).format('MMM YY')} - {dayjs(item?.education_to).format('MMM YY') || '-'}</Typography>
                                                        </Stack>
                                                    </Box>
                                                ))}
                                            </Stack>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Box>
                        )}

                        {workExperienceData?.filter(item => item?._id)?.length > 0 && (
                            <Box mb={2}>
                                <Box mb={2}>
                                    <Typography sx={{
                                        color: '#002149',
                                        fontFamily: 'Times New Roman',
                                        fontSize: '10px',
                                        fontStyle: 'normal',
                                        fontWeight: '700',
                                        lineHeight: '12px'
                                    }} mb={1}>Work Experience Overview</Typography>

                                    <TableContainer sx={{ border: '1.5px solid #E7EBFD', borderRadius: '8px' }}>
                                        <Table sx={{ minWidth: '100%', overflow: 'hidden', borderRadius: '8px' }} size="small" aria-label="a dense table">
                                            <TableHead sx={{
                                                borderRadius: '8px 8px 0px 0px',
                                                background: '#E7EBFD'
                                            }}>
                                                <TableRow>
                                                    <TableCell sx={{ borderBottom: 'none', 
                                                background: '#E7EBFD' }}>
                                                        <Typography sx={{
                                                            color: '#002149',
                                                            fontFamily: 'Times New Roman',
                                                            fontSize: '10px',
                                                            fontStyle: 'normal',
                                                            fontWeight: '700',
                                                            lineHeight: '10px'
                                                        }}>Period</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ borderBottom: 'none',
                                                background: '#E7EBFD' }}>
                                                        <Typography sx={{
                                                            color: '#002149',
                                                            fontFamily: 'Times New Roman',
                                                            fontSize: '10px',
                                                            fontStyle: 'normal',
                                                            fontWeight: '700',
                                                            lineHeight: '10px'
                                                        }}>Company Name</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ borderBottom: 'none', 
                                                background: '#E7EBFD' }}>
                                                        <Typography sx={{
                                                            color: '#002149',
                                                            fontFamily: 'Times New Roman',
                                                            fontSize: '10px',
                                                            fontStyle: 'normal',
                                                            fontWeight: '700',
                                                            lineHeight: '10px'
                                                        }}>Industry</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ borderBottom: 'none', 
                                                background: '#E7EBFD' }}>
                                                        <Typography sx={{
                                                            color: '#002149',
                                                            fontFamily: 'Times New Roman',
                                                            fontSize: '10px',
                                                            fontStyle: 'normal',
                                                            fontWeight: '700',
                                                            lineHeight: '10px'
                                                        }}>Last Designation</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ borderBottom: 'none', 
                                                background: '#E7EBFD'}}>
                                                        <Typography sx={{
                                                            color: '#002149',
                                                            fontFamily: 'Times New Roman',
                                                            fontSize: '10px',
                                                            fontStyle: 'normal',
                                                            fontWeight: '700',
                                                            lineHeight: '10px'
                                                        }}>Team</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                            {workExperienceData?.filter(item => item?._id && (item?.organization_name === 'Work Gap' ? item?.show_in_resume : true))?.map((data, index) => (
                                                    data?.organization_name === 'Work Gap' ? (data?.show_in_resume && (
                                                        <TableRow key={index} sx={{ background: index % 2 === 1 ? '#E7EBFD' : ''}}> 
                                                         <TableCell sx={{ borderBottom: 'none',  }}>                                                          
                                                                <Typography sx={{
                                                                    color: '#002149',
                                                                    fontFamily: 'Times New Roman',
                                                                    fontSize: '7px',
                                                                    fontStyle: 'normal',
                                                                    fontWeight: '400',
                                                                    lineHeight: '7px'
                                                                }}>{dayjs(data?.work_history_from).format('MMM YY')} - {data?.present ? 'Present' : dayjs(data?.work_history_to).format('MMM YY')}</Typography>
                                                            </TableCell>
                                                            <TableCell sx={{ borderBottom: 'none',  }}>
                                                                <Typography sx={{
                                                                    color: '#002149',
                                                                    fontFamily: 'Times New Roman',
                                                                    fontSize: '7px',
                                                                    fontStyle: 'normal',
                                                                    fontWeight: '400',
                                                                    lineHeight: '7px'
                                                                }}>{data?.gap_title ? `${data?.gap_title} (${data?.organization_name || '-'})` : '-'}</Typography>
                                                            </TableCell>
                                                            <TableCell sx={{ borderBottom: 'none',  }}>
                                                                <Typography sx={{
                                                                    color: '#002149',
                                                                    fontFamily: 'Times New Roman',
                                                                    fontSize: '7px',
                                                                    fontStyle: 'normal',
                                                                    fontWeight: '400',
                                                                    lineHeight: '7px'
                                                                }}>{data?.industry || '-'}</Typography>
                                                            </TableCell>
                                                            <TableCell sx={{ borderBottom: 'none',  }}>
                                                                <Typography sx={{
                                                                    color: '#002149',
                                                                    fontFamily: 'Times New Roman',
                                                                    fontSize: '7px',
                                                                    fontStyle: 'normal',
                                                                    fontWeight: '400',
                                                                    lineHeight: '7px'
                                                                }}>{data?.gap_explaination || '-'}</Typography>
                                                            </TableCell>
                                                            <TableCell sx={{ borderBottom: 'none',  }}>
                                                                <Typography sx={{
                                                                    color: '#002149',
                                                                    fontFamily: 'Times New Roman',
                                                                    fontSize: '7px',
                                                                    fontStyle: 'normal',
                                                                    fontWeight: '400',
                                                                    lineHeight: '7px'
                                                                }}>{data?.team_size_managed || '-'}</Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    )) : (
                                                        <TableRow key={index} sx={{ background: index % 2 === 1 ? '#E7EBFD' : ''}}>     
                                                            <TableCell sx={{ borderBottom: 'none',  }}>
                                                                <Typography sx={{
                                                                    color: '#002149',
                                                                    fontFamily: 'Times New Roman',
                                                                    fontSize: '7px',
                                                                    fontStyle: 'normal',
                                                                    fontWeight: '400',
                                                                    lineHeight: '7px'
                                                                }}>{dayjs(data?.work_history_from).format('MMM YY')} - {data?.present ? 'Present' : dayjs(data?.work_history_to).format('MMM YY')}</Typography>
                                                            </TableCell>
                                                            <TableCell sx={{ borderBottom: 'none',  }}>
                                                                <Typography sx={{
                                                                    color: '#002149',
                                                                    fontFamily: 'Times New Roman',
                                                                    fontSize: '7px',
                                                                    fontStyle: 'normal',
                                                                    fontWeight: '400',
                                                                    lineHeight: '7px'
                                                                }}>{data.organization_name || '-'}</Typography>
                                                            </TableCell>
                                                            <TableCell sx={{ borderBottom: 'none',  }}>
                                                                <Typography sx={{
                                                                    color: '#002149',
                                                                    fontFamily: 'Times New Roman',
                                                                    fontSize: '7px',
                                                                    fontStyle: 'normal',
                                                                    fontWeight: '400',
                                                                    lineHeight: '7px'
                                                                }}>{data?.organization_details?.industry_sector || '-'}</Typography>
                                                            </TableCell>
                                                            <TableCell sx={{ borderBottom: 'none',  }}>
                                                                <Typography sx={{
                                                                    color: '#002149',
                                                                    fontFamily: 'Times New Roman',
                                                                    fontSize: '7px',
                                                                    fontStyle: 'normal',
                                                                    fontWeight: '400',
                                                                    lineHeight: '7px'
                                                                }}>{data?.highest_designation || '-'}</Typography>
                                                            </TableCell>
                                                            <TableCell sx={{ borderBottom: 'none',  }}>
                                                                <Typography sx={{
                                                                    color: '#002149',
                                                                    fontFamily: 'Times New Roman',
                                                                    fontSize: '7px',
                                                                    fontStyle: 'normal',
                                                                    fontWeight: '400',
                                                                    lineHeight: '7px'
                                                                }}>{data?.team_size_managed || '-'}</Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>

                                <TableContainer sx={{ border: '1.5px solid #E7EBFD', borderRadius: '8px' }}>
                                    <Table sx={{ minWidth: '100%', overflow: 'hidden', borderRadius: '8px' }} size="small" aria-label="a dense table">
                                        <TableHead
                                            sx={{
                                                borderRadius: '8px 8px 0px 0px',
                                                background: '#E7EBFD',
                                            }}
                                        >
                                            <TableRow>
                                                <TableCell sx={{ borderBottom: 'none', 
                                                background: '#E7EBFD'}}>
                                                    <Typography
                                                        sx={{
                                                            color: '#002149',
                                                            fontFamily: 'Times New Roman',
                                                            fontSize: '10px',
                                                            fontStyle: 'normal',
                                                            fontWeight: '700',
                                                            lineHeight: '10px',
                                                        }}
                                                    >
                                                        Key Responsibilities and Accomplishments
                                                    </Typography>
                                                </TableCell>
                                                <TableCell sx={{ borderBottom: 'none',
                                                background: '#E7EBFD'}}>
                                                    <Typography
                                                        sx={{
                                                            color: '#002149',
                                                            fontFamily: 'Times New Roman',
                                                            fontSize: '10px',
                                                            fontStyle: 'normal',
                                                            fontWeight: '700',
                                                            lineHeight: '10px',
                                                        }}
                                                    >
                                                        Key Skills
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {Object.keys(groupedData)?.map((organizationName, orgIndex) => (
                                                <TableRow key={orgIndex}>
                                                    <TableCell sx={{ width: '70%', borderRight: '1px solid rgba(224, 224, 224, 1)'}}>
                                                        {/* Organization Name */}
                                                        <Typography
                                                            sx={{
                                                                color: '#111827',
                                                                fontFamily: 'Times New Roman',
                                                                fontSize: '7px',
                                                                fontStyle: 'normal',
                                                                fontWeight: '400',
                                                                lineHeight: '7px'
                                                            }}
                                                            mb={1}
                                                        >
                                                            <Box component={'span'} sx={{ fontWeight: 700 }}>
                                                                {organizationName}
                                                            </Box>
                                                            {organizationName?.industry && ` - ${organizationName?.industry}`}
                                                        </Typography>

                                                        {/* Display Designations and Details for each group */}
                                                        {groupedData[organizationName]?.entries?.map((data, dataIndex) => (
                                                            <div key={dataIndex}>
                                                                <Stack direction="row" alignItems={'center'} justifyContent={'space-between'} spacing={2} mb={1}>
                                                                    <Typography
                                                                        sx={{
                                                                            color: '#111827',
                                                                            fontFamily: 'Times New Roman',
                                                                            fontSize: '7px',
                                                                            fontStyle: 'normal',
                                                                            fontWeight: '400',
                                                                            lineHeight: '7px',
                                                                        }}
                                                                    >
                                                                        <Box component={'span'} sx={{ fontWeight: 700 }}>
                                                                            {data.highest_designation}
                                                                        </Box>
                                                                        {data.job_type && ` (${data.job_type})`}
                                                                    </Typography>
                                                                    <Typography
                                                                        sx={{
                                                                            color: '#374151',
                                                                            fontFamily: 'Times New Roman',
                                                                            fontSize: '7px',
                                                                            fontStyle: 'normal',
                                                                            fontWeight: '400',
                                                                            lineHeight: '7px',
                                                                        }}
                                                                    >
                                                                        {dayjs(data?.work_history_from).format('MMM YY')} -{' '}
                                                                        {data?.present ? 'Present' : dayjs(data?.work_history_to).format('MMM YY')}
                                                                    </Typography>
                                                                </Stack>

                                                                {/* Accomplishments List */}
                                                                <List sx={{ paddingTop: 0, paddingLeft: '1.5rem', listStyleType: 'disc' }}>
                                                                    {data?.key_accomplishments &&
                                                                        data?.key_accomplishments.map((accomplishment, keyIndex) => (
                                                                            <ListItem key={keyIndex}
                                                                                sx={{
                                                                                    display: 'list-item',
                                                                                }}
                                                                                disablePadding>
                                                                                <Typography
                                                                                    sx={{
                                                                                        color: '#002149',
                                                                                        fontFamily: 'Times New Roman',
                                                                                        fontSize: '7px',
                                                                                        fontStyle: 'normal',
                                                                                        fontWeight: '400',
                                                                                        lineHeight: '7px',
                                                                                    }}
                                                                                >
                                                                                    {accomplishment}
                                                                                </Typography>
                                                                            </ListItem>
                                                                        ))}
                                                                </List>
                                                            </div>
                                                        ))}
                                                    </TableCell>

                                                    {/* Display Skills */}
                                                    <TableCell sx={{width: '30%'}}>
                                                    <Stack direction="row" alignItems={'flex-start'} flexWrap="wrap"> 
                                                        {groupedData[organizationName]?.entries?.map((data, dataIndex) => (
                                                           data?.skills &&
                                                            data?.skills.map((skill, keyIndex) => (
                                                                <Typography
                                                                    key={keyIndex}
                                                                    sx={{
                                                                        color: '#002149',
                                                                        fontFamily: 'Times New Roman',
                                                                        fontSize: '7px',
                                                                        fontStyle: 'normal',
                                                                        fontWeight: '400',
                                                                        lineHeight: '7px',
                                                                        
                                                                    }}
                                                                >
                                                                    {skill}
                                                                </Typography>
                                                            ))
                                                        ))}
                                                        </Stack>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                            </Box>
                        )}

                        {customSection?.length > 0 && customSection.map((section, keyIndex) => (
                            section?.key && section?.message && section?.message !== '<p><br></p>' ?(
                                <Box mb={2} key={keyIndex}>
                                    <Typography sx={{
                                        color: '#002149',
                                        fontFamily: 'Times New Roman',
                                        fontSize: '10px',
                                        fontStyle: 'normal',
                                        fontWeight: '700',
                                        lineHeight: '10px'
                                    }}>{section?.key}</Typography>
                                    <Typography
                                        sx={{
                                            color: '#002149',
                                            fontFamily: 'Times New Roman',
                                            fontSize: '7px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: '7px',
                                            '& ul': {
                                                listStyleType: 'disc',
                                                marginLeft: '20px',
                                                padding: '2px'
                                            },
                                            '& ol': {
                                                listStyleType: 'decimal',
                                                marginLeft: '20px',
                                                padding: '2px'
                                            },
                                            '& li': {
                                                marginBottom: '1px',
                                                padding: '2px'
                                            },
                                        }}
                                    >
                                        <div dangerouslySetInnerHTML={{ __html: section?.message }} />
                                    </Typography>
                                </Box>
                            ) : null
                        ))}

                        {workExperienceData?.filter(item => item?._id)?.length > 0 && (
                            <Box>
                                <Box mb={2}>
                                    <Typography sx={{
                                        color: '#002149',
                                        fontFamily: 'Times New Roman',
                                        fontSize: '10px',
                                        fontStyle: 'normal',
                                        fontWeight: '700',
                                        lineHeight: '12px'
                                    }} mb={1}>Skills Summary</Typography>

                                    <TableContainer sx={{ border: '1.5px solid #E7EBFD', borderRadius: '8px' }}>
                                        <Table sx={{ minWidth: '100%', overflow: 'hidden', borderRadius: '8px' }} size="small" aria-label="a dense table">
                                            <TableHead sx={{
                                                borderRadius: '8px 8px 0px 0px',    
                                                background: '#E7EBFD'
                                            }}>
                                                <TableRow>
                                                    <TableCell sx={{ borderBottom: 'none', 
                                                background: '#E7EBFD' }}>
                                                        <Typography sx={{
                                                            color: '#002149',
                                                            fontFamily: 'Times New Roman',
                                                            fontSize: '10px',
                                                            fontStyle: 'normal',
                                                            fontWeight: '700',
                                                            lineHeight: '10px'
                                                        }}>Expertise</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ borderBottom: 'none', 
                                                background: '#E7EBFD'}}>
                                                        <Typography sx={{
                                                            color: '#002149',
                                                            fontFamily: 'Times New Roman',
                                                            fontSize: '10px',
                                                            fontStyle: 'normal',
                                                            fontWeight: '700',
                                                            lineHeight: '10px'
                                                        }}>Key Skills</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ borderBottom: 'none', 
                                                background: '#E7EBFD'}}>
                                                        <Typography sx={{
                                                            color: '#002149',
                                                            fontFamily: 'Times New Roman',
                                                            fontSize: '10px',
                                                            fontStyle: 'normal',
                                                            fontWeight: '700',
                                                            lineHeight: '10px',
                                                            width : '100px'
                                                        }}>Where learned/ used?</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ borderBottom: 'none', 
                                                background: '#E7EBFD'}}>
                                                        <Typography sx={{
                                                            color: '#002149',
                                                            fontFamily: 'Times New Roman',
                                                            fontSize: '10px',
                                                            fontStyle: 'normal',
                                                            fontWeight: '700',
                                                            lineHeight: '10px'
                                                        }}>Experience</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                            {workExperienceData?.filter(item => item?._id && item?.organization_name !== 'Work Gap')?.map((data, index) => (
                                                    <TableRow key={index} sx={{ background: index % 2 === 1 ? '#E7EBFD' : ''}}>
                                                        <TableCell sx={{ borderBottom: 'none',  }}>
                                                            <Typography sx={{
                                                                color: '#002149',
                                                                fontFamily: 'Times New Roman',
                                                                fontSize: '7px',
                                                                fontStyle: 'normal',
                                                                fontWeight: '400',
                                                                lineHeight: '7px'
                                                            }}>{data.highest_designation}</Typography>
                                                        </TableCell>
                                                        <TableCell sx={{ borderBottom: 'none', }}>
                                                            <Typography sx={{
                                                                color: '#002149',
                                                                fontFamily: 'Times New Roman',
                                                                fontSize: '7px',
                                                                fontStyle: 'normal',
                                                                fontWeight: '400',
                                                                lineHeight: '7px'
                                                            }}>{data?.skills?.join(',')}</Typography>
                                                        </TableCell>
                                                        <TableCell sx={{ borderBottom: 'none',  }}>
                                                            <Typography sx={{
                                                                color: '#002149',
                                                                fontFamily: 'Times New Roman',
                                                                fontSize: '7px',
                                                                fontStyle: 'normal',
                                                                fontWeight: '400',
                                                                lineHeight: '7px',
                                                            }}>{data.organization_name}</Typography>
                                                        </TableCell>
                                                        <TableCell sx={{ borderBottom: 'none', }}>
                                                            <Typography sx={{
                                                                color: '#002149',
                                                                fontFamily: 'Times New Roman',
                                                                fontSize: '7px',
                                                                fontStyle: 'normal',
                                                                fontWeight: '400',
                                                                lineHeight: '7px'
                                                            }}>{formatExperience(data.work_experience)}</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </Box>
                        )}

                        {financeTechologiesData?.length > 0 && (
                            <Box >
                                <Typography sx={{
                                    color: '#002149',
                                    fontFamily: 'Times New Roman',
                                    fontSize: '10px',
                                    fontStyle: 'normal',
                                    fontWeight: '700',
                                    lineHeight: '10px'
                                }} mb={1}>Technical Competencies</Typography>
                                <Stack direction="row" flexWrap="wrap" alignItems="center">
                                    {financeTechologiesData?.map((techSkills, techIndex) => (
                                        <Chip
                                            key={techIndex}
                                            label={techSkills}
                                            variant="outlined"
                                            sx={{
                                                borderRadius: '4px',
                                                border: '0.5px solid var(--Neutral-200, #C5D1DB)',
                                                background: '#F9FAFF',
                                                marginRight: '0.5rem',
                                                marginBottom: '0.5rem',
                                                height: '16px', // Smaller height for the chip
                                                padding: '0 4px', // Reduced padding for a more compact look
                                                '& .MuiChip-label': {
                                                    color: '#002149',
                                                    fontFamily: 'Times New Roman',
                                                    fontSize: '7px', // Smaller font size
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '7px',
                                                    padding: '0', // Remove default padding
                                                },
                                            }}
                                        />
                                    ))}
                                </Stack>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}

export default ResumeFormatVersionOne

import React, { useState } from 'react';
import { Box, Button, Icon, Stack, Typography, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import uploadImg from '../assets/upload-img.png';
import { uploadProfilePic, uploadPhotoInResume } from 'api/users';
import { enqueueSnackbar } from 'notistack';
import { useAuth } from 'hooks/useAuthContext';
import { useSnackbar } from 'components/snackbar';
import { Link } from 'react-router-dom';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const UploadButton = styled(Button)({
  position: 'relative',
  padding: 0,
  width: '50px',
  height: '50px',
  '&:hover .overlay': {
    opacity: 1,
  },
});

const Overlay = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  color: 'white',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  opacity: 0,
  transition: 'opacity 0.3s ease',
  fontSize: '12px',
  textAlign: 'center',
});

const UploadProfile = ({ onProfilePhotoChange, isResume, handleResumeProfile, profileData }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { loading, user, updateContext } = useAuth();
  const imgLogo = `${process.env.REACT_APP_HOMEPAGE}assets/default.png`;
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = async (e) => {
    if (!e.target?.files?.length) return false;
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append('file', e.target.files[0]);
      if (e.target.files[0]?.size > 1048576) {
        enqueueSnackbar("image size should be less than 1 MB", { variant: 'error' });
        return false;
      }

      if (isResume) {
        const url = await uploadPhotoInResume(formData);
        console.log(url);
        handleResumeProfile({ photo: url?.data?.photo, photoUrl: url?.data?.photoUrl });
        return;
      }
      const response = await uploadProfilePic(formData);
      if (response?.success && response.data) {
        updateContext({ photo: response?.data });
        onProfilePhotoChange();
      }
    } catch (error) {
      console.error('Error uploading file:', error);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  return (
    <Box>
      <Stack className="upload-photo-stack-wrapper" direction={{ xs: 'column', sm: 'row' }} justifyContent={'space-between'} alignItems={'center'} spacing={{ xs: '20px', md: 0 }}>
        <Box className="upload-profile-photo-info">
          <Typography className="step-heading xs-fs-16" variant="h6">
            Your Photo <Typography className="sub-text xs-fs-12" variant="span">(Max. 1MB)</Typography></Typography>
          <Typography className="step-info" variant="body2" gutterBottom>
            Attaching a professional photo leaves a good impression with recruiters. Here are some <Link href="#"> good examples</Link>
          </Typography>
        </Box>

        <Box className="upload-photo-wrapper">
          <Button component="label" variant="contained" className="upload-profile-btn" sx={{ '&:hover .overlay': { opacity: 1 } }}>
            <Icon className="upload-profile-icon">
              {isLoading ? (
                <CircularProgress size={24} /> // Show spinner while uploading
              ) : (
                <img alt="profile"
                  src={profileData?.photo || user?.photo || uploadImg}
                  onError={(e) => (e.target.src = uploadImg)}
                />
              )}
              <Overlay className="overlay">{user?.photo ? 'Change Photo' : 'Upload Photo'}</Overlay>
            </Icon>
            <VisuallyHiddenInput
              id="fileInput"
              accept="image/*"
              type="file"
              onChange={handleFileChange}
              tabIndex={-1}
            />
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

export default UploadProfile;

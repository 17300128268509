import {
    AppBar,
    Autocomplete,
    Box,
    Breadcrumbs,
    Button,
    Container,
    FormControl,
    FormControlLabel,
    FormLabel,
    IconButton,
    Menu,
    Radio,
    RadioGroup,
    TextField,
    Toolbar,
    Paper,
    Stack,
    Fade,
    Chip,
    Card,
    CardContent,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Typography,
    OutlinedInput,
    FormHelperText,
    Checkbox,
    ListItemText
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import * as React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
    updateBasicProfile,
    updatePersonalDetails,
    uploadProfilePic,
    getBasicProfileDetails,
    setDefaultResume,
    deleteResume,
} from 'api/users';
import { endpoints } from 'utils/axios';
import { useAuth } from 'hooks/useAuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm, register, watch, Controller } from 'react-hook-form';
import FormProvider from 'hooks/form/form-provider';
import { useSnackbar } from 'components/snackbar';
import { CandidateBasicProfileRule } from 'models/CandidateProfileSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import TypeAhead from 'components/TypeAhead';
import { cleanData } from 'data/city.js';

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    marginTop: '0.2rem!important',
    top: 5,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const Disability = forwardRef(({ profileData, setUpdating, hideBox, alignment, isResume, handleResumeProfile }, ref) => {
    const { enqueueSnackbar } = useSnackbar();
    const { user, updateContext } = useAuth();
    const [isFetched, setIsFetched] = useState(false);
    const [checked, setChecked] = React.useState(false);

    const defaultValues = {
        city: '',
        date_of_birth: null,
        sex: '',
        disability: '',
        name: user?.name || '',
    };

    const methods = useForm({
        defaultValues,
        resolver: yupResolver(CandidateBasicProfileRule),
    });

    const {
        control,
        register,
        handleSubmit,
        setValue,
        trigger,
        formState: { errors, isDirty, isValid, dirtyFields, isSubmitting },
    } = methods;

    const disabilities = [
        { label: 'Blindness' }, { label: 'Low vision' },
        { label: 'Hearing impairment' },
        { label: 'Locomotor disability' },
        { label: 'Leprosy cured' },
        { label: 'Cerebral Palsy' },
        { label: 'Intellectual disability' },
        { label: 'Mental illness' },
        { label: 'Muscular dystrophy' },
        { label: 'Parkinson\'s disease' },
        { label: 'Acid attack victim' },
        { label: 'Sickle cell disease' },
        { label: 'Hemophilia' },
        { label: 'Thalassemia' },
        { label: 'Speech and language disability' },
        { label: 'Multiple sclerosis' },
        { label: 'Specific learning disabilities' },
        { label: 'Chronic neurological conditions' },
        { label: 'Autism spectrum disorder' },
        { label: 'Dwarfism' }];

    useEffect(() => {
        const fetchData = () => {
            try {
                if (profileData) {
                    const result = profileData;
                    setValue('name', result?.full_name || user?.name || '');
                    setValue('city', result?.address?.city || user?.city || '');
                    setValue('date_of_birth', result?.date_of_birth || null);
                    setValue('sex', result?.sex || null);
                    setValue('disability', result?.disability || '');
                    setChecked(result?.disability ? true : false);
                }
                setIsFetched(true);
            } catch (error) {
                console.error('Error fetching jobs:', error);
            }
        };
        fetchData();
    }, []);

    const handleChange = () => {
        setChecked((prev) => !prev);
        setValue('disability', '');
        handleSubmit(onSubmit)();
    };

    const onSubmit = async (formData) => {
        try {
            setUpdating(true);
            if (isResume) {
                handleResumeProfile(formData);
                return;
            }
            const response = await updateBasicProfile(formData);
            if (response?.success === true && response.data) {
                updateContext({ name: response.data?.full_name });
                updateContext({ sex: response.data?.sex || null });
                updateContext({ date_of_birth: response.data?.date_of_birth || null });
                updateContext({ city: response.data?.address?.city || '' });
            } else if (response?.success === false) {
                enqueueSnackbar(response.message, { variant: 'error' });
            }
        } catch (e) {
            setUpdating(false);
        }
    };

    useImperativeHandle(ref, () => ({
        triggerValidation: async () => {
            const result = await trigger();
            return result;
        },
    }));

    return (
        <FormProvider methods={methods}>
            <Box>
                <Card className="disability-card " variant="outlined" sx={{ boxShadow: "none" }}>
                    <CardContent sx={{ padding: '0px!important' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6}>
                                <Stack direction="row" spacing={1.5} alignItems="start">
                                    <AntSwitch inputProps={{ 'aria-label': 'ant design' }} checked={checked} onChange={handleChange} />
                                    <Box sx={{ width: '100%' }}>
                                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                                            <Box>
                                                <Typography className="heading" variant="p" sx={{ display: 'inline-flex' }}>
                                                    Disability Declaration
                                                </Typography>
                                                <Typography className="info" variant="body2" gutterBottom>
                                                    Select this if you have UDID Card
                                                </Typography>
                                            </Box>
                                        </Stack>
                                    </Box>
                                </Stack>
                            </Grid>
                            {checked &&
                                <Grid item xs={12} sm={6} md={6}>
                                    <Fade in={checked}>
                                        <Box>
                                            <FormControl size="small" fullWidth>
                                                <Controller
                                                    control={control}
                                                    name="disability"
                                                    render={({ field }) => (
                                                        <Autocomplete
                                                            size="small"
                                                            {...field}
                                                            {...register('disability')}
                                                            id="disability"
                                                            name="disability"
                                                            options={disabilities?.map((item) => item?.label)}
                                                            renderInput={(params) => <TextField {...params} label="Disability type" />}
                                                            onChange={(e, value) => {
                                                                field.onChange(value);
                                                                handleSubmit(onSubmit)();
                                                            }}
                                                            inputValue={field.value || ''}
                                                            onInputChange={(e, value) => field.onChange(value)}
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                        </Box>
                                    </Fade>
                                </Grid>
                            }
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
        </FormProvider>
    );
});

export default Disability;

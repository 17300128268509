import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import {  useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFViewer = ({ pdfData, width }) => {
    const [numPages, setNumPages] = useState(null);
     const theme = useTheme();
      const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    // Switch to the new PDF when it's fully loaded
    const handleDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginBottom: '20px' }}>
            {/* Visible PDF */}
            {pdfData && (
                <Document
                    file={pdfData}
                    onLoadSuccess={handleDocumentLoadSuccess}
                    loading={null}
                >
                    {Array.from(new Array(numPages), (el, index) => (
                        <div key={`page_${index + 1}`} className="pdf-page-container" style={{ marginBottom: (index + 1 < numPages ? '10px' : '0') }}>
                            <Page
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                                renderAnnotationLayer={false}
                                renderTextLayer={false}
                                loading={null}
                                width={isMobile ? (width ? width : window.innerWidth * 0.8) : ''}                                
                            />
                        </div>
                    ))}
                </Document>
            )}
        </div>
    );
};

export default PDFViewer;

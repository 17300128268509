import React, { useState } from 'react';
import { Box, Button, Card, CardContent, Link, IconButton, InputLabel, OutlinedInput, Stack, SvgIcon, Typography, TextField, FormControl, InputAdornment, Divider } from '@mui/material';
import { setApplication } from 'api/application';
import { useSnackbar } from 'components/snackbar';
import { getTimeDifferenceString, changeNameFormat, formatNumber, toTitleCase } from 'utils/Utils';

const ExternalJob = ({ onClose, externalJobStatus, downloadResume, job, tab, candidateDetails, matchingSkills }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleContinueWithApplication = () => {
    const externalLink = tab === '2' ? job?.external_link : job?.job_id?.external_link;
    window.open(externalLink);
    handleJobInprogress();
  };
  const handleJobInprogress = async () => {
    try {
      setIsSubmitting(true);
      if (candidateDetails && matchingSkills && job) {
        const jobDetails = tab === '2' ? job : job?.job_id;
        const response = await setApplication({ candidateDetails, matchingSkills, jobDetails, status: 'application pending', tj_score: job?.tj_score });
        if (response?.success && response?.data != null) {
          const company = response?.data?.job_id?.company;
          const title = response?.data?.job_id?.title;
          enqueueSnackbar(`Your application to ${toTitleCase(company)} for ${title} role is in progress`, { variant: 'info' });
          if (tab === '3') {
            job.status = response?.data?.status;
            job.applied_at = response?.data?.applied_at;
          } else {
            job.application_status = response?.data?.status;
          }
          externalJobStatus();
          onClose();
        } else {
          enqueueSnackbar('Failed to submit the application. Please try again.', { variant: 'error' });
        }
      } else {
        enqueueSnackbar('Failed to submit the application. Please try again.', { variant: 'error' });
      }
    } catch (error) {
      console.error('Error submitting application:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box className="ext-job-dialog-wraper">
        <Stack justifyContent={'center'}>
            <Box sx={{textAlign: 'center'}} mb={3}>
                <Typography className="title" mb={0.5}>External Job Listing</Typography>
                <Typography className="info-text">You will be redirected to an external company platform to complete the application process. Make sure you have the relevant resume handy for this job application.</Typography>
            </Box>
            <Stack direction={{xs:'column-reverse', md:'row'}} alignItems={'center'} spacing={2.5}>
                <Button fullWidth variant="outlined" size="medium" onClick={handleContinueWithApplication} disabled={isSubmitting}>
                {isSubmitting ? 'In-progress...' : 'Continue with application'}
                </Button>
                <Button fullWidth variant="contained" size="medium" onClick={downloadResume}>
                Select & Download Resume
                </Button>
            </Stack>
        </Stack>
    </Box>
  )
}

export default ExternalJob;

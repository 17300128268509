import React, { forwardRef, useEffect, useLayoutEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
  SvgIcon,
  Typography,
  FormControl,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Grid,
  styled,
} from '@mui/material';
import { endpoints } from 'utils/axios';

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 6,
  width: 20,
  height: 20,
  boxShadow: 'inset 0 0 0 1.5px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: '#ebf1f5',
    ...theme.applyStyles('dark', {
      backgroundColor: '#30404d',
    }),
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(206,217,224,.5)',
    ...theme.applyStyles('dark', {
      background: 'rgba(57,75,89,.5)',
    }),
  },
  ...theme.applyStyles('dark', {
    boxShadow: '0 0 0 1px rgb(16 22 26 / 40%)',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))',
  }),
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#137cbd',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&::before': {
    display: 'block',
    width: 20,
    height: 20,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3',
  },
});

// Inspired by blueprintjs
function BpCheckbox(props) {
  return (
    <Checkbox
      sx={{ '&:hover': { bgcolor: 'transparent' },padding :{xs:"0px", sm:"0px" ,md : "9px"} }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ 'aria-label': 'Checkbox demo' }}
      {...props}
    />
  );
}

const AddSkills = ({ onClose, skills, selectedskills, onAddSkills, title, type }) => {
  const [selectedSkills, setSelectedSkills] = useState(selectedskills || []);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState('');
  const [filteredSkills, setFilteredSkills] = useState([...new Set([...selectedskills, ...skills])] || []);
  const focusRef = useRef(null);
  const [loading, setLoading] = useState(false);

  useLayoutEffect(() => {
    focusRef.current?.focus();
  }, [])

  useEffect(() => {
    if (skills?.length === 0 && selectedskills?.length === 0) {
      setLoading(true);
    }
    fetchData();
  }, []);

  const handleDoneClick = () => {
    if (selectedSkills.length < 3 && type !== 'jobType') {
      setError('Please select at least 3 skills');
      return;
    }
    if (selectedSkills.length > 8 && type !== 'jobType') {
      setError('Cannot add more than 8 skills');
    } else {
      onAddSkills(selectedSkills);
      onClose();
    }
  };

  const handleCheckboxChange = (event) => {
    const skillName = event.target.name;
    if (event.target.checked) {
      if (selectedSkills.length >= 8 && type !== 'jobType') {
        setError('Cannot add more than 8 skills');
        return;
      } else if (type === 'jobType' && selectedSkills.length >= 10) {
        setError('Cannot add more than 10 skills');
        return;
      }
      setSelectedSkills((prevSelectedSkills) => [...prevSelectedSkills, skillName]);
    } else {
      setSelectedSkills((prevSelectedSkills) => prevSelectedSkills.filter((skill) => skill !== skillName));
      setError('');
    }
  };

  // const handleJobSkills = (value) => {
  //   setSelectedSkills((prevSelectedSkills) =>[...prevSelectedSkills, value[0]]);
  // };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // const filteredSkills = skills.filter((skill) => skill.toLowerCase().includes(searchTerm.toLowerCase()));

  const fetchData = async (inputValue) => {
    try {
      let response;
      if (!inputValue) {
        response = await fetch(`${process.env.REACT_APP_END_POINT}${endpoints?.services?.allSkills}`);
        const resultData = await response.json();
        // setFilteredSkills([...new Set([...selectedskills, ...skills, ...resultData])]);
        setFilteredSkills([...new Map([...selectedskills, ...skills, ...resultData].map(skill => [skill.toLowerCase(), skill])).values()]);

      } else {
        response = await fetch(`${process.env.REACT_APP_END_POINT}${endpoints?.services?.skills}?q=${inputValue}`);
        const resultData = await response.json();
        if (resultData.length === 0) {
          const capitalizedInputValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
          setFilteredSkills([capitalizedInputValue]);
        } else {
          setFilteredSkills(resultData);
        }
      }
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (event) => {
    const newInputValue = event.target.value;
    fetchData(newInputValue);
  };

  return (
    <Card className="add-skills-dialog" variant="outlined">
      <CardContent>
      <IconButton className="close-dialog-btn" aria-label="delete" color="primary" sx={{ display: { xs: "none", sm: "none" } }} onClick={onClose}>
          <SvgIcon sx={{ width: '20px', height: '20px' }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <g clipPath="url(#clip0_456_20993)">
                <path
                  d="M7 7L17 17M17 7L7 17L17 7Z"
                  stroke="#111827"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_456_20993">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </SvgIcon>
        </IconButton>

        <Box mb={2.5} >
          <Typography className="title xs-fs-16" component="h6">
            {`Add Skills${title ? ` in ${title}` : ''}`}
          </Typography>
          <Typography className="sub-text xs-fs-12" component="p" >
            Please select relevant skills from the list below
          </Typography>
        </Box>

        <Box mb={1.5}>
          <FormControl fullWidth>
            <TextField
              label="Skill Name"
              inputRef={focusRef}
              onChange={handleInputChange}
              size="small"
              variant="outlined"
            />
          </FormControl>
        </Box>

        {error && (
          <Typography className="error-text" component="p" style={{ color: 'red', fontSize: '12px' }}>
            {error}
          </Typography>
        )}
        <Box className="add-skills-dialog-content" mb={3.5}>
          <Box className="content-inner" sx={{ paddingLeft: { xs: 0, md: 2 } }}>
            {loading ?
              <Stack sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '4px' }}>
                <CircularProgress size={24} />
              </Stack> :
              <FormGroup>
                {filteredSkills?.map((label, index) => (
                  <FormControlLabel
                    control={
                      <BpCheckbox checked={selectedSkills.some(skill => skill.toLowerCase() === label.toLowerCase())} onChange={handleCheckboxChange} name={label} />
                    }
                    key={index}
                    label={label}
                    sx={{ alignItems: "center !important" }}
                  />
                ))}
              </FormGroup>
            }
          </Box>
        </Box>

        <Box className="skills-dialog-actions">
          <Stack direction="row" alignItems={'center'} spacing={3}>
            <Button fullWidth className="otp-btn" variant="outlined" size="medium" onClick={onClose} tabIndex={-1}>
              Cancel
            </Button>
            <Button fullWidth className="otp-btn" variant="contained" size="medium" onClick={handleDoneClick}>
              Done
            </Button>
          </Stack>
        </Box>
      </CardContent>
    </Card>
  );
};

export default AddSkills;

import React, { useState, useRef, useLayoutEffect, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
  SvgIcon,
  Typography,
  FormControl,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  styled,
} from '@mui/material';
import { changeNameFormat } from 'utils/Utils';

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 6,
  width: 20,
  height: 20,
  boxShadow: 'inset 0 0 0 1.5px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: '#ebf1f5',
    ...theme.applyStyles('dark', {
      backgroundColor: '#30404d',
    }),
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(206,217,224,.5)',
    ...theme.applyStyles('dark', {
      background: 'rgba(57,75,89,.5)',
    }),
  },
  ...theme.applyStyles('dark', {
    boxShadow: '0 0 0 1px rgb(16 22 26 / 40%)',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))',
  }),
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#137cbd',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&::before': {
    display: 'block',
    width: 20,
    height: 20,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3',
  },
});

  // Inspired by blueprintjs
  function BpCheckbox({ checked, onChange, name, ...props }) {
    return (
      <Checkbox
        sx={{ '&:hover': { bgcolor: 'transparent' } ,padding :{xs:"0px", sm:"0px" ,md : "9px"}}}
        disableRipple
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        checked={checked} // Pass checked state
        onChange={onChange} // Handle state changes
        name={name} // Set the name
        {...props} // Forward other props
      />
    );
  }
const AddTechnology = ({ onClose, technology, selectedsoftware, onAddTechnology }) => {
  const [selectedSoftware, setSelectedSoftware] = useState(selectedsoftware || []);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState('');

  const focusRef = useRef(null);
  const [filteredTechnologies, setFilteredTechnologies] = useState(technology || []);

  useEffect(() => {
    setFilteredTechnologies(
      technology.filter((skill) => skill.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  }, []);
  

  useLayoutEffect(() => {
    focusRef.current?.focus();
  }, [])
  
  const handleDoneClick = () => {
    if (selectedSoftware.length >12) {
      setError('Cannot add more than 12 competencies.');
    } else {
      onAddTechnology(selectedSoftware);
      onClose();
    }
  };

  const handleCheckboxChange = (event) => {
    const technology = event.target.name;
    if (event.target.checked) {
      if (selectedSoftware.length >= 12) {
        setError('Cannot add more than 12 competencies.');
        return;
      }
      setSelectedSoftware((prevSelectedSkills) => [...prevSelectedSkills, technology]);
    } else {
      setSelectedSoftware((prevSelectedSkills) => prevSelectedSkills.filter((skill) => skill !== technology));
      setError('');
    }
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    
    let filterTech = [];
    const match = technology.filter((skill) => skill.toLowerCase().includes(value.toLowerCase()));

    if (value && value !== '' && match?.length === 0) {
      filterTech = [changeNameFormat(value)];
      filterTech = filterTech.filter((skill) => skill.toLowerCase().includes(value.toLowerCase()));
    } else{
      filterTech = technology.filter((skill) => skill.toLowerCase().includes(value.toLowerCase()));
    }
    setFilteredTechnologies(filterTech);
  };

  return (
    <Card className="add-skills-dialog" variant="outlined">
      <CardContent>
        <IconButton sx={{display : {xs: "none" , sm : "none" }}} className="close-dialog-btn" aria-label="delete" color="primary" onClick={onClose}>
          <SvgIcon sx={{ width: '20px', height: '20px' }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <g clipPath="url(#clip0_456_20993)">
                <path
                  d="M7 7L17 17M17 7L7 17L17 7Z"
                  stroke="#111827"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_456_20993">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </SvgIcon>
        </IconButton>

        <Box mb={2.5}>
          <Typography className="title xs-fs-16" component="h6" >
          Add technical competencies
          </Typography>
          <Typography className="sub-text xs-fs-12  " component="p">
          Please select from list below
          </Typography>
        </Box>

        {error && (
          <Box mb={1.5}>
            <Typography className="error-text" component="p" style={{ color: 'red' }}>
              {error}
            </Typography>
          </Box>
        )}

        <Box mb={1.5}>
          <FormControl fullWidth>
            <TextField
              label="Competency name"
              inputRef={focusRef}
              onChange={handleSearchChange}
              size="small"
              variant="outlined"
            />
          </FormControl>
        </Box>

        <Box className="add-skills-dialog-content" mb={3.5}> 
        <Box className="content-inner" sx={{paddingLeft: {xs: 0, md: 2}}}>
            <FormGroup>
              {filteredTechnologies
              ?.sort((a, b) => selectedSoftware.includes(b) - selectedSoftware.includes(a))
              .map((label, index) => (
                  <FormControlLabel
                  control={
                    <BpCheckbox
                      checked={selectedSoftware.includes(label)}
                      onChange={handleCheckboxChange}
                      name={label}
                    />
                    }
                    key={index}
                    label={label}
                    sx={{alignItems: "center !important"}}
                  />
              ))}
            </FormGroup>
            
          </Box>
        </Box>

        <Box className="skills-dialog-actions">
          <Stack direction="row" justifyContent="center" alignItems={'center'} spacing={{ xs: 2, md: 3 }}>
            <Button fullWidth className="otp-btn" variant="outlined" size="medium" onClick={onClose} tabIndex={-1}>
              Cancel
            </Button>
            <Button fullWidth className="otp-btn" variant="contained" size="medium" onClick={handleDoneClick}>
              Done
            </Button>
          </Stack>
        </Box>
      </CardContent>
    </Card>
  );
};

export default AddTechnology;

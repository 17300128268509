import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';

import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  CircularProgress,
  Container,
  Dialog,
  Drawer,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  SvgIcon,
  InputAdornment,
  TextField,
  Divider,
  DialogContent,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import Header from '../header/Header';
import { Link } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import CreateUpdateEducation from '../job-application/CreateUpdateEducation';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { NewJobSchema, NewCompanyJobSchema } from 'models/NewJobSchema';
import TypeAhead from 'components/TypeAhead';
import { endpoints } from 'utils/axios';
import FormProvider from 'hooks/form/form-provider';
import AddIcon from '@mui/icons-material/Add';
import AddSkills from '../basic-profile/AddSkills';
import { useAuth } from 'hooks/useAuthContext';
import { useSnackbar } from 'components/snackbar';
import QualificationOptions from 'components/QulificationOptions';
import { createJob, jobUpdated, getCompanyDetails, jobDetails } from 'api/job';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ScreeningQuestionDrawer from './ScreeningQuestionDrawer';
import TypeAheadPlain from '../TypeAheadPlain';
import { changeNameFormat } from 'utils/Utils';
import { cleanData, clusteredData } from 'data/city.js';
import { formatCurrency } from 'utils/Utils';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { error } from 'pdf-lib';
import { fi } from 'date-fns/locale';
import CopyUrlDialog from './CopyUrlDialog';
const backIcon = `${process.env.REACT_APP_HOMEPAGE}assets/arrow_back.svg`;
import { styled } from '@mui/material/styles';
// import { industrySector } from 'data/language'

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 6,
  width: 20,
  height: 20,
  boxShadow: 'inset 0 0 0 1.5px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  // backgroundColor: '#f5f8fa',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: '#ebf1f5',
    ...theme.applyStyles('dark', {
      backgroundColor: '#30404d',
    }),
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(206,217,224,.5)',
    ...theme.applyStyles('dark', {
      background: 'rgba(57,75,89,.5)',
    }),
  },
  ...theme.applyStyles('dark', {
    boxShadow: '0 0 0 1px rgb(16 22 26 / 40%)',
    backgroundColor: '#394b59',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))',
  }),
}));
const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#137cbd',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&::before': {
    display: 'block',
    width: 20,
    height: 20,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3',
  },
});
// Inspired by blueprintjs
function BpCheckbox(props) {
  return (
    <Checkbox
      sx={{ '&:hover': { bgcolor: 'transparent' } }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ 'aria-label': 'Checkbox demo' }}
      {...props}
    />
  );
}

const NewJobPosting = () => {
  const backButtonIcon = (
    <Icon className="extLoginIcon">
      <img alt="edit" width="24px" height="24px" src={backIcon} />
    </Icon>
  );
  // jobs type toggle buttons
  const [alignment, setAlignment] = React.useState('tj-jobs');
  const navigate = useNavigate();
  const [allCertifications, setAllCertifications] = useState([]);
  const [selectedChips, setSelectedChips] = useState([]);
  const [loading, setLoading] = useState(true);
  const [createdBy, setCreatedBy] = useState('');
  const [updatedAt, setUpdatedAt] = useState('');
  const [selectedQualifications, setSelectedQualifications] = useState([]);
  const [jobUniqueId, setJobUniqueId] = useState(null);
  const [jobId, setJobId] = useState(null);
  const [open, setOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();
  const location = useLocation();
  const id = location.state;
  const isEditMode = !!id;
  const [addMoreSkills, setAddMoreSkills] = useState([]);
  const [chipData, setChipData] = useState([]);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const focusRef = useRef(null);

  const quillRef = useRef(null);
  const compensationTypeRef = useRef(null);
  const [openCopyUrlDialog, setOpenCopyUrlDialog] = useState(false);
  const [posting, setPosting] = useState(false);
  const jobType = [{ label: 'Full time' }, { label: 'Part time' }, { label: 'Temporary' }, { label: 'Internship' }, { label: 'Trainee' }, { label: 'Freelancer (Self employed)' }];
  const preferredJobShifts = ['Any', 'Daytime', 'Evening', 'Night'];
  const workModelFormat = ['In office', 'Work from Home', 'Hybrid'];

  const fieldsOrder = [
    "company",
    "company_identification_number",
    "industry_sector",
    "compnay_website",
    "company_overview",
    "title",
    "job_overview",
    "role",
    "function",
    "location",
    "direct_reports",
    "description",
    "qualifications",
    "min",
    "max",
    "salary",
    "compensation",
    "skills",
  ];
  const defaultValues = {
    company: '',
    company_identification_number: '',
    industry_sector: '',
    company_website: '',
    company_overview: '',
    title: '',
    job_overview: '',
    role: '',
    function: '',
    job_type: 'Full time',
    job_shift: 'Daytime',
    work_mode: 'In office',
    location: '',
    direct_reports: 'Individual contributor (IC)',
    description: '',
    qualifications: [],
    certifications: [],
    min: '',
    max: '',
    experience: '',
    notice_period: 'No preference',
    salary: '',
    compensation: 'Fixed + Variable',
    key_skills: [],
    selectCompany: null,
    start_date: null,
    reporting_to: '',
    gender_preference: 'none',
    disability_preference: 'Not applicable',
    question: [],
    status: 'active',
    company_id: '',
    external_link: '',
    job_responsibilities: '',
    is_company_name_confidential: false,
    is_compensation_confidential: false,
    all_skills: [],
    other_location: [],
  };

  const methods = useForm({
    defaultValues,
    mode: 'onChange',
    // shouldFocusError: false,
    resolver: yupResolver(
      user?.user_type === 'company' || user?.user_type === 'companyadmin' || user?.user_type === 'recruiter'
        ? NewCompanyJobSchema(alignment)
        : NewJobSchema(alignment)
    ),
  });
  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    trigger,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { errors, isDirty, isValid, dirtyFields, isSubmitting },
    setFocus
  } = methods;
  const min = watch('min');
  const max = watch('max');

  useLayoutEffect(() => {
    if (!isEditMode) focusRef.current?.focus()
  }, []);

  const [openCUEducDrawer, setOpenCUEducDrawer] = React.useState(false);
  const [isOtpDialogOpen, setOtpDialogOpen] = useState(false);
  const [keyQuestions, setKeyQuestions] = useState(['']);

  const [jobData, setJobData] = useState({});
  const [showFlag, setShowFlag] = useState('');
  const [companyLoading, setCompanyLoading] = useState(false);
  const [errorDescription, setErrorDescription] = useState(false);
  const [errorCIN, setErrorCIN] = useState(false);
  const [coreSkill, setCoreSkills] = useState([]);

  const [degreeData, setDegreeData] = useState([]);
  const [certificateTitleData, setCertificateTitleData] = useState([]);
  const [roleData, setRoleData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      await fetchDegreeData();
      await fetchCertificateTitle();
      await fetchRoleData();
    }
    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isEditMode) {
          await fetchJobDetails(id);
        } else {
          await createNewJob();
        }
      } catch (error) {
        enqueueSnackbar('Failed to fetch data', { variant: 'error' });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    if (min !== "" && max !== "") {
      setValue('experience', `${min}-${max}`);
    }
  }, [min, max, setValue]);
  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      const firstErrorField = fieldsOrder?.find((field) => errors[field]);
      if (firstErrorField) {
        if (firstErrorField === "description") {
          if (quillRef.current) {
            const quillRefInstance = quillRef.current.getEditor();
            quillRefInstance.focus();
            quillRefInstance.root.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        } else if (firstErrorField === "compensation") {
          if (compensationTypeRef.current) {
            compensationTypeRef.current.focus();
            compensationTypeRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        }
        // else {
        //   setFocus(firstErrorField);
        // }
      }
    }
  }, [errors, setFocus]);

  const createUpdateEduDrawer = (newOpen) => () => {
    setOpenCUEducDrawer(newOpen);
  };
  const handleInputChange = (e) => {
    const value = e.target.value;
    setWebsite(value);
  };
  const handleAddMoreSkills = (moreSkills) => {
    if (moreSkills?.length > 0) {
      setCoreSkills((coreSkill || []).filter((skill) => (moreSkills || []).includes(skill)));
      setSelectedChips(moreSkills);
      setChipData(moreSkills);
      validateSkills(moreSkills);
    }
  };
  const handleOpenOtpDialog = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const createNewJob = async () => {
    try {
      const response = await createJob({ created_by: 'Admin Name', status: 'New' });
      if (response?.success && response?.data) {
        setJobUniqueId(response.data?._id);
        setJobId(response.data?.jobid);
        setCreatedBy(user?.name);
        setUpdatedAt(response.data?.updatedAt);
        if (user?.user_type === 'company' || user?.user_type === 'companyadmin' || user?.user_type === 'recruiter') {
          const companyId = user?.id;
          // setValue('selectCompany', { name: user?.name, id: companyId });
          const companyResponse = await getCompanyDetails({ id: companyId });
          if (companyResponse?.success && companyResponse?.data) {
            const companyData = companyResponse.data;
            setValue('company', companyData.full_name);
            setValue('company_id', companyData._id);
            setValue('industry_sector', companyData.industry_sector);
            setValue('company_overview', companyData.description || '');
          } else {
            enqueueSnackbar(companyResponse.message, { variant: 'error' });
          }
        }
      } else {
        enqueueSnackbar('Failed to create job', { variant: 'error' });
      }
    } catch (error) {
      throw error;
    }
  };
  const handleCopyUrl = () => {
    const url = `${window?.location?.host}/jobs/${jobId}`;
    navigator.clipboard.writeText(url).then(() => {
      enqueueSnackbar("Job link copied.", { variant: 'success' });
    }).catch((error) => {
      enqueueSnackbar('Failed to copy job link.', { variant: 'error' });
    });
  };
  const fetchJobDetails = async (id) => {
    try {
      const response = await jobDetails({ id });
      if (response?.success && response?.data) {
        const {
          _id,
          jobid,
          created_by,
          updatedAt,
          question = [],
          certifications = [],
          key_skills = [],
          qualifications = [],
          experience = '',
          all_skills = [],
          core_skills = [],
          external_link,
        } = response?.data;
        setJobData(response.data);
        if (external_link) setAlignment('external-job-posting');
        setJobUniqueId(_id);
        setJobId(jobid);
        setCreatedBy(created_by);
        setUpdatedAt(updatedAt);
        reset(response?.data);
        if (question.length > 0) {
          setKeyQuestions([...question]);
          setAllCertifications(certifications);
          setValue('question', question);
        }
        setChipData(key_skills?.length > 0 ? key_skills : all_skills);
        setSelectedChips(key_skills);
        setCoreSkills(core_skills);
        setSelectedQualifications(qualifications);
        if (experience.includes('-')) {
          const [min, max] = experience.split('-').map(Number);
          setValue('min', min);
          setValue('max', max);
        }
        setValue('all_skills', all_skills);
        setAddMoreSkills(all_skills);
      } else {
        enqueueSnackbar(response?.message || 'Failed to fetch job details', { variant: 'error' });
      }
    } catch (error) {
      console.error('Error fetching job details:', error);
    }
  };

  const onSubmit = handleSubmit(async (formData) => {
    // console.log(formData?.salary);
    if (!validateSkills(selectedChips)) {
      return;
    } else {
      setShowFlag('');
    }
    if (!validateDescription(formData?.description)) {
      return;
    }
    if (!validateCIN(formData?.company_identification_number)) {
      return;
    }
    formData.key_skills = selectedChips;
    formData.core_skills = coreSkill;
    formData.description = removeEmptyListItems(formData?.description);

    setJobData(formData);
    if (alignment !== 'external-job-posting')
      setOpenCUEducDrawer(true);
    else {
      await submitJobApplication(formData);
    }
    setPosting(false);
  });
  const errorRefs = useRef({});
  // const newJobPostingRef = useRef(null);
  const removeQualification = (index) => {
    const updatedQualifications = [...selectedQualifications];
    updatedQualifications.splice(index, 1);
    setSelectedQualifications(updatedQualifications);
  };

  const screeningQueDrawer = (newOpen) => () => {
    setOpenCUEducDrawer(newOpen);
  };

  const handleQuestions = (qus) => {
    setKeyQuestions(qus);
    setValue('question', qus);
  };

  const handleClick = (label) => {
    let totalSkillls;
    if (selectedChips?.includes(label)) {
      totalSkillls = selectedChips.filter((chip) => chip !== label);
      setSelectedChips(totalSkillls);
      let filterCoreSkill = coreSkill.filter((chip) => chip !== label);
      setCoreSkills(filterCoreSkill || []);
    } else {
      if (selectedChips?.length >= 10) {
        setShowFlag('You can select up to 10 skills.');
        return;
      } else {
        totalSkillls = [...selectedChips, label];
        setSelectedChips(totalSkillls);
        setShowFlag('');
      }
    }
    validateSkills(totalSkillls);
  };

  const removeCertification = (index) => {
    const updatedCertification = [...allCertifications];
    updatedCertification.splice(index, 1);
    setAllCertifications(updatedCertification);
  };

  const handleToggleChange = (event, newAlignment) => {
    if (newAlignment !== null) setAlignment(newAlignment);
  };

  const onReset = () => {
    reset(defaultValues);
    setAllCertifications([]);
    setKeyQuestions(['']);
    setSelectedQualifications([]);
    setSelectedChips([]);
    setAddMoreSkills([]);
    setCoreSkills([]);
    setChipData([]);
  };

  const saveAsDraft = async () => {
    const data = watch();
    // console.log(data?.salary);
    data.key_skills = selectedChips;
    data.core_skills = coreSkill;
    const filledData = Object.fromEntries(Object.entries(data).filter(([_, value]) => value !== '' && value !== null));
    filledData.status = 'draft';
    const response = await jobUpdated({ formData: filledData, job_id: jobUniqueId });
    if (response?.success === true && response?.data) {
      enqueueSnackbar('Draft job details saved successfully.', { variant: 'success' });
      navigate('/home');
    } else if (response?.success === false) {
      enqueueSnackbar('Failed to update job', { variant: 'error' });
      console.error('Failed to update job:', response.message);
    }
  };

  const validateSkills = (totalSkillls) => {
    if (totalSkillls?.length < 5) {
      setShowFlag('Please select at least 5 skills.');
      return false;
    } else if (totalSkillls?.length > 10) {
      setShowFlag('You can select up to 10 skills.');
      return false;
    }
    setShowFlag('');
    return true;
  };

  const handleCompanyData = async (id) => {
    setCompanyLoading(true);
    try {
      setValue('company_id', id || '');
      const companyResponse = await fetch(`${process.env.REACT_APP_END_POINT}${endpoints?.services?.singleCompanyData}?id=${id}`);
      const companyData = await companyResponse.json();
      setValue('industry_sector', companyData?.industry_sector || '');
      setValue('company_overview', companyData?.description || '');
      setValue('company_identification_number', companyData?.identification_number || '');
      setValue('company_website', companyData?.website || '');
      if (companyData?.industry_sector) trigger('industry_sector');
      if (companyData?.identification_number) trigger('company_identification_number');
      if (companyData?.description) trigger('company_overview');
      if (companyData?.website) trigger('company_website');
    } catch (error) {
      console.error('Error fetching company data:', error);
    }
    setCompanyLoading(false);
  };
  const validateDescription = (value) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(value, 'text/html');
    const text = doc.body.textContent || "";
    if (text && text?.length > 0) {
      setErrorDescription(false);
      return true;
    } else {
      setErrorDescription(true);
      // if (errorRefs.current.description) {
      //   errorRefs.current.description.scrollIntoView({ behavior: 'smooth', block: 'center' });
      // }
      return false;
    }
  };
  const validateCIN = (value) => {
    if (!value) {
      setErrorCIN(false);
      return true;
    }
    if (value && value?.length === 21) {
      setErrorCIN(false);
      return true;
    } else {
      setErrorCIN(true);
      return false;
    }
  };
  const handleOtherLocation = (location) => {
    const matchedClusterData = clusteredData.filter(cluster => cluster?.value?.includes(location?.value));
    const otherLocationName = matchedClusterData?.map(cluster => cluster?.name);
    setValue('other_location', otherLocationName);
  };
  const handleCoreSkills = (label) => {
    let totalSkillls;
    if (coreSkill?.includes(label)) {
      totalSkillls = coreSkill.filter((chip) => chip !== label);
      setCoreSkills(totalSkillls);
      setShowFlag('');
    } else {
      if (coreSkill?.length >= 5) {
        setShowFlag('You can select up to 5 core skills.');
        return;
      } else {
        totalSkillls = [...coreSkill, label];
        setCoreSkills(totalSkillls);
        setShowFlag('');
      }
    }
  };
  const submitJobApplication = async (formData) => {
    setPosting(true);
    formData.status = 'active';
    formData.question = [];
    try {
      const response = await jobUpdated({ formData, job_id: jobUniqueId });
      if (response?.success === true && response.data) {
        enqueueSnackbar(response.message || 'New job created successfully', { variant: 'success' });
        setOpenCopyUrlDialog(true);
      } else if (response?.success === false) {
        enqueueSnackbar('Failed to create job', { variant: 'error' });
        console.error('Failed to create job:', response.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setPosting(false);
    }
  };
  const handleCloseCopyUrlDialog = () => {
    setOpenCopyUrlDialog(false);
    navigate('/home');
  };
  const fetchDegreeData = async () => {
    const response = await fetch(`${process.env.REACT_APP_END_POINT}${endpoints?.services?.degrees}`);
    const resultData = await response.json();
    setDegreeData([...new Set([...resultData])]);
  };
  const fetchCertificateTitle = async () => {
    const response = await fetch(`${process.env.REACT_APP_END_POINT}${endpoints?.services?.certificate}`);
    const resultData = await response.json();
    setCertificateTitleData([...new Set([...resultData])]);
  };
  const fetchRoleData = async () => {
    const response = await fetch(`${process.env.REACT_APP_END_POINT}${endpoints?.services?.roles}`);
    const resultData = await response.json();
    setRoleData([...new Set([...resultData])]);
  };
  const removeEmptyListItems = (value) => {
    const div = document.createElement('div');
    div.innerHTML = value;
    const listItems = div.querySelectorAll('li');
    listItems.forEach((item) => {
      if (!item.textContent.trim()) {
        item.remove();
      }
    });
    return div.innerHTML;
  };


  if (loading || !degreeData || !degreeData?.length > 0 || !certificateTitleData || !certificateTitleData?.length > 0 || !roleData || !roleData?.length > 0) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '60px', alignItems: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }
  return (
    <Box>
      {/* <Header /> */}
      <Box className="admin-new-job-posting">
        <Container maxWidth="xl">
          <Box sx={{ padding: '5rem 1.5rem' }}>
            {/* <Link href="#" className="update-link" underline="none" onClick={createUpdateEduDrawer(true)}>
              open side panel
            </Link> */}
            <Box mb={1}>
              <Button size="small" className="back-btn" startIcon={backButtonIcon}  onClick={()=>{navigate(`/home`, { state: id })}}>
                Back to Job Listing
              </Button>
            </Box>

            {loading ?
              <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '30px', alignItems: 'center' }}>
                <CircularProgress />
              </Box> :
              <Box>
                <Box mb={4}>
                  <Typography className="page-title" mb={3}>
                    New Job Posting
                  </Typography>
                  <Box mb={2} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <ToggleButtonGroup
                      className="job-post-toggle-btn"
                      color="primary"
                      value={alignment}
                      exclusive
                      onChange={handleToggleChange}
                      aria-label="Platform"
                      sx={{ gap: '20px' }}
                    >
                      <ToggleButton value="tj-jobs">TJ Jobs</ToggleButton>
                      <ToggleButton value="external-job-posting" variant="outlined">External Job Posting</ToggleButton>
                    </ToggleButtonGroup>
                    {/* <Box>
                      <Chip
                        className="chip-job-status job"
                        size="small"
                        sx={{ height: '40px' }}
                        label={
                          <Box display="flex" alignItems="center" sx={{ padding: '10px' }}>
                            <Typography variant="body2">Job Id: <b>{jobId}</b> |</Typography>
                            <Typography
                              variant="body2"
                              sx={{
                                cursor: 'pointer',
                                color: '#1575C6',
                                marginLeft: '4px',
                                display: 'flex',
                                alignItems: 'center'
                              }}
                              onClick={handleCopyUrl}
                            >
                              Copy URL
                              &nbsp;
                              <SvgIcon sx={{ width: '13px', height: '13px', spacing: '2px' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7936 9.98322L15.8093 7.96767C18.346 5.43097 16.5855 1 12.9208 1C11.8735 1 10.8263 1.3971 10.0325 2.19088L6.1115 6.11171C4.09234 8.13087 4.74197 11.553 7.2992 12.7136L8.78896 11.2238C6.90337 11.0471 6.09224 8.74536 7.4188 7.4188L11.3394 3.49796C12.7322 2.10515 15.1544 3.078 15.1544 5.07917C15.1544 6.2076 14.4339 6.72832 13.6598 7.50241C13.9153 8.30423 13.9604 9.16066 13.7936 9.98322ZM4.34021 10.4976L3.49839 11.3394C2.1043 12.7335 3.08118 15.1544 5.07959 15.1544V15.1529C5.65323 15.1529 6.22665 14.9358 6.66058 14.5018L10.5812 10.5812C11.0151 10.1473 11.2323 9.57385 11.2323 9H11.2338C11.2338 7.84363 10.3609 6.88369 9.21104 6.77594L10.7006 5.28639C13.2606 6.44933 13.9058 9.87083 11.8883 11.8883L7.96767 15.8091C7.17389 16.6029 6.12674 17 5.07938 17C1.41488 17 -0.346033 12.5692 2.19088 10.0323L4.20644 8.01677C4.03964 8.83976 4.08494 9.69556 4.34021 10.4976Z" fill="#1575C6" stroke="white" stroke-width="0.5" />
                                </svg>
                              </SvgIcon>
                            </Typography>
                          </Box>
                        }
                      />
                    </Box> */}
                  </Box>
                </Box>
                <FormProvider methods={methods} onSubmit={onSubmit}>
                  {/* Company Details */}
                  <Box mb={4}>
                    <Box className="card-container">
                      <Box className="card-title">
                        <Typography className="title-text">Recruitment Company Details</Typography>
                      </Box>

                      {companyLoading ?
                        <Box className="card-body">
                          <Stack sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '4px', height: '210px' }}>
                            <CircularProgress size={24} />
                          </Stack>
                        </Box> :
                        <Box className="card-body">
                          <Grid container spacing={4} mb={2}>
                            <Grid item xs={6}>
                              <FormControl fullWidth>
                                {user?.user_type == 'admin' || user?.user_type == 'superadmin' && (
                                  <Controller
                                    control={control}
                                    name="company"
                                    defaultValue={defaultValues?.company || ''}
                                    render={({ field }) =>
                                      <TypeAhead
                                        {...field}
                                        {...register('company')}
                                        api={`${process.env.REACT_APP_END_POINT}${endpoints?.services?.company}`}
                                        multiple={false}
                                        fullWidth
                                        label="Company Name"
                                        displayTextKey="name"
                                        placeholder="Company Name"
                                        freeSolo={true}
                                        onInputChange={(e, value) => {
                                          setValue("company", value, { shouldDirty: true });
                                        }}
                                        onChange={(e, value) => {
                                          if (value?.name) {
                                            value.industry_sector ? setIsReadOnly(true) : setIsReadOnly(false);
                                            handleCompanyData(value?._id);
                                            setValue("company", value?.name, { shouldDirty: true });
                                            setValue("company", value);
                                          } else {
                                            setIsReadOnly(false)
                                            setValue("company", {});
                                            setValue('industry_sector', '');
                                            setValue('company_overview', '');
                                            setValue('company_identification_number', '');
                                            setValue('company_website', '');
                                            setValue('company_id', '');
                                          }
                                        }}
                                        inputProps={{
                                          style: { textTransform: 'capitalize' }
                                        }}
                                        error={!!errors.company}
                                        helperText={errors.company?.message}
                                      />
                                    }
                                  />
                                )}
                              </FormControl>
                              <Controller
                                name="is_company_name_confidential"
                                control={control}
                                render={({ field }) => (
                                  <FormControlLabel
                                    control={
                                      <BpCheckbox
                                        {...field}
                                        checked={field.value}
                                        onChange={(e) => field.onChange(e.target.checked)}
                                        sx={{ color: 'rgba(0, 0, 0, 0.38)', '&.Mui-checked': { color: '#1976d2', } }}
                                      />
                                    }
                                    label="Keep company name confidential"
                                    sx={{
                                      '& .MuiFormControlLabel-label': {
                                        fontWeight: 'normal',
                                        color: 'rgba(0, 0, 0, 0.54)',
                                      }
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <FormControl fullWidth>
                                <Controller
                                  name="company_identification_number"
                                  control={control}
                                  render={({ field }) => (
                                    <TextField
                                      {...field}
                                      {...register('company_identification_number')}
                                      size="small"
                                      error={!!errors.company_identification_number}
                                      helperText={errors.company_identification_number?.message}
                                      label="Company Identification Number (CIN)"
                                      onChange={(e) => {
                                        field.onChange(e.target.value);
                                        validateCIN(e.target.value);
                                      }}
                                      variant="outlined"
                                      fullWidth
                                      multiline
                                    />
                                  )}
                                />
                                {errorCIN && <FormHelperText error>Please enter a 21-character CIN.</FormHelperText>}
                              </FormControl>
                            </Grid>
                          </Grid>

                          <Grid container spacing={4} mb={3}>
                            <Grid item xs={6}>
                              <FormControl fullWidth>
                                <Controller
                                  name="industry_sector"
                                  control={control}
                                  render={({ field }) => (
                                    <TypeAhead
                                      {...field}
                                      {...register('industry_sector')}
                                      // data={industrySector}
                                      api={`${process.env.REACT_APP_END_POINT}${endpoints?.services?.industry}`}
                                      size="small"
                                      error={!!errors.industry_sector}
                                      helperText={errors.industry_sector?.message}
                                      label="Industry Sector"
                                       displayTextKey="name"
                                      variant="outlined"
                                      fullWidth
                                      placeholder="Industry Sector"
                                      freeSolo={false}
                                      // onInputChange={(e, value) => {
                                      //   setValue("industry_sector", value, { shouldDirty: true });
                                      // }}
                                      onChange={(e, value) => {
                                        setValue('industry_sector', value);
                                      }}
                                    />
                                  )}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                              <FormControl fullWidth>
                                <Controller
                                  name="company_website"
                                  control={control}
                                  defaultValue={defaultValues?.company_website || ''}
                                  render={({ field }) => (
                                    <TextField
                                      {...field}
                                      {...register('company_website')}
                                      size="small"
                                      error={!!errors.company_website}
                                      helperText={errors.company_website?.message}
                                      label="Company Website"
                                      variant="outlined"
                                      fullWidth
                                    />
                                  )}
                                />
                              </FormControl>
                            </Grid>
                          </Grid>

                          <FormControl fullWidth>
                            <Controller
                              name="company_overview"
                              control={control}
                              defaultValue={defaultValues?.company_overview}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  {...register('company_overview')}
                                  size="small"
                                  label="Company Overview"
                                  variant="outlined"
                                  fullWidth
                                  multiline
                                  error={!!errors.company_overview}
                                  helperText={errors.company_overview?.message || `${getValues('company_overview') ? getValues('company_overview')?.trim()?.split(/\s+/)?.length || 0 : 0} / 30 words`}
                                  onChange={(e) => {
                                    const inputValue = e.target.value;
                                    const words = inputValue.trim().split(/\s+/);
                                    if (words?.length <= 30) {
                                      setValue('company_overview', inputValue);
                                      clearErrors('company_overview');
                                    } else {
                                      setError('company_overview', { message: "Maximum 30 words allowed." });
                                    }
                                  }}
                                />
                              )}
                            />
                            {/* {errors?.company_overview && (
                            <FormHelperText error>{errors?.company_overview?.message}</FormHelperText>
                          )} */}
                          </FormControl>
                        </Box>
                      }
                    </Box>
                  </Box>

                  {/* Job Details */}
                  <Box mb={4}>
                    <Box className="card-container">
                      <Box className="card-title">
                        <Typography className="title-text">Job Details</Typography>
                      </Box>

                      <Box className="card-body">
                        {/* <FormControl fullWidth sx={{ marginBottom: '1rem' }}>

                          <Controller
                            name="title"
                            control={control}
                            defaultValue={defaultValues?.title}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                size="small"
                                {...register('title')}
                                error={!!errors.title}
                                helperText={errors.title?.message}
                                label="Title"
                                variant="outlined"
                                fullWidth
                              />
                            )}
                          />
                        </FormControl> */}
                        <FormControl fullWidth sx={{ marginBottom: '1rem' }}>
                          <Controller
                            name="job_overview"
                            control={control}
                            defaultValue={defaultValues?.job_overview}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                size="small"
                                {...register('job_overview')}
                                error={!!errors.job_overview}
                                helperText={errors.job_overview?.message}
                                label="Job Summary (Optional)"
                                variant="outlined"
                                fullWidth
                                multiline
                              // InputLabelProps={{ shrink: Boolean(watch('job_overview')) }}
                              />
                            )}
                          />
                          <Typography component="p" className="need-help-txt">
                            Need help with some examples?
                            <Link href="#"> Click Here</Link>
                          </Typography>
                        </FormControl>
                        <Box mb={1} />

                        <Grid container spacing={4}>
                          <Grid item xs={6}>
                            <FormControl fullWidth sx={{ marginBottom: '1rem' }}>
                              <Controller
                                control={control}
                                name="role"
                                defaultValue={defaultValues?.role || ''}
                                render={({ field }) => (
                                  <TextField
                                    size="small"
                                    {...field}
                                    {...register('role')}
                                    label="Designation"
                                    variant="outlined"
                                    fullWidth
                                    error={!!errors.role}
                                    helperText={errors.role ? errors.role.message : ''}
                                    onChange={(e) => {
                                      const updatedValue = changeNameFormat(e.target.value);
                                      setValue("role", updatedValue, { shouldDirty: true });
                                      setValue("title", updatedValue, { shouldDirty: true });
                                      trigger('role');
                                    }
                                    }
                                  />
                                )}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl fullWidth size="small" sx={{ marginBottom: '1rem' }}>
                              <Controller
                                control={control}
                                name="function"
                                defaultValue={defaultValues?.function || ''}
                                render={({ field }) => (
                                  <TypeAhead
                                    {...field}
                                    {...register('function')}
                                    data={roleData}
                                    freeSolo={true}
                                    label="Function"
                                    size="small"
                                    displayTextKey="name"
                                    onInputChange={(e, value) => {
                                      setValue("function", value, { shouldDirty: true });
                                    }}
                                    onChange={(e, value) => {
                                      setValue('all_skills', value?.skills);
                                      field.onChange(value?.name);
                                      setChipData(value?.skills);
                                      setAddMoreSkills(value?.skills);
                                      setSelectedChips([]);
                                      setCoreSkills([]);
                                    }}
                                  />
                                )}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid container spacing={4}>
                          <Grid item xs={6}>
                            <FormControl fullWidth size="small" sx={{ marginBottom: '1rem' }} error={!!errors.job_type}>
                              <InputLabel size='small' id="job_type">Job Type</InputLabel>
                              <Controller
                                control={control}
                                name="job_type"
                                defaultValue={defaultValues?.job_type || ''}
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    {...register('job_type')}
                                    labelId="job_type"
                                    error={!!errors.job_type}
                                    helperText={errors.job_type?.message}
                                    label="Job Type"
                                    variant="outlined"
                                    fullWidth
                                  >
                                    {jobType?.map((item) =>
                                      <MenuItem value={item?.label}>{item?.label}</MenuItem>
                                    )}
                                  </Select>
                                )}
                              />
                              <Typography variant="caption" color="error">
                                {errors?.job_type?.message}
                              </Typography>
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl fullWidth size="small" sx={{ marginBottom: '1rem' }} error={!!errors.job_shift}>
                              <InputLabel size="small" id="job_shift">
                                Job Shift
                              </InputLabel>
                              <Controller
                                name="job_shift"
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    {...register('job_shift')}
                                    size="small"
                                    label="Job Shift"
                                    variant="outlined" fullWidth>
                                    {preferredJobShifts?.map((item) =>
                                      <MenuItem value={item}>{item}</MenuItem>
                                    )}
                                  </Select>
                                )}
                              />
                              {errors.job_shift && <FormHelperText error>{errors.job_shift?.message}</FormHelperText>}
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid container spacing={4}>
                          <Grid item xs={6}>
                            <FormControl fullWidth size="small" sx={{ marginBottom: '1rem' }} error={!!errors.work_mode}>
                              <InputLabel size="small" id="work_mode">
                                Job Format
                              </InputLabel>
                              <Controller
                                control={control}
                                name="work_mode"
                                defaultValue={defaultValues?.work_mode || ''}
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    {...register('work_mode')}
                                    labelId="work_mode"
                                    error={!!errors.work_mode}
                                    helperText={errors.work_mode?.message}
                                    label="Job Format"
                                    variant="outlined"
                                    fullWidth
                                  >
                                    {workModelFormat?.map((item) =>
                                      <MenuItem value={item}>{item}</MenuItem>
                                    )}
                                  </Select>
                                )}
                              />
                              {errors?.work_mode && <FormHelperText error>{errors.work_mode?.message}</FormHelperText>}
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl fullWidth size="small" sx={{ marginBottom: '1rem' }}>
                              <Controller
                                control={control}
                                name="location"
                                defaultValue={defaultValues?.location || ''}
                                render={({ field }) => (
                                  <TypeAhead
                                    {...field}
                                    {...register('location')}
                                    api={`${process.env.REACT_APP_END_POINT}${endpoints?.services?.location}`}
                                    label="Location"
                                    freeSolo={true}
                                    onInputChange={(e, value) => {
                                      setValue("location", value, { shouldDirty: true });
                                    }}
                                    onChange={(e, value) => {
                                      setValue("location", value, { shouldDirty: true });
                                      handleOtherLocation(value);
                                    }}
                                  />
                                )}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>

                        <Grid container columnSpacing={4}>
                          <Grid item xs={6}>
                            <FormControl fullWidth size="small" error={!!errors?.direct_reports}>
                              <InputLabel id="direct_reports">Number of people reporting</InputLabel>
                              <Controller
                                control={control}
                                name="direct_reports"
                                defaultValue={defaultValues?.direct_reports || ''}
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    {...register('direct_reports')}
                                    labelId="direct_reports"
                                    label="Number of people reporting"
                                    variant="outlined"
                                    fullWidth

                                  >
                                    <MenuItem value="Individual contributor (IC)">Individual contributor (IC)</MenuItem>
                                    <MenuItem value="1-4">1-4</MenuItem>
                                    <MenuItem value="5-10">5-10</MenuItem>
                                    <MenuItem value="11-25">11-25</MenuItem>
                                    <MenuItem value="26-50">26-50</MenuItem>
                                    <MenuItem value="51-100">51-100</MenuItem>
                                    <MenuItem value="100+">100+</MenuItem>
                                  </Select>
                                )}
                              />
                              {errors?.direct_reports && <FormHelperText error>{errors?.direct_reports?.message}</FormHelperText>}
                            </FormControl>
                          </Grid>
                          {alignment === 'external-job-posting' && (
                            <Grid item xs={6}>
                              <FormControl fullWidth sx={{ marginBottom: '1rem' }}>
                                <Controller
                                  name="external_link"
                                  control={control}
                                  render={({ field }) => (
                                    <TextField
                                      {...field}
                                      {...register('external_link')}
                                      size="small"
                                      error={!!errors.external_link}
                                      helperText={errors.external_link?.message}
                                      label="Job Application Link"
                                      variant="outlined"
                                      fullWidth
                                    />
                                  )}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <Typography className="label-text" mb={1}>Description</Typography>
                            {/* description */}
                            <FormControl fullWidth sx={{ marginBottom: '0.5rem' }} className={`quill-container-job ${(errors.description || errorDescription) ? 'error-quill' : ''}`}>
                              <Controller
                                name="description"
                                control={control}
                                render={({ field }) => (
                                  <ReactQuill
                                    {...field}
                                    {...register('description')}
                                    ref={quillRef}
                                    value={field.value}
                                    onChange={(value) => {
                                      setValue('description', value);
                                      validateDescription(value);
                                      trigger('description');
                                    }}
                                    theme="snow"
                                    placeholder="Job description"
                                    modules={{
                                      toolbar: [
                                        [{ 'size': ['small', false, 'large', 'huge'] }],
                                        ['bold', 'italic', 'underline'],
                                        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                                      ]
                                    }}
                                  />
                                )}
                              />
                              {(errors?.description || errorDescription) && <FormHelperText error>Job description is required</FormHelperText>}
                              <Typography component="p" className="need-help-txt">
                                Need help with some examples?
                                <Link href="#"> Click Here</Link>
                              </Typography>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Box>

                  {/* Job Requirements */}
                  <Box mb={4}>
                    <Box className="card-container">
                      <Box className="card-title">
                        <Typography className="title-text">Job Requirements</Typography>
                      </Box>

                      <Box className="card-body">
                        <Grid container spacing={4}>
                          <Grid item xs={6}>
                            <FormControl fullWidth size="small" sx={{ marginBottom: '1rem' }}>
                              <Controller
                                control={control}
                                name="qualifications"
                                defaultValue={defaultValues?.qualifications || []}
                                render={({ field }) => (
                                  <TypeAhead
                                    {...field}
                                    {...register('qualifications')}
                                    data={degreeData}
                                    label="Minimum Educational Qualification (Any of the below can apply)"
                                    displayTextKey="display_name"
                                    multiple={true}
                                    disableClearable={true}
                                  />
                                )}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl fullWidth size="small">
                              <Controller
                                control={control}
                                name="certifications"
                                defaultValue={defaultValues?.certifications || []}
                                render={({ field }) => (
                                  <TypeAhead
                                    {...field}
                                    {...register('certifications')}
                                    data={certificateTitleData}
                                    multiple={true}
                                    label="Certification"
                                    displayTextKey="name"
                                  />
                                )}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid container spacing={4}>
                          <Grid item xs={6}>
                            <Typography className="label-text" mb={1}>
                              Experience Required (in years)
                            </Typography>

                            <Grid container spacing={4}>
                              <Grid item xs={6}>
                                <FormControl fullWidth sx={{ marginBottom: '1.5rem' }}>
                                  <Controller
                                    name="min"
                                    control={control}
                                    defaultValue={defaultValues?.min}
                                    render={({ field }) => (
                                      <TextField
                                        {...field}
                                        {...register('min')}
                                        type="number"
                                        label="Min Experience"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        error={!!errors.min}
                                        helperText={errors.min ? errors.min.message : ''}
                                      />
                                    )}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={6}>
                                <FormControl fullWidth sx={{ marginBottom: '1.5rem' }}>
                                  <Controller
                                    name="max"
                                    control={control}
                                    defaultValue={defaultValues?.max}
                                    render={({ field }) => (
                                      <TextField
                                        {...field}
                                        {...register('max')}
                                        type="number"
                                        label="Max Experience"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        error={!!errors.max}
                                        helperText={errors.max ? errors.max.message : ''}
                                      />
                                    )}
                                  />
                                </FormControl>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={6} sx={{ marginTop: '27px' }}>
                            <FormControl
                              fullWidth
                              size="small"
                              sx={{ marginBottom: '1.5rem' }}
                              error={!!errors.notice_period}
                            >
                              <InputLabel size="small" id="notice_period">
                                Maximum Notice Period (in days)
                              </InputLabel>
                              <Controller
                                name="notice_period"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    {...register('notice_period')}
                                    size="small"
                                    label=" Maximum Notice Period (in days)"
                                    variant="outlined"
                                    fullWidth
                                  >
                                    <MenuItem value="No preference">No preference</MenuItem>
                                    <MenuItem value="0 days (immediate)">0 days (immediate)</MenuItem>
                                    <MenuItem value="15">Upto 15 Days</MenuItem>
                                    <MenuItem value="30">Upto 30 Days</MenuItem>
                                    <MenuItem value="45">Upto 45 Days</MenuItem>
                                    <MenuItem value="60">Upto 60 Days</MenuItem>
                                    <MenuItem value="75">Upto 75 Days</MenuItem>
                                    <MenuItem value="90">Upto 90 Days</MenuItem>
                                    <MenuItem value="90+">Upto 90+ Days</MenuItem>
                                  </Select>
                                )}
                              />
                              {errors.notice_period && (
                                <FormHelperText error>{errors.notice_period.message}</FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                        </Grid>

                        <Grid container spacing={4}>
                          <Grid item xs={6}>
                            <FormControl fullWidth>
                              <Controller
                                control={control}
                                name="salary"
                                defaultValue={defaultValues?.salary || ''}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    label="Maximum Annual Compensation"
                                    variant="outlined"
                                    size="small"
                                    value={field.value}
                                    type="number"
                                    error={!!errors.salary}
                                    helperText={errors.salary?.message}
                                    onChange={(e) => {
                                      field.onChange(e.target.value);
                                      setValue('salary', e.target.value);
                                    }}
                                  />
                                )}
                              />
                            </FormControl>
                            <Controller
                              name="is_compensation_confidential"
                              control={control}
                              render={({ field }) => (
                                <FormControlLabel
                                  control={
                                    <BpCheckbox
                                      {...field}
                                      checked={field.value}
                                      onChange={(e) => field.onChange(e.target.checked)}
                                      sx={{ color: 'rgba(0, 0, 0, 0.38)', '&.Mui-checked': { color: '#1976d2', } }}
                                    />
                                  }
                                  label="Keep compensation confidential"
                                  sx={{
                                    '& .MuiFormControlLabel-label': {
                                      fontWeight: 'normal',
                                      color: 'rgba(0, 0, 0, 0.54)',
                                    }
                                  }}
                                />
                              )}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <FormControl fullWidth size="small" error={!!errors.compensation}>
                              <InputLabel size="small" id="compensation">
                                Compensation Type
                              </InputLabel>
                              <Controller
                                name="compensation"
                                control={control}
                                defaultValue={defaultValues?.compensation || ''}
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    {...register('compensation')}
                                    inputRef={compensationTypeRef}
                                    size="small"
                                    label="Compensation Type"
                                    variant="outlined"
                                    fullWidth>
                                    <MenuItem value="All Fixed">All Fixed</MenuItem>
                                    <MenuItem value="Fixed + Variable">Fixed + Variable</MenuItem>
                                    <MenuItem value="Fixed + Variable + Other Perks">Fixed + Variable + Other Perks</MenuItem>
                                  </Select>
                                )}
                              />
                              {errors.compensation && <FormHelperText error>{errors.compensation?.message}</FormHelperText>}
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Box>

                  {/* Required Expertise / Skills */}
                  <Box mb={4} className="job-posting-skills">
                    <Box className="card-container">
                      <Box className="card-title">
                        <Stack>
                          <Typography className="title-text">Required Expertise/ Skills</Typography>
                          <Typography className="info-text">Please add 5-10 relevant skills. You may indicate up to 5 preferred skills that are highly recommended. (Click on {' '}
                            <span style={{ display: 'inline-flex', alignItems: 'center' }}><StarIcon sx={{ color: '#faaf00', fontSize: 'inherit' }} /></span>
                            {' '}  to indicate a preferred skill)</Typography>
                        </Stack>
                      </Box>

                      <Box className="card-body" >
                        <Stack direction="row" flexWrap="wrap" >
                          {chipData?.map((label, index) => (
                            <Box className="chip_container" sx={{ height: '40px!important' }}>
                              {/* {selectedChips?.includes(label) &&
                              <IconButton aria-label="delete" className={coreSkill?.includes(label) ? "selected-star" : ''} onClick={() => handleCoreSkills(label)}>
                                <StarIcon />
                              </IconButton>} */}
                              <Chip
                                key={index}
                                label={
                                  selectedChips?.includes(label) ? <span style={{ display: 'flex', alignItems: 'center' }}>
                                    <IconButton aria-label="delete" onClick={(e) => { e.stopPropagation(); handleCoreSkills(label); }}>
                                      <StarIcon sx={{ fill: coreSkill?.includes(label) ? '#faaf00 !important' : 'white !important' }} />
                                    </IconButton>
                                    {label}
                                  </span> : label
                                }
                                size="small"
                                clickable
                                variant={selectedChips?.includes(label) ? 'filled' : 'outlined'}
                                style={{
                                  color: selectedChips?.includes(label) ? 'white' : 'black',
                                  backgroundColor: selectedChips?.includes(label) ? '#1575C6' : '',
                                }}
                                onClick={() => handleClick(label)}
                              />
                            </Box>
                          ))}
                        </Stack>

                        {showFlag && (
                          <Typography style={{ color: 'red', fontSize: 'small' }}>
                            {showFlag}
                          </Typography>
                        )}
                        <Box sx={{ padding: '4px 0px' }}>
                          <Link className="add-more-skills-link" size="small" onClick={handleOpenOtpDialog} underline="none">
                            Add More Skills
                          </Link>
                        </Box>
                        <Dialog
                          open={open}
                          onClose={handleClose}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                          className="otp-dialog-wrapper"
                        >
                          <AddSkills
                            onClose={handleClose}
                            skills={addMoreSkills}
                            selectedskills={selectedChips}
                            onAddSkills={handleAddMoreSkills}
                            type={'jobType'}
                          />
                        </Dialog>
                      </Box>
                    </Box>
                  </Box>

                  {/* Special Requirements */}
                  <Box mb={5}>
                    <Box className="card-container">
                      <Box className="card-title">
                        <Stack>
                          <Typography className="title-text">Special Requirements</Typography>
                          <Typography className="info-text">
                            Please mention if you are looking for specific candidate type
                          </Typography>
                        </Stack>
                      </Box>

                      <Box className="card-body">
                        <Grid container spacing={4}>
                          <Grid item xs={6}>
                            <FormControl fullWidth size="small" error={!!errors.gender_preference}>
                              <InputLabel size="small" id="gender_preference">
                                Gender preference
                              </InputLabel>
                              <Controller
                                name="gender_preference"
                                defaultValue=""
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    {...register('gender_preference')}
                                    size="small"
                                    label="Gender preference"
                                    variant="outlined"
                                    fullWidth>
                                    <MenuItem value="none">None</MenuItem>
                                    <MenuItem value="Male">Male</MenuItem>
                                    <MenuItem value="Female">Female</MenuItem>
                                    {/* <MenuItem value="Other">Other</MenuItem> */}
                                  </Select>
                                )}
                              />
                              {errors.gender_preference && (
                                <FormHelperText error>{errors.gender_preference?.message}</FormHelperText>
                              )}
                            </FormControl>
                          </Grid>

                          <Grid item xs={6}>
                            <FormControl fullWidth size="small" error={!!errors.disability_preference}>
                              <InputLabel id="">Disability Preference</InputLabel>
                              <Controller
                                control={control}
                                defaultValue=''
                                name="disability_preference"
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    {...register('disability_preference')}
                                    size="small"
                                    label="Disability preference"
                                    variant="outlined"
                                    fullWidth>
                                    <MenuItem value="Not applicable">Not applicable</MenuItem>
                                    <MenuItem value="Suitable for specially abled candidates only">Suitable for specially abled candidates only</MenuItem>
                                    <MenuItem value="Suitable for all candidates irrespective of any disabilities">Suitable for all candidates irrespective of any disabilities</MenuItem>
                                  </Select>
                                )}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Box>

                  {/* Questions Section */}
                  {/* <Box mb={5}>
                  <Box className="card-container">
                    <Box className="card-title">
                      <Stack>
                        <Typography className="title-text">Questions for Candidate Assessment</Typography>
                      </Stack>
                    </Box>

                    <Box className="card-body">
                      <Button variant="outlined" size="small" onClick={screeningQueDrawer(true)}>
                        Add Questions
                      </Button>
                      {errors.question && <FormHelperText error>{errors.question?.message}</FormHelperText>}
                    </Box>
                  </Box>
                </Box> */}

                  <Box>
                    <Stack direction="row" alignItems={'center'} justifyContent={'space-between'}>
                      <Box>
                        <Stack direction="row" spacing={2}>
                          <Button
                            className="continue-btn"
                            variant="contained"
                            size="medium"
                            disabled={isSubmitting || posting}
                            type="submit"
                          >
                            {alignment === 'external-job-posting' ? posting ? 'posting...' : 'Done' :
                              "Continue"
                            }
                          </Button>
                          {jobData?.status !== 'active' &&
                            <Button className="sad-btn" variant="outlined" size="medium" onClick={saveAsDraft}>
                              Save As Draft
                            </Button>}
                        </Stack>
                      </Box>
                      <Button className="reset-btn" size="medium" onClick={onReset}>
                        Reset
                      </Button>
                    </Stack>
                  </Box>
                </FormProvider>
              </Box>}
          </Box>
        </Container>
      </Box>

      <Drawer open={openCUEducDrawer} anchor={'right'} onClose={screeningQueDrawer(false)}>
        <ScreeningQuestionDrawer
          onClose={screeningQueDrawer(false)}
          keyQuestions={keyQuestions}
          setJobQuestions={handleQuestions}
          jobData={jobData}
          jobUniqueId={jobUniqueId}
          jobId={jobId}
        />
      </Drawer>
      <Dialog
        open={openCopyUrlDialog}
        // onClose={handleCloseCopyUrlDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="action-dialog-card external-list-dialog"
      > <DialogContent>
          <CopyUrlDialog onClose={handleCloseCopyUrlDialog} title={jobData?.title} jobId={jobId} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default NewJobPosting;

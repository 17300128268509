import React, { useEffect, useState, useRef, useCallback } from 'react';
import { InputAdornment, CircularProgress, SvgIcon, Link, Box, Button, Popover, Grid, IconButton, Checkbox, Stack, FormGroup, Typography, FormControl, RadioGroup, FormControlLabel, Radio, Divider, TextField, Slide, Drawer, useMediaQuery } from '@mui/material'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { getJobPreferanceDetails } from 'api/users';
import { debounce } from 'lodash';
import { cleanData, clusteredData } from 'data/city.js';
import { useTheme } from '@mui/material/styles';
import { getAllJobList } from 'api/job';
function TabPanel(props) {

    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const AdminSearchFilter = ({fetchJobs,loading,setFilterLoading, totalJobs }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        fetchJobPreferences();
    }, []);

    // job track menu
    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);

    // filter by tabs
    const [value, setValue] = React.useState(0);

    // show hide filter search
    const containerRef = React.useRef(null);
    const [showFilter, setShowFilter] = React.useState(true);
    const [showSearch, setShowSearch] = React.useState(false);
    const [preferedLocation, setPreferedLocation] = useState([]);
    const [jobTypes, setJobTypes] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const inputRef = useRef(null);
    // const [category, setCategory] = useState(categoryFilter ? categoryFilter : "");
    //  const [filterLoading, setFilterLoading] = useState(false);

    const [filterCriteria, setFilterCriteria] = useState({
        location: [],
        work_format: [],
        job_type: []
    });

    const [sortCriteria, setSortCriteria] = useState({
        salary: "",
        updatedAt: "",
    });

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    // const handlePopoverClose = (isCancel) => {
    //     setAnchorEl(null);
    //     const isFilterApplied = (filterCriteria?.location?.length || filterCriteria?.work_format?.length || filterCriteria?.job_type?.length);
    //     const isSortingApplied = Object?.keys(sortCriteria)?.length;
    //     if (isCancel && (isFilterApplied || isSortingApplied || filterApplied)) {
    //         setFilterApplied(false);
    //         setSortCriteria({});
    //         setFilterCriteria({
    //             location: [],
    //             work_format: [],
    //             job_type: []
    //         })
    //         fetchJobs(null, false, null, {
    //             location: [],
    //             work_format: [],
    //             job_type: []
    //         }, {}, activeTab, null, true);
    //         setFilterLoading(true);
    //     }
    // };

    const handleApplyFilter = async () => {
        if (!sortCriteria && filterCriteria?.location?.length === 0 && filterCriteria?.work_format?.length === 0 && filterCriteria?.job_type?.length === 0) {
            return;
        }
        setFilterLoading(true);
        setFilterApplied(true);
        fetchJobs(null, false, null, filterCriteria, sortCriteria, activeTab, category, true);
        handlePopoverClose();
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleShowSearch = () => {
        setShowSearch(true);
        setShowFilter(false);
        setTimeout(() => {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }, 300);
    };

    const handleCloseSearchJob = () => {
        if (searchValue) {
            setSearchValue('');
            setFilterLoading(true);
            fetchJobs(null, false, null, filterCriteria, sortCriteria, activeTab, null, true);
        }
    };
    const handleShowHide = () => {
        setShowSearch(false);
        setShowFilter(true);
        handleCloseSearchJob();
    }
    const handleSortChange = (event) => {
        const { name, value } = event.target;
        setSortCriteria({
            ...sortCriteria,
            [name]: value,
        });
    };

    const handleDoubleClick = (event) => {
        const { name, value } = event.target;
        setSortCriteria((prev) => ({
            ...prev,
            [name]: prev[name] === value ? "" : value,
        }));
    };

    const debouncedSearch = useCallback(
        debounce(async (value) => {
            // setFilterLoading(true);
            // setOpenSearch(value ? true : false);
            fetchJobs( value);
        }, 500),
        []
    );

    const handleSearchInputChange = (value) => {
        setSearchValue(value);
        debouncedSearch(value);
    };

    const handleChangeFilterCriteria = (event, value) => {
        const { name } = event.target;
        setFilterCriteria((prevCriteria) => ({
            ...prevCriteria,
            [name]: prevCriteria[name].includes(value)
                ? prevCriteria[name].filter((item) => item !== value)
                : [...prevCriteria[name], value],
        }));
    };

    const fetchJobPreferences = async () => {
        try {
            const response = await getJobPreferanceDetails();
            if (response?.success && response.data) {
                setJobTypes(response?.data?.job_type || [])
                const location = response.data.prefered_location.filter((loc) => loc !== "Any");
                setPreferedLocation(location || []);
            } else {
                enqueueSnackbar('Failed to fetch job preferences', { variant: 'error' });
            }
        } catch (error) {
            console.error('Error fetching job preferences:', error);
        }
    };
    const handleCategories = (e) => {
        setCategory(e.target.value);
    };

    return (
        <>
            <Box className="header-wrapper" ref={containerRef}>
                <Box className="filter-wrapper" mb={2}>
                    <Stack direction="row" justifyContent={'space-between'}>
                        <Typography className="head-text" variant="p">
                            {totalJobs.length>0 ?`${totalJobs.length} Active Jobs` : "No Result Found"}
                        </Typography>
                        <Box>
                            <Stack direction="row" spacing={'10px'}>
                                <IconButton
                                    aria-label="update"
                                    aria-owns={open }
                                    aria-haspopup="true"
                                    onClick={handlePopoverOpen}
                                >
                                    {<SvgIcon className="ellipse-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                                            <circle cx="6" cy="6.5" r="6" fill="#C12929" />
                                        </svg>
                                    </SvgIcon>}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.0923 12.3074H16.3846C16.7243 12.3074 17 12.0317 17 11.692C17 11.3523 16.7243 11.0766 16.3846 11.0766H7.0923C6.80677 9.67293 5.56431 8.61508 4.07692 8.61508C2.37846 8.61508 1 9.99355 1 11.692C1 13.3905 2.37846 14.7689 4.07692 14.7689C5.56431 14.7689 6.80677 13.7111 7.0923 12.3074ZM4.07692 9.84585C5.096 9.84585 5.92308 10.6729 5.92308 11.692C5.92308 12.7111 5.096 13.5382 4.07692 13.5382C3.05785 13.5382 2.23077 12.7111 2.23077 11.692C2.23077 10.6729 3.05785 9.84585 4.07692 9.84585ZM10.9077 3.69201H1.61538C1.27569 3.69201 1 3.9677 1 4.30739C1 4.64708 1.27569 4.92278 1.61538 4.92278H10.9077C11.1932 6.32647 12.4357 7.38432 13.9231 7.38432C15.6215 7.38432 17 6.00585 17 4.30739C17 2.60893 15.6215 1.23047 13.9231 1.23047C12.4357 1.23047 11.1932 2.28832 10.9077 3.69201ZM13.9231 2.46124C14.9422 2.46124 15.7692 3.28832 15.7692 4.30739C15.7692 5.32647 14.9422 6.15355 13.9231 6.15355C12.904 6.15355 12.0769 5.32647 12.0769 4.30739C12.0769 3.28832 12.904 2.46124 13.9231 2.46124Z" fill="#9CA3AF" stroke="white" stroke-width="0.5" />
                                    </svg>
                                </IconButton>
                                <IconButton className={showSearch ? "search-active" : ""} aria-label="update" onClick={handleShowSearch}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.23254 7.20043C1.23254 3.9044 3.9045 1.23242 7.20052 1.23242C10.4965 1.23242 13.1685 3.9044 13.1685 7.20043C13.1685 10.4964 10.4965 13.1684 7.20052 13.1684C3.90449 13.1684 1.23254 10.4964 1.23254 7.20043ZM7.20052 0.232422C3.35221 0.232422 0.232544 3.35212 0.232544 7.20043C0.232544 11.0487 3.35221 14.1684 7.20052 14.1684C8.94474 14.1684 10.5393 13.5275 11.7615 12.4684L14.9139 15.6208C15.1092 15.8161 15.4258 15.8161 15.621 15.6208C15.8163 15.4255 15.8163 15.1089 15.621 14.9137L12.4686 11.7613C13.5277 10.5391 14.1685 8.94459 14.1685 7.20043C14.1685 3.35212 11.0488 0.232422 7.20052 0.232422Z" fill="#9CA3AF" />
                                    </svg>
                                </IconButton>
                            </Stack>
                        </Box>
                    </Stack>
                </Box>
                {showSearch &&
                    <Box className="search-wrapper">
                        <Stack direction="row" alignItems={'center'} spacing={2} sx={{ width: '100%' }}>
                            <TextField id="outlined-basic" size="small" variant="outlined" inputRef={inputRef} value={searchValue} placeholder="Enter designation / companies / location" sx={{ background: '#ffffff', borderRadius: '12px', width: '100%' }}
                                onChange={(e) => handleSearchInputChange(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        loading && (
                                            
                                            <InputAdornment position="end">
                                                <CircularProgress size={20} />
                                            </InputAdornment>
                                        )
                                    )
                                }}
                            />
                            <Link onClick={handleShowHide} sx={{ 'cursor': 'pointer' }} className='cancel-btn' underline="none">Cancel</Link>
                        </Stack>
                    </Box>
                }
            </Box>

            {/* Desktop shotby filter popover */}
            {/* {isMobile ? (
                <Drawer
                    anchor="bottom"
                    open={open}
                    onClose={handlePopoverClose}
                    className="mobile-bottomsheet-dialog filter-by-overlay"
                >
                    <Box sx={{ height: 400, width: { xs: '100%', md: '600px' } }}>
                        <Grid container sx={{ height: '100%' }}>
                            <Grid item xs={4} md={5} className="filter-by-tabs-item">
                                <Tabs
                                    orientation="vertical"
                                    variant="scrollable"
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="Vertical tabs example"
                                    sx={{ height: '100%' }}
                                >
                                    <Tab label="Sort By" disableRipple {...a11yProps(0)} />
                                    <Tab label="Location" disableRipple {...a11yProps(1)} />
                                    <Tab label="Job Suitability" disableRipple {...a11yProps(2)} />
                                    <Tab label="Job Type" disableRipple {...a11yProps(3)} />
                                    {activeTab === 'saved-jobs' && <Tab label="Application Status" disableRipple {...a11yProps(4)} />}
                                </Tabs>
                            </Grid>

                            <Grid item xs={8} md={7} className="filter-by-tabs-content">
                                <TabPanel value={value} index={0} className="tab-panel-wrapper">
                                    <Box>
                                        <Box>
                                            <Typography className="title">Sort By</Typography>
                                        </Box>
                                        <Box>
                                            <FormControl>
                                                <RadioGroup
                                                    aria-labelledby="demo-controlled-radio-1"
                                                    name="salary"
                                                    value={sortCriteria.salary}
                                                    onChange={handleSortChange}
                                                >
                                                    <FormControlLabel sx={{ marginRight: { xs: 0 } }} value="1" control={<Radio onDoubleClick={handleDoubleClick} />} label="Compensation (High to Low)" />
                                                    <FormControlLabel sx={{ marginRight: { xs: 0 } }} value="0" control={<Radio onDoubleClick={handleDoubleClick} />} label="Compensation (Low to High)" />
                                                </RadioGroup>
                                            </FormControl>
                                            <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '0.5rem', marginBottom: '0.5rem' }} />
                                            <FormControl>
                                                <RadioGroup
                                                    aria-labelledby="demo-controlled-radio-2"
                                                    name="updatedAt"
                                                    value={sortCriteria.updatedAt}
                                                    onChange={handleSortChange}
                                                >
                                                    <FormControlLabel value="1" control={<Radio onDoubleClick={handleDoubleClick} />} label="Posted (Latest First)" />
                                                    <FormControlLabel value="0" control={<Radio onDoubleClick={handleDoubleClick} />} label="Posted (Oldest First)" />
                                                </RadioGroup>
                                            </FormControl>
                                            <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '0.5rem', marginBottom: '0.5rem' }} />
                                        </Box>
                                    </Box>
                                </TabPanel>
                                <TabPanel value={value} index={1} className="tab-panel-wrapper">
                                    <Box>
                                        <Box>
                                            <Typography className="title">Location</Typography>
                                            <FormControl>
                                                <FormGroup>
                                                    {preferedLocation?.map((data, index) => (
                                                        <FormControlLabel
                                                            key={index}
                                                            control={
                                                                <Checkbox
                                                                    checked={filterCriteria?.location?.some((fl) => (clusteredData?.find(ct => ct?.value === fl)?.name === data || fl === data))}
                                                                    onChange={(e) => handleChangeFilterCriteria(e, clusteredData?.find(ct => ct?.name === data)?.value || data)}
                                                                    // checked={filterCriteria?.location?.includes(data)}
                                                                    // onChange={(e) => handleChangeFilterCriteria(e, data)}
                                                                    name="location"
                                                                />
                                                            }
                                                            label={data}
                                                        />
                                                    ))}
                                                </FormGroup>
                                            </FormControl>
                                        </Box>
                                    </Box>
                                </TabPanel>
                                <TabPanel value={value} index={2} className="tab-panel-wrapper">
                                    <Box>
                                        <Typography className="title">Job Suitability</Typography>
                                        <FormControl>
                                            <FormGroup>

                                                {["Highly Suitable", "Moderately Suitable", "Not Suitable"]?.map(data => (
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                name="work_format"
                                                                checked={filterCriteria?.work_format?.includes(data)}
                                                                onChange={(e) => handleChangeFilterCriteria(e, data)}
                                                                value={data} />
                                                        }
                                                        label={data} />
                                                ))}
                                            </FormGroup>
                                        </FormControl>
                                    </Box>
                                </TabPanel>
                                <TabPanel value={value} index={3} className="tab-panel-wrapper">
                                    <Box>
                                        <FormControl>
                                            <FormGroup>
                                                <Typography className="title">Job Type</Typography>
                                                <Stack>
                                                {["Full time", "Part time", "Temporary", "Internship", "Trainee", "Freelancer (Self Employed)"]?.map((jobType, index) => (
                                                    <FormControlLabel
                                                        key={index}
                                                        control={
                                                            <Checkbox
                                                                checked={filterCriteria.job_type.includes(jobType)}
                                                                onChange={(e) => handleChangeFilterCriteria(e, jobType)}
                                                                name="job_type"
                                                                sx={{padding: '8px'}}
                                                            />
                                                        }
                                                        label={jobType}
                                                    />
                                                ))}
                                                </Stack>
                                            </FormGroup>
                                        </FormControl>
                                    </Box>
                                </TabPanel>
                                {activeTab === 'saved-jobs' &&
                                    <TabPanel value={value} index={4} className="tab-panel-wrapper">
                                        <Box>
                                            <Box>
                                                <Typography className="title">Application Status</Typography>
                                            </Box>
                                            <Box>
                                                <FormControl>
                                                    <RadioGroup
                                                        aria-labelledby="demo-controlled-radio-2"
                                                        name="category"
                                                        value={category}
                                                        onChange={handleCategories}
                                                    >
                                                        <FormControlLabel sx={{ marginRight: { xs: 0 } }} value="internal" control={<Radio />} label="Saved Jobs" />
                                                        <FormControlLabel sx={{ marginRight: { xs: 0 } }} value="external" control={<Radio />} label="Pending Jobs" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Box>
                                        </Box>
                                    </TabPanel>
                                }
                                <Box className="filter-by-actions-btn">
                                    <Stack direction="row" spacing={2}>
                                        <Button variant="outlined" fullWidth onClick={() => { handlePopoverClose(true) }}>{filterApplied ? "Clear Filter" : "Cancel"}</Button>
                                        <Button variant="contained" fullWidth onClick={handleApplyFilter}>Apply</Button>
                                    </Stack>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Drawer>) : (
                <Popover
                    elevation={3}
                    id="filter-by"
                    className="filter-by-overlay"
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={handlePopoverClose}
                    sx={{
                        top: '15px',
                        left: '-150px'
                    }}
                    disableRestoreFocus
                >
                    <Box
                        sx={{
                            position: "relative",
                            "&::before": {
                                backgroundColor: "white",
                                content: '""',
                                display: "block",
                                position: "absolute",
                                width: 12,
                                height: 12,
                                top: -8,
                                transform: "rotate(45deg)",
                                left: "calc(25% - 7px)",
                                borderTop: '1px solid #D2D5DB',
                                borderLeft: '1px solid #D2D5DB'
                            }
                        }}
                    />
                    <Box sx={{ height: 400, width: { xs: '100%', md: '600px' } }}>
                        <Grid container sx={{ height: '100%' }}>
                            <Grid item xs={4} md={5} className="filter-by-tabs-item">
                                <Tabs
                                    orientation="vertical"
                                    variant="scrollable"
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="Vertical tabs example"
                                    sx={{ height: '100%' }}
                                >
                                    <Tab label="Sort By" disableRipple {...a11yProps(0)} />
                                    <Tab label="Location" disableRipple {...a11yProps(1)} />
                                    <Tab label="Job Suitability" disableRipple {...a11yProps(2)} />
                                    <Tab label="Job Type" disableRipple {...a11yProps(3)} />
                                    {activeTab === 'saved-jobs' && <Tab label="Application Status" disableRipple {...a11yProps(4)} />}
                                </Tabs>
                            </Grid>

                            <Grid item xs={8} md={7} className="filter-by-tabs-content">
                                <TabPanel value={value} index={0} className="tab-panel-wrapper">
                                    <Box>
                                        <Box>
                                            <Typography className="title">Sort By</Typography>
                                        </Box>
                                        <Box>
                                            <FormControl>
                                                <RadioGroup
                                                    aria-labelledby="demo-controlled-radio-1"
                                                    name="salary"
                                                    value={sortCriteria.salary}
                                                    onChange={handleSortChange}
                                                >
                                                    <FormControlLabel sx={{ marginRight: { xs: 0 } }} value="1" control={<Radio onDoubleClick={handleDoubleClick} />} label="Compensation (High to Low)" />
                                                    <FormControlLabel sx={{ marginRight: { xs: 0 } }} value="0" control={<Radio onDoubleClick={handleDoubleClick} />} label="Compensation (Low to High)" />
                                                </RadioGroup>
                                            </FormControl>
                                            <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '0.5rem', marginBottom: '0.5rem' }} />
                                            <FormControl>
                                                <RadioGroup
                                                    aria-labelledby="demo-controlled-radio-2"
                                                    name="updatedAt"
                                                    value={sortCriteria.updatedAt}
                                                    onChange={handleSortChange}
                                                >
                                                    <FormControlLabel value="1" control={<Radio onDoubleClick={handleDoubleClick} />} label="Posted (Latest First)" />
                                                    <FormControlLabel value="0" control={<Radio onDoubleClick={handleDoubleClick} />} label="Posted (Oldest First)" />
                                                </RadioGroup>
                                            </FormControl>
                                            <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '0.5rem', marginBottom: '0.5rem' }} />
                                        </Box>
                                    </Box>
                                </TabPanel>
                                <TabPanel value={value} index={1} className="tab-panel-wrapper">
                                    <Box>
                                        <Box>
                                            <Typography className="title">Location</Typography>
                                            <FormControl>
                                                <FormGroup>
                                                    {preferedLocation?.map((data, index) => (
                                                        <FormControlLabel
                                                            key={index}
                                                            control={
                                                                <Checkbox
                                                                    checked={filterCriteria?.location?.some((fl) => (clusteredData?.find(ct => ct?.value === fl)?.name === data || fl === data))}
                                                                    onChange={(e) => handleChangeFilterCriteria(e, clusteredData?.find(ct => ct?.name === data)?.value || data)}
                                                                    // checked={filterCriteria?.location?.includes(data)}
                                                                    // onChange={(e) => handleChangeFilterCriteria(e, data)}
                                                                    name="location"
                                                                />
                                                            }
                                                            label={data}
                                                        />
                                                    ))}
                                                </FormGroup>
                                            </FormControl>
                                        </Box>
                                    </Box>
                                </TabPanel>
                                <TabPanel value={value} index={2} className="tab-panel-wrapper">
                                    <Box>
                                        <Typography className="title">Job Suitability</Typography>
                                        <FormControl>
                                            <FormGroup>

                                                {["Highly Suitable", "Moderately Suitable", "Not Suitable"]?.map(data => (
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                name="work_format"
                                                                checked={filterCriteria?.work_format?.includes(data)}
                                                                onChange={(e) => handleChangeFilterCriteria(e, data)}
                                                                value={data} />
                                                        }
                                                        label={data} />
                                                ))}
                                            </FormGroup>
                                        </FormControl>
                                    </Box>
                                </TabPanel>
                                <TabPanel value={value} index={3} className="tab-panel-wrapper">
                                    <Box>
                                        <FormControl>
                                            <FormGroup>
                                                <Typography className="title">Job Type</Typography>
                                                {["Full time", "Part time", "Temporary", "Internship", "Trainee", "Freelancer (Self Employed)"]?.map((jobType, index) => (
                                                    <FormControlLabel
                                                        key={index}
                                                        control={
                                                            <Checkbox
                                                                checked={filterCriteria.job_type.includes(jobType)}
                                                                onChange={(e) => handleChangeFilterCriteria(e, jobType)}
                                                                name="job_type"
                                                            />
                                                        }
                                                        label={jobType}
                                                    />
                                                ))}
                                            </FormGroup>
                                        </FormControl>
                                    </Box>
                                </TabPanel>
                                {activeTab === 'saved-jobs' &&
                                    <TabPanel value={value} index={4} className="tab-panel-wrapper">
                                        <Box>
                                            <Box>
                                                <Typography className="title">Application Status</Typography>
                                            </Box>
                                            <Box>
                                                <FormControl>
                                                    <RadioGroup
                                                        aria-labelledby="demo-controlled-radio-2"
                                                        name="category"
                                                        value={category}
                                                        onChange={handleCategories}
                                                    >
                                                        <FormControlLabel sx={{ marginRight: { xs: 0 } }} value="internal" control={<Radio />} label="Saved Jobs" />
                                                        <FormControlLabel sx={{ marginRight: { xs: 0 } }} value="external" control={<Radio />} label="Pending Jobs" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Box>
                                        </Box>
                                    </TabPanel>
                                }
                                <Box className="filter-by-actions-btn">
                                    <Stack direction="row" spacing={2}>
                                        <Button variant="outlined" fullWidth onClick={() => { handlePopoverClose(true) }}>{filterApplied ? "Clear Filter" : "Cancel"}</Button>
                                        <Button variant="contained" fullWidth onClick={handleApplyFilter}>Apply</Button>
                                    </Stack>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Popover>)} */}

        </>
    )
}

export default AdminSearchFilter

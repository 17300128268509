import { fetcher, endpoints, poster } from '../utils/axios';

export async function getApplicantsList(payload) {
    const URL = endpoints.admin.getApplicantsList;
    return await fetcher([URL, { params: payload }])
}
export async function getApplicantRating(payload) {
    const URL = endpoints.admin.getApplicationRating;
    return await poster([URL, payload])
}


export async function getCandidateList(payload) {
    const URL = endpoints.admin.getCandidateList;
    return await fetcher([URL, { params: payload }])
}

export async function getCandidateApplications(payload) {
    const URL = endpoints.admin.getCandidateApplications;
    return await fetcher([URL, { params: payload }])
}

export async function getCandidateInfo(payload) {
    const URL = endpoints.admin.getCandidateInfo;
    return await fetcher([URL, { params: payload }])
}

export async function setApplicationStatus(payload) {
    const URL = endpoints.admin.setApplicationStatus;
    return await poster([URL, payload])
}

export async function setFeedbackDetails(payload) {
    const URL = endpoints.admin.setFeedbackDetails;
    return await poster([URL, payload])
}
export async function updateSharedApplicant(payload) {
    const URL = endpoints.admin.updateSharedApplicant;
    return await poster([URL, payload])
}

export async function deleteFeedback(payload) {
    const URL = endpoints.admin.deleteFeedback;
    return await poster([URL, payload])
}

export async function updateCandidateStatus(payload) {
    const URL = endpoints.admin.updateCandidateStatus;
    return await poster([URL, payload])
}
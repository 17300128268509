import {
  AppBar,
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Menu,
  Radio,
  RadioGroup,
  TextField,
  Toolbar,
  Paper,
  Stack,
  Fade,
  Chip,
  Card,
  CardContent,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  OutlinedInput,
  FormHelperText,
  Checkbox,
  ListItemText,
  InputAdornment,
  Tooltip,
  SvgIcon
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import * as React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  updateBasicProfile,
  updatePersonalDetails,
  uploadProfilePic,
  getBasicProfileDetails,
  setDefaultResume,
  deleteResume,
} from 'api/users';
import { endpoints } from 'utils/axios';
import { useAuth } from 'hooks/useAuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm, register, watch, Controller } from 'react-hook-form';
import FormProvider from 'hooks/form/form-provider';
import { useSnackbar } from 'components/snackbar';
import { CandidateBasicProfileRule } from 'models/CandidateProfileSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import TypeAhead from 'components/TypeAhead';
import { cleanData } from 'data/city.js';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  marginTop: '0.2rem!important',
  top: 5,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const PersonalDetails = forwardRef(({ profileData, setUpdating, hideBox, alignment, isResume, handleResumeProfile }, ref) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { user, updateContext } = useAuth();
  const [isFetched, setIsFetched] = useState(false);
  const [open, setOpen] = useState(false);
  const [itemId, setItemId] = useState('');
  const [checked, setChecked] = React.useState(false);

  const defaultValues = {
    city: '',
    date_of_birth: null,
    sex: '',
    disability: '',
    name: user?.name || '',
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(CandidateBasicProfileRule),
  });

  const {
    control,
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors, isDirty, isValid, dirtyFields, isSubmitting },
  } = methods;

  const gender = [{ label: 'Male' }, { label: 'Female' }, { label: 'Other' }];

  const disabilities = [
    { label: 'Blindness' }, { label: 'Low vision' },
    { label: 'Hearing impairment' },
    { label: 'Locomotor disability' },
    { label: 'Leprosy cured' },
    { label: 'Cerebral Palsy' },
    { label: 'Intellectual disability' },
    { label: 'Mental illness' },
    { label: 'Muscular dystrophy' },
    { label: 'Parkinson\'s disease' },
    { label: 'Acid attack victim' },
    { label: 'Sickle cell disease' },
    { label: 'Hemophilia' },
    { label: 'Thalassemia' },
    { label: 'Speech and language disability' },
    { label: 'Multiple sclerosis' },
    { label: 'Specific learning disabilities' },
    { label: 'Chronic neurological conditions' },
    { label: 'Autism spectrum disorder' },
    { label: 'Dwarfism' }];

  const [fieldName, setField] = useState('');
  const cityInputRef = useRef(null);

  useEffect(() => {
    const fetchData = () => {
      try {
        if (profileData) {
          const result = profileData;
          setValue('name', result?.full_name || user?.name || '');
          setValue('city', result?.address?.city || user?.city || '');
          setValue('date_of_birth', result?.date_of_birth || null);
          setValue('sex', result?.sex || null);
          setValue('disability', result?.disability || '');
          setChecked(result?.disability ? true : false);
        }
        setIsFetched(true);
      } catch (error) {
        console.error('Error fetching jobs:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (isResume && fieldName === 'city' && cityInputRef.current) {
      cityInputRef.current.focus();
    }
  }, [fieldName]);

  const handleChange = () => {
    setChecked((prev) => !prev);
    setValue('disability', '');
    handleSubmit(onSubmit)();
  };

  const handleDateChange = async (date) => {
    setValue('date_of_birth', date);
    trigger('date_of_birth');
    const isDataValid = await trigger('date_of_birth');
    if (!user?.is_profile_updated) {
      if (isDataValid) {
        UpdateSingleFieldSubmit({ date_of_birth: date });
      }
    } else {
      if (isDataValid) {
        handleSubmit(onSubmit)();
      }
    }
  };

  const validateAndSubmit = async (name) => {
    const isValid = await trigger(name);
    if (isValid) {
      handleSubmit(onSubmit)();
    }
  };

  const onSubmit = async (formData) => {
    try {
      setUpdating(true);
      if (isResume) {
        handleResumeProfile(formData);
        return;
      }
      const response = await updateBasicProfile(formData);
      if (response?.success === true && response.data) {
        updateContext({ name: response.data?.full_name });
        updateContext({ sex: response.data?.sex || null });
        updateContext({ date_of_birth: response.data?.date_of_birth || null });
        updateContext({ city: response.data?.address?.city || '' });
      } else if (response?.success === false) {
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    } catch (e) {
      setUpdating(false);
    }
  };

  const UpdateSingleFieldSubmit = async (data) => {
    try {
      setUpdating(true);
      if (isResume) {
        handleResumeProfile(data);
        return;
      }
      const response = await updatePersonalDetails(data);
      if (response?.success === true && response.data) {
        updateContext({ name: response.data?.full_name || '' });
        updateContext({ sex: response.data?.sex || null });
        updateContext({ date_of_birth: response.data?.date_of_birth || null });
        updateContext({ city: response.data?.address?.city || '' });
      } else if (response?.success === false) {
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    } catch (e) {
      setUpdating(false);
    }
  }

  useImperativeHandle(ref, () => ({
    triggerValidation: async () => {
      const result = await trigger();
      return result;
    },
  }));

  const handleEdit = (type) => {
    setField(type);
  };

  return (
    <FormProvider methods={methods}>
      {/* {!hideBox && (
        <Box mb={3}>
          <Typography className="basic-profile-main-heading" variant="h6" gutterBottom>
            Create Your Profile
          </Typography>
          <Typography className="step-info" variant="body2" gutterBottom>
            These details would help us find most suitable jobs for you
          </Typography>
        </Box>)} */}
      <Box mb={3}>
        <Typography className="step-heading" variant="h6" gutterBottom>
          Personal Details
        </Typography>
        {/* <Typography className="step-info" variant="body2" gutterBottom>
          These details would help recruiters understand & connect with you.
        </Typography> */}
      </Box>
      <Box>
        <Grid container spacing={2} mb={3}>
          <Grid item xs={12} md={6}>
            {/* name */}
            {(isResume && fieldName !== 'name') ?
              <FormControl size="small" fullWidth sx={{ marginTop: '8px' }}>
                <InputLabel htmlFor="outlined-adornment-password">Name</InputLabel>
                <Controller
                  control={control}
                  name="name"
                  render={({ field }) => (
                    <OutlinedInput
                      {...field}
                      {...register('name')}
                      id="outlined-adornment-password"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                            onClick={() => handleEdit('name')}
                          >
                            <SvgIcon sx={{ width: '16px', height: '16px' }}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M12.2907 16.0003H2.10143C0.944657 16.0003 0.00354004 15.0593 0.00354004 13.9024V3.71313C0.00354004 2.55635 0.944542 1.61523 2.10143 1.61523H6.77058C7.10174 1.61523 7.36995 1.88345 7.36995 2.2146C7.36995 2.54576 7.10174 2.81398 6.77058 2.81398H2.10143C1.60546 2.81398 1.20238 3.21706 1.20238 3.71303V13.9023C1.20238 14.3983 1.60546 14.8014 2.10143 14.8014H12.2907C12.7867 14.8014 13.1898 14.3983 13.1898 13.9023V9.23319C13.1898 8.90203 13.458 8.63381 13.7892 8.63381C14.1203 8.63381 14.3885 8.90203 14.3885 9.23319V13.9023C14.3885 15.0591 13.4476 16.0003 12.2907 16.0003Z" fill="#6B7280" />
                                <path d="M4.44409 9.00838L11.0013 2.45117L13.5478 4.99768L6.9906 11.5549L4.44409 9.00838Z" fill="#6B7280" />
                                <path d="M15.7117 1.43961L14.5609 0.28882C14.1758 -0.0962733 13.551 -0.0962733 13.1651 0.28882L11.8495 1.60444L14.396 4.15095L15.7116 2.83533C16.0967 2.45024 16.0968 1.82545 15.7117 1.43961Z" fill="#6B7280" />
                                <path d="M3.6757 9.9353L3.08308 12.523C3.02914 12.7598 3.24042 12.9711 3.47717 12.9171L6.06492 12.3245L3.6757 9.9353Z" fill="#6B7280" />
                              </svg>
                            </SvgIcon>
                          </IconButton>
                        </InputAdornment>
                      }
                      inputProps={{
                        readOnly: true,
                        style: { fontSize: '16px' },
                      }}
                      disabled
                      label="Name"
                    />
                  )}
                />
              </FormControl> :
              <FormControl fullWidth size="small" sx={{ marginTop: '8px' }}>
                <Controller
                  control={control}
                  name="name"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      {...register('name')}
                      label="Name"
                      autoFocus={isResume ? true : false}
                      size="small"
                      variant="outlined"
                      error={!!errors.name}
                      helperText={errors.name ? errors.name.message : ''}
                      onChange={(e) => {
                        setValue('name', e.target.value);
                        trigger('name');
                        if (!user?.is_profile_updated) {
                          UpdateSingleFieldSubmit({ name: e.target.value });
                        } else {
                          validateAndSubmit('name');
                        }
                      }}
                      onBlur={() => {
                        setField('');
                      }}
                    // InputProps={{
                    //   endAdornment: (
                    //     isResume &&
                    //     <InputAdornment position="end">
                    //       <EditIcon />
                    //     </InputAdornment>
                    //   ),
                    // }}
                    />
                  )}
                />
              </FormControl>}
          </Grid>

          <Grid item xs={12} md={6}>
            {/* gender */}
            {(isResume && fieldName !== 'sex') ?
              <FormControl size="small" fullWidth sx={{ marginTop: '8px' }}>
                <InputLabel htmlFor="outlined-adornment-password">Gender</InputLabel>
                <Controller
                  control={control}
                  name="sex"
                  render={({ field }) => (
                    <OutlinedInput
                      {...field}
                      {...register('sex')}
                      id="outlined-adornment-password"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                            onClick={() => handleEdit('sex')}
                          >
                            <SvgIcon sx={{ width: '16px', height: '16px' }}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M12.2907 16.0003H2.10143C0.944657 16.0003 0.00354004 15.0593 0.00354004 13.9024V3.71313C0.00354004 2.55635 0.944542 1.61523 2.10143 1.61523H6.77058C7.10174 1.61523 7.36995 1.88345 7.36995 2.2146C7.36995 2.54576 7.10174 2.81398 6.77058 2.81398H2.10143C1.60546 2.81398 1.20238 3.21706 1.20238 3.71303V13.9023C1.20238 14.3983 1.60546 14.8014 2.10143 14.8014H12.2907C12.7867 14.8014 13.1898 14.3983 13.1898 13.9023V9.23319C13.1898 8.90203 13.458 8.63381 13.7892 8.63381C14.1203 8.63381 14.3885 8.90203 14.3885 9.23319V13.9023C14.3885 15.0591 13.4476 16.0003 12.2907 16.0003Z" fill="#6B7280" />
                                <path d="M4.44409 9.00838L11.0013 2.45117L13.5478 4.99768L6.9906 11.5549L4.44409 9.00838Z" fill="#6B7280" />
                                <path d="M15.7117 1.43961L14.5609 0.28882C14.1758 -0.0962733 13.551 -0.0962733 13.1651 0.28882L11.8495 1.60444L14.396 4.15095L15.7116 2.83533C16.0967 2.45024 16.0968 1.82545 15.7117 1.43961Z" fill="#6B7280" />
                                <path d="M3.6757 9.9353L3.08308 12.523C3.02914 12.7598 3.24042 12.9711 3.47717 12.9171L6.06492 12.3245L3.6757 9.9353Z" fill="#6B7280" />
                              </svg>
                            </SvgIcon>
                          </IconButton>
                        </InputAdornment>
                      }
                      inputProps={{
                        readOnly: true,
                        style: { fontSize: '16px' },
                      }}
                      disabled
                      label="Gender"
                    />
                  )}
                />
              </FormControl> :
              <FormControl fullWidth size="small" sx={{ marginTop: '8px' }}>
                <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                <Controller
                  control={control}
                  name="sex"
                  render={({ field }) => (
                    <Select
                      {...field}
                      {...register('sex')}
                      value={field?.value || ''}
                      labelId="demo-simple-select-label"
                      id="sex"
                      autoFocus={isResume ? true : false}
                      label="Gender"
                      error={!!errors.sex}
                      onBlur={() => {
                        field.onBlur();
                        setField('');
                        if (!field.value) {
                          trigger('sex');
                        }
                      }}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        trigger('sex');
                        if (!user?.is_profile_updated) {
                          UpdateSingleFieldSubmit({ sex: e.target.value });
                        } else {
                          handleSubmit(onSubmit)();
                        }
                      }}
                    // IconComponent={() => isResume && <EditIcon margin={true} />}
                    >
                      {gender.map((item) => (
                        <MenuItem key={item.label} value={item.label}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                {errors.sex && <FormHelperText error>{errors.sex?.message}</FormHelperText>}
              </FormControl>}
          </Grid>

          <Grid item xs={12} md={6}>
            {/* date of birth */}
            {(isResume && fieldName !== 'date_of_birth') ?
              <FormControl size="small" fullWidth sx={{ marginTop: '8px' }}>
                <InputLabel htmlFor="outlined-adornment-password">Date of Birth</InputLabel>
                <Controller
                  control={control}
                  name="date_of_birth"
                  defaultValue={defaultValues?.date_of_birth ? dayjs(defaultValues.date_of_birth).toISOString() : ""}
                  render={({ field }) => (
                    <OutlinedInput
                      {...field}
                      value={field.value ? dayjs(field.value).format("DD/MM/YYYY") : ""}
                      id="outlined-adornment-password"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                            onClick={() => handleEdit('date_of_birth')}
                          >
                            <SvgIcon sx={{ width: '16px', height: '16px' }}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M12.2907 16.0003H2.10143C0.944657 16.0003 0.00354004 15.0593 0.00354004 13.9024V3.71313C0.00354004 2.55635 0.944542 1.61523 2.10143 1.61523H6.77058C7.10174 1.61523 7.36995 1.88345 7.36995 2.2146C7.36995 2.54576 7.10174 2.81398 6.77058 2.81398H2.10143C1.60546 2.81398 1.20238 3.21706 1.20238 3.71303V13.9023C1.20238 14.3983 1.60546 14.8014 2.10143 14.8014H12.2907C12.7867 14.8014 13.1898 14.3983 13.1898 13.9023V9.23319C13.1898 8.90203 13.458 8.63381 13.7892 8.63381C14.1203 8.63381 14.3885 8.90203 14.3885 9.23319V13.9023C14.3885 15.0591 13.4476 16.0003 12.2907 16.0003Z" fill="#6B7280" />
                                <path d="M4.44409 9.00838L11.0013 2.45117L13.5478 4.99768L6.9906 11.5549L4.44409 9.00838Z" fill="#6B7280" />
                                <path d="M15.7117 1.43961L14.5609 0.28882C14.1758 -0.0962733 13.551 -0.0962733 13.1651 0.28882L11.8495 1.60444L14.396 4.15095L15.7116 2.83533C16.0967 2.45024 16.0968 1.82545 15.7117 1.43961Z" fill="#6B7280" />
                                <path d="M3.6757 9.9353L3.08308 12.523C3.02914 12.7598 3.24042 12.9711 3.47717 12.9171L6.06492 12.3245L3.6757 9.9353Z" fill="#6B7280" />
                              </svg>
                            </SvgIcon>
                          </IconButton>
                        </InputAdornment>
                      }
                      inputProps={{
                        readOnly: true,
                        style: { fontSize: '16px' },
                      }}
                      disabled
                      label="Date of Birth"
                      placeholder="DD/MM/YYYY"
                    />
                  )}
                />
              </FormControl> :
              <FormControl fullWidth size="small" sx={{ marginTop: '8px' }}>
                <Controller
                  control={control}
                  name="date_of_birth"
                  defaultValue={defaultValues?.date_of_birth ? dayjs(defaultValues?.date_of_birth) : null}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      views={['year', 'month', 'day']}
                      value={field?.value ? dayjs(field?.value) : null}
                      format="DD/MM/YYYY"
                      // inputFormat="DD MM, YYYY"
                      label="Date of Birth"
                      // placeholder="DD MMM, YYYY"
                      disableFuture
                      autoFocus={isResume ? true : false}
                      tabIndex={-1}
                      closeOnSelect={true}
                      slotProps={{
                        textField: {
                          size: 'small',
                          error: !!errors?.date_of_birth,
                          helperText: errors?.date_of_birth?.message,
                          autoFocus: isResume ? true : false,
                          // InputProps: {
                          //   readOnly: true,
                          //   onClick: (event) => event.target.blur(),
                          // },
                          // inputProps: {
                          // tabIndex: -1,
                          // placeholder: 'DD MMM, YYYY'
                          // },
                          // InputProps: {
                          //   endAdornment: (isResume &&
                          //     <InputAdornment position="end">
                          //       <EditIcon />
                          //     </InputAdornment>
                          //   ),
                          // },
                          onBlur: () => {
                            if (!field.value) {
                              trigger('date_of_birth');
                            }
                          }
                        },
                      }}
                      sx={{ width: '100%' }}
                      onChange={handleDateChange}
                      onClose={() => {
                        if (!field.value) {
                          trigger('date_of_birth');
                        }
                        setField('');
                      }}
                    />
                  )}
                />
              </FormControl>}
          </Grid>

          <Grid item xs={12} md={6}>
            {/* city */}
            {(isResume && fieldName !== 'city') ?
              <FormControl size="small" fullWidth sx={{ marginTop: '8px' }}>
                <InputLabel htmlFor="outlined-adornment-password">Current Location</InputLabel>
                <Controller
                  control={control}
                  name="city"
                  defaultValue={defaultValues?.city || ''}
                  render={({ field }) => (
                    <OutlinedInput
                      {...field}
                      {...register('city')}
                      id="outlined-adornment-password"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                            onClick={() => handleEdit('city')}
                          >
                            <SvgIcon sx={{ width: '16px', height: '16px' }}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M12.2907 16.0003H2.10143C0.944657 16.0003 0.00354004 15.0593 0.00354004 13.9024V3.71313C0.00354004 2.55635 0.944542 1.61523 2.10143 1.61523H6.77058C7.10174 1.61523 7.36995 1.88345 7.36995 2.2146C7.36995 2.54576 7.10174 2.81398 6.77058 2.81398H2.10143C1.60546 2.81398 1.20238 3.21706 1.20238 3.71303V13.9023C1.20238 14.3983 1.60546 14.8014 2.10143 14.8014H12.2907C12.7867 14.8014 13.1898 14.3983 13.1898 13.9023V9.23319C13.1898 8.90203 13.458 8.63381 13.7892 8.63381C14.1203 8.63381 14.3885 8.90203 14.3885 9.23319V13.9023C14.3885 15.0591 13.4476 16.0003 12.2907 16.0003Z" fill="#6B7280" />
                                <path d="M4.44409 9.00838L11.0013 2.45117L13.5478 4.99768L6.9906 11.5549L4.44409 9.00838Z" fill="#6B7280" />
                                <path d="M15.7117 1.43961L14.5609 0.28882C14.1758 -0.0962733 13.551 -0.0962733 13.1651 0.28882L11.8495 1.60444L14.396 4.15095L15.7116 2.83533C16.0967 2.45024 16.0968 1.82545 15.7117 1.43961Z" fill="#6B7280" />
                                <path d="M3.6757 9.9353L3.08308 12.523C3.02914 12.7598 3.24042 12.9711 3.47717 12.9171L6.06492 12.3245L3.6757 9.9353Z" fill="#6B7280" />
                              </svg>
                            </SvgIcon>
                          </IconButton>
                        </InputAdornment>
                      }
                      inputProps={{
                        readOnly: true,
                        style: { fontSize: '16px' },
                      }}
                      disabled
                      label="Current Location"
                    />
                  )}
                />
              </FormControl> :
              <FormControl fullWidth size="small" sx={{ marginTop: '8px' }}>
                <Controller
                  control={control}
                  name="city"
                  defaultValue={defaultValues?.city || ''}
                  render={({ field }) => (
                    <TypeAhead
                      {...field}
                      {...register('city')}
                      api={`${process.env.REACT_APP_END_POINT}${endpoints?.services?.location}`}
                      label="Current Location"
                      ref={cityInputRef}
                      onBlur={() => {
                        setField('');
                        field.onBlur();
                        if (!field.value) {
                          trigger('city');
                        }
                      }}
                      onInputChange={(e, value) => {
                        setValue("city", value, { shouldDirty: true });
                      }}
                      onChange={(e, value) => {
                        field.onChange(value);
                        if (!user?.is_profile_updated && value) {
                          UpdateSingleFieldSubmit({ city: value });
                        } else {
                          handleSubmit(onSubmit)();
                        }
                      }}
                    // clearIcon={isResume && <EditIcon fontSize="small" />}
                    />
                  )}
                />
              </FormControl>}
          </Grid>
        </Grid>
      </Box>
      {/* {alignment !== 'using-existing-resume' && <Box>
        <Card className="disability-card " variant="outlined" sx={{ boxShadow: "none" }}>
          <CardContent sx={{ padding: '0px!important' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <Stack direction="row" spacing={1.5} alignItems="start">
                  <AntSwitch inputProps={{ 'aria-label': 'ant design' }} checked={checked} onChange={handleChange} />
                  <Box sx={{ width: '100%' }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                      <Box>
                        <Typography className="heading" variant="p" sx={{ display: 'inline-flex' }}>
                          Disability Declaration
                        </Typography>
                        <Typography className="info" variant="body2" gutterBottom>
                          Select this if you have UDID Card
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>
                </Stack>
              </Grid>
              {checked &&
                <Grid item xs={12} sm={6} md={6}>
                  <Fade in={checked}>
                    <Box>
                      <FormControl size="small" fullWidth>
                        <Controller
                          control={control}
                          name="disability"
                          render={({ field }) => (
                            <Autocomplete
                              size="small"
                              {...field}
                              {...register('disability')}
                              id="disability"
                              name="disability"
                              options={disabilities?.map((item) => item?.label)}
                              renderInput={(params) => <TextField {...params} label="Disability type" />}
                              onChange={(e, value) => {
                                field.onChange(value);
                                handleSubmit(onSubmit)();
                              }}
                              inputValue={field.value || ''}
                              onInputChange={(e, value) => field.onChange(value)}
                            />
                          )}
                        />
                      </FormControl>
                    </Box>
                  </Fade>
                </Grid>
              }
            </Grid>
          </CardContent>
        </Card>
      </Box>} */}
    </FormProvider>
  );
});

export default PersonalDetails;

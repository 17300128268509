import React, { useState } from 'react';
import { Box, Button, Card, CardContent, Link, IconButton, InputLabel, OutlinedInput, Stack, SvgIcon, Typography, TextField, FormControl, InputAdornment, Divider } from '@mui/material'
import { MuiOtpInput } from 'mui-one-time-password-input'


const RemoveCertificate = ({ onClose, onConfirm, type, degree }) => {
    const [isRemoving, setIsRemoving] = useState(false);

    const handleRemove = async() => {
        setIsRemoving(true);
        await onConfirm();
        onClose();
    };

    return (
        <Card className="otp-card-wrapper" variant="outlined">
            <CardContent>
                <IconButton className="close-dialog-btn" aria-label="delete" color="primary"  sx ={{display : {xs :"none" , sm :"none"}}}  onClick={onClose}>
                    <SvgIcon sx={{ width: '20px', height: '20px' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <g clipPath="url(#clip0_456_20993)">
                                <path d="M7 7L17 17M17 7L7 17L17 7Z" stroke="#111827" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </g>
                            <defs>
                                <clipPath id="clip0_456_20993">
                                    <rect width="24" height="24" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </SvgIcon>
                </IconButton>



                <Box mb={3} sx={{ textAlign: 'center' }}>
                    <Typography mb={1} className="title" component="h6">
                        Overlapping Confirmation
                    </Typography>
                    <Typography sx={{textAlign: 'center!important'}} className="otp-sent-txt" component="p">
                    This experience or education overlaps with other experiences/ education.  Do you want to go ahead?
                    </Typography>
                </Box>

                <Stack direction="row" alignItems={'center'} spacing={3}>
                    <Button fullWidth className="otp-btn" variant="outlined" size="medium" onClick={onClose} tabIndex={-1}>
                        Cancel
                    </Button>
                    <Button fullWidth className="otp-btn" variant="contained" size="medium" onClick={handleRemove} disabled={isRemoving}>
                        {isRemoving ? 'In-progress...' : 'Confirm'}
                    </Button>
                </Stack>
            </CardContent>
        </Card>
    )
}

export default RemoveCertificate

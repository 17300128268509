import { Box, Button, Stack, SvgIcon, Container } from '@mui/material'
import { useAuth } from 'hooks/useAuthContext';

const Header = () => {
  const Logo = `${process.env.REACT_APP_HOMEPAGE}assets/TJ-Logo-Black 1.svg`;
  const { logout } = useAuth();
  const logoutIcon = (
    <SvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M16 8C16 8.21236 15.9029 8.41406 15.734 8.5522L13.0673 10.734C12.9237 10.8516 12.7474 10.9091 12.5718 10.9091C12.3571 10.9091 12.1436 10.8228 11.9929 10.6552C11.7191 10.3501 11.756 9.89098 12.0755 9.62962L13.1784 8.72727H6.85714C6.43638 8.72727 6.09524 8.40163 6.09524 8C6.09524 7.59837 6.43638 7.27273 6.85714 7.27273H13.1784L12.0755 6.37038C11.756 6.10902 11.7191 5.64986 11.9929 5.34482C12.2671 5.04013 12.7485 5.00497 13.0673 5.26598L15.734 7.4478C15.9029 7.58594 16 7.78764 16 8ZM9.14286 13.0909C9.14286 13.8931 8.45945 14.5455 7.61905 14.5455H3.04762C2.2074 14.5455 1.52381 13.8931 1.52381 13.0909V2.90909C1.52381 2.10689 2.2074 1.45455 3.04762 1.45455H7.61905C8.45945 1.45455 9.14286 2.10689 9.14286 2.90909V6.18182H10.6667V2.90909C10.6667 1.30504 9.29948 0 7.61905 0H3.04762C1.36719 0 0 1.30504 0 2.90909V13.0909C0 14.695 1.36719 16 3.04762 16H7.61905C9.29948 16 10.6667 14.695 10.6667 13.0909V9.81818H9.14286V13.0909Z" fill="white" />
      </svg>
    </SvgIcon>
  );
  const handleLogOut = async (event) => {
    logout();
  }

  return (
    <Box className="header">
      <Container maxWidth="xl">
        <Box className="header-top">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{ width: '100%' }}
          >
            <Box
              component="img"
              alt="Talent Junction"
              src={Logo}
            />
            <Button className="logout-btn" variant="outlined" onClick={handleLogOut} startIcon={logoutIcon}>
              LogOut
            </Button>
          </Stack>
        </Box>
        {/* <Box className="header-bottom-space"></Box> */}
      </Container>
    </Box>
  )
}

export default Header


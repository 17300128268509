import React from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const LeaveWithoutUpdatingDialog = ({ onClose, isDuplicating, handleProcessClicked, resumeName, handleClicked }) => {
    const navigate = useNavigate();
    const { data, totalResume, tab, jobData, createNewResume } = location.state || { data: null, totalResume: 0, tab: '4', jobData: null, createNewResume: false };
    const [open, setOpen] = useState(true)
    const [loading, setLoading] = useState(false);

    const handleCancel = () => {
        onClose();
    };

    const handleProceed = async () => {
        setLoading(true);
        if (isDuplicating) {
            handleProcessClicked();
        } else {
            handleClicked();
        }
    };
    return (
        <Box className="ext-job-dialog-wraper remove-job-dialog" sx={{ padding: '24px 20px' }}>
            <Stack justifyContent={'center'}>
                {isDuplicating ? (
                    <Box sx={{ textAlign: 'center' }} mb={4}>
                        <Typography className="sub-title" mb={0.5}>A new resume will be created with data defaulted from  "{resumeName}".  You may customise it for a specific purpose"</Typography>
                    </Box>
                ) : (
                    <Box sx={{ textAlign: 'center' }} mb={4}>
                        <Typography className="sub-title" mb={0.5}>A new resume will be created with data defaulted from " Master Resume ".  You may customise it for a specific purpose"</Typography>
                    </Box>
                )}
                <Stack direction={{ xs: 'column', md: 'row' }} alignItems={'center'} spacing={2.5}>
                    <Button fullWidth variant="outlined" size="medium" disableRipple disableElevation onClick={handleCancel}>Cancel</Button>
                    <Button className="continue-btn" fullWidth variant="contained" size="medium" disableRipple disableElevation onClick={handleProceed} disabled={loading}>{loading ? 'proceeding...' : ' proceed'}</Button>
                </Stack>
            </Stack>
        </Box>
    )
}

export default LeaveWithoutUpdatingDialog

import { Avatar, Box, Card, Link, CardContent, Chip, Container, Grid, Stack, Tab, Typography, ToggleButton, ToggleButtonGroup, Button, SvgIcon, Divider, IconButton, Menu, MenuItem, ListItemIcon, FormControl, InputLabel, Select, List, ListItem, Checkbox, Dialog, TextField, Drawer, Rating } from '@mui/material'
import React, { useState, useRef, useEffect, } from 'react'
import AdminSearchFilter from './AdminSearchFilter'
import { TabContext, TabList, TabPanel } from '@mui/lab';
import CandidateProfileDrawer from './CandidateProfileDrawer';
import { styled } from '@mui/material/styles';
import StarIcon from '@mui/icons-material/Star';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { useSnackbar } from 'components/snackbar';
import { getTimeDifferenceString, changeNameFormat, formatNumber, toTitleCase } from 'utils/Utils';
import { getAllJobList, deleteJob, createDuplicateJob, jobUpdated } from 'api/job';
import FullDetails from './FullDetails';
import { SplashScreen } from 'components/loading-screen';
import { useNavigate, useLocation } from 'react-router-dom';

const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: 6,
    width: 20,
    height: 20,
    boxShadow: 'inset 0 0 0 1.5px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
        ...theme.applyStyles('dark', {
            backgroundColor: '#30404d',
        }),
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
        ...theme.applyStyles('dark', {
            background: 'rgba(57,75,89,.5)',
        }),
    },
    ...theme.applyStyles('dark', {
        boxShadow: '0 0 0 1px rgb(16 22 26 / 40%)',
        backgroundColor: '#394b59',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))',
    }),
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&::before': {
        display: 'block',
        width: 20,
        height: 20,
        backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: '#106ba3',
    },
});

// Inspired by blueprintjs
function BpCheckbox(props) {
    return (
        <Checkbox
            sx={{ '&:hover': { bgcolor: 'transparent' } }}
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            inputProps={{ 'aria-label': 'Checkbox demo' }}
            {...props}
        />
    );
}

const ActiveJobs = ({ job }) => {
    // resume action menu
    const navigate = useNavigate();
    const [resumeActionAnchorEl, setResumeActionAnchorEl] = React.useState(null);
    const openActionMenu = Boolean(resumeActionAnchorEl);
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const { enqueueSnackbar } = useSnackbar();
    const [filterApplied, setFilterApplied] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [sortCriteria, setSortCriteria] = useState({});
    const [selectStatus, setSelectStatus] = React.useState('');
    const [showSearch, setShowSearch] = useState(false);
    const containerRef = useRef(null);
    const inputRef = useRef(null);
    const [searchValue, setSearchValue] = useState('');
    const [filterLoading, setFilterLoading] = useState(false);
    const [selectedJob, setSelectedJob] = useState(null);
    const [jobDetails, setJobDetails] = useState({});
    const location = useLocation();
    const id = location.state;
    const [activeId, setActiveId] = useState(id || null);
    const jobRefs = useRef([]); 
    const fetchJobs = async (search = {}) => {
        try {
            setLoading(true);
            const response = await getAllJobList({ search });
            if (response?.success && response.data) {
                setJobs(response.data || []);
                setJobDetails(response.data[0]);
                if (activeId) {
                    const currentJob = response.data.find(j => j._id === activeId);
                    if (currentJob) {
                        setJobDetails(currentJob);
                        setSelectedJob(currentJob);
                    }
                }
            } else {
                enqueueSnackbar('Failed to fetch jobs', { variant: 'error' });
                console.error('Failed to fetch jobs:', response?.message);
            }
        } catch (error) {
            console.error('Error fetching jobs:', error);
            enqueueSnackbar('Error fetching jobs', { variant: 'error' });
        } finally {
            setLoading(false);
            setFilterLoading(false);
        }
    };

    useEffect(() => {
        fetchJobs();
    }, []);
    const openResumeActionMenu = (event) => {
        setResumeActionAnchorEl(event.currentTarget);
    };
    const closeResumeActionMenu = () => {
        setResumeActionAnchorEl(null);
    };
    const handleCardClick = (job, event) => {
        event.stopPropagation();
        setJobDetails(job);
        setSelectedJob(job);
        setActiveId(job._id);

    };

    useEffect(() => {
        if (jobs.length > 0) {
            setSelectedJob(jobs[0]);
        }
    }, [jobs]);

    const defaultValues = {
        jobId: "",
        jobName: "",
        skills: [],
        experience: "",
        salary: "",
        education: [],
        location: [],
        companyName: "",
        function: [],
        role: [],
    };
    const methods = useForm({
        defaultValues,
        mode: "onChange",
    });
    useEffect(() => {
        if (activeId && jobRefs.current[activeId]) {
            jobRefs.current[activeId].scrollIntoView({ behavior: "smooth", block: "center" });
        }
    }, [activeId,jobs]);

    // setActiveId(id);
    // tabs 




    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleOpenEditJobDetailsDialog = () => {
        setOpenEditJobDetails(true);
    };

    const handleCloseEditJobDetailsDialog = () => {
        setOpenEditJobDetails(false);
    };
    const [openEditJobDetails, setOpenEditJobDetails] = React.useState(false);

    // select status
    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const selectStatusChange = (event) => {
        setSelectStatus(event.target.value);
    };

    // checkbox label
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

    // edit whatsapp dialog

    const [openRejectionReasonDialog, setOpenRejectionReasonDialog] = React.useState(false);

    const handleOpenRejectionReasonDialog = () => {
        setOpenRejectionReasonDialog(true);
    };

    const handleCloseRejectionReasonDialog = () => {
        setOpenRejectionReasonDialog(false);
    };
    const jobsWithUnrated = jobs.filter(job => job.unrated_applicants > 0);


    // Candiate profile drawer
    const handleShowSearch = () => {
        setShowSearch(true);
        setShowFilter(false);
        setTimeout(() => {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }, 300);
    };
    // Candiate profile drawer
    

    const [openCandiateProfileDrawer, setOpenCandiateProfileDrawer] = React.useState(false);

    const toggleCandiateProfileDrawer = (newOpenSkills) => () => {
        setOpenCandiateProfileDrawer(newOpenSkills);
    };

    // Ratings
    const [ratingsValue, setRatingsValue] = React.useState(4);

    if (loading) {
        return (
            <Grid
                container
                justifyContent="center"
                sx={{ position: 'relative', overflow: 'hidden', width: '100%', height: 'calc(100vh - 110px)', }}
                alignItems="center"
            >
                <SplashScreen />
            </Grid>
        );
    }

    return (
        <Box sx={{
            position: 'relative',
            overflow: 'hidden',
            width: '100%',
            height: { xs: 'calc(100vh - 70px)', sm: 'calc(100vh - 70px)', md: 'calc(100vh - 110px)', lg: 'calc(100vh - 110px)' },
        }}>
            <Grid container columnSpacing={2} sx={{ height: '100%', marginLeft: '0px' }}>
                <Grid item xs={4}>
                    <Box className="active-jobs-list">
                        {/* Filter / Search Component */}
                        <Stack direction="row" alignItems={'center'}>
                            <AdminSearchFilter fetchJobs={fetchJobs} loading={loading} setFilterLoading={setFilterLoading} totalJobs={jobs} />
                            <Button variant="contained" className="post-new-job-btn" size="small" onClick={() => navigate('/job-form')} disableElevation disableRipple>Post New Job</Button>
                        </Stack>

                        <Box className="active-jobs-container" sx={{ paddingBottom: '6rem!important' }}>
                            {jobs.map((job, index) => (
                                <Card key={job.id || index} ref={(el) => jobRefs.current[job._id] = el} onClick={(e) => handleCardClick(job, e)} className={`job-card-view admin-jobs-card ${activeId && job._id === activeId ? "first-job-item" : !activeId && index === 0 ? "first-job-item" : ""}`} variant="outlined" sx={{ marginBottom: '1rem', cursor: "pointer", }}>
                                    <CardContent sx={{ paddingBottom: '0.7rem!important' }} >
                                        {!job.is_external && (
                                            <Box className="tj-jobs">
                                                <Stack direction="row" alignItems={'center'} spacing={0.5}>
                                                    <Box className="tj-jobs-logo">
                                                        <Box
                                                            component="img"
                                                            alt="Talent Junction"
                                                            src={`${process.env.PUBLIC_URL}/assets/tj-job-logo.png`}
                                                        />
                                                    </Box>
                                                    <Typography className="tj-jobs-txt">TJ Job</Typography>
                                                </Stack>
                                            </Box>
                                        )}
                                        <Box className="active-jobs-info">
                                            <Grid container spacing={2}>
                                                <Grid item xs={8}>
                                                    <Typography className="post-title">{job.title}</Typography>
                                                    <Typography className="compnay-name">{job.company}</Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Box className="ctc-range">
                                                        <Typography className="text-1">{job.unrated_applicants ?? 0}/{job.total_applicants ?? 0}</Typography>
                                                        <Typography className="text-2">Unrated</Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>

                                            <Box mt={0.5} className="work-type">
                                                <Stack direction="row" flexWrap="wrap" alignItems={'center'} spacing={0.5}>
                                                    <Typography variant="p">{job?.location}</Typography>
                                                    <Typography variant="body1">•</Typography>
                                                    <Typography variant="p">{job?.job_type}</Typography>
                                                    <Typography variant="body1">•</Typography>
                                                    <Typography variant="p">{job?.work_mode}</Typography>
                                                </Stack>
                                            </Box>

                                            <Typography variant="p" className="posted-date">{job?.updatedAt ? getTimeDifferenceString(job?.updatedAt, "Status updated") : 'NA'}</Typography>

                                        </Box>
                                    </CardContent>
                                </Card>
                            ))}
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={8}>
                    {jobs?.length > 0 && jobDetails &&
                        <FullDetails jobDetails={jobDetails} fetchJobs={fetchJobs} />
                    }
                </Grid>
            </Grid>

            {/* Candiate profile drawer */}
            <Drawer open={openCandiateProfileDrawer} anchor={'right'} onClose={toggleCandiateProfileDrawer(false)}>
                <CandidateProfileDrawer />  
            </Drawer>

            {/* Linked in dialog */}
            <Dialog
                open={openRejectionReasonDialog}
                onClose={handleCloseRejectionReasonDialog}
                className="action-dialog-card reject-candi-dialog"
            >
                <Card variant="outlined">
                    <CardContent>
                        <Box >
                            <Stack spacing={0.5} mb={4}>
                                <Typography className="card-title" component="p">Reason for rejecting candidate</Typography>
                                <Typography className="card-info-text" component="p">Please provide the reasons for rejecting this candidate. It would be visible to candidate once the job is closed.</Typography>
                            </Stack>
                            <Box mb={4}>
                                <TextField
                                    fullWidth
                                    id="outlined-multiline-static"
                                    label="Reason (Max. 25 words)"
                                    multiline
                                    rows={4}
                                    placeholder="Reason given by the user in less than 25 words..."
                                    sx={{ backgroundColor: '#F9FAFF' }}
                                />
                            </Box>
                            <Stack direction="row" spacing={2}>
                                <Button size="medium">Cancel</Button>
                                <Button variant="contained" size="medium" sx={{ backgroundColor: '#1F9CD7!important', padding: '12px 40px!important' }}>Done</Button>
                            </Stack>
                        </Box>
                    </CardContent>
                </Card>
            </Dialog>
        </Box>
    )
}

export default ActiveJobs

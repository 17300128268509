import React from 'react'
import { Box, Stack, IconButton, InputAdornment, Button, Divider, FormHelperText, TextField, Grid, Snackbar, Typography, Card, Container, FormControl, OutlinedInput, SvgIcon, Icon, InputLabel, FormControlLabel, Checkbox, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Link } from '@mui/material';
import SlickSlider from 'react-slick';
import { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, register, Controller } from 'react-hook-form';
// components
import { useSnackbar } from 'components/snackbar'
// Validation
import { yupResolver } from '@hookform/resolvers/yup';
import { RegistrationSchema, OTPSchema } from '../../models/RegistrationSchema';
import { createUser, verify, removeUser } from 'api/profile';
import RegisterSection from './RegisterSection';

const Register = () => {
    const [open, setOpen] = useState(false);
    const [showOTP, setShowOTP] = useState(false)
    const [userId, setUserId] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const [email, setEmail] = useState('');

    const navigate = useNavigate();
    const { id } = useParams();
    const [showPassword, setShowPassword] = useState(false)

    const defaultValues = {
        full_name: '',
        otp: '',
        email: '',
        phone_number: '',
        password: '',
        re_password: '',
    };

    const methods = useForm({
        defaultValues,
        mode: "onChange",
        resolver: yupResolver(RegistrationSchema),
    });

    const {
        control,
        register,
        handleSubmit,
        trigger,
        setValue,
        watch,
        formState: { errors, isDirty, isValid, dirtyFields, isSubmitting },
    } = methods;

    const onSubmit = handleSubmit(async (formData) => {
        const response = await createUser(formData);
        if (response?.success === true && response.data?.id) {
            setUserId(response.data.id);
            setEmail(response.data.email);
            enqueueSnackbar(`OTP has been sent.`, { variant: 'success' })
            setShowOTP(true)
        } else {
            enqueueSnackbar(response.message, { variant: 'error' })
        }
    })

    const handleOpenOtpDialog = () => {
        setOpen(true);
    };

    const handleClose = async () => {
        const formData = methods.getValues();
        const response = await removeUser(formData);
        if (response?.success === true) {
            setUserId(null);
            setOpen(false);
            setShowOTP(false);
        } else {
            enqueueSnackbar(response.message, { variant: 'error' })
        }
    };

    const [showFlag, setShowFlag] = React.useState(false);

    const handleFlag = () => {
        setShowFlag(true);
    }

    const handleOnBlurFlag = () => {
        if (!watch('phone_number')) {
            setShowFlag(false);
        }
    }

    const handleChange = (e) => {
        if (e.target.value.length > 0) {
            setShowFlag(true);
        } else {
            setShowFlag(true);
        }
    };
    var settings = {
        autoplay: true, 
        fade: true,
        autoplaySpeed: 3000,
        speed: 1000,
        prevArrow: false,
        nextArrow: false
      };
    const [showTerm, setShowTerm] = useState(false);

    const handleOpenTermsDialog = () => {
        setShowTerm(true);
    }
    const handleCloseTermsDialog = () => {
        setShowTerm(false);
    }

    return (
        <Box sx={{ overflow: 'hidden' }}>
            <Grid container justifyContent={'center'} alignItems={'center'}>
                <Grid item xs={12} md={6} lg={6}>
                    <RegisterSection />
                </Grid>
                <Grid item xs={12} md={6} lg={6} sx={{ display: { xs: 'none', md: 'block' } }}>
                <div className='banner-image'>
          <div className='banner-text' style={{ marginLeft: "50px" }}>
            <Typography mb={3} className="title" component="h1" sx={{color:"white", fontSize : '35px', display : 'inline-block', marginTop : '65vh' }}>
              Exclusive&nbsp;
              <SlickSlider {...settings} className="text-slider" style={{
                display: "inline-block",
                verticalAlign: "top",
                width: "280px",
                height: "100px",
                overflow: "hidden"
              }}>
                <div className='slick-slide'>Jobs</div>
                <div className='slick-slide'>Resume</div>
                <div className='slick-slide'>Learning</div>
              </SlickSlider>
              <br/><span className='focus'>Platform For</span> Finance <br/>Professionals
            </Typography>
            <Box mt={0.5} mb={1} className="work-type" sx={{ color: 'white' }}>
              <Stack direction="row" alignItems={'center'} spacing={1}>
                <Typography variant="p" className="city">Find the perfect next job</Typography>
                <Typography variant="body1">•</Typography>
                <Typography variant="p" className="city">Chart your future career journey</Typography>
                <Typography variant="body1">•</Typography>
                <Typography variant="p" className="city">Achieve your pinnacle</Typography>
              </Stack>
            </Box>
          </div>
        </div>
                </Grid>
            </Grid>
        </Box>
    )
}
export default Register;
import React from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { deleteResume} from 'api/users';
const LeaveWithoutUpdatingDialog = ({ updateResume, resumeId ,handleClose ,isCreateResume}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data, totalResume, tab, jobData, createNewResume } = location.state || { data: null, totalResume: 0, tab: '4', jobData: null, createNewResume: false };
  const [open, setOpen] = useState(true)
  const [loading, setLoading] = useState(false);
  const [isProceeding, setIsProceeding] = useState(false);

  const handleCancel = () => {
    setOpen(false);
    navigate('/job-application', { state: { tab: tab || '4', data: jobData || null } });
  };

  const handleUpdateProceed = async () => {
    setLoading(true);
    try {
      await updateResume(resumeId);
      navigate('/job-application', { state: { tab: '4', data: jobData || null } });
    } catch (error) {
      console.error('Error updating resume:', error);
    } finally {
      setLoading(false);
    }
  };
  
  const handleProceed = async () => {
    setIsProceeding(true);
    try {
      if(isCreateResume){
      const response = await deleteResume({ id: resumeId });
      if (response.success) {
        console.log("Resume deleted");
      } else {
        console.log("Error deleting resume");
      }
    }
      navigate('/job-application', { state: { tab: "4" } });
    } catch (error) {
      console.error("API Error:", error);
    } finally{
      setIsProceeding(false); 
    }
  };  
  return (
    <Box className="ext-job-dialog-wraper remove-job-dialog" sx={{ padding: '24px 20px' }}>
      <Stack justifyContent={'center'}>
        <Box sx={{ textAlign: 'center' }} mb={4}>
          {isCreateResume? <Typography className="title" mb={0.5}>All changes will be lost and your new resume will not be created</Typography>
       : <Typography className="title" mb={0.5}>All changes will be lost and your new resume will not be updated</Typography>
      }
           </Box>
        <Stack direction={{ xs: 'column', md: 'row' }} alignItems={'center'} spacing={2.5}>
          {/* <Button fullWidth variant="outlined" size="medium" disableRipple disableElevation onClick={handleUpdateProceed} disabled={loading}>{loading ? 'Updating...' : 'Update & proceed'}</Button>
          <Button fullWidth variant="contained" size="medium" disableRipple disableElevation onClick={handleCancel}>Proceed without updating</Button> */}
           <Button fullWidth variant="outlined" size="medium" disableRipple disableElevation onClick={handleClose} disabled={loading}>Cancel</Button>
           <Button className="continue-btn" fullWidth variant="contained" size="medium"  disableRipple disableElevation disabled={isProceeding}  onClick={handleProceed}>{isProceeding ? 'Proceeding...' : 'Proceed'}</Button>
        </Stack>
      </Stack>
    </Box>
  )
}

export default LeaveWithoutUpdatingDialog

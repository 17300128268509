// @mui
import { Grid, Button, Card, CardContent, Dialog, Typography, Stack, Box, SvgIcon, Drawer, useMediaQuery, IconButton, Link } from '@mui/material';
import SlickSlider from 'react-slick';
// sections
import { LoginForm } from 'sections/auth/login';
import { useEffect, useState } from 'react';
const loginbanner = `${process.env.REACT_APP_HOMEPAGE}assets/login_side_banner.png`;
import { useTheme } from '@mui/material/styles';
// ----------------------------------------------------------------------


// ----------------------------------------------------------------------

export default function LoginPage() {
  const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  // const [isFirstVisit, setIsFirstVisit] = useState(false);
  // const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false);
  // const [userId, setUserId] = useState('');

  var settings = {
    autoplay: true,
    fade: true,
    autoplaySpeed: 3000,
    speed: 1000,
    prevArrow: false,
    nextArrow: false
  };

  // useEffect(() => {
  //   const getCookie = (name) => {
  //     const value = `; ${document.cookie}`;
  //     const parts = value.split(`; ${name}=`);
  //     if (parts.length === 2) return parts.pop().split(";").shift();
  //   };

  //   const visited = getCookie("hasVisitedLink");
  //   const id = getCookie("recentLogOutId");
  //   setUserId(id);
  //   const feedback = getCookie(`feedback${id}`);

  //   if (!visited) {
  //     setIsFirstVisit(true);
  //   }
  //   if (id) {
  //     setOpenFeedbackDialog(true);
  //   }
  // }, []);

  // const handleCloseDialog = () => {
  //   document.cookie = "hasVisitedLink=true; path=/; max-age=31536000";
  //   setIsFirstVisit(false);
  // };
  // const handleRedirectFeedback = () => {
  //   document.cookie = `feedback${userId}=true; path=/; max-age=31536000`;
  //   document.cookie = `recentLogOutId=; path=/; max-age=0`;
  //   window.open('https://docs.google.com/forms/d/e/1FAIpQLSeQqVSeHwASXPymILqJ5zAFfdfmVjIAOoTMXyezq8RErCURdw/viewform');
  //   setOpenFeedbackDialog(false);
  // };

  return (
    <Box sx={{ overflow: 'hidden' }}>
      <Grid container justifyContent={'center'} alignItems={'center'}>
        <Grid item xs={12} md={6} lg={6}>
          {/* Login form block */}
          <LoginForm />
        </Grid>

        <Grid item xs={12} md={6} lg={6} sx={{ display: { xs: 'none', md: 'block' } }}>
          {/* <img 
          src={loginbanner} 
          alt="Description of image" 
        /> */}
          <div className='banner-image'>
            <div className='banner-text' style={{ marginLeft: "50px" }}>
              <Typography mb={3} className="title" component="h1" sx={{ color: "white", fontSize: '35px', display: 'inline-block', marginTop: '65vh' }}>
                Exclusive&nbsp;
                <SlickSlider {...settings} className="text-slider" style={{
                  display: "inline-block",
                  verticalAlign: "top",
                  width: "280px",
                  height: "100px",
                  overflow: "hidden"
                }}>
                  <div className='slick-slide'>Jobs</div>
                  <div className='slick-slide'>Resume</div>
                  <div className='slick-slide'>Learning</div>
                </SlickSlider>
                <br /><span className='focus'>Platform For</span> Finance <br />Professionals
              </Typography>
              <Box mt={0.5} mb={1} className="work-type" sx={{ color: 'white' }}>
                <Stack direction="row" alignItems={'center'} spacing={1}>
                  <Typography variant="p" className="city">Find the perfect next job</Typography>
                  <Typography variant="body1">•</Typography>
                  <Typography variant="p" className="city">Chart your future career journey</Typography>
                  <Typography variant="body1">•</Typography>
                  <Typography variant="p" className="city">Achieve your pinnacle</Typography>
                </Stack>
              </Box>
            </div>
          </div>
        </Grid>
      </Grid>
      {/* Welcome pop */}
      {/* {isMobile ?
        <Drawer
          anchor="bottom"
          open={isFirstVisit}
          className="forgot-password-dialog"
        >
          <Card className="otp-card-wrapper" variant="outlined">
            <CardContent>
              <Box mb={3} sx={{ textAlign: 'center' }}>
                <Typography mb={3} className="title" component="h6">
                  Welcome to Talent Junction!
                </Typography>
                <Typography mb={2} sx={{ textAlign: 'left!important' }} className="otp-sent-txt" component="p">
                  We are a finance focused career platform. This a beta testing version.
                </Typography>

                <Typography mb={2} sx={{ textAlign: 'left!important' }} className="otp-sent-txt" component="p">
                  As you go through the profile building workflow, please create your actual profile. This will give you a realistic experience on the platform and enable you to see appropriate jobs that you can apply to.
                </Typography>

                <Typography mb={2} sx={{ textAlign: 'left!important' }} className="otp-sent-txt" component="p">
                  Don’t forget to leave your valuable feedback when you have finished testing. The feedback link can be seen at the top of the application.
                </Typography>

                <Typography mb={2} sx={{ textAlign: 'left!important' }} className="otp-sent-txt" component="p">
                  We greatly appreciate your time.</Typography>
              </Box>

              <Stack direction="row" alignItems={'center'} spacing={3}>
                <Button fullWidth className="otp-btn" variant="outlined" size="medium"
                  onClick={handleCloseDialog}
                >
                  OK
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </Drawer> :
        <Dialog
          open={isFirstVisit}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="otp-dialog-wrapper"
        >
          <Card className="otp-card-wrapper" variant="outlined">
            <CardContent>
              <Box mb={3} sx={{ textAlign: 'center' }}>
                <Typography mb={3} className="title" component="h6">
                  Welcome to Talent Junction!
                </Typography>
                <Typography mb={2} sx={{ textAlign: 'left!important' }} className="otp-sent-txt" component="p">
                  We are a finance focused career platform. This a beta testing version.
                </Typography>

                <Typography mb={2} sx={{ textAlign: 'left!important' }} className="otp-sent-txt" component="p">
                  As you go through the profile building workflow, please create your actual profile. This will give you a realistic experience on the platform and enable you to see appropriate jobs that you can apply to.
                </Typography>

                <Typography mb={2} sx={{ textAlign: 'left!important' }} className="otp-sent-txt" component="p">
                  Don’t forget to leave your valuable feedback when you have finished testing. The feedback link can be seen at the top of the application.
                </Typography>

                <Typography mb={2} sx={{ textAlign: 'left!important' }} className="otp-sent-txt" component="p">
                  We greatly appreciate your time.</Typography>
              </Box>

              <Stack direction="row" alignItems={'center'} spacing={3}>
                <Button fullWidth className="otp-btn" variant="outlined" size="medium"
                  onClick={handleCloseDialog}
                >
                  OK
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </Dialog>
      } */}

      {/* {isMobile ?
        <Drawer
          anchor="bottom"
          open={openFeedbackDialog}
          onClose={() => { setOpenFeedbackDialog(false); document.cookie = `recentLogOutId=; path=/; max-age=0`; }}
          className="forgot-password-dialog"
        >
          <Card className="otp-card-wrapper" variant="outlined">
            <CardContent>
              <IconButton className="close-dialog-btn" aria-label="delete" color="primary" onClick={() => { setOpenFeedbackDialog(false); document.cookie = `recentLogOutId=; path=/; max-age=0`; }}>
                <SvgIcon sx={{ width: '20px', height: '20px' }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <g clipPath="url(#clip0_456_20993)">
                      <path d="M7 7L17 17M17 7L7 17L17 7Z" stroke="#111827" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </g>
                    <defs>
                      <clipPath id="clip0_456_20993">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </SvgIcon>
              </IconButton>
              <Box mb={1}>
                <Typography mb={2} sx={{ textAlign: 'left!important' }} className="otp-sent-txt" component="p">
                  We strive to provide the best experience on the Talent Junction platform, and your feedback is crucial in helping us improve its functionality and services.
                </Typography>
                <Typography mb={2} sx={{ textAlign: 'left!important' }} className="otp-sent-txt" component="p">
                  We kindly request you to fill out a short feedback form and share your valuable insights with us.
                </Typography>
                <Typography mb={2} sx={{ textAlign: 'left!important' }} className="otp-sent-txt" component="p"><Link onClick={() => handleRedirectFeedback()}>Click here</Link> to provide your feedback
                </Typography>
                <Typography mb={1} sx={{ textAlign: 'left!important' }} className="otp-sent-txt" component="p">
                  Thank you for helping us make Talent Junction better for everyone!</Typography>
              </Box>
            </CardContent>
          </Card>
        </Drawer> :
        <Dialog
          open={openFeedbackDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="otp-dialog-wrapper"
        >
          <Card className="otp-card-wrapper" variant="outlined">
            <CardContent>
              <IconButton className="close-dialog-btn" aria-label="delete" color="primary" onClick={() => { setOpenFeedbackDialog(false); document.cookie = `recentLogOutId=; path=/; max-age=0`; }}>
                <SvgIcon sx={{ width: '20px', height: '20px' }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <g clipPath="url(#clip0_456_20993)">
                      <path d="M7 7L17 17M17 7L7 17L17 7Z" stroke="#111827" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </g>
                    <defs>
                      <clipPath id="clip0_456_20993">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </SvgIcon>
              </IconButton>
              <Box mb={1}>
                <Typography mb={2} sx={{ textAlign: 'left!important' }} className="otp-sent-txt" component="p">
                  We strive to provide the best experience on the Talent Junction platform, and your feedback is crucial in helping us improve its functionality and services.
                </Typography>
                <Typography mb={2} sx={{ textAlign: 'left!important' }} className="otp-sent-txt" component="p">
                  We kindly request you to fill out a short feedback form and share your valuable insights with us.
                </Typography>
                <Typography mb={2} sx={{ textAlign: 'left!important' }} className="otp-sent-txt" component="p">
                  <Link onClick={() => handleRedirectFeedback()}>Click here</Link> to provide your feedback
                </Typography>
                <Typography mb={1} sx={{ textAlign: 'left!important' }} className="otp-sent-txt" component="p">
                  Thank you for helping us make Talent Junction better for everyone!</Typography>
              </Box>
            </CardContent>
          </Card>
        </Dialog>
      } */}
    </Box>
  );
}
import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Grid, IconButton, Stack, Skeleton, Typography } from '@mui/material';
import { getBlogList } from 'api/blogs';
import { useAuth } from 'hooks/useAuthContext';

const likeIcon = `${process.env.REACT_APP_HOMEPAGE}assets/like-icon.png`;

const SuperchargeCareer = () => {
    const { user, updateContext } = useAuth();

    const [courseBlogs, setCourseBlogs] = useState([]);
    const [courseBlogCount, setCourseBlogCount] = useState(0);

    const [eventBlogs, setEventBlogs] = useState([]);
    const [eventBlogCount, setEventBlogCount] = useState(0);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchBlogs();
    }, []);

    const fetchBlogs = async () => {
        try {
            const response = await getBlogList({ userType: user?.userType || 'user' });
            if (response?.success && response?.data) {
                setCourseBlogs(response?.data?.CourseBlogs || []);
                setCourseBlogCount(response?.data?.CourseBlogsCount || 0);
                setEventBlogs(response?.data?.EventBlogs || []);
                setEventBlogCount(response?.data?.EventBlogsCount || 0);
            }
        } catch (error) {
            console.error('Error fetching jobs:', error);
        } finally {
            setLoading(false);
        }
    }

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box className="supercharge-your-career">
            <Box className="supercharge-inner-container">
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Box className="career-card">
                            <Box className="card-head">
                                <Typography className="title">Interesting finance articles, blogs, videos from all around</Typography>
                                <Typography className="info-text">Keep yourself abreast of the latest thoughts in the world of finance</Typography>
                            </Box>

                            <Box className="card-body">
                                <Stack spacing={{ xs: 2.5, md: 3 }}>
                                    {courseBlogs?.map((blog, index) => (
                                        <Box key={index} onClick={() => window.open(blog?.url, '_blank')}>
                                            <Stack direction="row" spacing={{ xs: 0, md: 2 }}>
                                                <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                                                    <Box
                                                        sx={{
                                                            width: '72px',
                                                            height: '72px',
                                                            borderRadius: '12px',
                                                            backgroundColor: '#D9D9D9',
                                                        }}
                                                    >
                                                        <img alt="name" src={blog?.image_url} style={{
                                                            height: "inherit", // Example height
                                                            width: "auto",
                                                            objectFit: "cover",
                                                            borderRadius: "6px"
                                                        }} />
                                                    </Box>
                                                </Box>
                                                <Box>
                                                    <Stack direction="row" alignItems={{ xs: 'flex-start', md: 'center' }} justifyContent={{ xs: 'space-between', md: 'flex-start' }} spacing={{ xs: 0, md: 1 }}>
                                                        <Typography className="category-title">
                                                            {blog?.title}
                                                        </Typography>
                                                        <IconButton className="arrow-btn" sx={{ padding: 0 }}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.033 0.644197C13.1726 0.702374 13.289 0.818726 13.3472 0.95835H13.353C13.3821 1.02816 13.3995 1.10379 13.3995 1.17942V4.62928C13.3995 4.94925 13.1377 5.21104 12.8178 5.21104C12.4978 5.21104 12.236 4.94925 12.236 4.62928V2.58147L7.41319 7.40429C7.29684 7.51482 7.1514 7.573 7.00014 7.573C6.84888 7.573 6.70344 7.52064 6.58708 7.40429C6.3602 7.1774 6.3602 6.81089 6.58708 6.584L11.4099 1.76118H9.3621C9.04213 1.76118 8.78033 1.49939 8.78033 1.17942C8.78033 0.85945 9.04213 0.597656 9.3621 0.597656H12.812C12.8876 0.597656 12.9632 0.615109 13.033 0.644197ZM12.2299 7.001C12.2299 6.68103 12.4917 6.41924 12.8117 6.41924C13.1317 6.41924 13.3934 6.68103 13.3934 7.001V11.0443C13.3934 12.3416 12.3346 13.4004 11.0373 13.4004H2.95661C1.65927 13.4004 0.600464 12.3416 0.600464 11.0443V2.96356C0.600464 1.66623 1.65927 0.607422 2.95661 0.607422H6.99986C7.31983 0.607422 7.58163 0.869215 7.58163 1.18919C7.58163 1.50916 7.31983 1.77095 6.99986 1.77095H2.95661C2.29921 1.77095 1.76399 2.30617 1.76399 2.96356V11.0443C1.76399 11.7017 2.29921 12.2369 2.95661 12.2369H11.0373C11.6947 12.2369 12.2299 11.7017 12.2299 11.0443V7.001Z" fill="#1575C6" />
                                                            </svg>
                                                        </IconButton>
                                                    </Stack>
                                                    <Typography className="category-info">
                                                        {blog?.short_description}
                                                    </Typography>

                                                    <Box mt={{ xs: 0.5, md: 1 }} className="category-type">
                                                        <Stack direction="row" alignItems={'center'} spacing={0.7}>
                                                            <Typography variant="p">{blog?.content_type}</Typography>
                                                            <Typography variant="body1">•</Typography>
                                                            <Typography variant="p">{blog?.duration}</Typography>
                                                            <Typography variant="body1">•</Typography>
                                                            <Typography variant="p" className="likes">
                                                                <img alt="name" width="12px" height="12px" style={{ marginTop: '-2px' }} src={likeIcon} />
                                                            </Typography>
                                                            <Typography variant="p">{blog?.impressions}</Typography>
                                                        </Stack>
                                                    </Box>
                                                </Box>
                                            </Stack>
                                        </Box>
                                    ))}
                                </Stack>

                                {/* <Box sx={{ textAlign: 'center', width: '100%' }}>
                                    <Button
                                        className="view-all-btn"
                                        endIcon={
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                <path d="M5.96667 2.7193L10.3133 7.06596C10.8267 7.5793 10.8267 8.4193 10.3133 8.93263L5.96667 13.2793" stroke="#1575C6" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        }>
                                        View all (43)
                                    </Button>
                                </Box> */}
                            </Box>
                        </Box>
                    </Grid>

                    {/* <Grid item xs={12}>
                        <Box className="career-card">
                            <Box className="card-head">
                                <Typography className="title">Events Around You</Typography>
                                <Typography className="info-text">Keep yourself abreast of the latest thoughts in the world of finance</Typography>
                            </Box>

                            <Box className="card-body">
                                <Stack spacing={3}>
                                    {eventBlogs?.map((event) => (
                                        <Box onClick={() => window.open(event?.url, '_blank')}>
                                            <Stack direction="row" spacing={{xs: 0, md: 2}}>
                                                <Box sx={{display: {xs:'none', md:'block'}}}>
                                                    <Box
                                                        sx={{
                                                            width: '72px',
                                                            height: '72px',
                                                            // borderRadius: '12px',
                                                            backgroundColor: '#D9D9D9',
                                                        }}
                                                    >
                                                        <img alt="name" src={event?.image_url} />
                                                    </Box>
                                                </Box>

                                                <Box>
                                                 <Stack direction="row" alignItems={{xs:'flex-start', md:'center'}} justifyContent={{xs: 'space-between', md:'flex-start'}} spacing={{xs: 0, md: 1}}> 
                                                    <Typography className="category-title">{event?.title}</Typography>
                                                    <IconButton className="arrow-btn" sx={{padding: 0}}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.033 0.644197C13.1726 0.702374 13.289 0.818726 13.3472 0.95835H13.353C13.3821 1.02816 13.3995 1.10379 13.3995 1.17942V4.62928C13.3995 4.94925 13.1377 5.21104 12.8178 5.21104C12.4978 5.21104 12.236 4.94925 12.236 4.62928V2.58147L7.41319 7.40429C7.29684 7.51482 7.1514 7.573 7.00014 7.573C6.84888 7.573 6.70344 7.52064 6.58708 7.40429C6.3602 7.1774 6.3602 6.81089 6.58708 6.584L11.4099 1.76118H9.3621C9.04213 1.76118 8.78033 1.49939 8.78033 1.17942C8.78033 0.85945 9.04213 0.597656 9.3621 0.597656H12.812C12.8876 0.597656 12.9632 0.615109 13.033 0.644197ZM12.2299 7.001C12.2299 6.68103 12.4917 6.41924 12.8117 6.41924C13.1317 6.41924 13.3934 6.68103 13.3934 7.001V11.0443C13.3934 12.3416 12.3346 13.4004 11.0373 13.4004H2.95661C1.65927 13.4004 0.600464 12.3416 0.600464 11.0443V2.96356C0.600464 1.66623 1.65927 0.607422 2.95661 0.607422H6.99986C7.31983 0.607422 7.58163 0.869215 7.58163 1.18919C7.58163 1.50916 7.31983 1.77095 6.99986 1.77095H2.95661C2.29921 1.77095 1.76399 2.30617 1.76399 2.96356V11.0443C1.76399 11.7017 2.29921 12.2369 2.95661 12.2369H11.0373C11.6947 12.2369 12.2299 11.7017 12.2299 11.0443V7.001Z" fill="#1575C6"/>
                                                    </svg>
                                                </IconButton>   
                                            </Stack>
                                                    <Typography className="category-info">{event?.short_description}</Typography>

                                                    <Box mt={1} className="category-type">
                                                        <Stack direction="row" alignItems={'center'} spacing={0.7}>
                                                            <Typography variant="p">{event?.content_type}</Typography>
                                                            <Typography variant="body1">•</Typography>
                                                            <Typography variant="p">{event?.duration}</Typography>
                                                            <Typography variant="body1">•</Typography>
                                                            <Typography variant="p" className="likes">
                                                                <img alt="name" width="12px" height="12px" style={{ marginTop: '-2px' }} src={likeIcon} />
                                                            </Typography>
                                                            <Typography variant="p">{event?.impressions}</Typography>
                                                        </Stack>
                                                    </Box>
                                                </Box>
                                            </Stack>
                                        </Box>
                                    ))}
                                </Stack>

                                <Box sx={{ textAlign: 'center', width: '100%' }}>
                                    <Button
                                        className="view-all-btn"
                                        endIcon={
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                <path d="M5.96667 2.7193L10.3133 7.06596C10.8267 7.5793 10.8267 8.4193 10.3133 8.93263L5.96667 13.2793" stroke="#1575C6" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        }>
                                        View all (12)
                                    </Button>
                                </Box> 
                            </Box>
                        </Box>
                    </Grid> */}
                </Grid>
            </Box>
        </Box>
    )
}

export default SuperchargeCareer

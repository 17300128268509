import React, { useState } from 'react'
import { useAuth } from 'hooks/useAuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm, register, watch, Controller } from 'react-hook-form';
import { Box, FormControl, Link, Grid, IconButton, InputAdornment, OutlinedInput, FormHelperText, SvgIcon, Button, Typography, Stack, Container, Icon, TextField, InputLabel, Divider, FormControlLabel, Checkbox, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Snackbar,useMediaQuery } from '@mui/material'
const loginLogo = `${process.env.REACT_APP_HOMEPAGE}assets/TJ-Logo-LightBG-withTagline 1.svg`
import { passwordReset } from 'api/login'
import { PasswordResetSchema } from 'models/ChangePasswordSchema';
import FormProvider from 'hooks/form/form-provider';
import { useSnackbar } from 'components/snackbar';
import { yupResolver } from '@hookform/resolvers/yup';
import SlickSlider from 'react-slick';
import { useTheme } from '@mui/material/styles';
const ResetPassword = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar()
    const { logout } = useAuth();
    const { user } = location.state;
    const [showPassword, setShowPassword] = useState(false)
    const [confirmPassword, setConfirmPassword] = useState(false);
     const theme = useTheme();
      const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const defaultValues = {
        password: '',
        confirm_password: ''
    };

    const methods = useForm({
        defaultValues,
        mode: "onChange",
        resolver: yupResolver(PasswordResetSchema)
    });

    const {
        control,
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors, isDirty, isValid, dirtyFields, isSubmitting },
    } = methods;

    var settings = {
        autoplay: true, 
        fade: true,
        autoplaySpeed: 3000,
        speed: 1000,
        prevArrow: false,
        nextArrow: false
      };

    const onSubmit = handleSubmit(async (formData) => {
        formData.user = user;
        const response = await passwordReset(formData);
        if (response?.success === true) {
            enqueueSnackbar(response.message, { variant: 'success' })
            reset();
            logout();
            navigate('/');
        } else if (response?.success === false) {
            enqueueSnackbar(response.message, { variant: 'error' })
            reset();
        }
    })

    return (
        <Box sx={{ overflow: 'hidden' }}>
            <FormProvider methods={methods} onSubmit={onSubmit}>
                {/* <Container maxWidth="xl" disableGutters> */}
                <Grid container justifyContent={'center'} alignItems={'center'}>
                    <Grid item xs={12} md={6} lg={6} >
                        <Box className="login-page-wrapper">
                            <Box className="login-content-wrapper"sx={{ padding: { xs: '40px 20px', md: '40px'}}}>
                                <Box className="tj-logo" sx={{marginBottom: {xs: '24px', md: '120px'}}}>
                                    <Box
                                       sx={{ mb: isMobile ? "" : 10 }}
                                        component="img"
                                        alt="Talent Junction"
                                        width="196px"
                                        height="61px"
                                        src={loginLogo}
                                    />
                                </Box>
                                {/* Register form block */}
                                <Box className="register-form-wrapper forgot-password-form">
                                    <Box sx={{marginBottom: {xs: '24px', md: '40px'}}}>
                                        <Typography className="title">Reset Password</Typography>
                                        <Typography className="info-text">Please enter new password</Typography>
                                    </Box>
                                    <Stack spacing={2.5}>
                                        <FormControl size="small" fullWidth sx={{
                                            display: 'inline-flex',
                                            flexDirection: 'column',
                                            position: 'relative',
                                            minWidth: '0px',
                                            padding: '0px',
                                            margin: '0px',
                                            border: '0px',
                                            verticalAlign: 'top',
                                            width: '100%',
                                            height: '67px',
                                        }}>
                                            <OutlinedInput
                                                id="outlined-adornment-password"
                                                type={showPassword ? 'text' : 'password'}
                                                {...register('password')}
                                                autoFocus
                                                error={!!errors.password}
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <SvgIcon sx={{ width: '16px', height: '16px' }}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16" fill="none">
                                                                <path fillRule="evenodd" clipRule="evenodd" d="M6.96303 0.516724C4.85522 0.516724 3.14501 2.22313 3.14501 4.32787V6.36885H2.58619C1.71626 6.36885 1 7.08116 1 7.95074V13.9009C1 14.7705 1.71626 15.4828 2.58619 15.4828H11.4173C12.2872 15.4828 13 14.7705 13 13.9009V7.95074C13 7.08116 12.2872 6.36885 11.4173 6.36885H10.7836V4.32787C10.7836 2.22313 9.07085 0.516724 6.96303 0.516724ZM6.96303 1.39708C8.59939 1.39708 9.90328 2.69667 9.90328 4.32787V6.36885H4.02536V4.32787C4.02536 2.69667 5.32666 1.39708 6.96303 1.39708ZM2.58619 7.24921H3.58346H10.3417H11.4173C11.8157 7.24921 12.1196 7.55469 12.1196 7.95074V13.9009C12.1196 14.2969 11.8157 14.6024 11.4173 14.6024H2.58619C2.18771 14.6024 1.88035 14.2969 1.88035 13.9009V7.95074C1.88035 7.55469 2.18771 7.24921 2.58619 7.24921ZM7.00172 8.80874C6.15965 8.80874 5.46454 9.5007 5.46454 10.3425C5.46454 11.0311 5.92961 11.617 6.55982 11.8075V12.6044C6.56005 12.6622 6.57166 12.7194 6.59399 12.7727C6.61631 12.8261 6.64893 12.8745 6.68996 12.9152C6.731 12.9559 6.77965 12.9881 6.83314 13.01C6.88663 13.0319 6.94391 13.0431 7.00172 13.0429C7.11787 13.0424 7.22913 12.9961 7.31126 12.9139C7.39338 12.8318 7.43972 12.7206 7.44018 12.6044V11.8083C8.07092 11.6186 8.53547 11.0321 8.53546 10.3425C8.53546 9.5007 7.84379 8.80874 7.00172 8.80874ZM7.00172 9.6891C7.36906 9.6891 7.65511 9.97753 7.65511 10.3425C7.65511 10.7074 7.36906 10.9924 7.00172 10.9924C6.63438 10.9924 6.34489 10.7074 6.34489 10.3425C6.34489 9.97753 6.63438 9.6891 7.00172 9.6891Z" fill="#6B7280" stroke="#6B7280" strokeWidth="0.5" />
                                                            </svg>
                                                        </SvgIcon>
                                                    </InputAdornment>
                                                }
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            tabIndex={-1}
                                                            edge="end"
                                                            onClick={() => setShowPassword(!showPassword)}
                                                        >
                                                            <SvgIcon sx={{ width: '16px', height: '16px' }}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="10" viewBox="0 0 16 10" fill="none">
                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M8 0.0604248C11.3236 0.0604248 14.1512 2.44413 15.5411 3.86595C16.153 4.48987 16.153 5.50774 15.5411 6.13366C14.1512 7.55549 11.3236 9.93919 8 9.93919C4.67641 9.93919 1.84877 7.55549 0.458942 6.13366C-0.152981 5.50974 -0.152981 4.49187 0.458942 3.86595C1.84877 2.44413 4.67641 0.0604248 8 0.0604248ZM8.002 9.07529C11.0076 9.07529 13.6293 6.85357 14.9231 5.52974C15.2091 5.23778 15.2091 4.76184 14.9231 4.46987C13.6293 3.14604 11.0076 0.924317 8.002 0.924317C4.99637 0.924317 2.3747 3.14604 1.08086 4.46987C0.7949 4.76384 0.7949 5.23778 1.08086 5.52974C2.3747 6.85357 4.99637 9.07529 8.002 9.07529ZM7.99995 1.42029C6.02619 1.42029 4.42039 3.02609 4.42039 4.99984C4.42039 6.97359 6.02619 8.57939 7.99995 8.57939C9.9737 8.57939 11.5795 6.97359 11.5795 4.99984C11.5795 3.02609 9.9737 1.42029 7.99995 1.42029ZM7.99995 7.7135C6.50413 7.7135 5.28629 6.49565 5.28629 4.99984C5.28629 3.50403 6.50413 2.28618 7.99995 2.28618C9.49576 2.28618 10.7136 3.50403 10.7136 4.99984C10.7136 6.49565 9.49576 7.7135 7.99995 7.7135ZM7.99994 3.36404C7.09605 3.36404 6.36414 4.09595 6.36414 4.99983C6.36414 5.90372 7.09605 6.63563 7.99994 6.63563C8.90383 6.63563 9.63573 5.90372 9.63573 4.99983C9.63573 4.09595 8.90383 3.36404 7.99994 3.36404Z" fill="#6B7280" />
                                                                </svg>
                                                            </SvgIcon>
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                placeholder='New Password (6+ characters)'
                                            // value={password}
                                            />
                                            <FormHelperText style={{ color: errors.password ? 'red' : 'inherit' }}>{errors.password?.message}</FormHelperText>
                                        </FormControl>
                                        <FormControl size="small" fullWidth sx={{
                                            display: 'inline-flex',
                                            flexDirection: 'column',
                                            position: 'relative',
                                            minWidth: '0px',
                                            padding: '0px',
                                            margin: '0px',
                                            border: '0px',
                                            verticalAlign: 'top',
                                            width: '100%',
                                            height: '67px',
                                        }}>
                                            <OutlinedInput
                                                id="outlined-adornment-confirm-password"
                                                type={confirmPassword ? 'text' : 'password'}
                                                {...register('confirm_password')}
                                                error={!!errors.confirm_password}
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <SvgIcon sx={{ width: '16px', height: '16px' }}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16" fill="none">
                                                                <path fillRule="evenodd" clipRule="evenodd" d="M6.96303 0.516724C4.85522 0.516724 3.14501 2.22313 3.14501 4.32787V6.36885H2.58619C1.71626 6.36885 1 7.08116 1 7.95074V13.9009C1 14.7705 1.71626 15.4828 2.58619 15.4828H11.4173C12.2872 15.4828 13 14.7705 13 13.9009V7.95074C13 7.08116 12.2872 6.36885 11.4173 6.36885H10.7836V4.32787C10.7836 2.22313 9.07085 0.516724 6.96303 0.516724ZM6.96303 1.39708C8.59939 1.39708 9.90328 2.69667 9.90328 4.32787V6.36885H4.02536V4.32787C4.02536 2.69667 5.32666 1.39708 6.96303 1.39708ZM2.58619 7.24921H3.58346H10.3417H11.4173C11.8157 7.24921 12.1196 7.55469 12.1196 7.95074V13.9009C12.1196 14.2969 11.8157 14.6024 11.4173 14.6024H2.58619C2.18771 14.6024 1.88035 14.2969 1.88035 13.9009V7.95074C1.88035 7.55469 2.18771 7.24921 2.58619 7.24921ZM7.00172 8.80874C6.15965 8.80874 5.46454 9.5007 5.46454 10.3425C5.46454 11.0311 5.92961 11.617 6.55982 11.8075V12.6044C6.56005 12.6622 6.57166 12.7194 6.59399 12.7727C6.61631 12.8261 6.64893 12.8745 6.68996 12.9152C6.731 12.9559 6.77965 12.9881 6.83314 13.01C6.88663 13.0319 6.94391 13.0431 7.00172 13.0429C7.11787 13.0424 7.22913 12.9961 7.31126 12.9139C7.39338 12.8318 7.43972 12.7206 7.44018 12.6044V11.8083C8.07092 11.6186 8.53547 11.0321 8.53546 10.3425C8.53546 9.5007 7.84379 8.80874 7.00172 8.80874ZM7.00172 9.6891C7.36906 9.6891 7.65511 9.97753 7.65511 10.3425C7.65511 10.7074 7.36906 10.9924 7.00172 10.9924C6.63438 10.9924 6.34489 10.7074 6.34489 10.3425C6.34489 9.97753 6.63438 9.6891 7.00172 9.6891Z" fill="#6B7280" stroke="#6B7280" strokeWidth="0.5" />
                                                            </svg>
                                                        </SvgIcon>
                                                    </InputAdornment>
                                                }
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            edge="end"
                                                            tabIndex={-1}
                                                            onClick={() => setConfirmPassword(!confirmPassword)}
                                                        >
                                                            <SvgIcon sx={{ width: '16px', height: '16px' }}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="10" viewBox="0 0 16 10" fill="none">
                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M8 0.0604248C11.3236 0.0604248 14.1512 2.44413 15.5411 3.86595C16.153 4.48987 16.153 5.50774 15.5411 6.13366C14.1512 7.55549 11.3236 9.93919 8 9.93919C4.67641 9.93919 1.84877 7.55549 0.458942 6.13366C-0.152981 5.50974 -0.152981 4.49187 0.458942 3.86595C1.84877 2.44413 4.67641 0.0604248 8 0.0604248ZM8.002 9.07529C11.0076 9.07529 13.6293 6.85357 14.9231 5.52974C15.2091 5.23778 15.2091 4.76184 14.9231 4.46987C13.6293 3.14604 11.0076 0.924317 8.002 0.924317C4.99637 0.924317 2.3747 3.14604 1.08086 4.46987C0.7949 4.76384 0.7949 5.23778 1.08086 5.52974C2.3747 6.85357 4.99637 9.07529 8.002 9.07529ZM7.99995 1.42029C6.02619 1.42029 4.42039 3.02609 4.42039 4.99984C4.42039 6.97359 6.02619 8.57939 7.99995 8.57939C9.9737 8.57939 11.5795 6.97359 11.5795 4.99984C11.5795 3.02609 9.9737 1.42029 7.99995 1.42029ZM7.99995 7.7135C6.50413 7.7135 5.28629 6.49565 5.28629 4.99984C5.28629 3.50403 6.50413 2.28618 7.99995 2.28618C9.49576 2.28618 10.7136 3.50403 10.7136 4.99984C10.7136 6.49565 9.49576 7.7135 7.99995 7.7135ZM7.99994 3.36404C7.09605 3.36404 6.36414 4.09595 6.36414 4.99983C6.36414 5.90372 7.09605 6.63563 7.99994 6.63563C8.90383 6.63563 9.63573 5.90372 9.63573 4.99983C9.63573 4.09595 8.90383 3.36404 7.99994 3.36404Z" fill="#6B7280" />
                                                                </svg>
                                                            </SvgIcon>
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                placeholder='Re-Enter New Password'
                                            // value={confirm_password}
                                            />
                                            <FormHelperText style={{ color: errors.confirm_password ? 'red' : 'inherit' }}>{errors.confirm_password?.message}</FormHelperText>
                                        </FormControl>

                                        <Button
                                            type='submit'
                                            variant='contained'
                                            size='large'
                                            className="login-btn"
                                            disabled={isSubmitting || !isValid}
                                        >
                                            Update Password
                                        </Button>

                                    </Stack>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={6} lg={6} sx={{ display: { xs: 'none', md: 'block' }}}>
                    <div className='banner-image'>
          <div className='banner-text' style={{ marginLeft: "50px" }}>
            <Typography mb={3} className="title" component="h1" sx={{color:"white", fontSize : '35px', display : 'inline-block', marginTop : '65vh' }}>
              Exclusive&nbsp;
              <SlickSlider {...settings} className="text-slider" style={{
                display: "inline-block",
                verticalAlign: "top",
                width: "280px",
                height: "100px",
                overflow: "hidden"
              }}>
                <div className='slick-slide'>Jobs</div>
                <div className='slick-slide'>Resume</div>
                <div className='slick-slide'>Learning</div>
              </SlickSlider>
              <br/><span className='focus'>Platform For</span> Finance <br/>Professionals
            </Typography>
            <Box mt={0.5} mb={1} className="work-type" sx={{ color: 'white' }}>
              <Stack direction="row" alignItems={'center'} spacing={1}>
                <Typography variant="p" className="city">Find the perfect next job</Typography>
                <Typography variant="body1">•</Typography>
                <Typography variant="p" className="city">Chart your future career journey</Typography>
                <Typography variant="body1">•</Typography>
                <Typography variant="p" className="city">Achieve your pinnacle</Typography>
              </Stack>
            </Box>
          </div>
        </div>
                    </Grid>
                </Grid>
                {/* </Container> */}
            </FormProvider>
        </Box>
    )
}

export default ResetPassword

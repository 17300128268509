import { Avatar, Box, Card, Link, CardContent, Chip, Container, Grid, Skeleton, Stack, Tab, Typography, ToggleButton, ToggleButtonGroup, Button, SvgIcon, Divider, IconButton, Menu, MenuItem, ListItemIcon, FormControl, InputLabel, Select, List, ListItem, Checkbox, Dialog, TextField, Drawer, Rating } from '@mui/material'
import React, { useState, useEffect, } from 'react'
import FilterSearch from './AdminSearchFilter'
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { styled } from '@mui/material/styles';
import StarIcon from '@mui/icons-material/Star';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { useSnackbar } from 'components/snackbar';
import { getTimeDifferenceString, changeNameFormat, formatNumber, toTitleCase } from 'utils/Utils';
import { getAllJobList, deleteJob, createDuplicateJob, jobUpdated } from 'api/job';
import { Link as RouterLink, useNavigate } from "react-router-dom";
import ViewJobDetails from './ViewJobDetails';
import CandidateProfileDrawer from './CandidateProfileDrawer';
import { getApplicantsList, updateSharedApplicant } from 'api/admin';
import { all } from 'axios';
import { setApplication } from 'api/application';
import { updateCandidateStatus } from 'api/admin';
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { handleUploadData } from 'api/users';

import exportFromJSON from 'export-from-json';
const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: 6,
    width: 20,
    height: 20,
    boxShadow: 'inset 0 0 0 1.5px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
        ...theme.applyStyles('dark', {
            backgroundColor: '#30404d',
        }),
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
        ...theme.applyStyles('dark', {
            background: 'rgba(57,75,89,.5)',
        }),
    },
    ...theme.applyStyles('dark', {
        boxShadow: '0 0 0 1px rgb(16 22 26 / 40%)',
        backgroundColor: '#394b59',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))',
    }),
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&::before': {
        display: 'block',
        width: 20,
        height: 20,
        backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: '#106ba3',
    },
});

// Inspired by blueprintjs
function BpCheckbox({ checked, onChange, props }) {
    return (
        <Checkbox
            sx={{ '&:hover': { bgcolor: 'transparent' } }}
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            checked={checked}
            onChange={onChange}
            inputProps={{ 'aria-label': 'Checkbox demo' }}
            {...props}
        />
    );
}

const FullDetails = ({ jobDetails  , fetchJobs}) => {
    const [resumeActionAnchorEl, setResumeActionAnchorEl] = React.useState(null);
    const openActionMenu = Boolean(resumeActionAnchorEl);
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const { enqueueSnackbar } = useSnackbar();
    const [showJobDetails, setShowJobDetails] = useState(false);
    const [selectedApplicants, setSelectedApplicants] = useState({});
    const [isDuplicating, setIsDuplicating] = useState(false);
    const [ActionAnchorEl, setActionAnchorEl] = React.useState(null);
    const [openDP, setOpenDP] = useState(false);
    const [selectedJobId, setSelectedJobId] = useState(null);
    const [title, setTitle] = useState('');
    const [openCloseDialog, setOpenCloseDialog] = useState(false);
    const [selectedJob, setSelectedJob] = useState('');
    const [openCS, setOpenCS] = useState(false);
    const [downloadedCount, setDownloadedCount] = useState(0);
    const [showSharedProfile, setShowSharedProfile] = useState({});
    const [downloadedApplicantsList,setDownloadedApplicantsList] = useState([]);
    const [newApplicants, setNewApplicants] = useState(0);
    const [selectedApplicantFeedback, setSelectedApplicantFeedback] = useState();
    const [allApplicants, setAllApplicants] = useState({
        allData: [],
        maybeData: [],
        unratedData: [],
        notSutableData: [],
        shortlistedData: [],
    });    
    const openResumeActionMenu = (event) => {
        setResumeActionAnchorEl(event.currentTarget);
    };
    const handleViewJobDetails = () => {
        setShowJobDetails(true);
        closeResumeActionMenu(); // Close the menu after clicking
    };
    const handleBack = () => {
        setShowJobDetails(false);
    };
    const closeResumeActionMenu = () => {
        setResumeActionAnchorEl(null);
    };

    const navigate = useNavigate();
    // tabs 
    // const [applicantId , setApplicantId] =useState('');
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // // select status
    const [selectStatus, setSelectStatus] = React.useState('');

    const handleStatusChange = async (event, applicantId,) => {
        // console.log(applicantId);

        // candidate id , status   // api call to update status of candidate
        try {
            const response = await updateCandidateStatus({ applicantId, status: event.target.value });
            if (response?.success) {
                setSelectStatus(event.target.value);
                enqueueSnackbar(response.message, { variant: 'success' });
            } else {
                enqueueSnackbar(response.message, { variant: 'error' });
            }
        }
        catch (error) {
            console.error('Error updating status:', error);
        }


    };
    const defaultValues = {
        jobId: "",
        jobName: "",
        skills: [],
        experience: "",
        salary: "",
        education: [],
        location: [],
        companyName: "",
        function: [],
        role: [],
      };

    const handleDuplicateJob = async (event, jobId) => {
        event.stopPropagation();
        setIsDuplicating(true);
        try {
            const response = await createDuplicateJob({ jobId });
            if (response?.success && response.data) {
                setActionAnchorEl(null);
                setOpenDP(false);
                navigate(`/job-form`, { state: response?.data?._id })
            }
        } catch (error) {
            console.error('Failed to create duplicate:', error);
            enqueueSnackbar('Failed to create duplicate. Please try again later.', { variant: 'error' });
        } finally {
            setIsDuplicating(false);
        }
    };
    function calculateDownload() {

    };
    jsPDF.API.autoTable = autoTable;

    const downloadSelectedCandidatesData = async (selectedJobId) => {
        // ✅ Get selected applicants only for this job
        const selectedApplicantsForJob = selectedApplicants[selectedJobId] || [];

        const diff  = selectedApplicantsForJob.filter(id=>!downloadedApplicantsList.includes(id));
         if(diff.length>0)setNewApplicants(diff.length);

        const response = await updateSharedApplicant({selectedApplicantsForJob,selectedJobId});
        if (response?.success) {
            enqueueSnackbar(response.message, { variant: 'success' });
            setDownloadedCount(response.data);
        } else {
            enqueueSnackbar(response.message, { variant: 'error' });
        }

        // ✅ Filter only applicants that are selected for this job
        const selectedCandidatesData = allApplicants.shortlistedData.filter(applicant =>
            selectedApplicantsForJob.includes(applicant._id)
        );
        
        selectedApplicantsForJob.forEach(applicantId => {
            selectedApplicants[applicantId] = { ...selectedApplicants[applicantId], isDownloaded: true };
        });
        // ✅ Update showSharedProfile for this specific job
        setShowSharedProfile(prev => ({
            ...prev,
            [selectedJobId]: selectedApplicantsForJob.length || 0,
        }));
        
        const CandidatesList = selectedCandidatesData.map(candidate => ({
            'Applicant ID': candidate?.applicationid,
            'Email': candidate?.candidate_profile?.email,
            'Phone': candidate?.candidate_profile?.phone_number,
            'Candidate Name': candidate?.candidate_profile?.full_name,
            'Designation': candidate?.designation,
            'Qualifications': candidate?.educations,
            'Location': candidate?.location,
            'Salary': candidate?.salary,
        }));
        const fileName = `candidateList_${Date.now()}`;
        
        // 1️⃣ Convert Data to PDF
        const pdfBlob = await generatePDF(CandidatesList, fileName);
        
        // 2️⃣ Create a File object for upload
        const pdfFile = new File([pdfBlob], `${fileName}.pdf`, { type: "application/pdf" });
        
        // 3️⃣ Upload the PDF file to the backend
         downloadPDFLocally(pdfBlob, fileName);
        
        
        await uploadPDFToServer(pdfFile);
        setNewApplicants(0);
        // 4️⃣ Download the PDF locally
    };



    // ✅ Function to Generate a PDF from Data
    const generatePDF = async (data, fileName) => {
        const doc = new jsPDF();
        doc.text("Candidate List", 14, 10);

        if (!data.length) {
            doc.text("No candidates selected.", 14, 20);
        } else {
            // Extract column headers from data keys
            const headers = [Object.keys(data[0])];

            // Convert data to array format for autoTable
            const rows = data.map(candidate => Object.values(candidate));

            // ✅ Use `autoTable` correctly
            autoTable(doc, {
                head: headers, // Table headers
                body: rows, // Table rows
                startY: 20, // Positioning after title
                styles: { fontSize: 10 }, // Adjust font size
            });
        }

        return doc.output("blob"); // Return as Blob for upload
    };

    // ✅ Function to Upload PDF File to Backend
    const uploadPDFToServer = async (file) => {
        const formData = new FormData();
        formData.append("file", file);

        try {
            const response = await handleUploadData(formData);
            if (response?.success) {
                console.log("PDF uploaded successfully!");
            } else {
                console.error("Upload failed:", response?.message);
            }
        } catch (error) {
            console.error("Error uploading file:", error);
        }
    };
    const downloadPDFLocally = (pdfBlob, fileName) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(pdfBlob);
        link.download = `${fileName}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    const handleCheckboxChange = (event, jobId, applicantId) => {
        setSelectedApplicants(prev => {
            const prevSelected = prev[jobId] || []; // Get existing applicants for this job

            return {
                ...prev,
                [jobId]: event.target.checked
                    ? [...prevSelected, applicantId] // ✅ Add applicant if checked
                    : prevSelected.filter(id => id !== applicantId), // ✅ Remove if unchecked
            };
        });
    };

    // checkbox label
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

    // edit whatsapp dialog

    const [openRejectionReasonDialog, setOpenRejectionReasonDialog] = React.useState(false);

    const handleOpenRejectionReasonDialog = () => {
        setOpenRejectionReasonDialog(true);
    };

    const handleCloseRejectionReasonDialog = () => {
        setOpenRejectionReasonDialog(false);
    };

    // Candiate profile drawer

    const [openCandiateProfileDrawer, setOpenCandiateProfileDrawer] = React.useState(false);
    const [selectedCandidate, setSelectedCandidate] = useState(null);


    const toggleCandiateProfileDrawer = (newOpenSkills, item) => () => {      
        setOpenCandiateProfileDrawer(newOpenSkills);
        setSelectedApplicantFeedback(item);        
    };
   
    const [openEditJobDetails, setOpenEditJobDetails] = React.useState(false);

    const handleOpenEditJobDetailsDialog = () => {
        setOpenEditJobDetails(true);
    };

    const handleCloseEditJobDetailsDialog = () => {
        setOpenEditJobDetails(false);
    };

    useEffect(() => {
        const fetchApplicants = async () => {
            setLoading(true);
            try {
                const response = await getApplicantsList({ id: jobDetails?._id });
                if (response?.success && response.data != null && response.data.applicant != null) {
                    setAllApplicants(response?.data?.filterStatusWiseData);
                    const downloadedApplicants = response?.data?.filterStatusWiseData?.shortlistedData
                        .filter(applicant => applicant.isDownloaded)  // Filter only downloaded ones
                        .map(applicant => applicant._id); // Extract their IDs

                    setDownloadedCount(downloadedApplicants.length);
                    setDownloadedApplicantsList(downloadedApplicants);
                    setValue("1");
                } else {
                    enqueueSnackbar(response.message, { variant: 'error' });
                }
            } catch (error) {
                console.error('Error fetching applications:', error);
            }
            setLoading(false);
        };
        fetchApplicants();
    }, [jobDetails, selectStatus ,newApplicants]);

    const handleRequirement = (score) => {
        const requirementKeys = [
            "qualification",
            "experienceMatchRating",
            "industry",
            "skillMatchRating"
        ];

        return requirementKeys.reduce((count, key) => count + (score?.[key] >= 3 ? 1 : 0), 0);
    };

    const selectStatusChange = () => {
        console.log("clicked")
    }
    const handlePreference = (score) => {
        const preferenceKeys = [
            "salaryMatchRating",
            "location",
            "format",
            "type",
            "shift",
            "notice",
            "salary"
        ];

        return preferenceKeys.reduce((count, key) => count + (score?.[key] >= 3 ? 1 : 0), 0);
    };

    const workHistory = (work) => {
        const filterWork = work?.filter(edu => edu?.organization_name !== 'Work Gap');
        const lastValidJob = [...work].reverse().find(job => job?.organization_name !== "Work Gap");
        const highestDesignation = lastValidJob?.highest_designation || "N/A";
        const organizationName = lastValidJob?.organization_name || "N/A";
        const fromDate = lastValidJob?.work_history_from ? new Date(lastValidJob.work_history_from).getFullYear() : "N/A";
        const toDate = lastValidJob?.work_history_to ? new Date(lastValidJob.work_history_to).getFullYear() : "Present";
        return (
            <Typography>{highestDesignation} at<Typography variant="caption" sx={{ fontWeight: 700 }}>{organizationName}({fromDate}-{toDate})</Typography>{filterWork?.length > 1 && (<Typography variant="caption" sx={{ fontWeight: 400, textDecoration: 'underline' }}>+{filterWork?.length - 1}</Typography>)}</Typography>)
    }

    const calculateAverageRating = (ratings) => {
        if (!ratings) return 0; 
    
        const values = Object.values(ratings);
        const sum = values.reduce((acc, curr) => acc + curr, 0);
        return values.length ? sum / values.length : 0; 
    };
    
    
    const updateRatings = (data, selectedApplicant) => {
        setAllApplicants((prevApplicants) => ({
            allData: prevApplicants.allData.map(applicant => 
                applicant._id === selectedApplicant._id ? { ...applicant, ratings: data } : applicant
            ),
            maybeData: prevApplicants.maybeData.map(applicant => 
                applicant._id === selectedApplicant._id ? { ...applicant, ratings: data } : applicant
            ),
            unratedData: prevApplicants.unratedData.map(applicant => 
                applicant._id === selectedApplicant._id ? { ...applicant, ratings: data } : applicant
            ),
            notSutableData: prevApplicants.notSutableData.map(applicant => 
                applicant._id === selectedApplicant._id ? { ...applicant, ratings: data } : applicant
            ),
            shortlistedData: prevApplicants.shortlistedData.map(applicant => 
                applicant._id === selectedApplicant._id ? { ...applicant, ratings: data } : applicant
            ),
        }));
    };
    
    

    const handleEditButton = (e, id) => {
        e.stopPropagation();
        navigate(`/job-form`, { state: id })
    }
    const handleDuplicate = (event, job) => {
        event.stopPropagation();
        setOpenDP(true);
        setTitle(job?.title);
        setSelectedJobId(job?._id)
      }
    const handleCloseDPDialog = (event) => {
        event.stopPropagation();
        setOpenDP(false);
      }
      const handleCloseCSDialog = (event) => {
        event.stopPropagation();
        setOpenCS(false);
      };
        const handleCloseJobStatus = async () => {
          try {
            setIsDuplicating(true);
            const jobData = selectedJob;
            jobData.status = 'closed';
            const jobId = jobData._id;
            const response = await jobUpdated({ formData: jobData, job_id: jobId });
            if (response?.success === true && response.data) {
              setOpenCloseDialog(false);
              setActionAnchorEl(null);
              enqueueSnackbar('Job closed successfully', { variant: 'success' });
              fetchJobs();
            }
          } catch (e) {
            enqueueSnackbar('Failed to close job', { variant: 'error' });
          } finally {
            setIsDuplicating(false);
          }
        };
        const handleCloseButton = (e, job) => {
            setSelectedJob(job);
            setOpenCloseDialog(true);
          };
    return (
        <Box className="admin-activ-jobs-full-details">
            {jobDetails && (
                <Box className="header-top">
                    {showJobDetails && (
                        <Button
                            className="backButton"
                            size="small"
                            onClick={handleBack}
                            startIcon={
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/arrow_back.svg`}
                                    alt="Back"
                                    width="20"
                                    height="20"
                                />
                            }
                            sx={{
                                marginBottom: '12px',
                                padding: '0',
                                "&.MuiButtonBase-root:hover": {
                                    bgcolor: "transparent"
                                }
                            }}
                            disableRipple
                            disableElevation
                        >
                            Back
                        </Button>
                    )}

                    <Stack direction="row" justifyContent={'space-between'} alignItems={'flex-start'} spacing={2}>
                        <Box>
                            <Typography className="title">{jobDetails?.title}</Typography>

                            <Stack direction="row" alignItems={'center'} spacing={1}>
                                <Typography className="company-name">{jobDetails?.company}</Typography>

                                <Box className="ratings">
                                    <Stack direction="row" alignItems={'center'} spacing={0.5}>
                                        <SvgIcon>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11" fill="none">
                                                <path d="M5.71663 0.815217C5.81002 0.546549 6.18998 0.546548 6.28337 0.815216L7.34214 3.86117C7.38334 3.97969 7.49395 4.06006 7.6194 4.06261L10.8435 4.12831C11.1278 4.13411 11.2452 4.49547 11.0186 4.66731L8.44889 6.61552C8.3489 6.69133 8.30665 6.82135 8.34299 6.94146L9.27679 10.028C9.35916 10.3003 9.05176 10.5236 8.81829 10.3611L6.17135 8.51924C6.06836 8.44757 5.93164 8.44757 5.82865 8.51924L3.18171 10.3611C2.94824 10.5236 2.64084 10.3003 2.72321 10.028L3.65701 6.94146C3.69335 6.82135 3.6511 6.69133 3.55111 6.61552L0.981412 4.66731C0.754753 4.49547 0.872166 4.13411 1.15654 4.12831L4.3806 4.06261C4.50605 4.06006 4.61666 3.97969 4.65786 3.86117L5.71663 0.815217Z" fill="#29C138" />
                                            </svg>
                                        </SvgIcon>
                                        <Typography className="rating-count">{jobDetails?.rating}</Typography>
                                    </Stack>
                                </Box>

                                <Divider orientation="vertical" variant="middle" flexItem />
                                <Typography className="city-name">{jobDetails?.location}</Typography>
                            </Stack>
                        </Box>

                        <Box className="admin-job-details">
                            <Stack direction="row" alignItems={'center'} spacing={2}>
                                {/* <Link href="#" className="download-link" underline="none">
                                            Download Candidate List
                                        </Link> */}
                                {showJobDetails && (<>
                                    <Button variant="outlined" size="medium" className="edit-job-det-btn" onClick={handleOpenEditJobDetailsDialog} disableRipple disableElevation disabled={allApplicants?.allData?.length > 0} >Edit Job Details</Button>

                                </>)}

                                <Typography className="posted-date">{jobDetails?.updatedAt ? getTimeDifferenceString(jobDetails?.updatedAt, "Posted") : 'NA'}</Typography>
                                <Box>
                                    <IconButton
                                        aria-label="more"
                                        aria-controls={openActionMenu ? 'resume-action-menu' : undefined}
                                        aria-expanded={openActionMenu ? 'true' : undefined}
                                        aria-haspopup="true"
                                        onClick={openResumeActionMenu}
                                        className="resume-action-menu-btn"
                                    >
                                        {/* <MoreVertIcon /> */}
                                        <svg xmlns="http://www.w3.org/2000/svg" width="4" height="20" viewBox="0 0 4 20" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M2 4C0.895431 4 5.79551e-07 3.10457 6.27833e-07 2C6.76115e-07 0.89543 0.895431 -1.35705e-07 2 -8.74228e-08C3.10457 -3.91405e-08 4 0.89543 4 2C4 3.10457 3.10457 4 2 4ZM2 12C0.895431 12 7.06698e-07 11.1045 7.54981e-07 9.99997C8.03263e-07 8.8954 0.895431 7.99997 2 7.99997C3.10457 7.99997 4 8.8954 4 9.99997C4 11.1045 3.10457 12 2 12ZM8.82124e-07 18C8.33842e-07 19.1046 0.895431 20 2 20C3.10457 20 4 19.1046 4 18C4 16.8955 3.10457 16 2 16C0.895431 16 9.30406e-07 16.8955 8.82124e-07 18Z" fill="#1575C6" />
                                        </svg>
                                    </IconButton>

                                    <Menu
                                        id="resume-action-menu"
                                        className="resume-action-menu"
                                        anchorEl={resumeActionAnchorEl}
                                        open={openActionMenu}
                                        onClose={closeResumeActionMenu}
                                        sx={{ marginTop: 2 }}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                    >
                                        <MenuItem onClick={handleViewJobDetails} >View Job Details</MenuItem>
                                        <MenuItem disabled={allApplicants.allData.length > 0} onClick={(e) => {
                                            e.stopPropagation();
                                            handleEditButton(e, jobDetails?._id);
                                        }}>Edit Job Details</MenuItem>
                                        <MenuItem onClick={(e) => {
                                             e.stopPropagation();
                                             handleDuplicate(e, jobDetails);
                                        }}>Duplicate Job</MenuItem>
                                        <Divider />
                                        <MenuItem disabled={allApplicants.allData.length>0} onClick={(e) => {
                                        e.stopPropagation();
                                        handleCloseButton(e, jobDetails);
                                      }} >Close Job</MenuItem>
                                        <Dialog
                                            open={openDP}
                                            onClose={(event) => handleCloseDPDialog(event)}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description"
                                            className="otp-dialog-wrapper"
                                        >
                                            <Card className="otp-card-wrapper" variant="outlined">
                                                <CardContent>
                                                    <IconButton
                                                        className="close-dialog-btn"
                                                        aria-label="delete"
                                                        color="primary"
                                                        onClick={(event) => handleCloseDPDialog(event)}
                                                    >
                                                        <SvgIcon sx={{ width: '20px', height: '20px' }}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <g clipPath="url(#clip0_456_20993)">
                                                                    <path
                                                                        d="M7 7L17 17M17 7L7 17L17 7Z"
                                                                        stroke="#111827"
                                                                        strokeWidth="1.5"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_456_20993">
                                                                        <rect width="24" height="24" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                        </SvgIcon>
                                                    </IconButton>
                                                    <Box mb={3} sx={{ textAlign: 'center' }}>
                                                        <Typography mb={1} className="title" component="h6">
                                                            Duplicate {jobDetails?.title}?
                                                        </Typography>
                                                        <Typography sx={{ textAlign: 'center!important' }} className="otp-sent-txt" component="p">
                                                            Are you sure you want to make Duplicate this {jobDetails?.title}?
                                                        </Typography>
                                                    </Box>
                                                    <Stack direction="row" alignItems="center" spacing={3}>
                                                        <Button fullWidth className="otp-btn" variant="outlined" size="medium" onClick={(event) => handleCloseDPDialog(event)}>
                                                            Cancel
                                                        </Button>
                                                        <Button
                                                            fullWidth
                                                            className="otp-btn"
                                                            variant="contained"
                                                            size="medium"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleDuplicateJob(e, jobDetails?._id);
                                                            }}
                                                            disabled={isDuplicating}
                                                        >
                                                            {isDuplicating ? 'Duplicating...' : 'Duplicate'}
                                                        </Button>
                                                    </Stack>
                                                </CardContent>
                                            </Card>
                                        </Dialog>
                                    </Menu>
                                </Box>
                            </Stack>
                        </Box>
                    </Stack>
                </Box>
            )}
            {downloadedCount > 0 && (
                <Box className="shared-candi-profile">
                    <Stack direction="row" alignItems={'center'} justifyContent={'space-between'}>
                        <Box className="shared-count-txt">
                            <Typography><Typography variant='caption'> {downloadedCount || 0} </Typography>candidate profiles shared with recruiting company</Typography>
                        </Box>
                        <Box>
                            <Stack direction="row" alignItems={'center'} spacing={0.5}>
                                <Typography className="view-details-txt">View Details</Typography>
                                <IconButton className="arrow-btn" sx={{ padding: 0 }} disabled>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path d="M5.96655 13.2797L10.3132 8.93306C10.8266 8.41973 10.8266 7.57973 10.3132 7.06639L5.96655 2.71973" stroke="#1575C6" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </IconButton>
                            </Stack>
                        </Box>
                    </Stack>
                </Box>)}

            <Box >
                {showJobDetails ? (
                    <Box>
                        <ViewJobDetails jobDetails={jobDetails} />
                    </Box>
                ) : (
                    <TabContext value={value}>
                        <Box className="admin-active-jobs-tabs">
                            <Container maxWidth="xl">
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label={`All (${allApplicants.allData.length})`} value="1" disableRipple />
                                    <Tab label={`Unrated (${allApplicants.unratedData.length})`} value="2" disableRipple />
                                    <Tab label={`May Be (${allApplicants.maybeData.length})`} value="3" disableRipple />
                                    <Tab label={`Not Suitable (${allApplicants.notSutableData.length})`} value="4" disableRipple />
                                    <Tab label={`Shortlisted (${allApplicants.shortlistedData.length})`} value="5" disableRipple />
                                </TabList>
                            </Container>
                        </Box>

                        <Box className="admin-active-jobs-wrapper">
                            <Box className="admin-active-jobs-content">
                                <TabPanel value="1" sx={{ padding: '20px' }}>

                                    <Box className="admin-filter-search active-jobs-list">
                                        {/* <FilterSearch/> */}
                                    </Box>
                                    {loading ? (
                                        <Stack direction="column" spacing={3}>
                                            <Skeleton height={40} width="50%" />
                                            <Skeleton height={40} width="100%" />
                                        </Stack>
                                    ) : (
                                        <Box className="all-tabs-content">
                                            {allApplicants?.allData?.map((item, index) => (
                                                <Box key={item?._id || index} className="admin-dash-det-card card-wrapper" mb={3}>
                                                    <Box className="card-content">
                                                        <Box className="badge best-fit">
                                                            <Typography>Best Fit</Typography>
                                                        </Box>

                                                        <Box sx={{ width: '100%' }}>
                                                            <Stack direction="row" alignItems={'center'} justifyContent={'space-between'}>
                                                                <Box>
                                                                    <Stack direction="row" alignItems={'center'} spacing={1}>
                                                                        <Typography className="candidate-name">{item?.candidate_profile?.full_name}</Typography>
                                                                        <IconButton className="arrow-btn" sx={{ padding: 0 }}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                                <path d="M5.96655 13.2797L10.3132 8.93306C10.8266 8.41973 10.8266 7.57973 10.3132 7.06639L5.96655 2.71973" stroke="#1575C6" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                                            </svg>
                                                                        </IconButton>
                                                                    </Stack>
                                                                </Box>

                                                                <Box>
                                                                    <FormControl sx={{ minWidth: 120 }} className="select-status-dp-menu" size="small">
                                                                        <InputLabel id="demo-select-small-label">Select Status</InputLabel>
                                                                        <Select
                                                                            labelId="demo-select-small-label"
                                                                            id="demo-select-small"
                                                                            value={item?.status}
                                                                            label="Select Status"
                                                                            onChange={(e) => { handleStatusChange(e, item?._id); }}
                                                                        >
                                                                            <MenuItem value="shortlisted" className="select-status-menu-item">Shortlisted</MenuItem>
                                                                            <MenuItem value="maybe" className="select-status-menu-item">May Be</MenuItem>
                                                                            <MenuItem value="rejected" className="select-status-menu-item">Not Suitable</MenuItem>
                                                                            <MenuItem value="applied" className="select-status-menu-item">Unrated</MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </Box>
                                                            </Stack>
                                                        </Box>

                                                        <Box mb={1} className="candi-profile-det">
                                                            <Stack direction="row" alignItems={'center'} flexWrap={'wrap'} spacing={1}>
                                                                <Typography>{item?.requirements_matched?.education[item?.requirements_matched?.education?.length - 1]?.degree_received}, <Typography variant="caption" sx={{ fontWeight: 700 }}>{item?.requirements_matched?.education[0]?.education_institute_name} ({item?.requirements_matched?.education[0]?.education_from?.slice(0, 4)} - {item?.requirements_matched?.education[0]?.education_to?.slice(0, 4)}) </Typography>{item?.requirements_matched?.education?.length > 1 && (<Typography variant="caption" sx={{ color: '#1575C6', textDecoration: 'underline' }}>+{item?.requirements_matched?.education?.length - 1}</Typography>)}</Typography>
                                                                <Divider orientation="vertical" variant="middle" flexItem />
                                                                {workHistory(item?.requirements_matched?.work_history)}

                                                            </Stack>
                                                        </Box>
                                                    </Box>

                                                    <Box className="card-content-bottom">
                                                        <Grid container spacing={2} columns={10}>
                                                            <Grid item xs={2}>
                                                                <Stack spacing={0.3}>
                                                                    <Typography className="text-title">Notice Period</Typography>
                                                                    <Typography className="text-info" sx={{ color: '#111827!important' }}>{item?.requirements_matched?.notice_period}</Typography>
                                                                </Stack>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Stack spacing={0.3}>
                                                                    <Typography className="text-title">Current Comp</Typography>
                                                                    <Typography className="text-info" sx={{ color: '#111827!important' }}>₹{item?.requirements?.salary||0}</Typography>
                                                                </Stack>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Stack spacing={0.3}>
                                                                    <Typography className="text-title">Requirements</Typography>
                                                                    <Typography className="text-info">
                                                                        <Typography variant="caption" sx={{ textDecorationColor: '#1575C6' }}>
                                                                            <Typography variant="caption" sx={{ textDecoration: 'underline', textDecorationColor: '#1575C6' }}>
                                                                                <Typography variant="caption" sx={{ color: '#111827', fontSize: '14px', fontWeight: 600 }}>{handleRequirement(item?.candidate_score)}</Typography>
                                                                                /4 </Typography>
                                                                        </Typography>
                                                                        Match</Typography>
                                                                </Stack>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Stack spacing={0.3}>
                                                                    <Typography className="text-title">Skills</Typography>
                                                                    <Typography className="text-info">
                                                                        <Typography variant="caption" sx={{ textDecorationColor: '#1575C6' }}>
                                                                            <Typography variant="caption" sx={{ textDecoration: 'underline', textDecorationColor: '#1575C6' }}>
                                                                                <Typography variant="caption" sx={{ color: '#111827', fontSize: '14px', fontWeight: 600 }}>{item?.requirements_matched?.matching_skills?.length}</Typography>
                                                                                /{item?.requirements?.matching_skills?.length} </Typography>
                                                                        </Typography>
                                                                        Match</Typography>
                                                                </Stack>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Stack spacing={0.3}>
                                                                    <Typography className="text-title">Preferences</Typography>
                                                                    <Typography className="text-info">
                                                                        <Typography variant="caption" sx={{ textDecorationColor: '#1575C6' }}>
                                                                            <Typography variant="caption" sx={{ textDecoration: 'underline', textDecorationColor: '#1575C6' }}>
                                                                                <Typography variant="caption" sx={{ color: '#111827', fontSize: '14px', fontWeight: 600 }}>{handlePreference(item?.candidate_score)}</Typography>
                                                                                /6 </Typography>
                                                                        </Typography>
                                                                        Match</Typography>
                                                                </Stack>
                                                            </Grid>
                                                        </Grid>
                                                        {item?.ratings && item.ratings?.jobStability|| item.ratings.financeUnderstanding|| item.ratings.communication|| item.ratings.careerProgression||  item.ratings.behavioralSkills ||item.ratings.overallRating ? (
                                                        <Box className="view-add-feedback">
                                                            <Stack direction="row" alignItems={'center'} spacing={1}>
                                                                <Typography>{item?.updatedAt ? getTimeDifferenceString(item?.updatedAt, "Last feedback captured") : "NA"}</Typography>
                                                                <Rating name="read-only" value={calculateAverageRating(item?.ratings)} readOnly emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />} />
                                                                <Link href="#" underline="none" className="feedback-link" onClick={toggleCandiateProfileDrawer(true,item)}>Update Feedback</Link>
                                                            </Stack>
                                                        </Box>
                                                        ) : (
                                                        <Box className="view-add-feedback">
                                                            <Stack direction="row" alignItems={'center'} spacing={1}>
                                                                <Typography>No feedback captured yet</Typography>
                                                                <Link href="#" underline="none" className="feedback-link" onClick={toggleCandiateProfileDrawer(true, item)}>Add Feedback</Link>
                                                            </Stack>
                                                        </Box>
                                                        )}
                                                    </Box>
                                                </Box>
                                            ))}
                                        </Box>)}
                                </TabPanel>

                                <TabPanel value="2" sx={{ padding: '20px' }}>
                                    <Box className="admin-filter-search active-jobs-list">
                                        {/* <FilterSearch /> */}
                                    </Box>
                                    {loading ? (
                                        <Stack direction="column" spacing={3}>
                                            <Skeleton height={40} width="50%" />
                                            <Skeleton height={40} width="100%" />
                                        </Stack>
                                    ) : (
                                        <Box className="unrated-tabs-content">
                                            {allApplicants?.unratedData.map((item, index) => (
                                                <Box key={item._id || index} className="admin-dash-det-card card-wrapper" mb={3}>
                                                    <Box className="card-content">
                                                        <Box className="badge best-fit">
                                                            <Typography>Best Fit</Typography>
                                                        </Box>

                                                        <Box sx={{ width: '100%' }}>
                                                            <Stack direction="row" alignItems={'center'} justifyContent={'space-between'}>
                                                                <Box>
                                                                    <Stack direction="row" alignItems={'center'} spacing={1}>
                                                                        <Typography className="candidate-name">{item?.candidate_profile?.full_name}</Typography>
                                                                        <IconButton className="arrow-btn" sx={{ padding: 0 }}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                                <path d="M5.96655 13.2797L10.3132 8.93306C10.8266 8.41973 10.8266 7.57973 10.3132 7.06639L5.96655 2.71973" stroke="#1575C6" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                                            </svg>
                                                                        </IconButton>
                                                                    </Stack>
                                                                </Box>

                                                                <Box>
                                                                    <FormControl sx={{ minWidth: 120 }} className="select-status-dp-menu" size="small">
                                                                        <InputLabel id="demo-select-small-label">Select Status</InputLabel>
                                                                        <Select
                                                                            labelId="demo-select-small-label"
                                                                            id="demo-select-small"
                                                                            value={item?.status}
                                                                            label="Select Status"
                                                                            onChange={(e) => { handleStatusChange(e, item?._id); }}
                                                                        >
                                                                            <MenuItem value="shortlisted" className="select-status-menu-item">Shortlisted</MenuItem>
                                                                            <MenuItem value="maybe" className="select-status-menu-item">May Be</MenuItem>
                                                                            <MenuItem value="rejected" className="select-status-menu-item">Not Suitable</MenuItem>
                                                                            <MenuItem value="applied" className="select-status-menu-item">Unrated</MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </Box>
                                                            </Stack>
                                                        </Box>

                                                        <Box mb={1} className="candi-profile-det">
                                                            <Stack direction="row" alignItems={'center'} flexWrap={'wrap'} spacing={1}>
                                                                <Typography>{item?.requirements_matched?.education[item?.requirements_matched?.education?.length - 1]?.degree_received}, <Typography variant="caption" sx={{ fontWeight: 700 }}>{item?.requirements_matched?.education[0]?.education_institute_name} ({item?.requirements_matched?.education[0]?.education_from?.slice(0, 4)} - {item?.requirements_matched?.education[0]?.education_to?.slice(0, 4)})</Typography>{item?.requirements_matched?.education?.length > 1 && (<Typography variant="caption" sx={{ color: '#1575C6', textDecoration: 'underline' }}>+{item?.requirements_matched?.education?.length - 1}</Typography>)}</Typography>
                                                                <Divider orientation="vertical" variant="middle" flexItem />
                                                                {workHistory(item?.requirements_matched?.work_history)}
                                                            </Stack>
                                                        </Box>
                                                    </Box>

                                                    <Box className="card-content-bottom">
                                                        <Grid container spacing={2} columns={10}>
                                                            <Grid item xs={2}>
                                                                <Stack spacing={0.3}>
                                                                    <Typography className="text-title">Notice Period</Typography>
                                                                    <Typography className="text-info" sx={{ color: '#111827!important' }}>{item?.requirements_matched?.notice_period}</Typography>
                                                                </Stack>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Stack spacing={0.3}>
                                                                    <Typography className="text-title">Current Comp</Typography>
                                                                    <Typography className="text-info" sx={{ color: '#111827!important' }}>₹{item?.requirements?.salary||0}</Typography>
                                                                </Stack>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Stack spacing={0.3}>
                                                                    <Typography className="text-title">Requirements</Typography>
                                                                    <Typography className="text-info">
                                                                        <Typography variant="caption" sx={{ textDecorationColor: '#1575C6' }}>
                                                                            <Typography variant="caption" sx={{ textDecoration: 'underline', textDecorationColor: '#1575C6' }}>
                                                                                <Typography variant="caption" sx={{ color: '#111827', fontSize: '14px', fontWeight: 600 }}>{handleRequirement(item?.candidate_score)}</Typography>
                                                                                /4 </Typography>
                                                                        </Typography>
                                                                        Match</Typography>
                                                                </Stack>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Stack spacing={0.3}>
                                                                    <Typography className="text-title">Skills</Typography>
                                                                    <Typography className="text-info">
                                                                        <Typography variant="caption" sx={{ textDecorationColor: '#1575C6' }}>
                                                                            <Typography variant="caption" sx={{ textDecoration: 'underline', textDecorationColor: '#1575C6' }}>
                                                                                <Typography variant="caption" sx={{ color: '#111827', fontSize: '14px', fontWeight: 600 }}>{item?.requirements_matched?.matching_skills?.length}</Typography>
                                                                                /{item?.requirements?.matching_skills?.length} </Typography>
                                                                        </Typography>
                                                                        Match</Typography>
                                                                </Stack>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Stack spacing={0.3}>
                                                                    <Typography className="text-title">Preferences</Typography>
                                                                    <Typography className="text-info">
                                                                        <Typography variant="caption" sx={{ textDecorationColor: '#1575C6' }}>
                                                                            <Typography variant="caption" sx={{ textDecoration: 'underline', textDecorationColor: '#1575C6' }}>
                                                                                <Typography variant="caption" sx={{ color: '#111827', fontSize: '14px', fontWeight: 600 }}>{handlePreference(item?.candidate_score)}</Typography>
                                                                                /6 </Typography>
                                                                        </Typography>
                                                                        Match</Typography>
                                                                </Stack>
                                                            </Grid>
                                                        </Grid>
                                                        {item?.ratings && item.ratings?.jobStability|| item.ratings.financeUnderstanding|| item.ratings.communication|| item.ratings.careerProgression||  item.ratings.behavioralSkills ||item.ratings.overallRating ? (
                                                        <Box className="view-add-feedback">
                                                            <Stack direction="row" alignItems={'center'} spacing={1}>
                                                                <Typography>{item?.updatedAt ? getTimeDifferenceString(item?.updatedAt, "Last feedback captured") : "NA"}</Typography>
                                                                <Rating name="read-only" value={calculateAverageRating(item?.ratings)} readOnly emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />} />
                                                                <Link href="#" underline="none" className="feedback-link" onClick={toggleCandiateProfileDrawer(true,item)}>Update Feedback</Link>
                                                            </Stack>
                                                        </Box>
                                                        ) : (
                                                        <Box className="view-add-feedback">
                                                            <Stack direction="row" alignItems={'center'} spacing={1}>
                                                                <Typography>No feedback captured yet</Typography>
                                                                <Link href="#" underline="none" className="feedback-link" onClick={toggleCandiateProfileDrawer(true, item)}>Add Feedback</Link>
                                                            </Stack>
                                                        </Box>
                                                        )}
                                                    </Box>
                                                </Box>
                                            ))}

                                        </Box>)}
                                </TabPanel>

                                <TabPanel value="3" sx={{ padding: '20px' }}>
                                    <Box className="admin-filter-search active-jobs-list">
                                        {/* <FilterSearch /> */}
                                    </Box>
                                    {loading ? (
                                        <Stack direction="column" spacing={3}>
                                            <Skeleton height={40} width="50%" />
                                            <Skeleton height={40} width="100%" />
                                        </Stack>
                                    ) : (
                                        <Box className="maybe-tabs-content">
                                            {allApplicants?.maybeData?.map((item, index) => (
                                                <Box key={item._id || index} className="admin-dash-det-card card-wrapper" mb={3}>
                                                    <Box className="card-content">
                                                        <Box className="badge best-fit">
                                                            <Typography>Best Fit</Typography>
                                                        </Box>

                                                        <Box sx={{ width: '100%' }}>
                                                            <Stack direction="row" alignItems={'center'} justifyContent={'space-between'}>
                                                                <Box>
                                                                    <Stack direction="row" alignItems={'center'} spacing={1}>
                                                                        <Typography className="candidate-name">{item?.candidate_profile?.full_name}</Typography>
                                                                        <IconButton className="arrow-btn" sx={{ padding: 0 }}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                                <path d="M5.96655 13.2797L10.3132 8.93306C10.8266 8.41973 10.8266 7.57973 10.3132 7.06639L5.96655 2.71973" stroke="#1575C6" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                                            </svg>
                                                                        </IconButton>
                                                                    </Stack>
                                                                </Box>

                                                                <Box>
                                                                    <FormControl sx={{ minWidth: 120 }} className="select-status-dp-menu" size="small">
                                                                        <InputLabel id="demo-select-small-label">Select Status</InputLabel>
                                                                        <Select
                                                                            labelId="demo-select-small-label"
                                                                            id="demo-select-small"
                                                                            value={item?.status}
                                                                            label="Select Status"
                                                                            onChange={(e) => { handleStatusChange(e, item?._id); }}
                                                                        >
                                                                            <MenuItem value="shortlisted" className="select-status-menu-item">Shortlisted</MenuItem>
                                                                            <MenuItem value="maybe" className="select-status-menu-item">May Be</MenuItem>
                                                                            <MenuItem value="rejected" className="select-status-menu-item">Not Suitable</MenuItem>
                                                                            <MenuItem value="applied" className="select-status-menu-item">Unrated</MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </Box>
                                                            </Stack>
                                                        </Box>

                                                        <Box mb={1} className="candi-profile-det">
                                                            <Stack direction="row" alignItems={'center'} flexWrap={'wrap'} spacing={1}>
                                                                <Typography>{item?.requirements_matched?.education[item?.requirements_matched?.education?.length - 1]?.degree_received}, <Typography variant="caption" sx={{ fontWeight: 700 }}>{item?.requirements_matched?.education[0]?.education_institute_name}({item?.requirements_matched?.education[0]?.education_from?.slice(0, 4)} - {item?.requirements_matched?.education[0]?.education_to?.slice(0, 4)})</Typography>{item?.requirements_matched?.education?.length > 1 && (<Typography variant="caption" sx={{ color: '#1575C6', textDecoration: 'underline' }}>+{item?.requirements_matched?.education?.length - 1}</Typography>)}</Typography>
                                                                <Divider orientation="vertical" variant="middle" flexItem />
                                                                {workHistory(item?.requirements_matched?.work_history)}

                                                            </Stack>
                                                        </Box>
                                                    </Box>

                                                    <Box className="card-content-bottom">
                                                        <Grid container spacing={2} columns={10}>
                                                            <Grid item xs={2}>
                                                                <Stack spacing={0.3}>
                                                                    <Typography className="text-title">Notice Period</Typography>
                                                                    <Typography className="text-info" sx={{ color: '#111827!important' }}>{item?.requirements_matched?.notice_period}</Typography>
                                                                </Stack>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Stack spacing={0.3}>
                                                                    <Typography className="text-title">Current Comp</Typography>
                                                                    <Typography className="text-info" sx={{ color: '#111827!important' }}>₹{item?.requirements?.salary||0}</Typography>
                                                                </Stack>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Stack spacing={0.3}>
                                                                    <Typography className="text-title">Requirements</Typography>
                                                                    <Typography className="text-info">
                                                                        <Typography variant="caption" sx={{ textDecorationColor: '#1575C6' }}>
                                                                            <Typography variant="caption" sx={{ textDecoration: 'underline', textDecorationColor: '#1575C6' }}>
                                                                                <Typography variant="caption" sx={{ color: '#111827', fontSize: '14px', fontWeight: 600 }}>{handleRequirement(item?.candidate_score)}</Typography>
                                                                                /4 </Typography>
                                                                        </Typography>
                                                                        Match</Typography>
                                                                </Stack>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Stack spacing={0.3}>
                                                                    <Typography className="text-title">Skills</Typography>
                                                                    <Typography className="text-info">
                                                                        <Typography variant="caption" sx={{ textDecorationColor: '#1575C6' }}>
                                                                            <Typography variant="caption" sx={{ textDecoration: 'underline', textDecorationColor: '#1575C6' }}>
                                                                                <Typography variant="caption" sx={{ color: '#111827', fontSize: '14px', fontWeight: 600 }}>{item?.requirements_matched?.matching_skills?.length} </Typography>
                                                                                /{item?.requirements?.matching_skills?.length}  </Typography>
                                                                        </Typography>
                                                                        Match</Typography>
                                                                </Stack>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Stack spacing={0.3}>
                                                                    <Typography className="text-title">Preferences</Typography>
                                                                    <Typography className="text-info">
                                                                        <Typography variant="caption" sx={{ textDecorationColor: '#1575C6' }}>
                                                                            <Typography variant="caption" sx={{ textDecoration: 'underline', textDecorationColor: '#1575C6' }}>
                                                                                <Typography variant="caption" sx={{ color: '#111827', fontSize: '14px', fontWeight: 600 }}>{handlePreference(item?.candidate_score)} </Typography>
                                                                                /6 </Typography>
                                                                        </Typography>
                                                                        Match</Typography>
                                                                </Stack>
                                                            </Grid>
                                                        </Grid>
                                                        {item?.ratings && item.ratings?.jobStability|| item.ratings.financeUnderstanding|| item.ratings.communication|| item.ratings.careerProgression||  item.ratings.behavioralSkills ||item.ratings.overallRating ? (
                                                        <Box className="view-add-feedback">
                                                            <Stack direction="row" alignItems={'center'} spacing={1}>
                                                                <Typography>{item?.updatedAt ? getTimeDifferenceString(item?.updatedAt, "Last feedback captured") : "NA"}</Typography>
                                                                <Rating name="read-only" value={calculateAverageRating(item?.ratings)} readOnly emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />} />
                                                                <Link href="#" underline="none" className="feedback-link" onClick={toggleCandiateProfileDrawer(true,item)}>Update Feedback</Link>
                                                            </Stack>
                                                        </Box>
                                                        ) : (
                                                        <Box className="view-add-feedback">
                                                            <Stack direction="row" alignItems={'center'} spacing={1}>
                                                                <Typography>No feedback captured yet</Typography>
                                                                <Link href="#" underline="none" className="feedback-link" onClick={toggleCandiateProfileDrawer(true, item)}>Add Feedback</Link>
                                                            </Stack>
                                                        </Box>
                                                        )}
                                                    </Box>
                                                </Box>
                                            ))}
                                        </Box>)}
                                </TabPanel>

                                <TabPanel value="4" sx={{ padding: '20px' }}>
                                    <Box className="admin-filter-search active-jobs-list">
                                        {/* <FilterSearch /> */}
                                    </Box>
                                    {loading ? (
                                        <Stack direction="column" spacing={3}>
                                            <Skeleton height={40} width="50%" />
                                            <Skeleton height={40} width="100%" />
                                        </Stack>
                                    ) : (
                                        <Box className="not-suitable-tabs-content">
                                            {allApplicants?.notSutableData?.map((item, index) => (
                                                <Box key={item._id || index} className="admin-dash-det-card card-wrapper" mb={3}>
                                                    <Box className="card-content">
                                                        <Box className="badge not-suitable">
                                                            <Typography>Not Suitable</Typography>
                                                        </Box>

                                                        <Box sx={{ width: '100%' }}>
                                                            <Stack direction="row" alignItems={'center'} justifyContent={'space-between'}>
                                                                <Box>
                                                                    <Stack direction="row" alignItems={'center'} spacing={1}>
                                                                        <Typography className="candidate-name">{item?.candidate_profile?.full_name}</Typography>
                                                                        <IconButton className="arrow-btn" sx={{ padding: 0 }}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                                <path d="M5.96655 13.2797L10.3132 8.93306C10.8266 8.41973 10.8266 7.57973 10.3132 7.06639L5.96655 2.71973" stroke="#1575C6" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                                            </svg>
                                                                        </IconButton>
                                                                    </Stack>
                                                                </Box>

                                                                <Box>
                                                                    <FormControl sx={{ minWidth: 120 }} className="select-status-dp-menu" size="small">
                                                                        <InputLabel id="demo-select-small-label">Select Status</InputLabel>
                                                                        <Select
                                                                            labelId="demo-select-small-label"
                                                                            id="demo-select-small"
                                                                            value={item?.status}
                                                                            label="Select Status"
                                                                            onChange={(e) => { handleStatusChange(e, item?._id); }}
                                                                        >
                                                                            <MenuItem value="shortlisted" className="select-status-menu-item">Shortlisted</MenuItem>
                                                                            <MenuItem value="maybe" className="select-status-menu-item">May Be</MenuItem>
                                                                            <MenuItem value="rejected" className="select-status-menu-item">Not Suitable</MenuItem>
                                                                            <MenuItem value="applied" className="select-status-menu-item">Unrated</MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </Box>
                                                            </Stack>
                                                        </Box>

                                                        <Box mb={1} className="candi-profile-det">
                                                            <Stack direction="row" alignItems={'center'} flexWrap={'wrap'} spacing={1}>
                                                                <Typography>{item?.requirements_matched?.education[item?.requirements_matched?.education?.length - 1]?.degree_received},<Typography variant="caption" sx={{ fontWeight: 700 }}>{item?.requirements_matched?.education[0]?.education_institute_name}({item?.requirements_matched?.education[0]?.education_from?.slice(0, 4)} - {item?.requirements_matched?.education[0]?.education_to?.slice(0, 4)})</Typography>{item?.requirements_matched?.education?.length > 1 && (<Typography variant="caption" sx={{ color: '#1575C6', textDecoration: 'underline' }}>+{item?.requirements_matched?.education?.length - 1}</Typography>)}</Typography>
                                                                <Divider orientation="vertical" variant="middle" flexItem />
                                                                {workHistory(item?.requirements_matched?.work_history)}

                                                            </Stack>
                                                        </Box>
                                                    </Box>

                                                    <Box className="card-content-bottom">
                                                        <Grid container spacing={2} columns={10}>
                                                            <Grid item xs={2}>
                                                                <Stack spacing={0.3}>
                                                                    <Typography className="text-title">Notice Period</Typography>
                                                                    <Typography className="text-info" sx={{ color: '#111827!important' }}>{item?.requirements_matched?.notice_period}</Typography>
                                                                </Stack>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Stack spacing={0.3}>
                                                                    <Typography className="text-title">Current Comp</Typography>
                                                                    <Typography className="text-info" sx={{ color: '#111827!important' }}>₹{item?.requirements?.salary||0}</Typography>
                                                                </Stack>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Stack spacing={0.3}>
                                                                    <Typography className="text-title">Requirements</Typography>
                                                                    <Typography className="text-info">
                                                                        <Typography variant="caption" sx={{ textDecorationColor: '#1575C6' }}>
                                                                            <Typography variant="caption" sx={{ textDecoration: 'underline', textDecorationColor: '#1575C6' }}>
                                                                                <Typography variant="caption" sx={{ color: '#111827', fontSize: '14px', fontWeight: 600 }}>{handleRequirement(item?.candidate_score)}</Typography>
                                                                                /4 </Typography>
                                                                        </Typography>
                                                                        Match</Typography>
                                                                </Stack>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Stack spacing={0.3}>
                                                                    <Typography className="text-title">Skills</Typography>
                                                                    <Typography className="text-info">
                                                                        <Typography variant="caption" sx={{ textDecorationColor: '#1575C6' }}>
                                                                            <Typography variant="caption" sx={{ textDecoration: 'underline', textDecorationColor: '#1575C6' }}>
                                                                                <Typography variant="caption" sx={{ color: '#111827', fontSize: '14px', fontWeight: 600 }}>{item?.requirements_matched?.matching_skills?.length}</Typography>
                                                                                /{item?.requirements?.matching_skills?.length}</Typography>
                                                                        </Typography>
                                                                        Match</Typography>
                                                                </Stack>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Stack spacing={0.3}>
                                                                    <Typography className="text-title">Preferences</Typography>
                                                                    <Typography className="text-info">
                                                                        <Typography variant="caption" sx={{ textDecorationColor: '#1575C6' }}>
                                                                            <Typography variant="caption" sx={{ textDecoration: 'underline', textDecorationColor: '#1575C6' }}>
                                                                                <Typography variant="caption" sx={{ color: '#111827', fontSize: '14px', fontWeight: 600 }}>{handlePreference(item?.candidate_score)}</Typography>
                                                                                /6 </Typography>
                                                                        </Typography>
                                                                        Match</Typography>
                                                                </Stack>
                                                            </Grid>
                                                        </Grid>
                                                        {item?.ratings && item.ratings?.jobStability|| item.ratings.financeUnderstanding|| item.ratings.communication|| item.ratings.careerProgression||  item.ratings.behavioralSkills ||item.ratings.overallRating ? (
                                                        <Box className="view-add-feedback">
                                                            <Stack direction="row" alignItems={'center'} spacing={1}>
                                                                <Typography>{item?.updatedAt ? getTimeDifferenceString(item?.updatedAt, "Last feedback captured") : "NA"}</Typography>
                                                                <Rating name="read-only" value={calculateAverageRating(item?.ratings)} readOnly emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />} />
                                                                <Link href="#" underline="none" className="feedback-link" onClick={toggleCandiateProfileDrawer(true,item)}>Update Feedback</Link>
                                                            </Stack>
                                                        </Box>
                                                        ) : (
                                                        <Box className="view-add-feedback">
                                                            <Stack direction="row" alignItems={'center'} spacing={1}>
                                                                <Typography>No feedback captured yet</Typography>
                                                                <Link href="#" underline="none" className="feedback-link" onClick={toggleCandiateProfileDrawer(true, item)}>Add Feedback</Link>
                                                            </Stack>
                                                        </Box>
                                                        )}
                                                    </Box>
                                                </Box>
                                            ))}

                                        </Box>)}
                                </TabPanel>

                                <TabPanel value="5" sx={{ padding: '20px' }}>
                                    <Box className="shortlisted-tab-header" mb={3}>
                                        <Stack direction="row" alignItems={'center'} justifyContent={'space-between'}>
                                            <Typography>Please select the profiles you wish to download and share with company</Typography>
                                            <Button variant="contained" size="small" onClick={() => downloadSelectedCandidatesData(jobDetails?._id)} disableElevation>Download Candidate Profiles ({selectedApplicants[jobDetails?._id]?.length || 0})</Button>
                                        </Stack>
                                    </Box>
                                    {loading ? (
                                        <Stack direction="column" spacing={3}>
                                            <Skeleton height={40} width="50%" />
                                            <Skeleton height={40} width="100%" />
                                        </Stack>
                                    ) : (
                                        <Box className="shortlisted-jobs-tabs-content">
                                            {allApplicants?.shortlistedData.map((item, index) => (
                                                <Box key={item._id || index} className="admin-dash-det-card card-wrapper" mb={3}>
                                                    <Box className="card-content">
                                                        <Box className="badge best-fit">
                                                            <Typography>Best Fit</Typography>
                                                        </Box>

                                                        <Box sx={{ width: '100%' }}>
                                                            <Stack direction="row" alignItems={'center'} justifyContent={'space-between'}>
                                                                <Box>
                                                                    <Stack direction="row" alignItems={'center'} spacing={1}>
                                                                        {/* <BpCheckbox  {...label} sx={{ padding: 0 }} checked={selectedApplicants.includes(item?._id)} onChange={(event) => handleCheckboxChange(event, item?._id)} /> */}
                                                                        <BpCheckbox
                                                                            {...label}
                                                                            sx={{ padding: 0 }}
                                                                            checked={(selectedApplicants[jobDetails?._id] || []).includes(item?._id)} // ✅ Correct way to check selection
                                                                            onChange={(event) => handleCheckboxChange(event, jobDetails?._id, item?._id)}
                                                                        />

                                                                        <Typography className="candidate-name">{item?.candidate_profile?.full_name}</Typography>
                                                                        <IconButton className="arrow-btn" sx={{ padding: 0 }}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                                <path d="M5.96655 13.2797L10.3132 8.93306C10.8266 8.41973 10.8266 7.57973 10.3132 7.06639L5.96655 2.71973" stroke="#1575C6" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                                            </svg>
                                                                        </IconButton>
                                                                    </Stack>
                                                                </Box>

                                                                <Box>
                                                                    <Stack direction="row" alignItems={'center'} spacing={1.5}>
                                                                        <FormControl sx={{ minWidth: 120 }} className="select-status-dp-menu" size="small">
                                                                            <InputLabel id="demo-select-small-label">Select Status</InputLabel>
                                                                            <Select
                                                                                labelId="demo-select-small-label"
                                                                                id="demo-select-small"
                                                                                value={item?.status}
                                                                                label="Select Status"
                                                                                onChange={(e) => { handleStatusChange(e, item?._id); }}
                                                                            >
                                                                                <MenuItem value="shortlisted" className="select-status-menu-item">Shortlisted</MenuItem>
                                                                                <MenuItem value="maybe" className="select-status-menu-item">May Be</MenuItem>
                                                                                <MenuItem value="rejected" className="select-status-menu-item">Not Suitable</MenuItem>
                                                                                <MenuItem value="applied" className="select-status-menu-item">Unrated</MenuItem>
                                                                            </Select>
                                                                        </FormControl>
                                                                    </Stack>
                                                                </Box>
                                                            </Stack>
                                                        </Box>

                                                        <Box mb={1} className="candi-profile-det">
                                                            <Stack direction="row" alignItems={'center'} flexWrap={'wrap'} spacing={1}>
                                                                <Typography>{item?.requirements_matched?.education[item?.requirements_matched?.education?.length - 1]?.degree_received},<Typography variant="caption" sx={{ fontWeight: 700 }}>{item?.requirements_matched?.education[0]?.education_institute_name}({item?.requirements_matched?.education[0]?.education_from?.slice(0, 4)} - {item?.requirements_matched?.education[0]?.education_to?.slice(0, 4)}) </Typography>{item?.requirements_matched?.education?.length > 1 && (<Typography variant="caption" sx={{ color: '#1575C6', textDecoration: 'underline' }}>+{item?.requirements_matched?.education?.length - 1}</Typography>)}</Typography>
                                                                <Divider orientation="vertical" variant="middle" flexItem />
                                                                {workHistory(item?.requirements_matched?.work_history)}

                                                            </Stack>
                                                        </Box>
                                                    </Box>

                                                    <Box className="card-content-bottom">
                                                        <Grid container spacing={2} columns={10}>
                                                            <Grid item xs={2}>
                                                                <Stack spacing={0.3}>
                                                                    <Typography className="text-title">Notice Period</Typography>
                                                                    <Typography className="text-info" sx={{ color: '#111827!important' }}>{item?.requirements_matched?.notice_period}</Typography>
                                                                </Stack>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Stack spacing={0.3}>
                                                                    <Typography className="text-title">Current Comp</Typography>
                                                                    <Typography className="text-info" sx={{ color: '#111827!important' }}>₹{item?.requirements?.salary||0}</Typography>
                                                                </Stack>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Stack spacing={0.3}>
                                                                    <Typography className="text-title">Requirements</Typography>
                                                                    <Typography className="text-info">
                                                                        <Typography variant="caption" sx={{ textDecorationColor: '#1575C6' }}>
                                                                            <Typography variant="caption" sx={{ textDecoration: 'underline', textDecorationColor: '#1575C6' }}>
                                                                                <Typography variant="caption" sx={{ color: '#111827', fontSize: '14px', fontWeight: 600 }}>{handleRequirement(item?.candidate_score)}</Typography>
                                                                                /4 </Typography>
                                                                        </Typography>
                                                                        Match</Typography>
                                                                </Stack>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Stack spacing={0.3}>
                                                                    <Typography className="text-title">Skills</Typography>
                                                                    <Typography className="text-info">
                                                                        <Typography variant="caption" sx={{ textDecorationColor: '#1575C6' }}>
                                                                            <Typography variant="caption" sx={{ textDecoration: 'underline', textDecorationColor: '#1575C6' }}>
                                                                                <Typography variant="caption" sx={{ color: '#111827', fontSize: '14px', fontWeight: 600 }}>{item?.requirements_matched?.matching_skills?.length}</Typography>
                                                                                /{item?.requirements?.matching_skills?.length}</Typography>
                                                                        </Typography>
                                                                        Match</Typography>
                                                                </Stack>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Stack spacing={0.3}>
                                                                    <Typography className="text-title">Preferences</Typography>
                                                                    <Typography className="text-info">
                                                                        <Typography variant="caption" sx={{ textDecorationColor: '#1575C6' }}>
                                                                            <Typography variant="caption" sx={{ textDecoration: 'underline', textDecorationColor: '#1575C6' }}>
                                                                                <Typography variant="caption" sx={{ color: '#111827', fontSize: '14px', fontWeight: 600 }}>{handlePreference(item?.candidate_score)} </Typography>
                                                                                /6 </Typography>
                                                                        </Typography>
                                                                        Match</Typography>
                                                                </Stack>
                                                            </Grid>
                                                        </Grid>
                                                        <Box className="view-add-feedback">
                                                            <Stack direction="row" alignItems={'center'} spacing={1}>
                                                                <Typography>{item?.updatedAt ? getTimeDifferenceString(item?.updatedAt, "Last feedback captured") : "NA"}</Typography>
                                                                <Rating name="read-only" value={calculateAverageRating(item?.ratings)} readOnly emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />} />
                                                                <Link href="#" underline="none" className="feedback-link" onClick={toggleCandiateProfileDrawer(true,item)}>Update Feedback</Link>
                                                            </Stack>
                                                        </Box>                                                      
                                                    </Box>
                                                </Box>
                                            ))}

                                        </Box>)}
                                </TabPanel>
                            </Box>
                        </Box>
                    </TabContext>
                )}
            </Box>
            <Drawer open={openCandiateProfileDrawer} anchor={'right'} onClose={toggleCandiateProfileDrawer(false)}>
                <CandidateProfileDrawer  jobDetails={jobDetails} selectedApplicantFeedback={selectedApplicantFeedback} onClose={toggleCandiateProfileDrawer(false)} updateRatings={updateRatings}/>
            </Drawer>

            {/* close job dialog */}
              <Dialog
                     open={openCloseDialog}
                     onClose={() => setOpenCloseDialog(false)}
                     aria-labelledby="alert-dialog-title"
                     aria-describedby="alert-dialog-description"
                     className="otp-dialog-wrapper"
                   ><Card className="otp-card-wrapper" variant="outlined">
                       <CardContent>
                         <IconButton
                           className="close-dialog-btn"
                           aria-label="delete"
                           color="primary"
                           onClick={() => setOpenCloseDialog(false)}
                         ><SvgIcon sx={{ width: '20px', height: '20px' }}>
                             <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                               <g clipPath="url(#clip0_456_20993)">
                                 <path
                                   d="M7 7L17 17M17 7L7 17L17 7Z"
                                   stroke="#111827"
                                   strokeWidth="1.5"
                                   strokeLinecap="round"
                                   strokeLinejoin="round"
                                 />
                               </g>
                               <defs>
                                 <clipPath id="clip0_456_20993"><rect width="24" height="24" fill="white" /></clipPath>
                               </defs>
                             </svg>
                           </SvgIcon>
                         </IconButton>
                         <Box mb={3} sx={{ textAlign: 'center' }}>
                           <Typography mb={1} className="title" component="h6">{selectedJob?.title}?</Typography>
                           <Typography sx={{ textAlign: 'center!important' }} className="otp-sent-txt" component="p">
                             Are you sure you want to close this {selectedJob?.title}?
                           </Typography>
                         </Box>
                         <Stack direction="row" alignItems="center" spacing={3}>
                           <Button fullWidth className="otp-btn" variant="outlined" size="medium" onClick={() => setOpenCloseDialog(false)}>
                             Cancel
                           </Button>
                           <Button
                             fullWidth
                             className="otp-btn"
                             variant="contained"
                             size="medium"
                             onClick={(e) => {
                               e.stopPropagation();
                               handleCloseJobStatus();
                             }}
                             disabled={isDuplicating}
                           >
                             {isDuplicating ? 'Closing...' : 'Close'}
                           </Button>
                         </Stack>
                       </CardContent>
                     </Card>
                   </Dialog>
            {/* Edit job details dialog */}
            <Dialog
                open={openEditJobDetails}
                onClose={handleCloseEditJobDetailsDialog}
                className="action-dialog-card reject-candi-dialog"
            >
                <Card variant="outlined">
                    <CardContent>
                        <Box >
                            <Stack spacing={0.5} mb={4}>
                                <Typography className="card-title" component="p">Edit Job Details</Typography>
                                <Typography className="card-info-text" component="p">Please note editing this job may impact candidature of applicants who have already applied. Suitable candidates might categorise as Not Suitable or vice verse.</Typography>
                                <Typography className="card-info-text" component="p">Sure you wish to go ahead and edit details?</Typography>
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <Button size="medium" onClick={handleCloseEditJobDetailsDialog}>Cancel</Button>
                                <Button variant="contained" size="medium" sx={{ backgroundColor: '#1F9CD7!important', padding: '12px 40px!important' }} onClick={(e) => {
                                    e.stopPropagation();
                                    handleEditButton(e, jobDetails?._id);
                                }}>Continue to Edit</Button>
                            </Stack>
                        </Box>
                    </CardContent>
                </Card>
            </Dialog>
        </Box>
    )
}
export default FullDetails
import { Box, IconButton, Stack, Typography, Button } from '@mui/material';
const backIcon = `${process.env.REACT_APP_HOMEPAGE}assets/back-arrow-white.png`;
import AddCertification from 'components/basic-profile/AddCertification';

const AddCertificationDrawer = ({
    onCertificateDataChange,
    certificateInstituteData,
    certificateTitleData,
    checkingOverlapping,
    onCancel,
    certificateData,
    id,
    selected,
    handleDeleteCertificate,
    handleArrowClick
}) => {

    return (
        <Box className="sidebar-wrapper">
            <Box className="sidebar-header">
                <Stack direction="row" justifyContent={'flex-start'} alignItems={'flex-start'} spacing={2}>
                    <IconButton aria-label="delete" onClick={() => handleArrowClick()}>
                        <img src={backIcon} alt="Back" width="20px" height="20px" loading="lazy" />
                    </IconButton>
                    <Stack direction="row" justifyContent={'space-between'} alignItems={'flex-start'} sx={{ minWidth: '100%' }}>
                        <Stack spacing={0.5}>
                            <Typography className="title">Add Certification</Typography>
                            <Typography className="sub-text">Select Certificate Received & Institute </Typography>
                        </Stack>
                        <IconButton className="delete-sec-btn" aria-label="delete" onClick={id ? (e) => { handleDeleteCertificate(selected._id, selected?.certificate_received, e) } : onCancel}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0948 2.37734C11.0948 2.22733 11.0353 2.08301 10.9289 1.97715C10.8231 1.87074 10.6787 1.81131 10.5287 1.81131H8.70671L8.44689 0.771503C8.33369 0.317545 7.92557 -0.000564375 7.45803 7.54076e-07H4.54298C4.07543 -0.000565244 3.66732 0.317545 3.55412 0.771503L3.29431 1.81131H1.47228C1.15983 1.81131 0.90625 2.06489 0.90625 2.37734C0.90625 2.68978 1.15983 2.94337 1.47228 2.94337H1.50171C1.46096 3.10186 1.44737 3.26658 1.46096 3.42959L2.14473 10.2668C2.24096 11.2523 3.0702 12.0027 4.06023 12H7.94092C8.93091 12.0028 9.7602 11.2523 9.85642 10.2668L10.5402 3.42901C10.5538 3.266 10.5396 3.10185 10.4994 2.94335H10.5289C10.6789 2.94335 10.8232 2.88392 10.9291 2.77751C11.0355 2.67166 11.0948 2.52733 11.0948 2.37734ZM4.63071 1.13206H7.3697L7.53951 1.8113H4.46087L4.63071 1.13206ZM9.32707 3.05494C9.3916 3.1257 9.4233 3.22135 9.41311 3.31702L8.72933 10.1542C8.69028 10.5601 8.34839 10.8691 7.94085 10.868H4.06015C3.65261 10.8691 3.31072 10.5601 3.27167 10.1542L2.58789 3.3176C2.57827 3.22194 2.6094 3.12684 2.67393 3.05552C2.73846 2.9842 2.82959 2.94345 2.92582 2.94345H9.07529C9.17151 2.94288 9.26255 2.98362 9.32707 3.05494ZM5.09484 4.52832C4.94483 4.52832 4.80051 4.58775 4.69465 4.69416C4.58824 4.80001 4.52881 4.94435 4.52881 5.09434V7.81131C4.52881 8.12375 4.78239 8.37734 5.09484 8.37734C5.40728 8.37734 5.66086 8.12375 5.66086 7.81131V5.09434C5.66086 4.94434 5.60143 4.80001 5.49502 4.69416C5.38917 4.58774 5.24483 4.52832 5.09484 4.52832ZM6.50599 4.69416C6.61185 4.58775 6.75617 4.52832 6.90618 4.52832C7.05617 4.52832 7.20051 4.58774 7.30636 4.69416C7.41277 4.80001 7.4722 4.94434 7.4722 5.09434V7.81131C7.4722 8.12375 7.21862 8.37734 6.90618 8.37734C6.59373 8.37734 6.34015 8.12375 6.34015 7.81131V5.09434C6.34015 4.94435 6.39958 4.80001 6.50599 4.69416Z" fill="#FF4949" />
                            </svg>
                        </IconButton>
                    </Stack>
                </Stack>
            </Box>
            <Box className="sidebar-content" mb={8}>
                <AddCertification
                    onCertificateDataChange={onCertificateDataChange}
                    certificateInstituteData={certificateInstituteData}
                    certificateTitleData={certificateTitleData}
                    checkingOverlapping={checkingOverlapping}
                    onCancel={onCancel}
                    certificateData={certificateData}
                    id={id}
                    handleArrowClick={handleArrowClick}
                />
            </Box>
            {/* <Box className="sidebar-footer"></Box> */}
        </Box>
    )
}

export default AddCertificationDrawer

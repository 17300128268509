import { Box, IconButton, Stack, Typography } from '@mui/material'
const backIcon = `${process.env.REACT_APP_HOMEPAGE}assets/back-arrow-white.png`
import JobsMarketsFullDetails from './JobsMarketsFullDetails'
import { update } from 'lodash'

const JobsMarketsFullDetailsMobile = ({ data, onApplied, savedJob, unsaveJob, handleChangeSaveOrUnsaveButton, handleSaveOrUnsaveJob, downloadResume, showExternalJobStatus, onJobPreferencesChange, updateTJScore, onClose }) => {
  return (
    <Box className="sidebar-wrapper job-details-mob-page">
      <Box className="sidebar-header">
        <Stack direction="row" justifyContent={'flex-start'} alignItems={'center'} spacing={2}>
          <IconButton aria-label="delete" sx={{ padding: 0 }}><img src={backIcon} alt="Back" width="20px" height="20px" loading="lazy" onClick={onClose} /></IconButton>
          <Typography className="title">Job Details</Typography>
        </Stack>
      </Box>
      <Box className="sidebar-content">
        <JobsMarketsFullDetails data={data} onApplied={onApplied} savedJob={savedJob} unsaveJob={unsaveJob} handleChangeSaveOrUnsaveButton={handleChangeSaveOrUnsaveButton} handleSaveOrUnsaveJob={handleSaveOrUnsaveJob} downloadResume={downloadResume} showExternalJobStatus={showExternalJobStatus} onJobPreferencesChange={onJobPreferencesChange} updateTJScore={updateTJScore} handleCloseDrawer={onClose} />
      </Box>
      {/* <Box className="sidebar-footer"></Box> */}
    </Box>
  )
}

export default JobsMarketsFullDetailsMobile

import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Stack,
  SvgIcon,
  ToggleButton,
  ToggleButtonGro,
  AppBar,
  Toolbarup,
  Typography,
  Drawer,
  ToggleButtonGroup, IconButton, useMediaQuery
} from '@mui/material';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { getApplicationApplyDetails } from 'api/application';
import ChatBotDrawer from './ChatBotDrawer';
import JopApplicationDrawer from './JopApplicationDrawer';
import UpdateReleventSkillsDrawer from './UpdateReleventSkillsDrawer';
import AutoSavingJobAppDialog from './AutoSavingJobAppDialog';
import UnsaveCard from './UnsaveCard';
import ExternalJob from './ExternalJob';
import ExternalJobStatus from './ExternalJobStatus';
// save application
import { useForm, Controller } from 'react-hook-form';
import { saveJobApplication } from 'api/application';
import { useSnackbar } from 'components/snackbar';
const JobNotMatchingIcon = `${process.env.REACT_APP_HOMEPAGE}assets/job-not-matching-icon.png`;
const ModSuitableIcon = `${process.env.REACT_APP_HOMEPAGE}assets/moderately-suitable-icon.png`;
const suitableIcon = `${process.env.REACT_APP_HOMEPAGE}assets/highly-suitable-icon.png`;
import { getTimeDifferenceString, changeNameFormat, formatNumber, toTitleCase } from 'utils/Utils';
import AddCircleIcon from '@mui/icons-material/AddCircle';
// import CloseIcon from '@mui/icons-material/Close';
// import CheckIcon from '@mui/icons-material/Check';
import { useAuth } from 'hooks/useAuthContext';
import { clusteredData } from 'data/city.js';
import StarIcon from '@mui/icons-material/Star';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { SuitableChip, ModerateChip, NotSuitableChip, CheckIcon, CrossIcon, WarningIcon } from '../icon/icon';
import { useTheme } from '@mui/material/styles';

const JobFullDetails = ({ data, onApplied, savedJob, handleChangeSaveOrUnsaveButton, handleSaveOrUnsaveJob, unsaveJob, downloadResume, showExternalJobStatus, onJobPreferencesChange, updateTJScore, handleCloseDrawer }) => {
  const { jobApply } = useAuth();

  const [saved, setSaved] = React.useState(false);
  const [alignment, setAlignment] = useState('job-overview');
  const [id, setId] = useState(data?._id || null);
  const [candidateDetails, setCandidateDetails] = useState({});
  const [resume, setResume] = useState({});
  const jobOverviewRef = useRef(null);
  const jobFullOverviewRef = useRef(null);
  const jobDescRef = useRef(null);
  const [requirementMatchItem] = useState([
    'Offered Compensation',
    'Job Location',
    'Work Format',
    'Job Type',
    'Working Hours',
    'Notice Period',
  ]);
  const [isMatch, setIsMatch] = useState(false);
  const [matchingSkills, setMatchingSkills] = useState([]);
  const [nonMatchingSkills, setNonMatchingSkills] = useState([]);
  const [dataSubmitted, setDataSubmitted] = useState(true);
  const { control, handleSubmit } = useForm();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [industrySector, setIndustrySector] = useState('');
  const [coreMatchSkills, setCoreMatchSkills] = useState([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [showMore, setShowMore] = useState(false);
  const isManualScroll = useRef(false);

  const fetchData = async (recommend = false) => {
    try {
      // console.log(recommend);
      // console.log(data, data?._id);
      const response = await getApplicationApplyDetails({ id: data?._id, recommend: recommend });
      if (response?.success && response?.data != null) {
        setCandidateDetails(response?.data);
        // console.log(data?.title);
        // console.log(response?.data?.aggregateScore);
        // console.log(response?.data?.rating);
        const TjScore = response?.data?.aggregateScore;
        if (recommend || (data.tj_score !== TjScore)) {
          updateTJScore(TjScore);
          data.tj_score = TjScore;
        }
        // console.log(response?.data?.user?.candidate);
        setResume({
          summary: response?.data?.user?.candidate?.summary,
          educationData: response?.data?.educations,
          certificateData: response?.data?.certificate,
          workExperienceData: response?.data?.workHistory,
          customSection: response?.data?.user?.candidate?.custom_section,
          financeTechnologiesData: response?.data?.user?.candidate?.finance_technologies,
        });
        setIndustrySector(isIndustrySectorMatched(response?.data?.workHistory, data?.industry_sector));
        setMatchingSkills(
          handleMatchingSkills(
            response?.data?.workHistory || [],
            response?.data?.certificate || [],
            response?.data?.educations || [],
            data?.key_skills || [],
            data?.core_skills || []
          )
        );
        if (data?.application_saved) {
          if (data?._id === unsaveJob) {
            handleOpenAutoUnsaveDialog();
          }
        } else {
          if (data?._id === savedJob) {
            handleSaveForLater();
          }
        }
        if (showExternalJobStatus === 'showExternalStatus') {
          handleExternalJobStatus();
        }
      } else {
        console.error('Failed to fetch job info:', response?.data?.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleMatchingSkills = (workHistory, certificate, education, jobSkills, coreSkills) => {
    if (!workHistory || !Array.isArray(workHistory)) {
      console.error('Invalid work history provided.');
      return [];
    }
    if (!jobSkills || !Array.isArray(jobSkills)) {
      console.error('Invalid job skills provided.');
      return [];
    }
    let candidateSkills = [];
    workHistory?.forEach((history) => {
      if (history?.skills && Array.isArray(history?.skills)) {
        candidateSkills.push(...history.skills.map((skill) => skill.toLowerCase()));
      } else {
        console.warn('Invalid or missing skills property in work history entry:', history);
      }
    });
    certificate?.forEach((history) => {
      if (history?.skills && Array.isArray(history?.skills)) {
        candidateSkills.push(...history.skills.map((skill) => skill.toLowerCase()));
      } else {
        console.warn('Invalid or missing skills property in work history entry:', history);
      }
    });
    education?.forEach((history) => {
      if (history?.skills && Array.isArray(history?.skills)) {
        candidateSkills.push(...history.skills.map((skill) => skill.toLowerCase()));
      } else {
        console.warn('Invalid or missing skills property in work history entry:', history);
      }
    });
    const uniqueCandidateSkills = [...new Set(candidateSkills)];
    const matchingSkills = [];
    const nonMatchingSkills = [];

    jobSkills?.forEach((skill) => {
      const lowerCaseSkill = skill.toLowerCase();
      if (uniqueCandidateSkills?.includes(lowerCaseSkill)) {
        matchingSkills.push(skill);
      } else {
        nonMatchingSkills.push({ skill, is_core: coreSkills?.includes(skill) });
      }
    });
    let coreMatchSkill = [];

    coreSkills?.forEach((skill) => {
      const lowerCaseSkill = skill.toLowerCase();
      if (uniqueCandidateSkills?.includes(lowerCaseSkill)) {
        coreMatchSkill.push(skill);
      }
    });
    setCoreMatchSkills(coreMatchSkill);
    setNonMatchingSkills(nonMatchingSkills);

    return matchingSkills;
  };

  useEffect(() => {
    setAlignment('job-overview');
    setDataSubmitted(true);
    setLoading(true);
    setSaved(false);
    fetchData();
  }, [data]);

  useEffect(() => {
    setAlignment('job-overview');
    setSaved(false);
    fetchData();
  }, [savedJob, unsaveJob]);

  const handleScroll = useCallback(() => {
    if (isManualScroll.current || !jobFullOverviewRef.current) return;

    const container = jobFullOverviewRef.current;
    const scrollPosition = container.scrollTop;

    const sections = [
      { id: "job-overview", ref: jobOverviewRef },
      { id: "job-desc", ref: jobDescRef },
    ];

    for (const section of sections) {
      if (!section.ref.current) continue;

      const offsetTop = section.ref.current.offsetTop;
      const height = section.ref.current.clientHeight;

      if (
        scrollPosition >= offsetTop - 100 &&
        scrollPosition < offsetTop + height - 100
      ) {
        if (alignment !== section.id) {
          setAlignment(section.id);
        }
        break;
      }
    }
  }, [alignment]);

  useEffect(() => {

    const container = jobFullOverviewRef.current;
    if (!container) return;

    container.addEventListener("scroll", handleScroll);

    handleScroll();

    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll, loading]);

  const handleToggleSave = () => {
    setSaved(true);
  };

  // update job application drawer

  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  // update relevent skills drawer

  const [openSkillsDrawer, setOpenSkillsDrawer] = useState(false);

  const toggleSkillsDrawer = (newOpenSkills) => () => {
    setOpenSkillsDrawer(newOpenSkills);
  };

  // chat bot drawer

  const [openChatBotDrawer, setOpenChatBotDrawer] = useState((jobApply?.job?._id === data?._id && !(jobApply?.job?.external_link)) ? jobApply?.openChatBot : false);

  const toggleChatBotDrawer = (newOpenSkills) => () => {
    setOpenChatBotDrawer(newOpenSkills);
  };

  // auto saving job app dialog

  const [autoSaveDialogOpen, setAutoSetDialogOpen] = useState(false);

  const autoSaveJobAppDialog = () => {
    setAutoSetDialogOpen(true);
  };

  const handleCloseAutoSaveDialog = () => {
    setAutoSetDialogOpen(false);
  };

  const handleToggleChange = (event, newAlignment) => {
    isManualScroll.current = true;
    if (newAlignment !== null) {
      setAlignment(newAlignment);
      let targetRef;

      switch (newAlignment) {
        case 'job-overview':
          targetRef = jobOverviewRef;
          break;
        case 'job-desc':
          targetRef = jobDescRef;
          break;
        default:
          break;
      }

      jobFullOverviewRef.current.scrollTo({
        top: targetRef.current.offsetTop,
        block: 'start',
        behavior: 'smooth',
      });

      setTimeout(() => {
        isManualScroll.current = false;
      }, 500);
    }
  };

  const matchIcon = (job, candidate, title) => {
    let match = 1;

    if (title === 'Offered Compensation') {
      match = candidate === 4 ? 3 : candidate;
    } else {
      match = candidate === 3 ? 3 : 1;
    }

    const IconComponent = match === 3 ? CheckIcon : match === 2 ? WarningIcon : CrossIcon;

    return (
      <Box>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <IconComponent />
          <Typography className="pref-match-title">{title || 'NA'}</Typography>
        </Stack>
      </Box>
    );
  };

  const matchNoticePeriod = (job, candidate) => {
    if (job === 'No preference') {
      return true;
    }
    if (job === '0 days (immediate)') {
      if (candidate === '0 days (immediate)') {
        return true
      }
      return false;
    } else if (candidate === '0 days (immediate)') {
      return true;
    } else if (job === '90+') {
      if (candidate === '90+') {
        return true
      }
      return false;
    } else if (candidate === '90+') {
      return false;
    } else {
      let jN = parseInt(job);
      let cN = parseInt(candidate);
      return (cN <= jN ? true : false);
    }
  };

  const matchLocation = (job, candidate) => {
    const locations = candidate?.split(', ');
    let matchFound = false;
    let matchedCluster = [];

    locations?.forEach((item) => {
      matchedCluster = [...matchedCluster, clusteredData.find(cluster => cluster.name === item)];
    });
    let matchedValueCluster = [];
    matchedCluster?.forEach((item) => {
      matchedValueCluster = [...matchedValueCluster, item?.value];
    });

    if (matchedCluster) {
      matchedCluster = matchedValueCluster?.join(', ');
      matchFound = matchedCluster?.includes(job);
      if (matchFound) return true;
    }
    matchFound = candidate?.includes(job);

    return matchFound;
  };

  const handleSaveForLater = async () => {
    try {
      setDataSubmitted(false);
      if (savedJob === '') {
        handleSaveOrUnsaveJob(data._id, 'saveJob');
      }
      if (candidateDetails && Object.keys(candidateDetails).length > 0) {
        const response = await saveJobApplication({ jobId: data?._id, companyId: data?.company_id, type: 'saved' });
        if (response?.success && response?.data != null) {
          enqueueSnackbar('Successfully saved for later.', { variant: 'success' });
          data.application_saved = response?.data?.status === 'saved';
        } else {
          enqueueSnackbar('Failed to save the application. Please try again.', { variant: 'error' });
        }
      } else {
        enqueueSnackbar('Failed to save the application. Please try again.', { variant: 'error' });
      }
    } catch (error) {
      console.error('Error saving for later:', error);
    } finally {
      handleChangeSaveOrUnsaveButton('saveJob');
      setDataSubmitted(true);
    }
  };

  const handleUnsaveJob = async () => {
    try {
      if (unsaveJob === '') {
        handleSaveOrUnsaveJob(data._id, 'unsaveJob');
      }
      if (candidateDetails && Object.keys(candidateDetails).length > 0) {
        const response = await saveJobApplication({ jobId: data?._id, companyId: data?.company_id, type: 'unsaved' });
        if (response?.success) {
          data.application_saved = false;
          handleCloseAutoUnsaveDialog();
        } else {
          enqueueSnackbar('Failed to Unsave the application. Please try again.', { variant: 'error' });
        }
      } else {
        enqueueSnackbar('Failed to Unsave the application. Please try again.', { variant: 'error' });
      }
    } catch (error) {
      console.error('Error unsaving for later:', error);
    }
  };

  const requirementsMatch = (job, candidate, title) => {
    let match = 1;
    if (title === 'Qualification') match = candidate === 4 ? 3 : 1;
    if (title === 'Experience' || title === 'Skills') match = candidate === 4 ? 3 : candidate;
    if (title === 'Industry') match = candidate === 4 ? 3 : 2;

    if (match === 3) {
      return (
        <CheckIcon />
      )
    } else if (match === 2) {
      return (
        <WarningIcon />
      )
    } else {
      return (
        <CrossIcon />
      )
    }
  };
  const compareQualifications = (jobQualifications, candidateQualifications) => {
    if (!jobQualifications || !candidateQualifications) return 0;
    const jobQualValues = [...new Set(jobQualifications.map(value => value.toLowerCase()))];
    const candidateQualValues = [...new Set(candidateQualifications.map((qual) => qual.toLowerCase()))];
    const matchedQualifications = [...new Set(candidateQualValues.filter((qual) => jobQualValues.includes(qual)))];
    if (matchedQualifications?.length >= 1) {
      return 4;
    } else {
      return 0;
    }
  };

  const handleSelectedResume = (resume, answers) => {
    toggleChatBotDrawer(false);
    onApplied();
  };

  const handleUpdatePreference = async (preference) => {
    onJobPreferencesChange();
    await fetchData(true);
  };

  const handleSkillsUpdate = (skills) => {
    fetchData(true);
  };

  const recommendedChip = (score) => score >= 3 ? <SuitableChip /> : score >= 2 ? <ModerateChip /> : <NotSuitableChip />;

  const [autoUnsaveDialogOpen, setAutoUnsaveDialogOpen] = useState(false);

  const handleOpenAutoUnsaveDialog = () => {
    setAutoUnsaveDialogOpen(true);
  };

  const handleCloseAutoUnsaveDialog = () => {
    setAutoUnsaveDialogOpen(false);
    handleChangeSaveOrUnsaveButton('unsaveJob');
  };

  const handleNonMatchingSkills = () => {
    fetchData();
  };

  const [openExternalJob, setOpenExternalJob] = useState((jobApply?.job?._id === data?._id && jobApply?.job?.external_link) ? jobApply?.openChatBot : false);
  const handleApplyJob = () => {
    if (data?.external_link) {
      setOpenExternalJob(true);
    } else {
      setOpenChatBotDrawer(true);
    }
  };
  const handleCloseExternalJobDialog = () => {
    setOpenExternalJob(false);
  };

  const [openExternalJobStatus, setOpenExternalJobStatus] = useState(false);
  const handleExternalJobStatus = () => {
    setOpenExternalJobStatus(true);
  };
  const handleCloseExternalJobStatusDialog = (msg) => {
    setOpenExternalJobStatus(false);
    if (msg === "applied") {
      onApplied();
    }
  };
  const handleDownloadResume = () => {
    const dataForExternalJob = { job: data, candidateDetails: candidateDetails, matchingSkills: matchingSkills, tab: '2' };
    downloadResume(dataForExternalJob);
    handleCloseExternalJobDialog();
  };

  const isIndustrySectorMatched = (workHistory, targetIndustrySector) => {
    return workHistory?.some(
      (work) => work?.organization_details?.industry_sector === targetIndustrySector
    ) || false;
  };
  const handleIndustryType = (work) => {
    const industryType = [];
    work?.forEach((item) => {
      const sector = item?.organization_details?.industry_sector;
      if (sector) industryType.push(sector);
    });
    const uniqueIndustryType = [...new Set(industryType)];
    return uniqueIndustryType;
  };
  const industryMessage = () => {
    const industryType = handleIndustryType(candidateDetails?.workHistory);
    if (industryType?.length > 0) return `Your experiance is in following industry, ${industryType?.join(', ')}`;
    else return `There are no industries associated with your work experiences`;
  };

  const percentage = (num, den) => {
    // console.log(num, den);
    const per = Math.round((num / den) * 100)
    return per;
  };

  const matchExperience = (job, candidate) => {
    if (!job || !candidate) return 0;
    // console.log(job, candidate);
    const [minExperience, maxExperience] = job?.split("-");
    const upperLimit = parseInt(maxExperience);
    const lowerLimit = parseInt(minExperience);
    if (candidate <= upperLimit && candidate >= lowerLimit) return 4;
    else return 0;
  };

  const expectedCtc = parseInt(candidateDetails?.user?.candidate?.job_preferences?.expected_ctc, 10);
  const jobCompensation = parseInt(data?.salary, 10);
  const comparisonText = expectedCtc > jobCompensation ? 'Less' : 'Greater';
  const truncatedDescription = showMore ? data?.job_overview : data?.job_overview.substring(0, 50) + (data?.job_overview.length > 50 ? '...' : '');

  return (
    <Box className="job-full-details">
      {/* header block */}
      <Box className="job-full-details-header">
        <Box className="header-top">
          <Stack direction="row" justifyContent={'space-between'} spacing={2}>
            <Box>
              <Typography className="title">{data?.title || 'NA'}</Typography>
              <Stack direction="row" alignItems={'center'} spacing={1}>
                {data?.is_company_name_confidential ? <Typography className="company-name">Company Name - Confidential</Typography> : <Typography className="company-name">{data?.company ? toTitleCase(data?.company) : 'NA'}</Typography>}
                <Divider orientation="vertical" variant="middle" flexItem />
                <Typography className="city-name">{data?.location || 'N/A'}</Typography>
              </Stack>
            </Box>

            {loading ? (
              <Stack direction="column" spacing={3}>
                <Skeleton height={40} width="100%" />
              </Stack>
            ) : (
              <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <Stack direction="row" alignItems={'center'} spacing={3}>
                  {<>
                    {data?.application_saved ? (
                      <Button sx={{ width: '150px' }}
                        variant="outlined"
                        size="large"
                        className="saved-btn"
                        onClick={handleOpenAutoUnsaveDialog}
                        startIcon={
                          <SvgIcon>
                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 8 6" fill="none">
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M7.77869 1.3764L3.45821 5.69687C3.16468 5.99041 2.67851 5.99041 2.3758 5.69687L0.220152 3.54122C-0.0733838 3.24769 -0.0733838 2.76152 0.220152 2.45881C0.513687 2.16527 0.999855 2.16527 1.30256 2.45881L2.92618 4.08243L6.70545 0.303159C6.99898 0.00962395 7.48515 0.00962395 7.78786 0.303159C8.07222 0.596695 8.07222 1.08286 7.77869 1.3764Z"
                                fill="white"
                              />
                            </svg>
                          </SvgIcon>
                        }
                      >
                        Job Saved
                      </Button>
                    ) : (
                      <Button sx={{ width: '150px' }}
                        variant="outlined"
                        size="large"
                        className="save-btn"
                        startIcon={<AddCircleIcon fontSize="large" />}
                        onClick={handleSubmit(handleSaveForLater)}
                        disabled={!dataSubmitted}
                      >
                        {dataSubmitted ? 'Save Job' : 'Saving...'}
                      </Button>
                    )}
                    <Button sx={{ width: '150px' }}
                      variant="contained"
                      size="large"
                      className={data?.external_link ? "external-apply-now-btn" : "apply-now-btn"}
                      endIcon={data?.external_link ? < SvgIcon >
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16 16" fill="none">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5413 0.0581763C15.7159 0.130897 15.8613 0.276338 15.934 0.450867H15.9413C15.9776 0.538131 15.9995 0.632668 15.9995 0.727205V5.03953C15.9995 5.43949 15.6722 5.76673 15.2723 5.76673C14.8723 5.76673 14.5451 5.43949 14.5451 5.03953V2.47977L8.51653 8.50829C8.37109 8.64646 8.18929 8.71918 8.00021 8.71918C7.81114 8.71918 7.62934 8.65373 7.4839 8.50829C7.20029 8.22468 7.20029 7.76654 7.4839 7.48293L13.5124 1.45441H10.9527C10.5527 1.45441 10.2255 1.12717 10.2255 0.727205C10.2255 0.327242 10.5527 0 10.9527 0H15.265C15.3595 0 15.4541 0.0218161 15.5413 0.0581763ZM14.5375 8.0013C14.5375 7.60134 14.8648 7.27409 15.2648 7.27409C15.6647 7.27409 15.992 7.60134 15.992 8.0013V13.0554C15.992 14.677 14.6684 16.0005 13.0468 16.0005H2.94591C1.32424 16.0005 0.000732422 14.677 0.000732422 13.0554V2.9545C0.000732422 1.33283 1.32424 0.00932173 2.94591 0.00932173H7.99998C8.39994 0.00932173 8.72719 0.336564 8.72719 0.736526C8.72719 1.13649 8.39994 1.46373 7.99998 1.46373H2.94591C2.12417 1.46373 1.45514 2.13276 1.45514 2.9545V13.0554C1.45514 13.8771 2.12417 14.5461 2.94591 14.5461H13.0468C13.8685 14.5461 14.5375 13.8771 14.5375 13.0554V8.0013Z" fill="white" />
                        </svg>
                      </SvgIcon> : null}
                      onClick={handleApplyJob}
                    >
                      Apply Now
                    </Button>
                  </>
                  }
                </Stack>
              </Box>
            )}
          </Stack>
        </Box>

        {loading ? (
          <Box className="header-bottom">
            <Skeleton height={40} width="40%" />
          </Box>
        ) : (
          <Box className="header-bottom">
            <Stack direction="row" alignItems={'center'} justifyContent={'space-between'} sx={{ width: '100%' }} spacing={2}>
              <ToggleButtonGroup
                className="job-desc-toggle-btn"
                color="primary"
                value={alignment}
                exclusive
                onChange={handleToggleChange}
                aria-label="Platform"
              >
                <ToggleButton value="job-overview">Job Overview</ToggleButton>
                <ToggleButton value="job-desc">Job Details</ToggleButton>
              </ToggleButtonGroup>
            </Stack>
          </Box>
        )}
      </Box>

      {/* job overview block */}
      {loading ? (
        <Box className="job-full-overview">
          <Box className="job-overview-wrapper" mb={4}>
            <Card variant="outlined" className="requirements-match-card" sx={{ marginBottom: 3 }}>
              <CardContent>
                <Skeleton height={60} />
                <Skeleton height={40} width="60%" />
              </CardContent>
            </Card>
          </Box>
        </Box>
      ) : (
        <Box ref={jobFullOverviewRef} className="job-full-overview">
          <Box ref={jobOverviewRef} id="job-overview">
            <Box className="job-overview-wrapper" mb={4}>
              <Typography className="title">Job Overview</Typography>
              <Typography className="about-post-info" style={{ textAlign: 'justify', marginBottom: 15 }}>
                {data?.company_overview}
              </Typography>

              {isMobile ?
                <Typography className="about-post-info" style={{ textAlign: 'justify' }}>
                  {truncatedDescription}
                  {data?.job_overview.length > 50 && (
                    <Link component="button" onClick={() => setShowMore(!showMore)}>
                      {!showMore && 'Read More'}
                    </Link>
                  )}
                </Typography>
                : <Typography className="about-post-info" style={{ textAlign: 'justify' }}>{data?.job_overview}
                </Typography>}
            </Box>

            <Card variant="outlined" className="requirements-match-card" sx={{ marginBottom: 3 }}>
              <CardContent>
                {recommendedChip(data?.tj_score)}
                <Typography className="title">Requirements Match</Typography>
                <Typography className="info-text">
                  Matched against your educational background & work experience
                </Typography>
                <Box className="requirements-match" mt={1.5} >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                      <Tooltip title={`You have ${candidateDetails?.qualification?.join(', ')} education`} arrow placement='top'>
                        <Box>
                          <Stack direction="row" align spacing={0.5}>
                            {requirementsMatch(data?.qualifications, candidateDetails?.rating?.qualification, 'Qualification')}
                            <Box>
                              <Typography className="req-title" sx={{ marginTop: '-2px' }}>Required Education</Typography>
                              <Typography className="req-text">
                                {data?.qualifications?.join(' | ')}
                              </Typography>
                            </Box>
                          </Stack>
                        </Box>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Tooltip title={`You have ${candidateDetails?.totalExperience} years of experience`} arrow placement='top'>
                        <Box>
                          <Stack direction="row" spacing={0.5}>
                            {requirementsMatch(data?.experience, candidateDetails?.rating?.experienceMatchRating, 'Experience')}
                            <Box>
                              <Typography className="req-title" sx={{ marginTop: '-2px' }}>Required Work Experience</Typography>
                              <Typography className="req-text">
                                {data?.experience ? `${data?.experience} Years` : 'N/A'}</Typography>
                            </Box>
                          </Stack>
                        </Box>
                      </Tooltip>

                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Tooltip title={<>
                        {coreMatchSkills?.length > 0 ? (<div>Your preferred skills match = {percentage(coreMatchSkills?.length, data?.core_skills?.length)}%</div>) : ''}
                        <div>Your required skills match = {percentage(matchingSkills?.length, data?.key_skills?.length)}%</div>
                      </>} arrow placement="top">
                        <Box>
                          <Stack direction="row" spacing={0.5}>
                            {requirementsMatch(data?.key_skills?.length, candidateDetails?.rating?.skillMatchRating, 'Skills')}
                            <Box>
                              <Typography className="req-title" sx={{ marginTop: '-2px' }}>Required Skills</Typography>
                              <Typography className="req-text">
                                {coreMatchSkills?.length > 0 && (<>{coreMatchSkills?.length || 0}/{data?.core_skills ? data?.core_skills?.length : 0} Preferred<br /></>)}
                                {matchingSkills ? matchingSkills?.length : 0}/{data?.key_skills ? data?.key_skills?.length : 0} Total
                              </Typography>
                            </Box>
                          </Stack>
                        </Box>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Tooltip title={industryMessage()} arrow placement='top'>
                        <Box>
                          <Stack direction="row" spacing={0.5}>
                            {requirementsMatch(data?.industry_sector, candidateDetails?.rating?.industry, 'Industry')}
                            <Box>
                              <Typography className="req-title" sx={{ marginTop: '-2px' }}>Industry Type</Typography>
                              <Typography className="req-text">{data?.industry_sector || 'N/A'}</Typography>
                            </Box>
                          </Stack>
                        </Box>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Box>

                <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '1rem', marginBottom: '1rem' }} />

                <Box className="preferences-match">
                  <Typography className="title">Preferences Match</Typography>
                  <Typography className="pref-info-text" component="p">
                    Matched against your defined job preferences.{' '}
                    <Link href="#" underline="none" onClick={toggleDrawer(true)}>click here to update preferences</Link>
                  </Typography>
                  <Box mt={2}>
                    <Stack spacing={1.5}>
                      <Grid container>
                        <Grid item xs={12} md={3}>
                          <Stack direction="row" alignItems={'center'} spacing={0.5}>
                            {matchIcon(
                              data?.salary,
                              candidateDetails?.rating?.salaryMatchRating,
                              requirementMatchItem[0]
                            )}
                          </Stack>
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <Box sx={{ marginLeft: { xs: '20px' } }}>
                            <Stack direction={{ xs: 'column', md: 'row' }} alignItems={'flex-start'} flexWrap="wrap" spacing={{ xs: 0, md: 0.7 }}>
                              {data?.is_compensation_confidential ? <Typography className="pref-match-info">Not Disclosed</Typography> : (<>
                                <Typography className="pref-match-info">
                                  ₹{formatNumber(data?.salary)}
                                  {data?.compensation && `(${data?.compensation})`}
                                </Typography>
                              </>)}
                              {candidateDetails?.rating?.salaryMatchRating < 3 &&
                                <Typography className="pref-match-info notice-period-error">
                                  ({comparisonText} than your{' '}
                                  <Tooltip
                                    title={`Your expected compensation is ₹${formatNumber(candidateDetails?.user?.candidate?.job_preferences?.expected_ctc)}`}
                                    arrow placement="top"
                                  >
                                    <span style={{ textDecoration: 'underline' }}>expected compensation</span>
                                  </Tooltip>
                                  )
                                </Typography>
                              }
                            </Stack>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12} md={3}>
                          <Stack direction="row" alignItems={'center'} spacing={0.5}>
                            {matchIcon(
                              data?.location,
                              candidateDetails?.rating?.location,
                              requirementMatchItem[1]
                            )}
                          </Stack>
                        </Grid>
                        <Grid item xs={12} md={9}> {/* ---------location---------------- */}
                          <Box sx={{ marginLeft: { xs: '20px' } }}>
                            <Stack direction={{ xs: 'column', md: 'row' }} alignItems={'flex-start'} flexWrap="wrap" spacing={{ xs: 0, md: 0.7 }}>
                              <Typography className="pref-match-info" sx={{ flexShrink: 0 }}>{data?.location || 'NA'}</Typography>
                              {candidateDetails?.rating?.location < 3 &&
                                <Typography className="pref-match-info notice-period-error">
                                  (Does not match any of your{' '}
                                  <Tooltip
                                    title={`Your preferred locations are ${candidateDetails?.user?.candidate?.job_preferences?.prefered_location?.join(', ')}`}
                                    arrow placement="top"
                                  >
                                    <span style={{ textDecoration: 'underline' }}>preferred locations</span>
                                  </Tooltip>
                                  )
                                </Typography>
                              }
                            </Stack>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12} md={3}>
                          <Stack direction="row" alignItems={'center'} spacing={0.5}>
                            {matchIcon(
                              data?.work_mode,
                              candidateDetails?.rating?.format,
                              requirementMatchItem[2]
                            )}
                          </Stack>
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <Box sx={{ marginLeft: { xs: '20px' } }}>
                            <Stack direction={{ xs: 'column', md: 'row' }} alignItems={'flex-start'} flexWrap="wrap" spacing={{ xs: 0, md: 0.7 }}>
                              <Typography className="pref-match-info" sx={{ flexShrink: 0 }}>{data?.work_mode || 'NA'}</Typography>
                              {candidateDetails?.rating?.format < 3 &&
                                <Typography className="pref-match-info notice-period-error">
                                  (Does not match any of your{' '}
                                  <Tooltip
                                    title={`Your preferred work formats is ${candidateDetails?.user?.candidate?.job_preferences?.work_format?.join(', ')}`}
                                    arrow placement="top"
                                  >
                                    <span style={{ textDecoration: 'underline' }}>preferred job formats</span>
                                  </Tooltip>
                                  )
                                </Typography>
                              }
                            </Stack>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12} md={3}>
                          <Stack direction="row" alignItems={'center'} spacing={0.5}>
                            {matchIcon(
                              data?.job_type,
                              candidateDetails?.rating?.type,
                              requirementMatchItem[3]
                            )}
                          </Stack>
                        </Grid>
                        < Grid item xs={12} md={9}>
                          <Box sx={{ marginLeft: { xs: '20px' } }}>
                            <Stack direction={{ xs: 'column', md: 'row' }} alignItems={'flex-start'} flexWrap="wrap" spacing={{ xs: 0, md: 0.7 }}>
                              <Typography className="pref-match-info" sx={{ flexShrink: 0 }}>{data?.job_type || 'NA'}</Typography>
                              {candidateDetails?.rating?.type < 3 &&
                                <Typography className="pref-match-info notice-period-error">
                                  (Does not match any of your{' '}
                                  <Tooltip
                                    title={`Your preferred job type is ${candidateDetails?.user?.candidate?.job_preferences?.job_type?.join(', ')}`}
                                    arrow placement="top"
                                  >
                                    <span style={{ textDecoration: 'underline' }}>preferred job types</span>
                                  </Tooltip>
                                  )
                                </Typography>
                              }
                            </Stack>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12} md={3}>
                          <Stack direction="row" alignItems={'center'} spacing={0.5}>
                            {matchIcon(
                              data?.job_shift,
                              candidateDetails?.rating?.shift,
                              requirementMatchItem[4]
                            )}
                          </Stack>
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <Box sx={{ marginLeft: { xs: '20px' } }}>
                            <Stack direction={{ xs: 'column', md: 'row' }} alignItems={'flex-start'} flexWrap="wrap" spacing={{ xs: 0, md: 0.7 }}>
                              <Typography className="pref-match-info" sx={{ flexShrink: 0 }}>
                                {data?.job_shift ? `${data?.job_shift}` : 'NA'}
                              </Typography>
                              {candidateDetails?.rating?.shift < 3 &&
                                <Typography className="pref-match-info notice-period-error">
                                  (Does not match any of your{' '}
                                  <Tooltip
                                    title={`Your preferred job shift is ${candidateDetails?.user?.candidate?.job_preferences?.preferred_job_shifts?.join(', ')} as your preferred shift`}
                                    arrow placement="top"
                                  >
                                    <span style={{ textDecoration: 'underline' }}>preferred shift</span>
                                  </Tooltip>
                                  )
                                </Typography>
                              }
                            </Stack>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12} md={3}>
                          <Stack direction="row" alignItems={'center'} spacing={0.5}>
                            {matchIcon(
                              data?.notice_period,
                              candidateDetails?.rating?.notice,
                              requirementMatchItem[5]
                            )}
                          </Stack>
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <Box sx={{ marginLeft: { xs: '20px' } }}>
                            <Stack direction={{ xs: 'column', md: 'row' }} alignItems={'flex-start'} flexWrap="wrap" spacing={{ xs: 0, md: 0.7 }}>
                              <Typography className="pref-match-info" sx={{ flexShrink: 0 }}>{data?.notice_period ? ((data?.notice_period === '0 days (immediate)' || data?.notice_period === 'No preference') ? `${data?.notice_period}` : `Upto ${data?.notice_period} days`) : 'NA'}</Typography>
                              {candidateDetails?.rating?.notice < 3 &&
                                <Typography className="pref-match-info notice-period-error">
                                  (Lower than your{' '}
                                  <Tooltip
                                    title={`Your preferred notice period is ${candidateDetails?.user?.candidate?.job_preferences?.notice_period} days`}
                                    arrow placement="top"
                                  >
                                    <span style={{ textDecoration: 'underline' }}>current notice period</span>
                                  </Tooltip>
                                  )
                                </Typography>
                              }
                            </Stack>
                          </Box>
                        </Grid>
                      </Grid>
                    </Stack>
                  </Box>
                </Box>
              </CardContent>
            </Card>

            <Card variant="outlined" className="skills-match-card" sx={{ marginBottom: 3 }}>
              <CardContent>
                <Box>
                  <Stack direction={{ xs: 'column', md: 'row' }} justifyContent={'space-between'} >
                    <Box>
                      <Typography className="title">Skills Match Analysis</Typography>
                    </Box>
                    <Box>
                    </Box>
                  </Stack>
                  <Typography className="info-text">
                    You possess {matchingSkills?.length || 0} out {data?.key_skills?.length || 0} required skills. {''}
                    <Link href="#" underline="none" className="update-skills-link" onClick={toggleSkillsDrawer(true)}>
                      click here to add missing skills
                    </Link>
                  </Typography>
                </Box>
                {/* <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '1rem', marginBottom: '1rem' }} /> */}

                <Stack direction="row" alignItems={'center'} spacing={0.1} mb={1} mt={1}>
                  <StarIcon className="star-icon" sx={{ width: '12px', color: '#faaf00', marginLeft: 'auto' }} />
                  <Typography sx={{
                    display: 'inline-flex',
                    color: '#374151',
                    fontFamily: '"Inter", sans-serif',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: '18px',
                    margin: '0 5px!important'
                  }}>Denote preferred skills</Typography>
                  <Tooltip title={`For success in this job, these skills are considered to be the most important`} arrow placement="top" >
                    <SvgIcon sx={{ width: '12px' }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                        <path d="M0 6C0 9.30764 2.69236 12 6 12C9.30764 12 12 9.30764 12 6C12 2.69236 9.30764 0 6 0C2.69236 0 0 2.69236 0 6ZM6 1.09091C8.70764 1.09091 10.9091 3.29236 10.9091 6C10.9091 8.70764 8.70764 10.9091 6 10.9091C3.29236 10.9091 1.09091 8.70764 1.09091 6C1.09091 3.29236 3.29236 1.09091 6 1.09091Z" fill="#1575C6" />
                        <path d="M6.00004 8.74382C6.30114 8.74382 6.5455 8.49946 6.5455 8.19837V5.41655C6.5455 5.11546 6.30114 4.87109 6.00004 4.87109C5.69895 4.87109 5.45459 5.11546 5.45459 5.41655V8.19837C5.45459 8.49946 5.69895 8.74382 6.00004 8.74382Z" fill="#1575C6" />
                        <path d="M6.00004 4.34599C6.03495 4.34599 6.07204 4.34163 6.10695 4.33508C6.14186 4.32853 6.17677 4.31763 6.2095 4.30453C6.24223 4.29144 6.27277 4.27399 6.30332 4.25435C6.33168 4.23472 6.36004 4.21072 6.38623 4.18672C6.48659 4.08417 6.5455 3.94453 6.5455 3.80053C6.5455 3.65653 6.48659 3.5169 6.38623 3.41435C6.25968 3.28781 6.06986 3.23108 5.89313 3.26599C5.85823 3.27253 5.82332 3.28344 5.79059 3.29653C5.75786 3.30963 5.72732 3.32708 5.69677 3.34672C5.66841 3.36635 5.64004 3.39035 5.61386 3.41435C5.5135 3.5169 5.45459 3.65653 5.45459 3.80053C5.45459 3.94453 5.5135 4.08417 5.61386 4.18672C5.71641 4.28708 5.85604 4.34599 6.00004 4.34599Z" fill="#1575C6" />
                      </svg>
                    </SvgIcon>
                  </Tooltip>
                </Stack>

                <Stack direction="row" flexWrap="wrap" sx={{ marginBottom: '1rem' }} >
                  {data?.key_skills
                    ? data?.key_skills.map((skill, index) => (
                      <Chip
                        key={index}
                        icon={
                          matchingSkills?.includes(skill) ? (
                            <Box className="match-icon">
                              <SvgIcon>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="8"
                                  height="6"
                                  viewBox="0 0 8 6"
                                  fill="none"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M7.77869 1.3764L3.45821 5.69687C3.16468 5.99041 2.67851 5.99041 2.3758 5.69687L0.220152 3.54122C-0.0733838 3.24769 -0.0733838 2.76152 0.220152 2.45881C0.513687 2.16527 0.999855 2.16527 1.30256 2.45881L2.92618 4.08243L6.70545 0.303159C6.99898 0.00962395 7.48515 0.00962395 7.78786 0.303159C8.07222 0.596695 8.07222 1.08286 7.77869 1.3764Z"
                                    fill="white"
                                  />
                                </svg>
                              </SvgIcon>
                            </Box>
                          ) : (
                            <Box className="error-match-icon">
                              <SvgIcon>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="6"
                                  height="6"
                                  viewBox="0 0 6 6"
                                  fill="none"
                                >
                                  <path
                                    d="M0.134092 5.23008L2.36417 3L0.134092 0.769922C0.0918098 0.728272 0.0581919 0.678662 0.0351778 0.623955C0.0121637 0.569247 0.000208773 0.510524 2.71012e-06 0.451173C-0.000203353 0.391823 0.0113435 0.333018 0.0339772 0.278152C0.0566109 0.223286 0.0898834 0.173444 0.131876 0.131501C0.173868 0.0895583 0.223749 0.0563443 0.278642 0.0337753C0.333535 0.0112063 0.392353 -0.000271149 0.451703 4.86231e-06C0.511054 0.000280873 0.569763 0.0123049 0.624443 0.0353835C0.679123 0.0584621 0.728693 0.0921385 0.770294 0.13447L2.99962 2.36455L5.2297 0.13447C5.2713 0.0921385 5.32087 0.0584621 5.37555 0.0353835C5.43023 0.0123049 5.48894 0.000280873 5.54829 4.86231e-06C5.60764 -0.000271149 5.66646 0.0112063 5.72135 0.0337753C5.77624 0.0563443 5.82613 0.0895583 5.86812 0.131501C5.91011 0.173444 5.94338 0.223286 5.96602 0.278152C5.98865 0.333018 6.0002 0.391823 5.99999 0.451173C5.99978 0.510524 5.98783 0.569247 5.96482 0.623955C5.9418 0.678662 5.90818 0.728272 5.8659 0.769922L3.63507 3L5.86515 5.23008C5.90743 5.27173 5.94105 5.32134 5.96407 5.37605C5.98708 5.43075 5.99904 5.48948 5.99924 5.54883C5.99945 5.60818 5.9879 5.66698 5.96527 5.72185C5.94263 5.77671 5.90936 5.82656 5.86737 5.8685C5.82538 5.91044 5.7755 5.94366 5.7206 5.96622C5.66571 5.98879 5.60689 6.00027 5.54754 6C5.48819 5.99972 5.42948 5.9877 5.3748 5.96462C5.32012 5.94154 5.27055 5.90786 5.22895 5.86553L2.99962 3.63545L0.769545 5.86553C0.685044 5.94877 0.571075 5.99524 0.452464 5.99483C0.333852 5.99442 0.220209 5.94715 0.136288 5.86333C0.0523672 5.77951 0.00496864 5.66592 0.00441704 5.54731C0.00386544 5.4287 0.0502055 5.31468 0.133343 5.23008H0.134092Z"
                                    fill="white"
                                  />
                                </svg>
                              </SvgIcon>
                            </Box>
                          )
                        }
                        label={
                          data?.core_skills?.includes(skill) ?
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                              <StarIcon className="star-icon" sx={{ color: '#faaf00', padding: '5px', marginLeft: 'auto' }} />
                              <span>{skill}</span>
                            </Box> : skill
                        }
                        variant="outlined"
                        className={matchingSkills?.includes(skill) ? 'match-skill' : 'destructive-skill'}
                      />
                    ))
                    : 'No Skills'}
                </Stack>
              </CardContent>
            </Card>
          </Box>

          <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '1rem', marginBottom: '1rem' }} />

          <Box ref={jobDescRef} id="job-desc" className="job-description">
            <Typography className="title">Job Details</Typography>
            <Typography className="info-text list-style" >
              <div dangerouslySetInnerHTML={{ __html: data?.description }} />
            </Typography>
          </Box>
        </Box>
      )}
      <Box sx={{ display: { xs: 'block', md: 'none' } }} padding={1}>
        <Stack direction="row" alignItems={'center'} justifyContent={"center"} spacing={2}>
          {<>
            {data?.application_saved ? (
              <Button fullWidth
                variant="outlined"
                size="large"
                className="saved-btn"
                onClick={handleOpenAutoUnsaveDialog}
                startIcon={
                  <SvgIcon sx={{ marginRight: '0px !important' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 8 6" fill="none">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.77869 1.3764L3.45821 5.69687C3.16468 5.99041 2.67851 5.99041 2.3758 5.69687L0.220152 3.54122C-0.0733838 3.24769 -0.0733838 2.76152 0.220152 2.45881C0.513687 2.16527 0.999855 2.16527 1.30256 2.45881L2.92618 4.08243L6.70545 0.303159C6.99898 0.00962395 7.48515 0.00962395 7.78786 0.303159C8.07222 0.596695 8.07222 1.08286 7.77869 1.3764Z"
                        fill="white"
                      />
                    </svg>
                  </SvgIcon>
                }
              >
                Job Saved
              </Button>
            ) : (
              <Button fullWidth
                variant="outlined"
                size="large"
                className="save-btn"
                startIcon={<AddCircleIcon fontSize="large" />}
                onClick={handleSubmit(handleSaveForLater)}
                disabled={!dataSubmitted}
              >
                {dataSubmitted ? 'Save Job' : 'Saving...'}
              </Button>
            )}
            <Button fullWidth
              variant="contained"
              size="large"
              className={data?.external_link ? "external-apply-now-btn" : "apply-now-btn"}
              endIcon={data?.external_link ? < SvgIcon >
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16 16" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5413 0.0581763C15.7159 0.130897 15.8613 0.276338 15.934 0.450867H15.9413C15.9776 0.538131 15.9995 0.632668 15.9995 0.727205V5.03953C15.9995 5.43949 15.6722 5.76673 15.2723 5.76673C14.8723 5.76673 14.5451 5.43949 14.5451 5.03953V2.47977L8.51653 8.50829C8.37109 8.64646 8.18929 8.71918 8.00021 8.71918C7.81114 8.71918 7.62934 8.65373 7.4839 8.50829C7.20029 8.22468 7.20029 7.76654 7.4839 7.48293L13.5124 1.45441H10.9527C10.5527 1.45441 10.2255 1.12717 10.2255 0.727205C10.2255 0.327242 10.5527 0 10.9527 0H15.265C15.3595 0 15.4541 0.0218161 15.5413 0.0581763ZM14.5375 8.0013C14.5375 7.60134 14.8648 7.27409 15.2648 7.27409C15.6647 7.27409 15.992 7.60134 15.992 8.0013V13.0554C15.992 14.677 14.6684 16.0005 13.0468 16.0005H2.94591C1.32424 16.0005 0.000732422 14.677 0.000732422 13.0554V2.9545C0.000732422 1.33283 1.32424 0.00932173 2.94591 0.00932173H7.99998C8.39994 0.00932173 8.72719 0.336564 8.72719 0.736526C8.72719 1.13649 8.39994 1.46373 7.99998 1.46373H2.94591C2.12417 1.46373 1.45514 2.13276 1.45514 2.9545V13.0554C1.45514 13.8771 2.12417 14.5461 2.94591 14.5461H13.0468C13.8685 14.5461 14.5375 13.8771 14.5375 13.0554V8.0013Z" fill="white" />
                </svg>
              </SvgIcon> : null}
              onClick={handleApplyJob}
            >
              Apply Now
            </Button>
          </>
          }
        </Stack>
      </Box>

      {/* apply now chatboat drawer */}
      <Drawer
        open={openChatBotDrawer}
        className="chat-bot-drawer-dialog"
        anchor={'right'}
        onClose={toggleChatBotDrawer(false)}
      >
        <ChatBotDrawer
          questions={data?.question}
          onClose={toggleChatBotDrawer(false)}
          onSelected={handleSelectedResume}
          tab={'2'}
          data={data}
          candidateDetails={candidateDetails}
          matchingSkills={matchingSkills}
          handleCloseDrawer={handleCloseDrawer}
        />
      </Drawer>

      {/* update job preferences drawer */}
      <Drawer open={open} anchor={'right'} onClose={toggleDrawer(false)}>
        <JopApplicationDrawer
          onClose={toggleDrawer(false)}
          data={candidateDetails?.user?.candidate?.job_preferences}
          onUpdatePreference={handleUpdatePreference}
        />
      </Drawer>

      {/* update relevent skills drawer */}
      <Drawer open={openSkillsDrawer} anchor={'right'} onClose={toggleSkillsDrawer(false)}>
        <UpdateReleventSkillsDrawer
          onClose={toggleSkillsDrawer(false)}
          workExperience={candidateDetails?.workHistory}
          education={candidateDetails?.educations}
          certificate={candidateDetails?.certificate}
          skillsUpdate={handleSkillsUpdate}
          nonMatchingSkills={nonMatchingSkills}
          matchingSkills={matchingSkills}
          setNonMatchingSkills={handleNonMatchingSkills}
        />
      </Drawer>

      {/* auto save job app dialog */}
      <Dialog
        open={autoSaveDialogOpen}
        onClose={handleCloseAutoSaveDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="auto-save-app-dialog"
      >
        <AutoSavingJobAppDialog />
      </Dialog>
      {/* unsaved job */}
      {isMobile ? (
        <Drawer
          anchor='bottom'
          open={autoUnsaveDialogOpen}
          onClose={handleCloseAutoUnsaveDialog}
          className="mobile-bottomsheet-dialog action-dialog-card external-job-app-dialog"
        >
          <UnsaveCard onClose={handleCloseAutoUnsaveDialog} onUnsave={handleUnsaveJob} title={data?.title} />
        </Drawer>
      )
        : (<Dialog
          open={autoUnsaveDialogOpen}
          onClose={handleCloseAutoUnsaveDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="action-dialog-card external-list-dialog"
        > <DialogContent>
            <UnsaveCard onClose={handleCloseAutoUnsaveDialog} onUnsave={handleUnsaveJob} title={data?.title} />
          </DialogContent>
        </Dialog>)}
      {/* external job and download resume dialog */}
      {isMobile ?

        <Drawer
          anchor="bottom"
          open={openExternalJob}
          onClose={handleCloseExternalJobDialog}
          className="mobile-bottomsheet-dialog action-dialog-card external-job-app-dialog"
        >
          <ExternalJob onClose={handleCloseExternalJobDialog} externalJobStatus={handleExternalJobStatus} downloadResume={handleDownloadResume} candidateDetails={candidateDetails} matchingSkills={matchingSkills} job={data} tab={'2'} />

        </Drawer>
        : <Dialog
          open={openExternalJob}
          onClose={handleCloseExternalJobDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="action-dialog-card external-list-dialog"
        > <DialogContent>
            <ExternalJob onClose={handleCloseExternalJobDialog} externalJobStatus={handleExternalJobStatus} downloadResume={handleDownloadResume} candidateDetails={candidateDetails} matchingSkills={matchingSkills} job={data} tab={'2'} />
          </DialogContent>
        </Dialog>}

      {/* external job status */}
      <Dialog
        open={openExternalJobStatus}
        onClose={handleCloseExternalJobStatusDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="action-dialog-card external-list-dialog"
      > <DialogContent>
          <ExternalJobStatus onClose={handleCloseExternalJobStatusDialog} job={data} tab={'2'} candidateDetails={candidateDetails} matchingSkills={matchingSkills} />
        </DialogContent>
      </Dialog>

    </Box>
  );
};

export default JobFullDetails;

import React from 'react'
import { Box, Stack, IconButton, InputAdornment, Button, Divider, FormHelperText, TextField, Grid, Snackbar, Typography, Card, Container, FormControl, OutlinedInput, SvgIcon, Icon, InputLabel, FormControlLabel, Checkbox, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Link, Drawer, useMediaQuery } from '@mui/material';

import OneTimePassword from './OneTimePassword'
import { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, register, Controller } from 'react-hook-form';
// components
import FormProvider from 'hooks/form/form-provider';
import { useSnackbar } from 'components/snackbar'
// Validation
import { yupResolver } from '@hookform/resolvers/yup';
import { RegistrationSchema, OTPSchema } from '../../models/RegistrationSchema';
import { createUser, verify, removeUser } from 'api/profile';
import TermAndCondition from './TermAndCondition';
import { useTheme } from '@mui/material/styles';
import ResumeOnboarded from '../ResumeOnBoarded/ResumeOnboarded.js';

const Register = ({ jobId, setOpenRegister, onRegister }) => {
    const loginLogo = `${process.env.REACT_APP_HOMEPAGE}assets/TJ-Logo-LightBG-withTagline 1.svg`;
    const [open, setOpen] = useState(false);
    const [showOTP, setShowOTP] = useState(false)
    const [userId, setUserId] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [showTerm, setShowTerm] = useState(false);
    const [showFlag, setShowFlag] = React.useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();
    const { id } = useParams();
    const [showPassword, setShowPassword] = useState(false);
    const [openOnboardedResume, setOpenOnboardedResume] = useState(false);

    const handleOpenOnboardedResumeDialog = () => {
        setShowOTP(false);
        setOpenOnboardedResume(true);
    };
    const handleCloseOnboardedResumeDialog = () => {
        setOpenOnboardedResume(false);
    };

    const defaultValues = {
        full_name: '',
        otp: '',
        email: '',
        phone_number: '',
        password: '',
        re_password: '',
    };

    const methods = useForm({
        defaultValues,
        mode: "onChange",
        resolver: yupResolver(RegistrationSchema),
    });

    const {
        control,
        register,
        handleSubmit,
        trigger,
        setValue,
        watch,
        formState: { errors, isDirty, isValid, dirtyFields, isSubmitting },
    } = methods;

    const onSubmit = handleSubmit(async (formData) => {
        const response = await createUser(formData);
        if (response?.success === true && response.data?.id) {
            setUserId(response.data.id);
            setEmail(response.data.email);
            setPhoneNumber(response.data.phone_number);
            enqueueSnackbar(`OTP has been sent.`, { variant: 'success' })
            setShowOTP(true)
        } else {
            enqueueSnackbar(response.message, { variant: 'error' })
        }
    })

    const handleOpenOtpDialog = () => {
        setOpen(true);
    };

    const handleClose = async () => {
        const formData = methods.getValues();
        const response = await removeUser(formData);
        if (response?.success === true) {
            setUserId(null);
            setOpen(false);
            setShowOTP(false);
        } else {
            enqueueSnackbar(response.message, { variant: 'error' })
        }
    };

    const handleFlag = () => {
        setShowFlag(true);
    }

    const handleOnBlurFlag = () => {
        if (!watch('phone_number')) {
            setShowFlag(false);
        }
    }

    const handleChange = (e) => {
        if (e.target.value.length > 0) {
            setShowFlag(true);
        } else {
            setShowFlag(true);
        }
    };


    const handleOpenTermsDialog = () => {
        setShowTerm(true);
    }
    const handleCloseTermsDialog = () => {
        setShowTerm(false);
    }
    const handleRegisterClose = () => {
        if (jobId) {
            setOpenRegister(false);
        } else {
            navigate('/');
        }
    }

    return (
        <Box >
            <FormProvider methods={methods} onSubmit={onSubmit}>
                <Box className="login-page-wrapper">
                    <Box className="login-content-wrapper" sx={{ padding: { xs: '40px 20px !important', md: '40px !important' } }}>
                        <Box className="tj-logo" sx={{ marginBottom: { xs: '24px', md: '20px' } }}>
                            <Box
                                component="img"
                                alt="Talent Junction"
                                src={loginLogo}
                            />
                        </Box>
                        {/* Register form block */}
                        <Box className="register-form-wrapper">
                            <Box sx={{ marginBottom: { xs: '24px', md: '20px' } }}>
                                <Typography className="text-primary" component="p">
                                    Join the league of quality finance
                                    <Link underline="none" sx={{ cursor: 'pointer' }}> Candidates </Link>
                                    sought out by <Link underline="none" sx={{ cursor: 'pointer' }}> Top Companies</Link>
                                </Typography>
                            </Box>
                            <Stack spacing={2.5}>
                                <FormControl fullWidth
                                    sx={{
                                        display: 'inline-flex',
                                        flexDirection: 'column',
                                        position: 'relative',
                                        minWidth: '0px',
                                        padding: '0px',
                                        margin: '0px',
                                        border: '0px',
                                        verticalAlign: 'top',
                                        width: '100%',
                                        height: '60px',
                                    }}>
                                    <TextField
                                        name="full_name"
                                        size="small"
                                        label="Full Name"
                                        type="text"
                                        tabIndex={0}
                                        variant="outlined"
                                        autoComplete="full_name"
                                        placeholder="Enter your full name"
                                        autoFocus
                                        fullWidth
                                        {...register('full_name')}
                                        error={!!errors.full_name}
                                        helperText={errors.full_name?.message}
                                    />
                                </FormControl>
                                <FormControl fullWidth
                                    className="wh-number-input"
                                    size="small"
                                    onClick={handleFlag}
                                    onChange={handleChange}
                                    onBlur={handleOnBlurFlag}
                                    onFocus={handleFlag}
                                    sx={{
                                        display: 'inline-flex',
                                        flexDirection: 'column',
                                        position: 'relative',
                                        minWidth: '0px',
                                        padding: '0px',
                                        margin: '0px',
                                        border: '0px',
                                        verticalAlign: 'top',
                                        width: '100%',
                                        height: '60px',
                                    }}>
                                    <Controller
                                        control={control}
                                        name='phone_number'
                                        defaultValue={defaultValues?.phone_number}
                                        render={({ field }) =>
                                            <TextField
                                                {...field}
                                                size="small"
                                                id='phone_number'
                                                name='phone_number'
                                                placeholder='Enter your whatsapp number'
                                                label="WhatsApp Number"
                                                type="number"
                                                {...register('phone_number')}
                                                error={!!errors.phone_number}
                                                helperText={errors.phone_number?.message}
                                            />
                                        } />
                                    {showFlag &&
                                        <Box className="iti-flag-container" sx={{ marginTop: "-3px" }}>
                                            <Stack direction="row" alignItems={'center'} spacing={1}>
                                                <Box className="iti-flag"></Box>
                                                <Box class="selected-dial-code">+91</Box>
                                                <Divider orientation="vertical" variant="middle" flexItem />
                                            </Stack>
                                        </Box>
                                    }
                                </FormControl>
                                <FormControl size="small" fullWidth
                                    className="password-field"
                                    sx={{
                                        display: 'inline-flex',
                                        flexDirection: 'column',
                                        position: 'relative',
                                        minWidth: '0px',
                                        padding: '0px',
                                        margin: '0px',
                                        border: '0px',
                                        verticalAlign: 'top',
                                        width: '100%',
                                        height: '67px',
                                    }}>
                                    <Box className="password-lock-icon">
                                        <IconButton tabIndex={-1}>
                                            <SvgIcon sx={{ width: '16px', height: '16px' }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12" fill="none">
                                                    <path
                                                        d="M14 -0.000244141H2.00001C1.09796 -0.000244141 0.363647 0.734069 0.363647 1.63612V10.3634C0.363647 11.2654 1.09796 11.9998 2.00001 11.9998H14C14.9021 11.9998 15.6364 11.2654 15.6364 10.3634V1.63612C15.6364 0.734069 14.9021 -0.000244141 14 -0.000244141ZM13.6366 1.09066L8.32798 5.0724C8.13502 5.21694 7.86572 5.21694 7.67276 5.0724L2.36343 1.09066H13.6366ZM14 10.9088H2.00001C1.69864 10.9088 1.45456 10.6641 1.45456 10.3634V1.77244L7.01819 5.94512C7.30659 6.16194 7.65364 6.27035 8.00001 6.27035C8.34638 6.27035 8.69343 6.16195 8.98183 5.94512L14.5455 1.77244V10.3634C14.5455 10.6641 14.3014 10.9088 14 10.9088Z"
                                                        fill="#6B7280"
                                                    />
                                                </svg>
                                            </SvgIcon>
                                        </IconButton>
                                    </Box>
                                    <InputLabel htmlFor="outlined-adornment-email">Email Id</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-email"
                                        type='text'
                                        tabIndex={2}
                                        placeholder="Enter your email Id"
                                        {...register('email', {
                                            onChange: (e) => {
                                                const lowercaseValue = e.target.value.toLowerCase();
                                                setValue('email', lowercaseValue, { shouldValidate: true });
                                            },
                                        })}
                                        error={!!errors.email}
                                        label='Email Id'
                                    />
                                    <FormHelperText style={{ color: errors.email ? '#FF4842' : 'inherit' }}>{errors.email?.message}</FormHelperText>
                                </FormControl>
                                <FormControl size="small" fullWidth
                                    className="password-field"
                                    sx={{
                                        display: 'inline-flex',
                                        flexDirection: 'column',
                                        position: 'relative',
                                        minWidth: '0px',
                                        padding: '0px',
                                        margin: '0px',
                                        border: '0px',
                                        verticalAlign: 'top',
                                        width: '100%',
                                        height: '67px',
                                    }}>
                                    <Box className="password-lock-icon">
                                        <IconButton tabIndex={-1}>
                                            <SvgIcon sx={{ width: '16px', height: '16px' }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="17" viewBox="0 0 14 17" fill="none">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.96303 1.05762C4.85522 1.05762 3.14501 2.76403 3.14501 4.86876V6.90975H2.58619C1.71626 6.90975 1 7.62206 1 8.49163V14.4418C1 15.3113 1.71626 16.0237 2.58619 16.0237H11.4173C12.2872 16.0237 13 15.3113 13 14.4418V8.49163C13 7.62206 12.2872 6.90975 11.4173 6.90975H10.7836V4.86876C10.7836 2.76403 9.07085 1.05762 6.96303 1.05762ZM6.96303 1.93797C8.59939 1.93797 9.90328 3.23756 9.90328 4.86876V6.90975H4.02536V4.86876C4.02536 3.23756 5.32666 1.93797 6.96303 1.93797ZM2.58619 7.7901H3.58346H10.3417H11.4173C11.8157 7.7901 12.1196 8.09559 12.1196 8.49163V14.4418C12.1196 14.8378 11.8157 15.1433 11.4173 15.1433H2.58619C2.18771 15.1433 1.88035 14.8378 1.88035 14.4418V8.49163C1.88035 8.09559 2.18771 7.7901 2.58619 7.7901ZM7.00172 9.34964C6.15965 9.34964 5.46454 10.0416 5.46454 10.8834C5.46454 11.572 5.92961 12.1578 6.55982 12.3483V13.1453C6.56005 13.2031 6.57166 13.2603 6.59399 13.3136C6.61631 13.3669 6.64893 13.4153 6.68996 13.4561C6.731 13.4968 6.77965 13.529 6.83314 13.5509C6.88663 13.5728 6.94391 13.584 7.00172 13.5838C7.11787 13.5833 7.22913 13.537 7.31126 13.4548C7.39338 13.3727 7.43972 13.2615 7.44018 13.1453V12.3492C8.07092 12.1595 8.53547 11.573 8.53546 10.8834C8.53546 10.0416 7.84379 9.34964 7.00172 9.34964ZM7.00172 10.23C7.36906 10.23 7.65511 10.5184 7.65511 10.8834C7.65511 11.2483 7.36906 11.5333 7.00172 11.5333C6.63438 11.5333 6.34489 11.2483 6.34489 10.8834C6.34489 10.5184 6.63438 10.23 7.00172 10.23Z" fill="#6B7280" stroke="#6B7280" stroke-width="0.5" />
                                                </svg>
                                            </SvgIcon>
                                        </IconButton>
                                    </Box>
                                    <InputLabel htmlFor="outlined-adornment-password">Password (7 to 12 characters)</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={showPassword ? 'text' : 'password'}
                                        autoComplete="current-password"
                                        tabIndex={2}
                                        placeholder="Enter your password"
                                        {...register('password')}
                                        error={!!errors.password}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    edge="end"
                                                    tabIndex={-1}
                                                    onClick={() => setShowPassword(!showPassword)}
                                                >
                                                    <SvgIcon sx={{ width: '16px', height: '16px' }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="10" viewBox="0 0 16 10" fill="none">
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M8 0.0604248C11.3236 0.0604248 14.1512 2.44413 15.5411 3.86595C16.153 4.48987 16.153 5.50774 15.5411 6.13366C14.1512 7.55549 11.3236 9.93919 8 9.93919C4.67641 9.93919 1.84877 7.55549 0.458942 6.13366C-0.152981 5.50974 -0.152981 4.49187 0.458942 3.86595C1.84877 2.44413 4.67641 0.0604248 8 0.0604248ZM8.002 9.07529C11.0076 9.07529 13.6293 6.85357 14.9231 5.52974C15.2091 5.23778 15.2091 4.76184 14.9231 4.46987C13.6293 3.14604 11.0076 0.924317 8.002 0.924317C4.99637 0.924317 2.3747 3.14604 1.08086 4.46987C0.7949 4.76384 0.7949 5.23778 1.08086 5.52974C2.3747 6.85357 4.99637 9.07529 8.002 9.07529ZM7.99995 1.42029C6.02619 1.42029 4.42039 3.02609 4.42039 4.99984C4.42039 6.97359 6.02619 8.57939 7.99995 8.57939C9.9737 8.57939 11.5795 6.97359 11.5795 4.99984C11.5795 3.02609 9.9737 1.42029 7.99995 1.42029ZM7.99995 7.7135C6.50413 7.7135 5.28629 6.49565 5.28629 4.99984C5.28629 3.50403 6.50413 2.28618 7.99995 2.28618C9.49576 2.28618 10.7136 3.50403 10.7136 4.99984C10.7136 6.49565 9.49576 7.7135 7.99995 7.7135ZM7.99994 3.36404C7.09605 3.36404 6.36414 4.09595 6.36414 4.99983C6.36414 5.90372 7.09605 6.63563 7.99994 6.63563C8.90383 6.63563 9.63573 5.90372 9.63573 4.99983C9.63573 4.09595 8.90383 3.36404 7.99994 3.36404Z" fill="#6B7280" />
                                                        </svg>
                                                    </SvgIcon>
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label='Password (6+ characters)'
                                    />
                                    <FormHelperText style={{ color: errors.password ? '#FF4842' : 'inherit' }}>{errors.password?.message}</FormHelperText>
                                </FormControl>
                                {/* <Box> */}
                                <Typography className="text-secondary" component="p" >
                                    By continuing, you agree to our{' '}
                                    <Link underline="none" tabIndex={-1} sx={{ cursor: 'pointer' }} onClick={() => handleOpenTermsDialog()}>Terms & Conditions</Link>
                                </Typography>
                                {/* </Box> */}
                                <Button
                                    type="submit"
                                    disabled={isSubmitting || !isValid}
                                    variant='contained'
                                    size='large'
                                    className={`${isValid ? "login-btn" : 'disable-login-btn'}`}
                                    disableElevation
                                    onClick={handleOpenOtpDialog}
                                >
                                    Register
                                </Button>
                                <Box>
                                    <Typography className="register-link" component="p">
                                        Already registered?&nbsp;
                                        <Link onClick={() => handleRegisterClose()} sx={{ cursor: 'pointer' }} underline="none">
                                            Login
                                        </Link>
                                    </Typography>
                                </Box>
                            </Stack>
                        </Box>
                    </Box>
                </Box>
            </FormProvider>
            {showOTP && open && (isMobile ?
                <Drawer
                    anchor="bottom"
                    open={open}
                    onClose={(event, reason) => {
                        if (reason !== 'backdropClick') {
                            handleClose()
                        }
                    }}
                    className="forgot-password-dialog"
                >
                    <OneTimePassword
                        userId={userId}
                        onCancelOTP={handleClose}
                        phoneNumber={phoneNumber}
                        email={email}
                        isRegister={true}
                        isEmailVerified={false}
                        isPhoneVerified={true}
                        jobId={jobId}
                        close={handleOpenOnboardedResumeDialog}
                    />
                </Drawer> :
                <Dialog
                    open={open}
                    onClose={(event, reason) => {
                        if (reason !== 'backdropClick') {
                            handleClose()
                        }
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className="otp-dialog-wrapper"
                >
                    <OneTimePassword userId={userId} onCancelOTP={handleClose} email={email} isRegister={true} isEmailVerified={false} isPhoneVerified={true} jobId={jobId} close={handleOpenOnboardedResumeDialog} />
                </Dialog>)}
            <Dialog
                open={showTerm}
                onClose={handleCloseTermsDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="otp-dialog-wrapper"
            >
                <TermAndCondition onClose={handleCloseTermsDialog} />
            </Dialog>

            {/* Dialog for Larger Screens */}
            {!isMobile && (
                <Dialog open={openOnboardedResume} onClose={handleCloseOnboardedResumeDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="forgot-password-dialog">
                    <ResumeOnboarded />
                </Dialog>
            )}

            {/* Drawer for Mobile Screens */}
            {isMobile && (
                <Drawer
                    anchor="bottom"
                    open={openOnboardedResume}
                    onClose={handleCloseOnboardedResumeDialog}
                    className="resume-onboard-screen forgot-password-dialog mobile-drawer-view"
                >
                    <ResumeOnboarded />
                </Drawer>
            )}

        </Box>
    )
}
export default Register;
import { Tooltip, Box, Dialog, FormControl, Stack, Divider, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, SvgIcon, Typography, TextField, FormHelperText, Link } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { amber, green } from '@mui/material/colors';
import { useAuth } from 'hooks/useAuthContext';
import UpdateWhatsapp from './UpdateWhatsapp';
import React, { useEffect, useState } from 'react';
import UpdateEmail from './UpdateEmail';
import { useSnackbar } from 'components/snackbar';
import { CandidateBasicContactRule } from 'models/CandidateProfileSchema';
import { useMediaQuery, Drawer, Card, CardContent, Button } from '@mui/material';
// import Profile from 'pages/temp/Profile';
// import LinkIcon from '@mui/icons-material/Link';
// import LinkedInProfileDialog from './LinkedInProfileDialog'
import { useTheme } from '@mui/material/styles';
import { addLinkedInProfile } from 'api/users';
const ContactDetails = ({ contactDetailsError, isResume, handleResumeProfile }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { user, logout, updateContext } = useAuth();
    const [open, setOpen] = useState(user?.phone ? false : true);
    const [contact, setContact] = useState(user?.phone ? '' : 'phone');
    const [error, setErrors] = useState('');
    const [openLinkedInDialog, setOpenLinkedInDialog] = React.useState(false);
    const [linkedinProfile, setLinkedinProfile] = useState(user?.linkedin_profile || '');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleOpenContactInfo = () => {
        setOpen(true);
        setContact('email');
    };

    const handleOpenWhatsappContact = () => {
        setOpen(true);
        setContact('phone');
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseWithNumber = (phoneNumber) => {
        if (phoneNumber) {
            updateContext({ 'phone': phoneNumber });
        }
        setOpen(false);
    };

    const handleCloseWithEmail = (email) => {
        if (email) {
            updateContext({ 'email': email });
        }
        setOpen(false);
    };

    const validateLinkedInProfile = (linkedinProfile) => {
        if (!linkedinProfile) {
            return true;
        }
        try {
            return linkedinProfile.includes('linkedin.com');
        } catch (e) {
            return false;
        }
    };

    const handleLinkedInProfile = async (event) => {
        const linkedInProfile = event.target.value;
        setLinkedinProfile(linkedInProfile);
        if (!validateLinkedInProfile(linkedInProfile)) {
            setErrors('Please enter a valid LinkedIn profile URL.');
            return false;
        }
        setErrors('');
        if (isResume) {
            handleResumeProfile({ 'linkedInProfile': linkedInProfile });
            return;
        }
        const response = await addLinkedInProfile({ 'linkedInProfile': linkedInProfile });
        if (response?.success === true && response?.data) {
            updateContext({ 'linkedin_profile': linkedInProfile });
            // enqueueSnackbar(response?.message, { variant: 'success' });
        } else if (response?.success === false) {
            enqueueSnackbar(response.message, { variant: 'error' });
        }

    };

    const handleCloseLinkedInDialog = () => {
        setOpenLinkedInDialog(false);
    };

    return (
        <Box>
            <Typography className="step-heading" variant="h6" gutterBottom>
                Contact Details
            </Typography>
            <Typography className="step-info" variant="body2" gutterBottom>
                These details would help us reach out to you directly
            </Typography>
            <Box component="form" noValidate autoComplete="off" mt={2}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }} mb={1}>
                    <Grid item xs={12} md={6} mb={2.5}>
                        <FormControl size="small" className="wh-number-input" fullWidth>
                            <InputLabel htmlFor="outlined-adornment-password">WhatsApp Number</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                endAdornment={
                                    <InputAdornment position="end">
                                        {/* <Tooltip title={isResume && "Data updated in this section will be reflected across all resumes"} placement="top" arrow> */}
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                edge="end"
                                                onClick={handleOpenWhatsappContact}
                                            >
                                                <SvgIcon sx={{ width: '16px', height: '16px' }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                        <path d="M12.2907 16.0003H2.10143C0.944657 16.0003 0.00354004 15.0593 0.00354004 13.9024V3.71313C0.00354004 2.55635 0.944542 1.61523 2.10143 1.61523H6.77058C7.10174 1.61523 7.36995 1.88345 7.36995 2.2146C7.36995 2.54576 7.10174 2.81398 6.77058 2.81398H2.10143C1.60546 2.81398 1.20238 3.21706 1.20238 3.71303V13.9023C1.20238 14.3983 1.60546 14.8014 2.10143 14.8014H12.2907C12.7867 14.8014 13.1898 14.3983 13.1898 13.9023V9.23319C13.1898 8.90203 13.458 8.63381 13.7892 8.63381C14.1203 8.63381 14.3885 8.90203 14.3885 9.23319V13.9023C14.3885 15.0591 13.4476 16.0003 12.2907 16.0003Z" fill="#6B7280" />
                                                        <path d="M4.44409 9.00838L11.0013 2.45117L13.5478 4.99768L6.9906 11.5549L4.44409 9.00838Z" fill="#6B7280" />
                                                        <path d="M15.7117 1.43961L14.5609 0.28882C14.1758 -0.0962733 13.551 -0.0962733 13.1651 0.28882L11.8495 1.60444L14.396 4.15095L15.7116 2.83533C16.0967 2.45024 16.0968 1.82545 15.7117 1.43961Z" fill="#6B7280" />
                                                        <path d="M3.6757 9.9353L3.08308 12.523C3.02914 12.7598 3.24042 12.9711 3.47717 12.9171L6.06492 12.3245L3.6757 9.9353Z" fill="#6B7280" />
                                                    </svg>
                                                </SvgIcon>
                                            </IconButton>
                                        {/* </Tooltip> */}
                                    </InputAdornment>
                                }
                                inputProps={{
                                    readOnly: true,
                                }}
                                disabled
                                label="WhatsApp Number"
                                value={user?.phone || ''}
                            />
                            {user?.phone &&
                                <Box className="iti-flag-container" sx={{ marginTop: "-2px;" }}>
                                    <Stack direction="row" alignItems={'center'} spacing={1}>
                                        <Box className="iti-flag"></Box>
                                        <Box className="selected-dial-code">+91</Box>
                                        <Divider orientation="vertical" variant="middle" flexItem />
                                    </Stack>
                                </Box>
                            }
                            {
                                !user?.phone ? <FormHelperText error>Please add your WhatsApp number.</FormHelperText>
                                    :
                                    <FormHelperText>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {/* {user?.is_phone_verified ? ( */}
                                            <>
                                                <CheckCircleIcon style={{ color: green[500], marginRight: 4, fontSize: '15px' }} />
                                                <Typography sx={{ color: green[500], fontSize: '12px' }} variant="body2">
                                                    Verified
                                                </Typography>
                                            </>
                                            {/* ) :  ( */}
                                            {/* <>
                                            <ErrorOutlineIcon style={{ color: amber[700], marginRight: 4, fontSize: '15px' }} />
                                            <Typography sx={{ color: amber[700], fontSize: '12px' }} variant="body2">
                                                Not verified
                                            </Typography>
                                            <Link href="/verify" underline="hover" style={{ marginLeft: 8, fontSize: '12px' }}>
                                                Verify now
                                            </Link>
                                        </> */}
                                            {/* )} */}
                                        </div>
                                    </FormHelperText>
                            }
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} mb={2.5}>
                        <FormControl size="small" fullWidth>
                            <InputLabel htmlFor="outlined-adornment-password">Email Id</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-confirm-password"
                                startAdornment={
                                    <InputAdornment position="start">
                                        <SvgIcon sx={{ width: '16px', height: '16px' }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12" fill="none">
                                                <path
                                                    d="M14 -0.000244141H2.00001C1.09796 -0.000244141 0.363647 0.734069 0.363647 1.63612V10.3634C0.363647 11.2654 1.09796 11.9998 2.00001 11.9998H14C14.9021 11.9998 15.6364 11.2654 15.6364 10.3634V1.63612C15.6364 0.734069 14.9021 -0.000244141 14 -0.000244141ZM13.6366 1.09066L8.32798 5.0724C8.13502 5.21694 7.86572 5.21694 7.67276 5.0724L2.36343 1.09066H13.6366ZM14 10.9088H2.00001C1.69864 10.9088 1.45456 10.6641 1.45456 10.3634V1.77244L7.01819 5.94512C7.30659 6.16194 7.65364 6.27035 8.00001 6.27035C8.34638 6.27035 8.69343 6.16195 8.98183 5.94512L14.5455 1.77244V10.3634C14.5455 10.6641 14.3014 10.9088 14 10.9088Z"
                                                    fill="#6B7280"
                                                />
                                            </svg>
                                        </SvgIcon>
                                    </InputAdornment>
                                }
                                endAdornment={
                                    <InputAdornment position="end">
                                        {/* <Tooltip title={isResume && "Data updated in this section will be reflected across all resumes"} placement="top" arrow> */}
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                edge="end"
                                                onClick={handleOpenContactInfo}
                                            >
                                                <SvgIcon sx={{ width: '16px', height: '16px' }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                        <path d="M12.2907 16.0003H2.10143C0.944657 16.0003 0.00354004 15.0593 0.00354004 13.9024V3.71313C0.00354004 2.55635 0.944542 1.61523 2.10143 1.61523H6.77058C7.10174 1.61523 7.36995 1.88345 7.36995 2.2146C7.36995 2.54576 7.10174 2.81398 6.77058 2.81398H2.10143C1.60546 2.81398 1.20238 3.21706 1.20238 3.71303V13.9023C1.20238 14.3983 1.60546 14.8014 2.10143 14.8014H12.2907C12.7867 14.8014 13.1898 14.3983 13.1898 13.9023V9.23319C13.1898 8.90203 13.458 8.63381 13.7892 8.63381C14.1203 8.63381 14.3885 8.90203 14.3885 9.23319V13.9023C14.3885 15.0591 13.4476 16.0003 12.2907 16.0003Z" fill="#6B7280" />
                                                        <path d="M4.44409 9.00838L11.0013 2.45117L13.5478 4.99768L6.9906 11.5549L4.44409 9.00838Z" fill="#6B7280" />
                                                        <path d="M15.7117 1.43961L14.5609 0.28882C14.1758 -0.0962733 13.551 -0.0962733 13.1651 0.28882L11.8495 1.60444L14.396 4.15095L15.7116 2.83533C16.0967 2.45024 16.0968 1.82545 15.7117 1.43961Z" fill="#6B7280" />
                                                        <path d="M3.6757 9.9353L3.08308 12.523C3.02914 12.7598 3.24042 12.9711 3.47717 12.9171L6.06492 12.3245L3.6757 9.9353Z" fill="#6B7280" />
                                                    </svg>
                                                </SvgIcon>
                                            </IconButton>
                                        {/* </Tooltip> */}
                                    </InputAdornment>
                                }
                                disabled
                                label="Email Id"
                                value={user?.email}
                            />
                            <FormHelperText>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {user?.is_email_verified ? (
                                        <>
                                            <CheckCircleIcon style={{ color: green[500], marginRight: 4, fontSize: '15px' }} />
                                            <Typography sx={{ color: green[500], fontSize: '12px' }} variant="body2">
                                                Verified
                                            </Typography>
                                        </>
                                    ) : (
                                        <>
                                            <ErrorOutlineIcon style={{ color: amber[700], marginRight: 4, fontSize: '15px' }} />
                                            <Typography sx={{ color: amber[700], fontSize: '12px' }} variant="body2">
                                                Verification pending.
                                            </Typography>
                                            <Link onClick={handleOpenContactInfo} style={{ marginLeft: 8, fontSize: '12px', cursor: 'pointer', textDecoration: 'none' }}>
                                                Verify now
                                            </Link>
                                        </>
                                    )}
                                </div>
                            </FormHelperText>
                        </FormControl>

                    </Grid>
                </Grid>
                <FormControl size="small" fullWidth>
                    <InputLabel htmlFor="linked-in-profile">LinkedIn Profile URL (Optional)</InputLabel>
                    <OutlinedInput
                        id="linked-in-profile"
                        onBlur={handleLinkedInProfile}
                        onChange={handleLinkedInProfile}
                        label="LinkedIn Profile (Optional)"
                        value={linkedinProfile}
                    />
                    {error && <FormHelperText error>{error}</FormHelperText>}
                </FormControl>
            </Box>

            {isMobile ?
                <Drawer
                    anchor="bottom"
                    open={open}
                    onClose={user?.phone ? handleClose : ''}
                    className="forgot-password-dialog"
                >
                    {contact === 'phone' && <UpdateWhatsapp onCancel={user?.phone ? handleClose : logout} onCancelWithNumber={handleCloseWithNumber} isResume={isResume} handleResumeProfile={handleResumeProfile} />}
                    {contact === 'email' && <UpdateEmail onCancel={handleClose} onCancelWithEmail={handleCloseWithEmail} isResume={isResume} handleResumeProfile={handleResumeProfile} />}
                </Drawer> :
                <Dialog
                    open={open}
                    onClose={user?.phone ? handleClose : ''}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className="otp-dialog-wrapper">
                    {contact === 'phone' && <UpdateWhatsapp onCancel={user?.phone ? handleClose : logout} onCancelWithNumber={handleCloseWithNumber} isResume={isResume} handleResumeProfile={handleResumeProfile} />}
                    {contact === 'email' && <UpdateEmail onCancel={handleClose} onCancelWithEmail={handleCloseWithEmail} isResume={isResume} handleResumeProfile={handleResumeProfile} />}
                </Dialog>
            }

            {/* Linked in dialog */}
            {/* <Dialog
                open={openLinkedInDialog}
                onClose={handleCloseLinkedInDialog}
                className="action-dialog-card lined-in-dialog"
            >
                <LinkedInProfileDialog onCloseDialog={handleCloseLinkedInDialog}/>
                
            </Dialog> */}
        </Box>
    )
}

export default ContactDetails

import React from 'react'
import { Box, Button, Card, CardContent, FormControl, FormControlLabel, Grid, Radio, Stack, Typography, IconButton, SvgIcon } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import { useState, useRef } from 'react';
import { addCandidateResume } from '../../api/users';
import { useSnackbar } from 'components/snackbar';
// import CorrectTick from '../assets/correct_tick.png';
const CorrectTick = `${process.env.REACT_APP_HOMEPAGE}assets/correct-tick.png`;
const loginLogo = `${process.env.REACT_APP_HOMEPAGE}assets/TJ-Logo-LightBG-withTagline 1.svg`;

const ResumeOnboarded = () => {
    const [selectedOption, setSelectedOption] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const fileInputRef = useRef(null);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const handleChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type === "application/pdf") {
            setSelectedFile(file);
            // console.log("PDF Selected:", file.name);
        } else {
            enqueueSnackbar("Please upload a valid PDF file.");
        }
    };

    const handleSubmit = async () => {
        if (selectedOption === "create") {
            navigate('/');
        } else if (selectedOption === "existing") {
            if (!selectedFile) {
                enqueueSnackbar("Please select a file first.");
                return;
            }
            if (selectedFile.size > 2 * 1024 * 1024) {
                enqueueSnackbar("File size should be less than 2MB", { variant: "error" });
                return;
            }

            // Allow only PDFs
            if (selectedFile.type !== "application/pdf") {
                enqueueSnackbar("Only PDF files are allowed", { variant: "error" });
                return;
            }
            handleFileSubmit(selectedFile);
        }
    };

    const handleFileUploadClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileSubmit = async () => {
        if (!selectedFile) {
            enqueueSnackbar("Please select a file first", { variant: "error" });
            handleFileUploadClick();
            return;
        }

        try {
            const formData = new FormData();
            formData.append("file", selectedFile);
            const response = await addCandidateResume(formData);
            if (response?.success) {
                enqueueSnackbar("Resume uploaded successfully", { variant: "success" });
                // console.log("Upload Success:", response);
                navigate('/registration-upload-resume');
            } else {
                enqueueSnackbar("Failed to upload resume", { variant: "error" });
                // console.log("Upload Error:", response?.message);
            }
        } catch (error) {
            console.error("Upload Error:", error);
            enqueueSnackbar("Failed to upload resume", { variant: "error" });
        }
    }

    return (
        <Card variant="outlined" className="resume-onboarded-card-container" sx={{ padding: { xs: 0, md: '24px 32px' } }}>
            <CardContent sx={{ padding: { xs: '16px', md: '0!important' } }}>
                <Box mb={3}>
                    <Stack direction={'row'} justifyContent={'flex-start'} spacing={2}>
                        <IconButton className="back-arrow" aria-label="delete" disableRipple onClick={() => navigate('/')}>
                            <SvgIcon>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M17.6992 9.41164H3.93112L8.27894 5.30155C8.55068 5.04467 8.55068 4.61653 8.27894 4.35965C8.00721 4.10277 7.55431 4.10277 7.28257 4.35965L1.84779 9.58289C1.75721 9.66852 1.66663 9.83978 1.66663 10.011C1.66663 10.1823 1.75721 10.3535 1.84779 10.4392L7.28257 15.6624C7.37315 15.748 7.55431 15.8337 7.73547 15.8337C7.91663 15.8337 8.09779 15.748 8.18836 15.6624C8.4601 15.4055 8.4601 14.9774 8.18836 14.7205L3.84054 10.6104H17.6087C17.971 10.6104 18.3333 10.3535 18.3333 9.9254C18.3333 9.49727 18.0616 9.41164 17.6992 9.41164Z" fill="#002149" />
                                </svg>
                            </SvgIcon>
                        </IconButton>
                        <Box
                            className="logo"
                            component="img"
                            alt="Talent Junction"
                            src={loginLogo}
                        />
                    </Stack>
                </Box>
                <Box mb={2.5}>
                    <Stack spacing={1}>
                        <Typography className="title">Let’s get you onboarded...</Typography>
                        <Typography className="info-text" sx={{ textAlign: 'left!important' }}>Please select one of the option below to help us create your profile and identify the most suitable jobs for you</Typography>
                    </Stack>
                </Box>
                <Box>
                    <Grid container spacing={2}>
                        {/* Create a resume option */}
                        <Grid item xs={12} md={6}>
                            <Box className={`resume-onboarded-card ${selectedOption === "create" ? "selected" : ""}`} onClick={() => setSelectedOption("create")}                            >
                                <Box className="card-top">
                                    <Box className="recommended-label"><Typography>RECOMMENDED</Typography></Box>
                                    <FormControl sx={{ marginBottom: '12px' }} fullWidth>
                                        <FormControlLabel
                                            value="create"
                                            control={<Radio checked={selectedOption === "create"} onChange={handleChange} />}
                                            label="Create a resume"
                                            labelPlacement="start"
                                            componentsProps={{
                                                typography: { sx: { fontSize: '14px !important' } }
                                            }}
                                        />
                                    </FormControl>
                                    <Stack className="list-item" spacing={1}>
                                        <Stack direction="row" spacing={1}>
                                            <Box
                                                component="img"
                                                alt="Talent Junction"
                                                src={CorrectTick}
                                                sx={{ width: "12px", height: "12px", marginTop: "3px!important" }}
                                            />
                                            <Typography>World class resume in minutes</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={1}>
                                            <Box
                                                component="img"
                                                alt="Talent Junction"
                                                src={CorrectTick}
                                                sx={{ width: "12px", height: "12px", marginTop: "3px!important" }}
                                            />
                                            <Typography>Add multiple customised versions</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={1}>
                                            <Box
                                                component="img"
                                                alt="Talent Junction"
                                                src={CorrectTick}
                                                sx={{ width: "12px", height: "12px", marginTop: "3px!important" }}
                                            />
                                            <Typography>Best job matching</Typography>
                                        </Stack>
                                    </Stack>
                                </Box>
                            </Box>
                        </Grid>

                        {/* Already have resume option */}
                        <Grid item xs={12} md={6}>
                            <Box className={`resume-onboarded-card ${selectedOption === "existing" ? "selected" : ""}`} onClick={() => { setSelectedOption("existing"); handleFileUploadClick(); }}>
                                <Box className="card-top" sx={{ height: { xs: 'auto', md: '180px!important' } }}>
                                    <FormControl sx={{ marginBottom: "12px" }} fullWidth>
                                        <FormControlLabel
                                            value="existing"
                                            control={<Radio checked={selectedOption === "existing"} onChange={handleChange} />}
                                            label="Already have resume"
                                            labelPlacement="start"
                                            componentsProps={{
                                                typography: { sx: { fontSize: '14px !important' } }
                                            }}
                                        />
                                    </FormControl>
                                    <Stack className="list-item" spacing={1}>
                                        <Stack direction="row" spacing={1}>
                                            <Box
                                                component="img"
                                                alt="Talent Junction"
                                                src={CorrectTick}
                                                sx={{ width: "12px", height: "12px", marginTop: "3px!important" }}
                                            />
                                            <Typography>Create world class TJ format resume later</Typography>
                                        </Stack>
                                        {/* <Stack direction="row" justifyContent="center" alignItems="center" sx={{ mt: "auto" }}>
                                            <Typography>
                                                Remember: Adding comprehensive details ensures best job matching
                                            </Typography>
                                        </Stack> */}
                                    </Stack>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <input
                    type="file"
                    accept="application/pdf"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                />
                {/* Continue Button */}
                <Box sx={{ marginTop: { xs: '24px', md: '32px' } }}>
                    <Button fullWidth className="continue-btn" variant="contained" size="medium" onClick={handleSubmit} disabled={!selectedOption}>
                        Continue
                    </Button>
                </Box>
            </CardContent>
        </Card >
    )
}

export default ResumeOnboarded

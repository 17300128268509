// utils
import { fetcher, endpoints, poster, formPoster, patcher } from '../utils/axios';

export async function getUser() {
    const URL = endpoints.user.root;
    return await fetcher([URL, { params: null }])
}

export async function getUserProfile(payload) {
    const URL = endpoints.user.getUserProfile;
    return await fetcher([URL, payload])
}

export async function updateBasicProfile(payload) {
    const URL = endpoints.user.updateBasicProfile;
    return await poster([URL, payload])
}

export async function updatePersonalDetails(payload) {
    const URL = endpoints.user.updatePersonalDetails;
    return await poster([URL, payload])
}


export async function addLinkedInProfile(payload) {
    const URL = endpoints.user.addLinkedInProfile;
    return await poster([URL, payload])
}

export async function getBasicProfileDetails(payload) {
    const URL = endpoints.user.getBasicProfileDetails;
    return await fetcher([URL, { params: payload }])
}

export async function getKeyFocusArea(payload) {
    const URL = endpoints.user.getKeyFocusArea;
    return await fetcher([URL, { params: payload }])
}

export async function addCandidateResume(payload, headers) {
    const URL = endpoints.user.addCandidateResume;
    return await formPoster([URL, payload, headers])
}

export async function setDefaultResume(payload) {
    const URL = endpoints.user.setDefaultResume
    return await poster([URL, payload])
}

export async function deleteResume(payload) {
    const URL = endpoints.user.deleteResume;
    return await poster([URL, payload])
}

export async function addJobPreferance(payload) {
    const URL = endpoints.user.addJobPreferance;
    return await poster([URL, payload])
}

export async function getJobPreferanceDetails(payload) {
    const URL = endpoints.user.getJobPreferanceDetails;
    return await fetcher([URL, { params: payload }])
}

export async function uploadProfilePic(payload, headers) {
    const URL = endpoints.user.uploadProfilePic;
    return await formPoster([URL, payload, headers])
}
export async function handleUploadData(payload, headers) {
    const URL = endpoints.user.handleUploadData;
    return await formPoster([URL, payload, headers])
}
export async function uploadResumeData(payload, headers) {
    const URL = endpoints.user.uploadResumeData;
    return await formPoster([URL, payload, headers])
}

export async function getAllEducationDetails(payload) {
    const URL = endpoints.user.getAllEducationDetails;
    return await fetcher([URL, { params: payload }])
}

export async function getResumeUrl(payload) {
    const URL = endpoints.user.getResumeUrl;
    return await fetcher([URL, { params: payload }])
}
export async function updateResumeUrl(payload) {
    const URL = endpoints.user.updateResumeUrl;
    return await formPoster([URL, payload])
}

export async function getAllCertificationDetails(payload) {
    const URL = endpoints.user.getAllCertificationDetails;
    return await fetcher([URL, { params: payload }])
}

export async function getEducationDetails(payload) {
    const URL = endpoints.user.getEducationDetails;
    return await fetcher([URL, { params: payload }])
}

export async function getCertificationDetails(payload) {
    const URL = endpoints.user.getCertificationDetails;
    return await fetcher([URL, { params: payload }])
}

export async function getWorkHistoryData(payload) {
    const URL = endpoints.user.getWorkHistoryData;
    return await fetcher([URL, { params: payload }])
}

export async function updateCandidateCertificate(payload) {
    const URL = endpoints.user.updateCandidateCertificate;
    return await poster([URL, payload])
}

export async function updateCandidateEducation(payload) {
    const URL = endpoints.user.updateCandidateEducation;
    return await poster([URL, payload])
}
export async function updateCandidateWorkHistory(payload) {
    const URL = endpoints.user.updateCandidateWorkHistory;
    return await poster([URL, payload])
}
export async function updateCustomTotalWorkExperience(payload) {
    const URL = endpoints.user.updateCustomTotalWorkExperience;
    return await poster([URL, payload])
}

export async function addCandidateExpertiesAndSkills(payload) {
    const URL = endpoints.user.addCandidateExpertiesAndSkills;
    return await poster([URL, payload])
}

export async function getExpertiseAndSkillsDetails(payload) {
    const URL = endpoints.user.getExpertiseAndSkillsDetails;
    return await fetcher([URL, { params: payload }])
}

export async function updateStarDetails(payload) {
    const URL = endpoints.user.updateStarDetails;
    return await poster([URL, payload])
}

export async function deleteExpertiseAndSkills(payload) {
    const URL = endpoints.user.deleteExpertiseAndSkills;
    return await poster([URL, payload])
}
export async function deleteEducationHistory(payload) {
    const URL = endpoints.user.deleteEducationHistory;
    return await poster([URL, payload])
}
export async function deleteWorkHistory(payload) {
    const URL = endpoints.user.deleteWorkHistory;
    return await poster([URL, payload])
}
export async function deleteCertificateHistory(payload) {
    const URL = endpoints.user.deleteCertificateHistory;
    return await poster([URL, payload])
}

export async function passwordReset(payload) {
    const URL = endpoints.user.passwordReset;
    return await poster([URL, payload])
};

export async function getFunctionalDomain(payload) {
    const URL = endpoints.user.getFunctionalDomain;
    return await fetcher([URL, { params: payload }])
}

export async function getDomainExpertise(payload) {
    const URL = endpoints.user.getDomainExpertise;
    return await fetcher([URL, { params: payload }])
}

export async function getExpertiseSkills(payload) {
    const URL = endpoints.user.getExpertiseSkills;
    return await fetcher([URL, { params: payload }])
}

export async function addCustomSection(payload) {
    const URL = endpoints.user.addCustomSection;
    return await poster([URL, payload])
}

export async function getCustomSection(payload) {
    const URL = endpoints.user.getCustomSection;
    return await fetcher([URL, { params: payload }])
}

export async function deleteCustomSection(payload) {
    const URL = endpoints.user.deleteCustomSection;
    return await poster([URL, payload])
}

export async function addSummary(payload) {
    const URL = endpoints.user.addSummary;
    return await poster([URL, payload])
}
export async function updateFinanceTechnologies(payload) {
    const URL = endpoints.user.updateFinanceTechnologies;
    return await poster([URL, payload])
}

export async function updateUser(payload) {
    const URL = endpoints.user.updateUser;
    return await poster([URL, payload])
}

export async function getTemplate(payload) {
    const URL = endpoints.user.getTemplate;
    return await fetcher([URL, { params: payload }])
}
export async function setTemplate(payload) {
    const URL = endpoints.user.setTemplate;
    return await poster([URL, payload])
}

export async function createDuplicateResume(payload) {
    const URL = endpoints.user.createDuplicateResume;
    return await poster([URL, payload])
}

export async function getResumesData(payload) {
    const URL = endpoints.user.getResumesData;
    return await fetcher([URL, { params: payload }])
}
export async function setResumesData(payload) {
    const URL = endpoints.user.setResumesData;
    return await poster([URL, payload])
}

export async function updateResumeData(payload) {
    const URL = endpoints.user.updateResumeData;
    return await poster([URL, payload])
    
}
export async function getSummary(payload) {
    const URL = endpoints.user.getSummary;
    return await fetcher([URL, { params: payload }])
}

export async function updateContact(payload) {
    const URL = endpoints.user.updateContact;
    return await poster([URL, payload])
}

export async function setContact(payload) {
    const URL = endpoints.user.setContact;
    return await poster([URL, payload])
}

export async function updateCandidateWorkExperience(payload) {
    const URL = endpoints.user.updateCandidateWorkExperience;
    return await poster([URL, payload])
}

export async function updateCandidateWorkExperienceGap(payload) {
    const URL = endpoints.user.updateCandidateWorkExperienceGap;
    return await poster([URL, payload])
}
export async function updateSkills(payload) {
    const URL = endpoints.user.updateSkills;
    return await poster([URL, payload])
}

export async function addSingleJobPreference(payload) {
    const URL = endpoints.user.addSingleJobPreference;
    return await poster([URL, payload])
}
// for resume
export async function uploadPhotoInResume(payload, headers) {
    const URL = endpoints.user.uploadPhotoInResume;
    return await formPoster([URL, payload, headers])
}
export async function uploadResumeInResume(payload, headers) {
    const URL = endpoints.user.uploadResumeInResume;
    return await formPoster([URL, payload, headers])
}

// Old apis

export async function getB2Busers(payload) {
    const URL = endpoints.user.getB2Busers;
    // return await fetcher([URL, payload])
    return await fetcher([URL + `${""}`])
}

export async function removeB2BUser(payload) {
    const URL = endpoints.user.getB2Busers;
    return await fetcher([URL, payload])
}

export async function getB2BAssociatedUser(payload) {
    const URL = endpoints.user.getB2BAssociatedUser;
    return await fetcher([URL, payload])
}

export async function addB2BAssociatedUser(payload) {
    const URL = endpoints.user.addB2BAssociatedUser;
    return await poster([URL, payload])
}

export async function removeB2BAssociatedUser(payload) {
    const URL = endpoints.user.removeB2BAssociatedUser;
    return await poster([URL, payload])
}

export async function saveB2BProfile(payload) {
    const URL = endpoints.user.saveB2BProfile
    // return await poster([{URL, body: {companyProfileData : payload} }])
    return await poster([URL, payload])
}

export async function getInternalUsers(payload) {
    const URL = endpoints.user.getInternalUsers
    // return await poster([{URL, body: {companyProfileData : payload} }])
    return await fetcher([URL, payload])
}

export async function saveInternalUsers(payload) {
    const URL = endpoints.user.saveInternalUsers
    // return await poster([{URL, body: {companyProfileData : payload} }])
    return await poster([URL, payload])
}

export async function removeInternalUsers(payload) {
    const URL = endpoints.user.removeInternalUsers
    // return await poster([{URL, body: {companyProfileData : payload} }])
    return await poster([URL, payload])
}

export async function updateUserProfile(payload) {
    const URL = endpoints.user.updateUserProfile
    // return await poster([{URL, body: {companyProfileData : payload} }])
    return await poster([URL, payload])
}
export async function getUserOrderStatus(payload) {
    const URL = endpoints.user.getUserOrder
    return await fetcher([URL, payload])
}

export async function getInvoicePdf(payload) {
    const URL = endpoints.user.getInvoicePdf
    return await poster([URL, payload])
}

export async function saveInvoiceDetails(payload) {
    const URL = endpoints.user.saveInvoiceDetails
    return await poster([URL, payload])
}

export async function fetchInvoiceData(payload) {
    const URL = endpoints.user.fetchInvoiceData
    return await poster([URL, payload])
}

export async function toCheckInvoiceNumber(payload) {
    const URL = endpoints.user.toCheckInvoiceNumber
    return await poster([URL, payload])
} 

import { Box, Button, Stack, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { createPDF } from 'utils/createPDF';
import { downloadBlobFile } from 'utils/Utils';
const ResumeDownloaded = ({ onClose, handleContinueExternalApplication, isSubmitting }) => {

  return (
    <Box>
      <Stack justifyContent={'center'}>
        <Box sx={{ textAlign: 'center' }} mb={3}>
          <Typography className="title" mb={1}>
            Resume Downloaded
          </Typography>
          <Typography className="info-text">
            Now you can proceed to the external platform & complete your application with the downloaded
            application. All the best.
          </Typography>
        </Box>
        <Stack direction={{ xs: 'column-reverse', md: 'row' }} alignItems={'center'} spacing={2.5}>
          <Button fullWidth variant="outlined" size="medium" onClick={onClose}>
            Do It Later
          </Button>
          <Button
            fullWidth
            variant="contained"
            size="medium"
            onClick={handleContinueExternalApplication}
            disabled={isSubmitting}
          >
            {isSubmitting ? 'In-progress...' : 'Continue with application'}
          </Button>
        </Stack>
      </Stack>
    </Box>
  )
}

export default ResumeDownloaded;

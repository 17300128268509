import {
  Box,
  Card,
  CardContent,
  Typography,
  IconButton,
  Button,
  Stack,
  Dialog,
  Drawer,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, useMediaQuery, Tooltip, SvgIcon
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import dayjs from 'dayjs';
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import EducationDetails from './AddEducation';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { deleteWorkHistory, deleteEducationHistory } from 'api/users';
import { useSnackbar } from 'components/snackbar';
import RemoveEducation from './RemoveEducationDialog';
import { endpoints } from 'utils/axios';
import { changeNameFormat, allCapitalize } from 'utils/Utils';
import EducationDetailsMobile from '../basic-details-mobile-ui/EducationDetailsMobile';
import { useTheme } from '@mui/material/styles';
import InfoNote from './InfoNote';

const EducationSection = ({ item, educationData, onCancel, expandedSection, handleExpand, onEducationDataChange, certificateData, workExperienceData, showEdu, handleShowEdu, handleEducationDelete, uploadResume }) => {
  const [showEducation, setShowEducation] = useState(false);
  const [educations, setEducations] = useState([]);
  const [actionType, setActionType] = useState('');
  const [open, setOpen] = useState(false);
  const [itemId, setItemId] = useState('');
  const [title, setTitle] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [selectedCard, setSelectedCard] = useState(null);
  const [idForScroll, setIdForScroll] = useState(null);
  const [scroll, setScroll] = useState(false);
  const educationRef = useRef(null);
  const detailsRef = useRef(null);
  const [openDetailsDrawer, setOpenDetailsDrawer] = React.useState(false);
  const [degreeData, setDegreeData] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    fetchDegreeData();
  }, []);

  useEffect(() => {
    const newWork = educationData?.filter(data =>
      !educations?.some(history => history._id === data._id)
    );
    if (educationData && educationData?.length > 0) setIdForScroll(newWork[0]?._id || null);
    setEducations(educationData);
  }, [educationData]);
  useEffect(() => {
    if (idForScroll && scroll) {
      scrollToChild(idForScroll);
    }
  }, [idForScroll]);

  useLayoutEffect(() => {
    if (expandedSection?.section === 'education' && detailsRef?.current) {
      detailsRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
      // const offset = 40;
      // const element = detailsRef.current;
      // const scrollElement = (() => {
      //   let node = element.parentElement;
      //   while (node) {
      //     if (node.scrollHeight > node.clientHeight || node.scrollWidth > node.clientWidth) {
      //       return node;
      //     }
      //     node = node.parentElement;
      //   }
      //   return window;
      // })();
      // const scrollPosition = scrollElement === window
      //   ? element.getBoundingClientRect().top + window.scrollY - offset
      //   : element.offsetTop - offset;
      // if (scrollElement === window) {
      //   window.scrollTo({ top: scrollPosition, behavior: "smooth" });
      // } else {
      //   scrollElement.scrollTo({ top: scrollPosition, behavior: "smooth" });
      // }
    }
  }, [detailsRef, expandedSection]);

  const scrollToChild = (targetId) => {
    if (targetId) {
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        targetElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }
  };

  const handleCardClick = (item, index) => {
    handleExpand('education', index);
    setSelectedCard(item);
    setShowEducation(false);
    // setOpenDetailsDrawer(true);
  };

  const scrollToEducation = () => {
    educationRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleDeleteEducation = async (educationId, title, event) => {
    event.stopPropagation();
    if (!educationId) {
      localStorage.removeItem("education");
      setEducations([...educations?.filter(edu => edu?._id)]);
      setSelectedCard(null);
    } else {
      setActionType('education');
      setTitle(title);
      setItemId(educationId);
      setOpen(true);
      setOpenDetailsDrawer(false);
    }
  };

  const onHandleDataChange = async (isChanged) => {
    setShowEducation(false);
    if (isChanged) {
      await onEducationDataChange();
      setScroll(true);
    }
    setOpenDetailsDrawer(false);
    // scrollToEducation();
  };

  const handleClose = () => {
    setOpen(false);
    setItemId('');
  };
  const handleOpenDrawer = (newOpen) => {
    setOpenDetailsDrawer(newOpen);
  };
  const handleCardClickInMobile = (item, index) => {
    // handleExpand('education', index);
    // setOpenDetailsDrawer(newOpen);
    setSelectedCard(item);
    // setShowEducation(false);
    setOpenDetailsDrawer(true);
  };

  const handleDeleteAction = async () => {
    if (showEdu) {
      handleDeleteEdu(itemId);
      return;
    }
    if (actionType === 'education') {
      const response = await deleteEducationHistory({ educationId: itemId });
      if (response && response?.success) {
        setEducations(response?.data || []);
        onEducationDataChange();
      } else {
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    }
    handleClose();


  };

  const handleAddNewEdu = () => {
    setShowEducation(true);
    handleExpand("education", educations.length);
    setSelectedCard({});
  }
  const fetchDegreeData = async () => {
    const response = await fetch(`${process.env.REACT_APP_END_POINT}${endpoints?.services?.degrees}`);
    const resultData = await response.json();
    setDegreeData([...new Set([...resultData])]);
  };
  const checkingOverlapping = (data) => {
    const checkOverlap = (items, fromKey, toKey, typeLabel, degreeKey) => {
      for (let i = 0; i < items.length; i++) {
        const fromDate = new Date(items[i][fromKey]);
        const toDate = typeLabel === 'work experience' ? (items[i]?.present ? new Date() : new Date(items[i][toKey])) : new Date(items[i][toKey]);
        const dataFromDate = new Date(data?.education_from);
        const dataToDate = new Date(data?.education_to);

        if (fromDate <= dataToDate && dataFromDate <= toDate) {
          return { isOverlapping: true, type: typeLabel, degree: items[i][degreeKey] };
        }
      }
      return { isOverlapping: false };
    };

    const filterEducation = educationData?.filter(edu => edu?._id !== data?._id);

    const educationOverlap = checkOverlap(filterEducation, 'education_from', 'education_to', 'education', 'degree_received');
    if (educationOverlap.isOverlapping) return educationOverlap;

    const certificateOverlap = checkOverlap(certificateData, 'education_from', 'education_to', 'certificate', 'certificate_received');
    if (certificateOverlap.isOverlapping) return certificateOverlap;

    const workOverlap = checkOverlap(workExperienceData, 'work_history_from', 'work_history_to', 'work experience', 'last_job_function');
    if (workOverlap.isOverlapping) return workOverlap;

    return { isOverlapping: false };
  };

  const handleEducationResume = (education) => {
    localStorage.removeItem('education');
    setShowEducation(false);
    setOpenDetailsDrawer(false);
    setScroll(true);
    handleShowEdu('Education', education);
  }

  const handleDeleteEdu = (eduId) => {
    handleEducationDelete(eduId);
    // onEducationDataChange();   
    handleClose();
  };

  return (
    <Box className="candidate-basic-det-wrapper" sx={{ position: 'relative' }}>
      <Box mb={1.5}>
        <Typography className="step-heading xs-fs-16" variant="h6" gutterBottom>
          Your Education
        </Typography>
        <Typography className="step-info xs-fs-12" variant="body2" gutterBottom>
          {uploadResume ? 'Please provide details of your latest educational qualification (mandatory). You may also add other qualifications if you wish.' : 'Add your highest education degree first'}
        </Typography>
      </Box>
      {/* {showEdu && <InfoNote />} */}
      <Box mb={2}>
        {educations.length !== 0 &&
          educations.map((item, index) => (
            <React.Fragment key={item._id || index}>
              {expandedSection?.section !== 'education' || expandedSection.index !== index ? (
                <Box className={showEdu && "update-resume-accord-wrapper"}>
                  <Card id={idForScroll === item?._id ? idForScroll : null} className={`info-list-item ${item?.status !== "active" ? "incomplete-details" : ""}`} variant="outlined" key={item._id} sx={{ marginTop: 1 }} onClick={() => isMobile ? handleCardClickInMobile(item, index) : handleCardClick(item, index)}>
                    <CardContent >
                      <Stack direction="row" alignItems={'center'} justifyContent={'space-between'} spacing={2}>
                        <Box>
                          <Stack direction="row" alignItems={'center'} spacing={1.5}>
                            <Typography className="title-wrapper" variant="h6">
                              {item.degree_received} at {allCapitalize(item.education_institute_name)}
                            </Typography>
                            {item?.status !== "active" && <Box className="incomplete-tag">Incomplete</Box>}
                          </Stack>
                          <Typography className="info-wrapper" variant="body2" gutterBottom>
                            {item.education_from ? `${dayjs(item.education_from).format('MMM YYYY')} - ${item.education_to ? dayjs(item.education_to).format('MMM YYYY') : 'Present'}` : ''}
                          </Typography>
                        </Box>
                        <Stack direction="row" alignItems={'center'} spacing={2} >
                          {showEdu ?
                            <Tooltip title="Data updated in this section will be reflected across all resumes" placement="top" arrow>
                              <IconButton aria-label="delete" sx={{ padding: 0, marginRight: 1 }} className="edit-icon" disableRipple>
                                <SvgIcon>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M12.2906 16.0002H2.10131C0.944535 16.0002 0.00341797 15.0592 0.00341797 13.9023V3.71301C0.00341797 2.55623 0.94442 1.61511 2.10131 1.61511H6.77045C7.10161 1.61511 7.36983 1.88332 7.36983 2.21448C7.36983 2.54564 7.10161 2.81385 6.77045 2.81385H2.10131C1.60534 2.81385 1.20225 3.21694 1.20225 3.71291V13.9022C1.20225 14.3982 1.60534 14.8013 2.10131 14.8013H12.2906C12.7866 14.8013 13.1897 14.3982 13.1897 13.9022V9.23306C13.1897 8.9019 13.4579 8.63369 13.789 8.63369C14.1202 8.63369 14.3884 8.9019 14.3884 9.23306V13.9022C14.3884 15.059 13.4475 16.0002 12.2906 16.0002Z" fill="#1575C6" />
                                    <path d="M4.44385 9.0085L11.0011 2.45129L13.5476 4.9978L6.99036 11.555L4.44385 9.0085Z" fill="#1575C6" />
                                    <path d="M15.7113 1.43961L14.5605 0.28882C14.1754 -0.0962733 13.5506 -0.0962733 13.1647 0.28882L11.8491 1.60444L14.3956 4.15095L15.7113 2.83533C16.0963 2.45024 16.0964 1.82545 15.7113 1.43961Z" fill="#1575C6" />
                                    <path d="M3.67534 9.9352L3.08272 12.5229C3.02878 12.7597 3.24005 12.971 3.47681 12.917L6.06455 12.3244L3.67534 9.9352Z" fill="#1575C6" />
                                  </svg>
                                </SvgIcon>
                              </IconButton>
                            </Tooltip> :
                            <IconButton
                              className="delete-sec-btn"
                              aria-label="delete"
                              onClick={(event) => handleDeleteEducation(item._id, item.degree_received, event)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M11.0948 2.37734C11.0948 2.22733 11.0353 2.08301 10.9289 1.97715C10.8231 1.87074 10.6787 1.81131 10.5287 1.81131H8.70671L8.44689 0.771503C8.33369 0.317545 7.92557 -0.000564375 7.45803 7.54076e-07H4.54298C4.07543 -0.000565244 3.66732 0.317545 3.55412 0.771503L3.29431 1.81131H1.47228C1.15983 1.81131 0.90625 2.06489 0.90625 2.37734C0.90625 2.68978 1.15983 2.94337 1.47228 2.94337H1.50171C1.46096 3.10186 1.44737 3.26658 1.46096 3.42959L2.14473 10.2668C2.24096 11.2523 3.0702 12.0027 4.06023 12H7.94092C8.93091 12.0028 9.7602 11.2523 9.85642 10.2668L10.5402 3.42901C10.5538 3.266 10.5396 3.10185 10.4994 2.94335H10.5289C10.6789 2.94335 10.8232 2.88392 10.9291 2.77751C11.0355 2.67166 11.0948 2.52733 11.0948 2.37734ZM4.63071 1.13206H7.3697L7.53951 1.8113H4.46087L4.63071 1.13206ZM9.32707 3.05494C9.3916 3.1257 9.4233 3.22135 9.41311 3.31702L8.72933 10.1542C8.69028 10.5601 8.34839 10.8691 7.94085 10.868H4.06015C3.65261 10.8691 3.31072 10.5601 3.27167 10.1542L2.58789 3.3176C2.57827 3.22194 2.6094 3.12684 2.67393 3.05552C2.73846 2.9842 2.82959 2.94345 2.92582 2.94345H9.07529C9.17151 2.94288 9.26255 2.98362 9.32707 3.05494ZM5.09484 4.52832C4.94483 4.52832 4.80051 4.58775 4.69465 4.69416C4.58824 4.80001 4.52881 4.94435 4.52881 5.09434V7.81131C4.52881 8.12375 4.78239 8.37734 5.09484 8.37734C5.40728 8.37734 5.66086 8.12375 5.66086 7.81131V5.09434C5.66086 4.94434 5.60143 4.80001 5.49502 4.69416C5.38917 4.58774 5.24483 4.52832 5.09484 4.52832ZM6.50599 4.69416C6.61185 4.58775 6.75617 4.52832 6.90618 4.52832C7.05617 4.52832 7.20051 4.58774 7.30636 4.69416C7.41277 4.80001 7.4722 4.94434 7.4722 5.09434V7.81131C7.4722 8.12375 7.21862 8.37734 6.90618 8.37734C6.59373 8.37734 6.34015 8.12375 6.34015 7.81131V5.09434C6.34015 4.94435 6.39958 4.80001 6.50599 4.69416Z"
                                  fill="#FF4949"
                                />
                              </svg>
                            </IconButton>}

                          <KeyboardArrowDownOutlinedIcon />
                        </Stack>
                      </Stack>
                    </CardContent>
                  </Card>
                </Box>
              ) : (
                <Box className={showEdu && "update-resume-accord-wrapper"} ref={detailsRef}>
                  <Card className="form-wrapper" variant="outlined">
                    <CardContent sx={{ padding: showEdu && '0!important' }}>
                      <EducationDetails
                        key={item?._id}
                        educationData={educations.find((_, idx) => idx === expandedSection.index)}
                        id={item?._id}
                        onCancel={() => {
                          setShowEducation(false);
                          setSelectedCard(null);
                          localStorage.removeItem('education');
                          onCancel()
                        }}
                        handleArrowClick={onCancel}
                        onEducationDataChange={onHandleDataChange}
                        degreeData={degreeData}
                        checkingOverlapping={checkingOverlapping}
                        showEdu={showEdu}
                        handleShowEdu={handleEducationResume}
                        handleDeleteEdu={handleDeleteEdu}
                      />
                    </CardContent>
                  </Card>
                </Box>
              )}
            </React.Fragment>
          ))}
      </Box>
      {(showEducation && expandedSection?.section === 'education' &&
        (<Box className={showEdu && "update-resume-accord-wrapper"} ref={detailsRef}>
          <Card className="form-wrapper" variant="outlined">
            <CardContent sx={{ padding: showEdu && '0!important' }}>
              <EducationDetails
                onEducationDataChange={onHandleDataChange}
                onCancel={() => {
                  setShowEducation(false);
                  setSelectedCard(null);
                  localStorage.removeItem('education');
                  onCancel();
                }}
                handleArrowClick={onCancel}
                degreeData={degreeData}
                checkingOverlapping={checkingOverlapping}
                showEdu={showEdu}
                handleShowEdu={handleEducationResume}
                handleDeleteEdu={handleDeleteEdu}
              />
            </CardContent>
          </Card>
        </Box>
        ))}
      <Box mt={1.5} >
        {(!selectedCard || selectedCard?.status || expandedSection?.section !== 'education') &&
          <Button
            className="add-btn"
            disableRipple
            startIcon={<AddIcon />}
            disabled={educations.some(edu => !edu?._id)}
            onClick={() => {
              isMobile ? handleOpenDrawer(true) : handleAddNewEdu();
            }}>
            Add Education
          </Button>
        }
      </Box>
      {openDetailsDrawer && (
        <Drawer className="mob-side-panel" open={openDetailsDrawer} anchor={'right'} >
          <EducationDetailsMobile
            educationData={educationData}
            id={selectedCard?._id}
            onCancel={() => {
              setShowEducation(false);
              setSelectedCard(null);
              localStorage.removeItem('education');
              onCancel();
              setOpenDetailsDrawer(false);
            }}
            handleArrowClick={() => {
              onHandleDataChange(true);
              setOpenDetailsDrawer(false);
              onCancel();
            }}
            onEducationDataChange={onHandleDataChange}
            degreeData={degreeData}
            handleDeleteEducation={handleDeleteEducation}
            selectedCard={selectedCard}
            checkingOverlapping={checkingOverlapping} />
        </Drawer>
      )}
      {isMobile ?
        <Drawer
          anchor="bottom"
          open={open}
          onClose={handleClose}
          className="forgot-password-dialog otp-dialog-wrapper"
        >
          <RemoveEducation onClose={handleClose} onRemove={handleDeleteAction} title={title} />

        </Drawer> :
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="otp-dialog-wrapper"
        >
          <RemoveEducation onClose={handleClose} onRemove={handleDeleteAction} title={title} />
        </Dialog>}
    </Box >
  );
};

export default EducationSection;

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, FormControl, Link, Grid, IconButton, InputAdornment, OutlinedInput, SvgIcon, Button, Typography, Stack, Container, Icon, TextField, InputLabel, Divider, FormControlLabel, Checkbox, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material'
const loginLogo = `${process.env.REACT_APP_HOMEPAGE}assets/TJ-Logo-LightBG-withTagline 1.svg`;
import { forgotPassword, verifyOTP, changeNewPassword } from 'api/login';
import { useSnackbar } from 'components/snackbar';
import SlickSlider from 'react-slick';
// import ResumeOnboarded from '../ResumeOnBoarded/ResumeOnboarded.js';
const ForgotPassword = () => {
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { method } = location.state;
    const [open, setOpen] = useState(false);
    const [emailOrPhone, setEmailOrPhone] = useState('');
    const [error, setError] = useState('');
    const [disable, setDisable] = useState(false);
    const [showFlag, setShowFlag] = useState(false);

    const handleOpenOtpDialog = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    // const [openOnboardedResume, setOpenOnboardedResume] = useState(false);
    // const handleOpenOnboardedResumeDialog = () => {
    //     setOpenOnboardedResume(true);
    // };
    // const handleCloseOnboardedResumeDialog = () => {
    //     setOpenOnboardedResume(false);
    // };
    // show hide flag
    const handleOnBlurFlag = () => {
        if (emailOrPhone === '') {
            setShowFlag(false);
        }
    }
    var settings = {
        autoplay: true, 
        fade: true,
        autoplaySpeed: 3000,
        speed: 1000,
        prevArrow: false,
        nextArrow: false
      };
      
    const handleFlag = (e) => {
        const value = event.target.value;
        setEmailOrPhone(value);

        const phoneRegex = /^\d+$/; // Regex to check if input is a phone number
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regex to check if input is a valid email

        const isPhoneNumber = phoneRegex.test(value);
        setShowFlag(isPhoneNumber);
    }

    const handleChange = (event) => {
        const value = event.target.value;
        setEmailOrPhone(value);

        const phoneRegex = /^\d+$/; // Regex to check if input is a phone number
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regex to check if input is a valid email

        const isPhoneNumber = phoneRegex.test(value);
        setShowFlag(isPhoneNumber);

        if (isPhoneNumber) {
            setError('');
        } else if (emailRegex.test(value)) {
            setError('');
        } else {
            setError('Please enter a valid email id or WhatsApp number.');
        }
    };


    const onSubmit = async () => {
        const formData = { emailOrPhone, method };

        // Phone number regex (for 10 digits)
        const phoneRegex = /^\d{10}$/;

        // Email regex
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // Validate phone or email
        if (!phoneRegex.test(emailOrPhone) && !emailRegex.test(emailOrPhone)) {
            setDisable(false);
            setError('Please enter a valid phone number or email address.');
            return;
        }
        let response
        if (formData?.emailOrPhone?.includes('@')) {
            response = await forgotPassword({ email: emailOrPhone.toLowerCase(), method: method });
        } else {
            response = await forgotPassword({ phone: emailOrPhone.toLowerCase(), method: method });
        }
        if (response?.success && response.data) {
            setDisable(true);
            enqueueSnackbar(response.message, { variant: 'success' });
         
            if (method === 'otp') {
                navigate('/enter-otp', { state: { method, user: response.data } });
            } else if (method === 'link') {
                navigate('/');
            }
            // setEmailOrPhone('');
            // setShowFlag(false);


        } else {
            enqueueSnackbar(response.message, { variant: 'error' });
            // setEmailOrPhone('');
            // setShowFlag(false);
        }
        setDisable(false);
    };

    return (
        <Box sx={{ overflow: 'hidden' }}>
            <Box>
                <Grid container justifyContent={'center'} alignItems={'center'}>
                    <Grid item xs={12} md={6} lg={6} >
                        <Box className="login-page-wrapper">
                            <Box className="login-content-wrapper" sx={{ padding: { xs: '40px 20px!important', md: '40px!important' } }}>
                                {/* <Box sx={{marginBottom: {xs: '16px', md: '80px'}}}>
                                    <Link className="fogot-pass-link" 
                                    component={RouterLink} to="/" 
                                    underline="none" >
                                        <IconButton aria-label="delete" color="primary" sx={{p: 0}}>
                                            <SvgIcon sx={{width: '20px', height: '20px'}}>      
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                                    <path d="M17.6994 9.87063H3.93124L8.27907 5.76053C8.55081 5.50365 8.55081 5.07552 8.27907 4.81864C8.00733 4.56176 7.55443 4.56176 7.28269 4.81864L1.84791 10.0419C1.75733 10.1275 1.66675 10.2988 1.66675 10.47C1.66675 10.6413 1.75733 10.8125 1.84791 10.8981L7.28269 16.1214C7.37327 16.207 7.55443 16.2926 7.73559 16.2926C7.91675 16.2926 8.09791 16.207 8.18849 16.1214C8.46023 15.8645 8.46023 15.4364 8.18849 15.1795L3.84066 11.0694H17.6088C17.9711 11.0694 18.3334 10.8125 18.3334 10.3844C18.3334 9.95625 18.0617 9.87063 17.6994 9.87063Z" fill="#002149"/>
                                                </svg>
                                            </SvgIcon>     
                                        </IconButton>
                                    </Link>
                                </Box> */}

                                <Box className="tj-logo" sx={{ marginBottom: { xs: '24px', md: '40px' } }}>
                                    <Box
                                        component="img"
                                        alt="Talent Junction"
                                        src={loginLogo}
                                    />
                                </Box>

                                {/* Register form block */}
                                <Box className="register-form-wrapper forgot-password-form">
                                    <Box sx={{ marginBottom: { xs: '24px', md: '40px' } }}>
                                        <Stack spacing={'4px'}>
                                            <Typography className="title">
                                                {method === 'otp' && "Login using OTP"}
                                                {method === 'link' && "Reset Password"}
                                                {!method && "No method specified. Please select any method."}
                                            </Typography>
                                            <Typography className="info-text">
                                                {method === 'otp' && "Please enter your registered WhatsApp number or email to receive OTP"}
                                                {method === 'link' && `Please enter your registered WhatsApp number or email. Reset link will be sent to your registered WhatsApp number and email Id.`}
                                                {!method && "No option specified. Please select any method."}
                                            </Typography>
                                        </Stack>
                                    </Box>

                                    <Stack spacing={2.5}>
                                        <FormControl size="small" fullWidth
                                            // onClick={handleFlag} 
                                            sx={{
                                                display: 'inline-flex',
                                                flexDirection: 'column',
                                                position: 'relative',
                                                minWidth: '0px',
                                                padding: '0px',
                                                margin: '0px',
                                                border: '0px',
                                                verticalAlign: 'top',
                                                width: '100%',
                                                height: '67px',
                                            }} >
                                            <InputLabel htmlFor="outlined-adornment-password">Email Id / WhatsApp Number</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-password"
                                                label='Email Id / WhatsApp Number'
                                                value={emailOrPhone}
                                                onFocus={handleFlag}
                                                onChange={handleChange}
                                                onBlur={handleOnBlurFlag}
                                                inputProps={{
                                                    style: {
                                                        paddingLeft: showFlag ? '80px' : '16px',
                                                    },
                                                }}
                                            />
                                            {error && (
                                                <Box sx={{ marginBottom: "-1px" }}>
                                                    <Typography className="error-text" size="small" component="p" style={{ color: "#FF4842", fontSize: "0.75rem", fontFamily: "Public Sans, sans-serif", fontWeight: 400, marginLeft: "14px" }}>
                                                        {error}
                                                    </Typography>
                                                </Box>
                                            )}

                                            {showFlag &&
                                                <InputAdornment position="start">
                                                    <Box className="iti-flag-container" sx={{ marginTop: "-3px" }}>
                                                        <Stack direction="row" alignItems={'center'} spacing={1}>
                                                            <Box className="iti-flag" ></Box>
                                                            <Box className="selected-dial-code">+91</Box>
                                                            <Divider orientation="vertical" variant="middle" flexItem />
                                                        </Stack>
                                                    </Box>
                                                </InputAdornment>
                                            }
                                        </FormControl>
                                        <Button
                                            type='submit'
                                            variant='contained'
                                            size='large'
                                            disableElevation
                                            className="login-btn"
                                            disabled={disable}
                                            onClick={() => {
                                                setDisable(true);
                                                onSubmit();
                                            }}
                                        >
                                            {method === 'otp' && " Send OTP"}
                                            {method === 'link' && "Send Reset Password Link"}
                                            {!method && "No method specified. Please select any method."}
                                        </Button>
                                        <Box>
                                            <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" alignItems="center">
                                                <Typography component="p" sx={{ fontSize: "14px" }}>
                                                    Already registered?&nbsp;
                                                    <Link onClick={() => navigate('/')} sx={{ cursor: 'pointer' }} underline="none">
                                                        Login
                                                    </Link>
                                                </Typography>

                                                <Typography component="p" sx={{ fontSize: "14px" }}>
                                                    Haven’t registered?&nbsp;
                                                    <Link onClick={() => navigate('/register')} sx={{ cursor: 'pointer' }} underline="none">
                                                        Register
                                                    </Link>
                                                </Typography>
                                            </Stack>
                                        </Box>

                                    </Stack>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={6} lg={6} sx={{ display: { xs: 'none', md: 'block' } }}>
                    <div className='banner-image'>
          <div className='banner-text' style={{ marginLeft: "50px" }}>
            <Typography mb={3} className="title" component="h1" sx={{color:"white", fontSize : '35px', display : 'inline-block', marginTop : '65vh' }}>
              Exclusive&nbsp;
              <SlickSlider {...settings} className="text-slider" style={{
                display: "inline-block",
                verticalAlign: "top",
                width: "280px",
                height: "100px",
                overflow: "hidden"
              }}>
                <div className='slick-slide'>Jobs</div>
                <div className='slick-slide'>Resume</div>
                <div className='slick-slide'>Learning</div>
              </SlickSlider>
              <br/><span className='focus'>Platform For</span> Finance <br/>Professionals
            </Typography>
            <Box mt={0.5} mb={1} className="work-type" sx={{ color: 'white' }}>
              <Stack direction="row" alignItems={'center'} spacing={1}>
                <Typography variant="p" className="city">Find the perfect next job</Typography>
                <Typography variant="body1">•</Typography>
                <Typography variant="p" className="city">Chart your future career journey</Typography>
                <Typography variant="body1">•</Typography>
                <Typography variant="p" className="city">Achieve your pinnacle</Typography>
              </Stack>
            </Box>
          </div>
        </div>
                    </Grid>
                </Grid>
            </Box>
             
        </Box>
    )
}

export default ForgotPassword
